import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  'dialog',
  'overlay',
  'closeButton',
  'header',
  'body',
  'footer',
]);

const sizes = {
  md: definePartsStyle({
    dialog: {
      maxWidth: '30rem',
    },
  }),
};

export const drawerTheme = defineMultiStyleConfig({
  baseStyle: {
    dialog: {
      borderRadius: '0.5rem 0 0 0.5rem',
      padding: '0 !important',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.1)',
    },
    closeButton: {
      // Base styles.
      backgroundColor: 'var(--primary-400)',
      borderRadius: '0.5rem 0 0 0.5rem',
      boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
      color: 'var(--white) !important',
      height: 'fit-content !important',
      paddingBlock: '1rem !important',
      paddingInline: '0.25rem !important',
      _hover: {
        backgroundColor: 'var(--primary-800)',
      },
      // Positioning.
      transform: 'auto',
      translateX: '-100%',
      left: 0,
      top: '0.625rem !important',
      right: 'initial !important',
      position: 'absolute',
    },
    header: {
      color: 'unset !important',
      padding: '1.5rem 1rem !important',
      margin: '0 !important',
      borderBottom: `1px solid var(--gray-200) !important`,
    },
    body: {
      padding: '1.5rem 1rem !important',
    },
    footer: {
      borderTop: 'none',
      padding: '1.5rem 1rem !important',
    },
  },
  defaultProps: {
    size: 'md',
  },
  sizes,
});
