import { Button, GridItem, Icon, VisuallyHidden } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

import { Detection, DetectionVote } from '../../../@types/api/v0/rest/Detection';
import theme from '../../../theme';
import { Degree360Icon, DislikeIcon, LikeIcon } from '../../Icon';

export interface SearchDetectionItemProps {
  /** The detection being shown in this component. */
  detection: Detection;
  /**
   * Flag indicating whether or not this detection is the currently-selected detection. If true, the detection will be
   * rendered larger than the base size.
   */
  isActive?: boolean;
  /** Handler called when the user clicks the "View 360" button on a search detection. */
  on360Click: (detection: Detection) => void;
  /** Handler called when the user selects a detection. */
  onDetectionSelect: (detection: Detection) => void;
  /** Handler called when the user votes on a detection. A vote value of `L` means "like", `D` means "dislike". */
  onDetectionVote: (detection: Detection, vote: 'L' | 'D') => void;
  /** The user's most recent vote on the detection, if present. */
  userVote?: DetectionVote;
}

const SearchDetectionItem = (props: SearchDetectionItemProps) => {
  const { detection, isActive, on360Click, onDetectionSelect, onDetectionVote, userVote } = props;

  const ref = useRef<HTMLDivElement>(null);

  const handleVote = (vote: 'L' | 'D') => {
    if (vote === userVote?.vote_type) {
      return;
    }

    onDetectionVote(detection, vote);
  };

  useEffect(() => {
    if (isActive) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [isActive]);

  const wasLiked = userVote?.vote_type === 'L';
  const wasDisliked = userVote?.vote_type === 'D';

  return (
    <GridItem
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      borderRadius={theme.radii.card}
      gridColumn={isActive ? 'span 2' : undefined}
      gridRow={isActive ? 'span 2' : undefined}
      position="relative"
      ref={ref}
      style={{ backgroundImage: `url(${detection.image_name})` }}
    >
      <Button
        backgroundColor="transparent"
        height="100%"
        onClick={() => onDetectionSelect(detection)}
        padding={0}
        width="100%"
        _hover={{ backgroundColor: 'transparent' }}
      >
        <VisuallyHidden>{isActive ? 'Open in 360 degree viewer' : 'Expand'}</VisuallyHidden>
      </Button>
      <Button
        backgroundColor={theme.colors.white}
        border="1px solid var(--primary-500)"
        borderRadius="100%"
        color={theme.colors.brand.primary[500]}
        height="2.375rem"
        lineHeight={1}
        minWidth="2.375rem"
        onClick={(event) => {
          event.stopPropagation();
          on360Click(detection);
        }}
        padding="0.375rem"
        position="absolute"
        right="0.5rem"
        top="0.5rem"
        variant="unstyled"
        width="2.375rem"
        _hover={{
          backgroundColor: theme.colors.white,
          borderColor: theme.colors.brand.primary[700],
          color: theme.colors.brand.primary[700],
        }}
      >
        <Icon aria-label="Open in 360 degree viewer" as={Degree360Icon} height="1.5rem" width="1.5rem" />
      </Button>
      <Button
        backgroundColor={wasLiked ? theme.colors.brand.primary[500] : theme.colors.white}
        borderColor={wasLiked ? 'transparent' : theme.colors.brand.gray[600]}
        borderRadius="100%"
        borderStyle="solid"
        borderWidth="1px"
        bottom="0.5rem"
        color={wasLiked ? theme.colors.white : theme.colors.brand.gray[600]}
        height="2.125rem"
        lineHeight={1}
        minWidth="2.125rem"
        onClick={(event) => {
          event.stopPropagation();
          handleVote('L');
        }}
        padding="0.375rem"
        position="absolute"
        right="3rem"
        variant="unstyled"
        width="2.125rem"
        _hover={{
          backgroundColor: wasLiked ? theme.colors.brand.primary[800] : theme.colors.white,
          borderColor: wasLiked ? 'transparent' : theme.colors.brand.gray[900],
          color: wasLiked ? theme.colors.white : theme.colors.brand.gray[900],
        }}
      >
        <Icon aria-label="Like" as={LikeIcon} height="1.25rem" width="1.25rem" />
      </Button>
      <Button
        backgroundColor={wasDisliked ? theme.colors.brand.primary[500] : theme.colors.white}
        borderColor={wasDisliked ? 'transparent' : theme.colors.brand.gray[600]}
        borderRadius="100%"
        borderStyle="solid"
        borderWidth="1px"
        bottom="0.5rem"
        color={wasDisliked ? theme.colors.white : theme.colors.brand.gray[600]}
        height="2.125rem"
        lineHeight={1}
        minWidth="2.125rem"
        onClick={(event) => {
          event.stopPropagation();
          handleVote('D');
        }}
        padding="0.375rem"
        position="absolute"
        right="0.5rem"
        variant="unstyled"
        width="2.125rem"
        _hover={{
          backgroundColor: wasDisliked ? theme.colors.brand.primary[800] : theme.colors.white,
          borderColor: wasDisliked ? 'transparent' : theme.colors.brand.gray[900],
          color: wasDisliked ? theme.colors.white : theme.colors.brand.gray[900],
        }}
      >
        <Icon aria-label="Dislike" as={DislikeIcon} height="1.25rem" width="1.25rem" />
      </Button>
    </GridItem>
  );
};

export default SearchDetectionItem;
