import { tableAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  th: {
    padding: ['.5rem .625rem !important', '.5rem .625rem !important', '0.875rem 1rem !important'],
  },
  td: {
    padding: '0 !important',
  },
  table: {
    fontVariantNumeric: 'normal',
  },
});

export const tableTheme = defineMultiStyleConfig({ baseStyle });
