import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Progress,
  Select,
  Text,
  VisuallyHidden,
  useDisclosure,
} from '@chakra-ui/react';
import { CellContext, ColumnDef, FilterFn, createColumnHelper } from '@tanstack/react-table';
import { compareAsc, format } from 'date-fns';
import { ReactElement, useCallback, useMemo, useState } from 'react';

import { UserProfile } from '../../@types/api/v0/rest/UserProfile';
import { PortfolioProject } from '../../@types/api/v1/bespoke/PortfolioDashboard';
import { Caption, Chip, FormControl, SearchInput } from '../../components';
import { DataTable } from '../../components/DataTable/DataTable';
import { TableCell } from '../../components/DataTable/TableCell';
import { ViewUsersDrawerContainer } from '../../components/Drawers/UserManagement/ViewUsersDrawerContainer';
import { AddIcon, TriangleUpIcon, WarningIcon } from '../../components/Icon';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import InviteUserModalContainer from '../../components/UserManagement/InviteUserModal/InviteUserModalContainer';
import { PendoTopic } from '../../constants/analytics';
import { stateAbbreviations } from '../../constants/stateAbbreviations';
import theme from '../../theme';
import { getRoundedProgressValue } from '../../utils/progressUtils';
import { ActionsColumn } from './ActionsColumn';
import { PercentChange } from './PercentChange';
import { ProjectDrawerContainer } from './ProjectDrawerContainer';
import { ProjectInfoWithThumbnail } from './ProjectInfoWithThumbnail';
import { ProjectUpdatedColumn } from './ProjectUpdatedColumn';

import classes from './ProjectList.module.scss';

export interface ProjectListPageProps {
  canInviteUsers?: boolean;
  projects: PortfolioProject[];
  userProfile?: UserProfile;
}

/**
 * TODO: we should strongly consider adding a "submit" or "search" button to the search input,
 * as this would eliminate the need for a fully-optimized + debounced input and make it
 * much more explicit to the user when the rows in the table will actually be filtered
 */
const ProjectListPage = ({ canInviteUsers, projects, userProfile }: ProjectListPageProps): ReactElement => {
  interface GlobalFilterState {
    searchText?: string;
    state?: string;
  }
  const [globalFilter, setGlobalFilter] = useState<GlobalFilterState>();

  const [selectedProject, setSelectedProject] = useState<PortfolioProject>();

  /** Start of User Management stuff */
  const {
    isOpen: isViewUsersDrawerOpen,
    onOpen: onViewUsersDrawerOpen,
    onClose: onViewUsersDrawerClose,
  } = useDisclosure();

  const { isOpen: isProjectDrawerOpen, onOpen: onProjectDrawerOpen, onClose: onProjectDrawerClose } = useDisclosure();

  const {
    isOpen: isInviteUserModalOpen,
    onOpen: onOpenInviteUserModal,
    onClose: onCloseInviteUserModal,
  } = useDisclosure();

  const handleInviteUserClick = () => {
    onOpenInviteUserModal();
  };

  const handleViewUsersClick = useCallback(
    (project: PortfolioProject) => {
      onViewUsersDrawerOpen();
      setSelectedProject(project);
    },
    [onViewUsersDrawerOpen]
  );
  /** End of User Management stuff */

  const clearFilters = () => {
    setGlobalFilter(undefined);
  };

  const handleSearchInputChange = useCallback(
    (searchText: string) => {
      setGlobalFilter({ ...globalFilter, searchText });
    },
    [globalFilter]
  );

  /** Filter function to be used when globally filtering the table (i.e. not filtering on any specific column, but across all columns simultaneously) */
  const globalFilterFn: FilterFn<PortfolioProject> = (row, columnId, globalFilter: GlobalFilterState) => {
    const { name, address_line, city, state, zip_code } = row.original;

    if (globalFilter.state && state !== globalFilter.state) {
      return false;
    }

    if (!globalFilter.searchText) {
      return true;
    }

    const searchList = [name, `${address_line}, ${city}, ${state} ${zip_code}`];

    return searchList.some((word) => {
      const { searchText } = globalFilter;

      if (!searchText) {
        return false;
      }

      return word && word.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  const stateFilterOptions = useMemo(() => {
    const uniqueStates: string[] = [];
    for (const row of projects) {
      if (!uniqueStates.includes(row.state)) {
        uniqueStates.push(row.state);
      }
    }

    uniqueStates.sort();
    return uniqueStates;
  }, [projects]);

  const portfolioData: PortfolioProject[] = [...projects];

  const projectLeadGenUrl = useMemo(() => {
    if (!userProfile) {
      return window.PROJECT_LEAD_GEN_URL;
    }

    // Note: These field IDs should be fixed. A value must be passed for every field in order for FormTitan to
    // pre-populate anything.
    const projectLeadGenSearchParams = new URLSearchParams();
    projectLeadGenSearchParams.append('vfn', userProfile.first_name);
    projectLeadGenSearchParams.append('vln', userProfile.last_name);
    projectLeadGenSearchParams.append('ve', userProfile.email);
    projectLeadGenSearchParams.append('vp', userProfile.phone || '+1');

    return `${window.PROJECT_LEAD_GEN_URL}?${projectLeadGenSearchParams.toString()}`;
  }, [userProfile]);

  const columnHelper = createColumnHelper<PortfolioProject>();

  const columns: ColumnDef<PortfolioProject, any>[] = [
    columnHelper.accessor('name', {
      header: 'Building Name',
      cell: (cell) => (
        <TableCell>
          <ProjectInfoWithThumbnail
            projectId={cell.row.original.id}
            projectName={cell.row.original.name}
            projectAddress={{
              street: cell.row.original.address_line,
              city: cell.row.original.city,
              state: cell.row.original.state,
              zip: cell.row.original.zip_code,
            }}
            thumbnail={cell.row.original.thumbnail}
          />
        </TableCell>
      ),
      sortingFn: 'text',
      size: 600,
    }),
    columnHelper.accessor('momentum', {
      header: 'Momentum',
      cell: (cell: CellContext<PortfolioProject, boolean | null>) => {
        const momentum = cell.getValue();

        const handleClick = () => {
          onProjectDrawerOpen();
          setSelectedProject(cell.row.original);
        };

        return (
          <TableCell onClick={handleClick} pendoTopic={PendoTopic.PORTFOLIO} pendoLabel="Momentum cell">
            {momentum === true && (
              <>
                <Icon
                  aria-hidden
                  as={TriangleUpIcon}
                  color={theme.colors.brand.secondary[500]}
                  height="1.5rem"
                  width="1.5rem"
                />
                <VisuallyHidden>Positive momentum</VisuallyHidden>
              </>
            )}
            {momentum === false && (
              <>
                <Icon
                  aria-hidden
                  as={WarningIcon}
                  color={theme.colors.brand.secondary[500]}
                  height="1.5rem"
                  width="1.5rem"
                />
                <VisuallyHidden>Delay warning</VisuallyHidden>
              </>
            )}
            {momentum === null && <Text fontSize="0.875rem">-</Text>}
          </TableCell>
        );
      },
      sortingFn: (rowA, rowB, columnId) => {
        const rowAValue: boolean | null = rowA.getValue(columnId);
        const rowBValue: boolean | null = rowB.getValue(columnId);

        const rowAValueScored = rowAValue === false ? -1 : rowAValue === true ? 1 : 0;
        const rowBValueScored = rowBValue === false ? -1 : rowBValue === true ? 1 : 0;
        const difference = rowAValueScored - rowBValueScored;
        if (difference !== 0) {
          return difference;
        }

        // If the scored momentum values are tied, perform a secondary sort by last walkthrough capture time.
        return new Date(rowA.getValue('updated')).valueOf() - new Date(rowB.getValue('updated')).valueOf();
      },
      sortDescFirst: false,
      size: 50,
    }),
    columnHelper.accessor('updated', {
      header: 'Last Walkthrough',
      cell: (cell) => (
        <TableCell>
          <ProjectUpdatedColumn updatedDate={cell.row.original.updated} />
        </TableCell>
      ),
      sortingFn: (rowA, rowB, columnId) =>
        new Date(rowA.getValue(columnId)).valueOf() - new Date(rowB.getValue(columnId)).valueOf(),
      size: 150,
      sortDescFirst: true,
    }),
    columnHelper.accessor('total_progress', {
      header: 'Estimated Progress',
      cell: (cell) => {
        const progress = cell.row.original.total_progress;
        const roundedProgress = cell.row.original.progress_tracking_enabled ? getRoundedProgressValue(progress) : 0;
        const displayValue =
          cell.row.original.progress_tracking_enabled && typeof progress === 'number' && Number.isFinite(progress)
            ? `${roundedProgress}%`
            : '-';

        const handleClick = () => {
          onProjectDrawerOpen();
          setSelectedProject(cell.row.original);
        };

        return (
          <TableCell
            onClick={handleClick}
            pendoTopic={PendoTopic.PORTFOLIO}
            pendoLabel="Open progress drawer from estimated progress"
          >
            <Flex flexFlow="column nowrap" height="100%" justifyContent="center" flex={1}>
              <Text fontSize="0.875rem">{displayValue}</Text>
              <Progress
                aria-hidden
                value={roundedProgress ?? 0}
                flex={1}
                variant="purpleProgress"
                marginTop="0.25rem"
              />
            </Flex>
          </TableCell>
        );
      },
      sortingFn: (rowA, rowB) => {
        const rowAValue: number | null = rowA.original.total_progress;
        const rowBValue: number | null = rowB.original.total_progress;

        if (rowAValue === rowBValue) {
          return 0;
        }
        if (rowAValue === null || !rowA.original.progress_tracking_enabled) {
          return -1;
        }
        if (rowBValue === null || !rowB.original.progress_tracking_enabled) {
          return 1;
        }
        return rowAValue - rowBValue;
      },
      sortDescFirst: true,
      size: 150,
    }),
    columnHelper.accessor('percent_change', {
      header: () => (
        <Tooltip width="100%" label="Last 30 days" openDelay={1000} placement="top">
          <Text fontWeight={700} width="100%">
            % Change
          </Text>
        </Tooltip>
      ),
      cell: (cell) => {
        const handleClick = () => {
          onProjectDrawerOpen();
          setSelectedProject(cell.row.original);
        };

        return (
          <TableCell
            onClick={handleClick}
            pendoTopic={PendoTopic.PORTFOLIO}
            pendoLabel="Open progress drawer from percent change"
          >
            <Box fontSize="0.875rem">
              <PercentChange
                isPlainText
                value={cell.row.original.progress_tracking_enabled ? cell.getValue<number | null>() : null}
              />
            </Box>
          </TableCell>
        );
      },
      sortingFn: (rowA, rowB) => {
        const rowAValue: number | null = rowA.original.percent_change;
        const rowBValue: number | null = rowB.original.percent_change;

        if (rowAValue === rowBValue) {
          return 0;
        }
        if (rowAValue === null || !rowA.original.progress_tracking_enabled) {
          return -1;
        }
        if (rowBValue === null || !rowB.original.progress_tracking_enabled) {
          return 1;
        }
        return rowAValue - rowBValue;
      },
      sortDescFirst: true,
      size: 100,
    }),
    columnHelper.accessor('baseline_completion_date', {
      header: 'Baseline Completion',
      cell: (cell) => {
        const completionDate: string | null = cell.getValue();

        const handleClick = () => {
          onProjectDrawerOpen();
          setSelectedProject(cell.row.original);
        };

        return (
          <TableCell onClick={handleClick} pendoTopic={PendoTopic.PORTFOLIO} pendoLabel="Baseline completion cell">
            <Text fontSize="0.875rem">
              {completionDate ? format(new Date(`${completionDate} 12:00:00`), 'MMM d, yyyy') : '-'}
            </Text>
          </TableCell>
        );
      },
      sortingFn: (rowA, rowB) => {
        const rowAValue: string | null = rowA.original.baseline_completion_date;
        const rowBValue: string | null = rowB.original.baseline_completion_date;

        if (rowAValue === rowBValue) {
          return 0;
        }
        if (rowAValue === null) {
          return 1;
        }
        if (rowBValue === null) {
          return -1;
        }
        return compareAsc(new Date(rowAValue), new Date(rowBValue));
      },
      sortDescFirst: false,
      sortUndefined: 'last',
      size: 100,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (cell) => (
        <TableCell>
          <ActionsColumn onClickViewUsers={() => handleViewUsersClick(cell.row.original)} />
        </TableCell>
      ),
      size: 50,
    }),
  ];

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" marginBlockEnd="1rem">
        <Heading as="h1" size="lg">
          My Projects
        </Heading>
        <Box>
          <Button
            as="a"
            data-pendo-topic={PendoTopic.PORTFOLIO}
            data-pendo-label="Add project"
            href={projectLeadGenUrl}
            rel="noreferrer"
            target="_blank"
            padding="0.625rem 1.25rem"
            size="md"
            variant="mediumEmphasisV2"
          >
            <Icon aria-hidden as={AddIcon} marginRight="0.25rem" fontSize="1.25rem" />
            New project
          </Button>
          {canInviteUsers && (
            <Button
              data-pendo-label="Open invite modal from from portfolio page"
              data-pendo-topic={PendoTopic.USER_MANAGEMENT}
              marginInlineStart="0.5rem"
              padding="0.625rem 1.25rem"
              onClick={handleInviteUserClick}
              size="md"
              variant="highEmphasisV2"
            >
              Invite users
            </Button>
          )}
        </Box>
      </Flex>
      <SearchInput
        filterable
        filterHeading="Filter by"
        filterList={
          <>{globalFilter?.state && <Chip>{stateAbbreviations[globalFilter.state] ?? globalFilter.state}</Chip>}</>
        }
        filterPopoverBody={
          <>
            <FormControl>
              <Caption as={FormLabel}>State or Province</Caption>
              <Select
                className={classes.stateSelect}
                data-pendo-label="Filter by state"
                data-pendo-topic={PendoTopic.PORTFOLIO}
                onChange={(event) => {
                  setGlobalFilter({ ...globalFilter, state: event.target.value });
                }}
                role="menu"
                value={globalFilter?.state}
              >
                <option value="">All states/provinces</option>
                {stateFilterOptions.map((state) => (
                  <option key={state} value={state}>
                    {stateAbbreviations[state] ?? state}
                  </option>
                ))}
              </Select>
            </FormControl>
          </>
        }
        inputProps={{ 'data-pendo-label': 'Search projects', 'data-pendo-topic': 'portfolio' }}
        onChange={handleSearchInputChange}
        onClearFilters={clearFilters}
        placeholder="Search for projects"
        showClearButton={Boolean(globalFilter?.searchText) || Boolean(globalFilter?.state)}
        value={globalFilter?.searchText ?? ''}
        variant="light"
      />
      <Box className={classes.tableWrapper}>
        <DataTable
          data={portfolioData}
          columns={columns}
          shouldUsePagination
          pageSize={30}
          defaultSort={{ id: 'updated', desc: true }}
          paginationRowUnit="projects"
          globalFilterFn={globalFilterFn}
          globalFilter={globalFilter}
        />
      </Box>
      {canInviteUsers && <InviteUserModalContainer isOpen={isInviteUserModalOpen} onClose={onCloseInviteUserModal} />}
      {selectedProject && (
        <>
          <ViewUsersDrawerContainer
            isOpen={isViewUsersDrawerOpen}
            onClose={onViewUsersDrawerClose}
            project={selectedProject}
          />
          <ProjectDrawerContainer
            isOpen={isProjectDrawerOpen}
            onClose={onProjectDrawerClose}
            project={selectedProject}
          />
        </>
      )}
    </>
  );
};

export default ProjectListPage;
