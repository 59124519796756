import { Stat, StatLabel, StatNumber } from '@chakra-ui/react';

import { PercentChange } from '../../pages/ProjectList/PercentChange';

export interface PercentChangeStatProps {
  /** Value to display. */
  value: number | null;
}

export const PercentChangeStat = ({ value }: PercentChangeStatProps) => {
  return (
    <Stat>
      <StatLabel>% Change</StatLabel>
      <StatNumber>
        <PercentChange isPlainText value={value} />
      </StatNumber>
    </Stat>
  );
};
