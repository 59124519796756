import classNames from 'classnames';

import { Button } from '../';
import { CollapseIcon, ExpandIcon } from '../Icon';

import commonClasses from '../../Common.module.scss';
import classes from './FullScreenToggle.module.scss';

interface Props {
  full: boolean;
  onClick: () => void;
}

const FullScreenToggle = ({ onClick, full }: Props) => {
  return (
    <div
      className={classNames(commonClasses.view360ButtonGroup, classes.buttonGroup, {
        [classes.collapse]: full,
      })}
    >
      <Button
        data-pendo-label={full ? 'Collapse' : 'Expand'}
        data-pendo-topic="minimap"
        onClick={onClick}
        variant="view360"
      >
        {full ? <CollapseIcon aria-label="Collapse" /> : <ExpandIcon aria-label="Expand" />}
      </Button>
    </div>
  );
};

export default FullScreenToggle;
