import { ButtonProps, Icon, Tooltip } from '@chakra-ui/react';
import { HTMLAttributes, ReactElement } from 'react';

import { Button } from '../';
import { MinusIcon, PlusIcon } from '../Icon';

interface PendoAttributes extends HTMLAttributes<HTMLDivElement> {
  'data-pendo-label'?: string;
  'data-pendo-topic'?: string;
}

interface Props {
  buttonProps?: ButtonProps & PendoAttributes;
  disabled: boolean;
  onClick: () => void;
}

export const ZoomOut = ({ buttonProps, disabled, onClick }: Props): ReactElement => {
  return (
    <Tooltip hasArrow label="Zoom Out" placement="left">
      <Button
        data-pendo-label={buttonProps?.['data-pendo-label']}
        data-pendo-topic={buttonProps?.['data-pendo-topic']}
        isDisabled={disabled}
        id="zoom-out-button"
        onClick={() => onClick()}
        variant="view360"
      >
        <Icon aria-label="Zoom out" as={MinusIcon} fontSize="1.25rem" />
      </Button>
    </Tooltip>
  );
};

export const ZoomIn = ({ buttonProps, disabled, onClick }: Props): ReactElement => {
  return (
    <Tooltip hasArrow label="Zoom In" placement="left">
      <Button
        data-pendo-label={buttonProps?.['data-pendo-label']}
        data-pendo-topic={buttonProps?.['data-pendo-topic']}
        isDisabled={disabled}
        id="zoom-in-button"
        onClick={() => onClick()}
        variant="view360"
      >
        <Icon aria-label="Zoom in" as={PlusIcon} fontSize="1.25rem" />
      </Button>
    </Tooltip>
  );
};
