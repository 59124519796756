import { ReactElement, ReactNode } from 'react';

import classes from './MessageCard.module.scss';

export interface MessageCardProps {
  title: string;
  titleAs?: ((props: { className?: string | undefined; children?: ReactNode }) => ReactElement) | string;
  children?: ReactNode;
}

const MessageCard = ({ children, title, titleAs = 'h1' }: MessageCardProps): ReactElement => {
  const TitleTagName = titleAs;

  return (
    <div className={classes.card}>
      <TitleTagName>{title}</TitleTagName>
      {children}
    </div>
  );
};

export default MessageCard;
