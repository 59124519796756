import { HTTP_METHOD, HTTP_MODE } from '../@types/Http';
import { getJWTAccessToken } from '../api';
import { store } from '../store';

/**
 * Convert an arbitrary object to a `FormData` instance. This implementation does not index array elements. Given a key
 * `users` whose value is an array of `number` elements, the appended form data will have shape:
 *
 * ```
 * users[]: 1
 * users[]: 2
 * ```
 *
 * Instead of:
 *
 * ```
 * users[0]: 1
 * users[1]: 2
 * ```
 */
export const objectToFormData = (obj: any, rootName = ''): FormData => {
  const formData = new FormData();

  function appendFormData(data: any, root: string): void {
    if (data instanceof File) {
      formData.append(root, data, data.name);
    } else if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i += 1) {
        appendFormData(data[i], `${root}[]`);
      }
    } else if (typeof data === 'object' && data) {
      Object.entries(data).forEach(([key, value]) => {
        const keyName = root === '' ? key : `${root}[${key}]`;
        appendFormData(value, keyName);
      });
    } else if (data !== null && typeof data !== 'undefined') {
      formData.append(root, data);
    }
  }

  appendFormData(obj, rootName);
  return formData;
};

async function request<T>(method: HTTP_METHOD, url: string, headers = new Headers(), body?: any): Promise<T> {
  const options = {
    mode: HTTP_MODE.CORS,
    credentials: 'include',
    headers,
    method,
    body,
  };

  options.headers.append('accept', 'application/json');

  const response = await fetch(url, options as any);
  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const responseBody = await response.json();
  return responseBody as T;
}

function requestEmptyResponse(method: HTTP_METHOD, url: string, headers = new Headers()) {
  const options = {
    mode: HTTP_MODE.CORS,
    credentials: 'include',
    headers,
    method,
  };

  return fetch(url, options as any).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
  });
}

export const getRequest = (url: string, headers?: Headers) => request(HTTP_METHOD.GET, url, headers);
export const deleteRequest = (url: string, headers?: Headers) => requestEmptyResponse(HTTP_METHOD.DELETE, url, headers);

export const withProcoreAuth = (procoreCompanyId?: number): Headers => {
  const headers = new Headers();
  const state = store.getState();

  const procoreToken = state.auth.procoreToken;
  if (procoreToken) {
    headers.append('Authorization', `Bearer ${procoreToken}`);
  } else {
    console.warn('Attempting to make a request with procore authorization, but cookie not set');
  }
  if (procoreCompanyId) {
    // This header is required most of the time
    // https://developers.procore.com/documentation/mpz-headers
    headers.append('Procore-Company-Id', String(procoreCompanyId));
  }
  return headers;
};

export const withAuthToken = async (headers = new Headers()): Promise<Headers> => {
  const token = await getJWTAccessToken();
  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  } else {
    console.warn('Attempting to make a request with auth token, but cookie not set');
  }

  return headers;
};
