import {
  Box,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Icon,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { differenceInDays } from 'date-fns';
import { useState } from 'react';
import { useCookies } from 'react-cookie';

import { Floorplan } from '../../@types/api/v0/rest/Floorplan';
import { MatchingFloorplan } from '../../@types/api/v0/rest/Node';
import { Project } from '../../@types/api/v0/rest/Project';
import { Walkthrough } from '../../@types/api/v0/rest/Walkthrough';
import { TreeElement } from '../../@types/api/v1/bespoke/ProjectHierarchy';
import CollapsibleListItem from '../../components/CollapsibleList/CollapsibleListItem';
import CollapsibleListLeaf from '../../components/CollapsibleList/CollapsibleListLeaf';
import { FLOORPLAN_ID_PREFIX } from '../../components/CollapsibleList/treeUtils';
import { DoubleRightArrowIcon } from '../../components/Icon';
import LoadingIndicator from '../../components/LoadingIndicator';
import theme from '../../theme';
import { naturalSort } from '../../utils';
import { formatIsoDate } from '../../utils/dateUtils';
import { formatAddress } from '../../utils/stringUtils';
import DateWarningModal from '../DateWarningModal/DateWarningModal';
import { getFloorplanTimestamp, getLocationParameters } from './locationsDrawerUtils';

export interface LocationsDrawerProps {
  /** The current floorplan. */
  floorplan: Floorplan;
  /** Flag indicating if the component is loading or not. If true, render only a loading spinner in the drawer. */
  isLoading?: boolean;
  /** Flag indicating if the drawer is open or not. If false, nothing is rendered. */
  isOpen?: boolean;
  /** When the user clicks on a given floorplan, bring them to a comparably recent walkthrough of the selected floor. */
  matches?: MatchingFloorplan[];
  /** Handler to call when the drawer is closed. */
  onClose: () => void;
  /** Handler to call when a new location is selected from this drawer. */
  onLocationSelect: (location: {
    projectId: number;
    floorplanId: number;
    walkthroughId?: number;
    nodeId?: string;
    date?: string;
  }) => void;
  /** The current project. */
  project: Project;
  /** The project's hierarchy representation to render within the drawer. */
  projectHierarchy: TreeElement[];
  /** The current walkthrough. */
  walkthrough?: Walkthrough;
}

const LocationsDrawer = (props: LocationsDrawerProps) => {
  const { floorplan, isLoading, isOpen, matches, onClose, onLocationSelect, project, projectHierarchy, walkthrough } =
    props;

  const [pendingLocationParams, setPendingLocationParams] = useState<{
    projectId: number;
    floorplanId: number;
    walkthroughId?: number;
    nodeId?: string;
    date?: string;
  }>();

  const {
    isOpen: isDateWarningModalOpen,
    onOpen: openDateWarningModal,
    onClose: closeDateWarningModal,
  } = useDisclosure();

  const [cookies] = useCookies(['onsitevision_date_warning_acknowledged']);
  const wasDateWarningAcknowledged = Boolean(cookies.onsitevision_date_warning_acknowledged);

  /**
   * When a floorplan is selected, determine where the user should be sent. If a selection has a "matching node", the
   * user will be sent to a node close to the current node's position on the destination floorplan.
   *
   * If the `walkthrough` prop is present (i.e. the user is currently viewing a walkthrough or walkthrough node in 360)
   * and the destination also has a target walkthrough date, we compute the difference between the two dates. If they
   * aren't on the same day, a warning modal may be shown to the user.
   */
  const onFloorplanSelect = (nextFloorplan: Floorplan) => {
    const matchingNode = matches?.find((match) => match.floorplan === nextFloorplan.id);
    const destinationParameters = getLocationParameters(project, walkthrough?.when, nextFloorplan, matchingNode);

    const daysDifference =
      destinationParameters.date && walkthrough
        ? differenceInDays(new Date(destinationParameters.date), new Date(walkthrough.when))
        : 0;
    const showDateWarning = !wasDateWarningAcknowledged && Math.abs(daysDifference) > 0;

    if (showDateWarning) {
      setPendingLocationParams(destinationParameters);
      openDateWarningModal();
    } else {
      onLocationSelect(destinationParameters);
    }
  };

  /** When the user acknowledges the date warning, change locations, close the modal, and clear the pending changes. */
  const onWarningAcknowledge = () => {
    onLocationSelect(pendingLocationParams!);
    closeDateWarningModal();
    setPendingLocationParams(undefined);
  };

  /** When the user acknowledges the date warning, change locations, close the modal, and clear the pending changes. */
  const onWarningClose = () => {
    closeDateWarningModal();
    setPendingLocationParams(undefined);
  };

  /** When `projectHierarchy` is empty, simply list all floorplans. */
  const renderFloorplanList = () => {
    return naturalSort(project.floorplans, 'name').map((floorplanOption) => {
      const captionTimestamp = getFloorplanTimestamp({
        currentFloorplan: floorplan,
        currentWalkthrough: walkthrough,
        floorplan: floorplanOption,
        matches,
      });
      return (
        <CollapsibleListLeaf
          key={`floorplan-${floorplanOption.id}`}
          isActive={floorplanOption.id === floorplan.id}
          item={floorplanOption}
          onClick={onFloorplanSelect}
        >
          {captionTimestamp && (
            <Text
              as="span"
              color={theme.colors.brand.gray[600]}
              paddingBlockStart="0.25rem"
              textStyle="detail"
              whiteSpace="normal"
            >
              {formatIsoDate(captionTimestamp)?.formattedDate}
            </Text>
          )}
        </CollapsibleListLeaf>
      );
    });
  };

  /** When `projectHierarchy` is populated, render the floorplans in a tree representation. */
  const renderListWithHierarchy = (elements: TreeElement[], depth = 0) => {
    const listElements = [];
    for (const element of elements) {
      if (element.children) {
        listElements.push(
          <CollapsibleListItem key={element.id} itemId={element.id} name={element.name} depth={depth}>
            {renderListWithHierarchy(element.children, depth + 1)}
          </CollapsibleListItem>
        );
        continue;
      }

      const elementFloorplan = project.floorplans.find(({ id }) => `${FLOORPLAN_ID_PREFIX}${id}` === element.id);
      if (!elementFloorplan) {
        continue;
      }

      const captionTimestamp = getFloorplanTimestamp({
        currentFloorplan: floorplan,
        currentWalkthrough: walkthrough,
        floorplan: elementFloorplan,
        matches,
      });

      listElements.push(
        <CollapsibleListLeaf
          key={`floorplan-${elementFloorplan.id}`}
          isActive={elementFloorplan.id === floorplan.id}
          item={elementFloorplan}
          onClick={onFloorplanSelect}
        >
          {captionTimestamp && (
            <Text
              as="span"
              color={theme.colors.brand.gray[600]}
              paddingBlockStart="0.25rem"
              textStyle="detail"
              whiteSpace="normal"
            >
              {formatIsoDate(captionTimestamp)?.formattedDate}
            </Text>
          )}
        </CollapsibleListLeaf>
      );
    }

    return listElements;
  };

  return (
    <>
      <ChakraDrawer isOpen={Boolean(isOpen)} onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton>
            <Icon aria-hidden as={DoubleRightArrowIcon} height="1.5rem" width="1.5rem" />
          </DrawerCloseButton>
          <DrawerHeader>
            <Heading as="h1" size="lg">
              {project.name}
            </Heading>
            <Text color={theme.colors.brand.gray[600]} fontSize="0.875rem">
              {formatAddress(project.address_line, project.city, project.state, project.zip_code)}
            </Text>
            {walkthrough && (
              <>
                <Text color={theme.colors.brand.gray[600]} fontSize="0.875rem" paddingBlockStart="1rem">
                  Current walkthrough
                </Text>
                <Text fontSize="1rem">{formatIsoDate(walkthrough?.when)?.formattedDateTime}</Text>
              </>
            )}
          </DrawerHeader>
          <DrawerBody>
            {isLoading && <LoadingIndicator />}
            {!isLoading && (
              <Box
                backgroundColor={theme.colors.brand.gray[50]}
                borderBottom={`1px solid ${theme.colors.brand.gray[200]}`}
                borderTop={`1px solid ${theme.colors.brand.gray[200]}`}
                marginBlockStart="0.75rem"
                paddingBlock="0.5rem"
              >
                {projectHierarchy.length === 0 ? renderFloorplanList() : renderListWithHierarchy(projectHierarchy)}
              </Box>
            )}
          </DrawerBody>
        </DrawerContent>
      </ChakraDrawer>
      <DateWarningModal
        fromDate={walkthrough?.when}
        isOpen={isDateWarningModalOpen}
        onAcknowledge={onWarningAcknowledge}
        onClose={onWarningClose}
        toDate={pendingLocationParams?.date}
      />
    </>
  );
};

export default LocationsDrawer;
