import classNames from 'classnames';
import { ReactElement, ReactNode, useEffect, useState } from 'react';

import { getCssRootValue } from '../../utils/device';

import classes from './CoachOverlay.module.scss';

export interface CoachOverlayProps {
  autoHideDuration?: number;
  children?: ReactNode;
  title?: string;
  titleAs?: ((props: { className?: string | undefined; children?: ReactNode }) => ReactElement) | string;
}

enum VisibilityStatus {
  Visible,
  Fading,
  Hidden,
}

const fadeTransitionDuration = (getCssRootValue('--transition-duration-coach', true) as number | undefined) ?? 250;

const CoachOverlay = ({
  autoHideDuration,
  children,
  title,
  titleAs = 'span',
}: CoachOverlayProps): ReactElement | null => {
  const [status, setStatus] = useState<VisibilityStatus>(VisibilityStatus.Visible);

  useEffect(() => {
    // If the autoHideDuration prop is removed (or technically, set to 0)
    if (!autoHideDuration && status === VisibilityStatus.Hidden) {
      setStatus(VisibilityStatus.Visible);
    }
    // If the overlay should not auto-hide or it has already been hidden, stop.
    if (!autoHideDuration || status === VisibilityStatus.Hidden) {
      return;
    }

    let currentTimeout: ReturnType<typeof setTimeout> | undefined;
    if (status === VisibilityStatus.Visible) {
      currentTimeout = setTimeout(() => {
        setStatus(VisibilityStatus.Fading);
      }, autoHideDuration);
    }
    if (status === VisibilityStatus.Fading) {
      currentTimeout = setTimeout(() => {
        setStatus(VisibilityStatus.Hidden);
      }, fadeTransitionDuration);
    }

    if (currentTimeout) {
      // eslint-disable-next-line consistent-return
      return () => clearTimeout(currentTimeout);
    }
  }, [autoHideDuration, status]);

  if (!title && !children) {
    throw new Error("At least one of 'title' or 'children' is required");
  }

  if (status === VisibilityStatus.Hidden) {
    return null;
  }

  const TitleTagName = titleAs;
  return (
    <div className={classNames(classes.overlay, { [classes.overlayExit]: status === VisibilityStatus.Fading })}>
      {title && <TitleTagName className={classes.title}>{title}</TitleTagName>}
      <div className={classes.body}>{children}</div>
    </div>
  );
};

export default CoachOverlay;
