import { DrawerProps } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { PortfolioProject } from '../../@types/api/v1/bespoke/PortfolioDashboard';
import { PortfolioDashboardApi } from '../../api/v1/bespoke/PortfolioDashboardApi';
import { PortfolioDashboardQueryKeys, QueryTopics } from '../../constants/queries';
import { ProjectDrawer } from './ProjectDrawer';

export type ProjectDrawerContainerProps = Pick<DrawerProps, 'isOpen' | 'onClose'> & {
  project: PortfolioProject;
};

export const ProjectDrawerContainer = ({ isOpen, onClose, project }: ProjectDrawerContainerProps) => {
  const progressHistoryQuery = useQuery({
    enabled: Boolean(project),
    queryKey: [QueryTopics.PORTFOLIO_DASHBOARD, PortfolioDashboardQueryKeys.PROGRESS_HISTORY, project.id],
    queryFn: async ({ signal }) => (await PortfolioDashboardApi.getProgressHistory(project?.id, { signal })).data,
  });

  const milestoneProgressQuery = useQuery({
    enabled: Boolean(project),
    queryKey: [QueryTopics.PORTFOLIO_DASHBOARD, PortfolioDashboardQueryKeys.MILESTONE_PROGRESS, project.id],
    queryFn: async ({ signal }) => (await PortfolioDashboardApi.getMilestoneProgress(project?.id, { signal })).data,
  });

  const { isFetching: isProgressHistoryFetching, isError: isProgressHistoryError } = progressHistoryQuery;
  const { isFetching: isMilestoneProgressFetching, isError: isMilestoneProgressError } = milestoneProgressQuery;

  const isFetching = isProgressHistoryFetching || isMilestoneProgressFetching;
  const isError = isProgressHistoryError || isMilestoneProgressError;

  return (
    <ProjectDrawer
      isOpen={isOpen}
      onClose={onClose}
      project={project}
      progressHistory={progressHistoryQuery.data}
      milestoneProgress={milestoneProgressQuery.data}
      isFetching={isFetching}
      isError={isError}
    />
  );
};
