import { CommonProperties, datadogRum } from '@datadog/browser-rum';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { useContext, useEffect, useMemo, useState } from 'react';

import { Me } from '../@types/OnSiteIQ';
import { AppContext } from '../AppContext';
import { getFullName } from '../utils/user';

type DatadogUser = CommonProperties['usr'];

interface HookProps {
  ready: boolean;
  user?: Me;
}

/**
 * Custom hook to integrate with Datadog, Pendo, and Google Tag Manager. Note that all Pendo function calls where user
 * data is passed require the objects to be cloned. Pendo unexpectedly modifies the parameters, but we need the user
 * data to be unmodified for comparisons (e.g. when pendo.updateOptions is called).
 */
export function useAnalyticsProvider({ ready, user }: HookProps) {
  const [currentDatadogUser, setCurrentDatadogUser] = useState<DatadogUser | undefined>();
  const [currentPendoUser, setCurrentPendoUser] = useState<pendo.InitOptions | undefined>();
  const [pendoInitialized, setPendoInitialized] = useState(false);

  const dataDogUser = useMemo<DatadogUser | undefined>(() => {
    if (!user) {
      return undefined;
    }

    return {
      id: String(user.id),
      name: getFullName(user),
      email: user.email,
    };
  }, [user]);

  const pendoUser = useMemo<pendo.InitOptions | undefined>(() => {
    if (!user) {
      return undefined;
    }

    return {
      visitor: {
        id: process.env.REACT_APP_ENVIRONMENT !== 'production' ? `TEST-${user.id}` : Number(user.id),
        name: getFullName(user),
        email: user.email,
        user_persona: user.userpersona?.persona_name,
        title: user.title,
        internal: user.is_internal,
        company_name: user.company_name,
      },
      account: {
        id:
          process.env.REACT_APP_ENVIRONMENT !== 'production' ? 'TEST' : user?.analytics_account_description?.account_id,
        name: user?.analytics_account_description?.account_name,
      },
    } as pendo.InitOptions;
  }, [user]);

  /**
   * When the memoized Datadog user changes, follow the same steps as outlined for Pendo in the comment above.
   */
  function onDatadogUserChange() {
    if (!ready) {
      return;
    }
    if (!datadogRum.getInternalContext()?.session_id) {
      console.debug('[useAnalytics] No Datadog RUM context found.');
      return;
    }

    try {
      // NOTE: After you try refactoring this conditional and realize that this is the best option available,
      // please add your name and tally for future readers.
      // cpackard: 1
      if (!currentDatadogUser && dataDogUser) {
        console.debug('[useAnalytics] Setting Datadog user...', dataDogUser);
        datadogRum.setUser(dataDogUser);
        setCurrentDatadogUser(dataDogUser);
      } else if (currentDatadogUser && !dataDogUser) {
        console.debug('[useAnalytics] Clearing Datadog user session...', dataDogUser);
        datadogRum.clearUser();
        setCurrentDatadogUser(dataDogUser);
      } else if (currentDatadogUser && dataDogUser && currentDatadogUser.id !== dataDogUser.id) {
        console.error('[useAnalytics] Datadog user should be cleared before setting a new one!');
        throw new Error('Datadog session already in progress', { cause: { code: 'SessionInProgress' } });
      } else if (
        currentDatadogUser &&
        dataDogUser &&
        currentDatadogUser.id === dataDogUser.id &&
        !isEqual(currentDatadogUser, dataDogUser)
      ) {
        console.debug('[useAnalytics] Updating Datadog user details...', dataDogUser);
        datadogRum.setUser(dataDogUser);
        setCurrentDatadogUser(dataDogUser);
      }
    } catch (error) {
      console.warn('[useAnalytics] Failed to set Datadog user. This is likely due to a content blocker.', error);
    }
  }

  /**
   * When the application is ready, initialize Pendo.
   */
  function onPendoReady() {
    if (!ready || pendoInitialized) {
      return;
    }

    console.debug('[useAnalytics] Initializing Pendo...', pendoUser);
    try {
      window.pendo.initialize(cloneDeep(pendoUser));
    } catch (error) {
      console.warn('[useAnalytics] Failed to initialize Pendo. This is likely due to a content blocker.', error);
    }

    // TODO: do this in a different effect. This is Google Tag Manager.
    window.dataLayer.push({
      event: 'gtm.js',
      ...user,
    });

    setPendoInitialized(true);
    setCurrentPendoUser(pendoUser);
  }

  /**
   * When the computed (i.e. memoized) Pendo user changes, either:
   * 1) Identify them (triggered on login)
   * 2) Clear their data (triggered on logout)
   * 3) Update the user details (triggered on user profile update)
   *
   * If the upper component attempts to set a completely new user before the previous is cleared, an error is thrown
   * for visibility.
   */
  function onPendoUserChange() {
    if (!ready || !pendoInitialized) {
      return;
    }

    try {
      if (!currentPendoUser && pendoUser) {
        console.debug('[useAnalytics] Logging in Pendo user...', pendoUser);

        window.pendo.identify(cloneDeep(pendoUser));
        setCurrentPendoUser(pendoUser);
      } else if (currentPendoUser && !pendoUser) {
        console.debug('[useAnalytics] Clearing Pendo session...', pendoUser);

        window.pendo.clearSession();
        setPendoInitialized(false);
        setCurrentPendoUser(pendoUser);
      } else if (currentPendoUser && pendoUser && currentPendoUser.visitor?.id !== pendoUser.visitor?.id) {
        console.error('[useAnalytics] Pendo session should be cleared before another is started!');
        throw new Error('Pendo session already in progress', { cause: { code: 'SessionInProgress' } });
      } else if (
        currentPendoUser &&
        pendoUser &&
        currentPendoUser.visitor?.id === pendoUser.visitor?.id &&
        !isEqual(currentPendoUser, pendoUser)
      ) {
        console.debug('[useAnalytics] Updating Pendo user details...', pendoUser);

        window.pendo.updateOptions(cloneDeep(pendoUser));
        setCurrentPendoUser(pendoUser);
      }
    } catch (error) {
      console.warn('[useAnalytics] Pendo operation failed. This is likely due to a content blocker.', error);
    }
  }

  useEffect(onDatadogUserChange, [currentDatadogUser, dataDogUser, ready]);

  useEffect(onPendoReady, [pendoInitialized, pendoUser, ready, user]);

  useEffect(onPendoUserChange, [currentPendoUser, pendoInitialized, pendoUser, ready]);

  return {
    pendoUser,
    // Later on, provide methods such as "logEvent" if needed...
  };
}

/**
 * In order to prevent multiple invocations of the hook from reinitializing Pendo or Google Tag Manager, the function
 * above should only be used directly by some context-providing wrapper component (e.g. AppContext). Inside child
 * components, use this hook.
 */
export default function useAnalytics() {
  const { analytics } = useContext(AppContext);
  return analytics;
}
