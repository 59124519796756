import { AxiosRequestConfig } from 'axios';

import {
  MatchingFloorplan,
  NodeSnapshotEmail,
  NodeSnapshotEmailRequestBody,
  TimeTravelPair,
} from '../../../@types/api/v0/rest/Node';
import { get, post } from '../../next';

export const NodeApi = {
  /**
   * Retrieve the set of nodes close to the current node in 2D floorplan distance generated by walkthroughs of other
   * floorplans within the same project.
   * @param id Unique ID of the current node.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping a list of matching floorplan nodes.
   */
  getMatchingFloorplans: (nodeId: string, requestConfig?: AxiosRequestConfig) =>
    get<MatchingFloorplan[]>(`/api/nodes/${nodeId}/matching_floorplans/`, requestConfig),
  /**
   * Retrieve the set of time travel pairs (i.e. valid destinations) for the current node.
   * @param id Unique ID of the current node.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping a list of valid time travel pairs.
   */
  getTimeTravelPairs: (nodeId: string, requestConfig?: AxiosRequestConfig) =>
    get<TimeTravelPair[]>(`/api/nodes/${nodeId}/timetravel/`, requestConfig),
  /**
   * Share a snapshot with a set of recipients.
   * @param nodeId Unique ID of the node on which the snapshot was taken.
   * @param requestBody Request payload with snapshot details.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping a representation of the snapshot email, if successful.
   */
  sendSnapshotEmail: (nodeId: string, requestBody: NodeSnapshotEmailRequestBody, requestConfig?: AxiosRequestConfig) =>
    post<NodeSnapshotEmailRequestBody, NodeSnapshotEmail>(
      `/api/nodes/${nodeId}/snapshotemail/`,
      requestBody,
      requestConfig
    ),
};
