export const PROGRESS_TRACKING_TOOLTIPS: Record<string, string> = {
  DATE_SELECTION_1:
    'Select an available date in the past to review the progress (% complete) of floor plans and their related milestones at that particular point in time.',
  DATE_SELECTION_2:
    'Each available date in the calendar represents the date a Capture Specialist completed a walkthrough on the job site.',
  DATE_SELECTION_3: 'Progress Tracking data is temporarily unavailable for walkthroughs prior to March 9, 2023.',
};

export const PROGRESS_TABLE_HEADER_TOOLTIPS: Record<string, string> = {
  EARTHWORK:
    'Earthwork is considered 100% complete when all excavation activities are finished, and the site is ready to receive foundation work.',
  FOUNDATION:
    'Foundation is considered 100% complete when all below grade concrete walls and slabs have been poured and vertical construction begins.',
  SUPERSTRUCTURE: `Superstructure is considered 100% complete when all vertical columns and horizontal beams are installed, all floors have a concrete slab or plywood subfloor, and the building is "topped-out."`,
  'BUILDING ENVELOPE':
    'Building envelope is considered 100% complete when all exterior cladding and facade finishes are installed, including panels, masonry, siding, and coping.',
  'EXTERIOR OPENINGS':
    'Exterior openings are considered 100% complete when all openings on all floors are fully installed and enclosed with glass or other specified materials at all elevations.',
  'PLUMBING ROUGH-IN':
    'Plumbing Rough-In is considered to be 100% complete when all vertical and horizontal water supply, sanitary, and drain piping is installed and insulated as needed.',
  'INTERIOR FRAMING':
    'Framing is considered 100% complete when framing members are fully assembled and affixed to the floors and ceilings, and when the support structure is fully installed at perimeter and demising walls and is ready to receive drywall or sheathing.',
  'MECHANICAL ROUGH-IN':
    'Mechanical rough-in is considered 100% complete when all main and branch ductwork and condenser piping is installed across the entire floor as per the drawings and is ready for trim.',
  'SPRINKLER ROUGH-IN':
    'Sprinkler Rough-In is considered to be 100% complete when all piping run, branch lines, and heads are installed across a given floor, but final valve connections are not made yet.',
  'ELECTRICAL ROUGH-IN':
    'Electrical Rough-In is considered 100% complete when all wiring, conduit, and junction boxes for electrical components are installed throughout a given floor, and panels have been mounted.',
  INSULATION:
    'Insulation is considered to be 100% complete when all insulation material has been installed on a given floor, including walls, floors, and ceilings.',
  DRYWALL:
    'Drywall is considered 100% complete when all gypsum wall boards are hung to framing, taped, sanded, and ready for paint or wall covering installation.',
  'INTERIOR FLOOR FINISHES':
    'Interior floor finishes are considered 100% complete when all sub-flooring components and final flooring material (wood, tile, carpet, polished concrete, etc.) has been installed.',
  'INTERIOR CEILING FINISHES':
    'Interior ceiling finishes are considered 100% complete when all of the given floor area that is scheduled to receive interior ceiling materials (such as drywall, acoustic tiles, plaster, or wood paneling) has been installed on the ceiling.',
  'INTERIOR WALL FINISHES':
    'Interior wall finishes are considered 100% complete when all of the walls in the given area are covered with the specified finish material, which may include paint, wallpaper, tile, wood paneling, or decorative plaster.',
  CASEWORK:
    'Casework is considered 100% complete when all built-in cabinetry and shelving units are assembled and permanently attached.',
  'INTERIOR FIXTURES': `Interior fixtures are considered to be 100% complete when all permanent, built-in elements of a building's interior are installed and functioning as intended. These fixtures include but are not limited to sinks, toilets, showers, bathtubs, and light fixtures.`,
  APPLIANCES:
    'Appliances are considered 100% complete when all of the scheduled appliances are installed and connected to power sources.',
  ELEVATORS:
    'Elevators are considered to be 100% complete when all vertical transportation components, including shafts, cabs, rails, hoists, and machines, are installed and operational.',
  'FF&E':
    'FF&E (Furniture, Fixtures, & Equipment) is considered 100% complete when all of the moveable FF&E items are on-site and arranged into their final positions.',
  'MAJOR EQUIPMENT (MEP)': `Major equipment is considered to be 100% complete when all necessary systems are installed, tested, and commissioned according to the manufacturer's specifications and industry standards.`,
  HARDSCAPE:
    'Hardscape is considered 100% complete when all of the exterior hard-wearing landscape materials are installed.',
};
