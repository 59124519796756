import {
  Box,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Icon,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import orderBy from 'lodash/orderBy';

import { LoadingIndicator } from '../..';
import { Annotation } from '../../../@types/api/v0/rest/Annotation';
import { Project } from '../../../@types/api/v0/rest/Project';
import { Reply, ReplyCreateRequestBody } from '../../../@types/api/v0/rest/Reply';
import { Walkthrough } from '../../../@types/api/v0/rest/Walkthrough';
import { AnnotationApi } from '../../../api/v0/rest/AnnotationApi';
import { AuthApi } from '../../../api/v0/rest/AuthApi';
import { ReplyApi } from '../../../api/v0/rest/ReplyApi';
import { AuthQueryKeys, QueryTopics } from '../../../constants/queries';
import theme from '../../../theme';
import { formatIsoDate } from '../../../utils/dateUtils';
import { formatAddress } from '../../../utils/stringUtils';
import { DoubleRightArrowIcon } from '../../Icon';
import Toast from '../../Toast';
import AnnotationListItem from './AnnotationListItem';

export interface AnnotationsDrawerContainerProps {
  /** The list of annotations on this walkthrough to show in the drawer. */
  annotations?: Annotation[];
  /**
   * Show a loading spinner. Useful in cases when the parent component will change the drawer's content soon, and to
   * prevent the user from performing actions on annotations in the meantime. For example, when the 360º viewer is
   * transitioning between ground nodes on a slow connection, the user should not be able to immediately look at an
   * annotation until the scene has fully transitioned.
   */
  isLoading?: boolean;
  /** Whether or not the drawer is visible. */
  isOpen?: boolean;
  /** Handler called when the user deletes an annotation. */
  onAnnotationDelete: (annotation: Annotation) => void;
  /** Handler called when the user updates an annotation. */
  onAnnotationUpdate: (annotation: Annotation) => void;
  /** Handler called when the user clicks the "View 360" button on an annotation. */
  onAnnotationView: (annotation: Annotation) => void;
  /** Handler to call when the user wants to close the drawer. */
  onClose: () => void;
  /** Handler called when the user creates a new reply on an annotation. */
  onReplyCreate: (annotation: Annotation, reply: Reply) => void;
  /** Handler called when the user deletes a reply. */
  onReplyDelete: (annotation: Annotation, reply: Reply) => void;
  /** Handler called when the user updates a reply. */
  onReplyUpdate: (annotation: Annotation, reply: Reply) => void;
  /** The current project. */
  project: Project;
  /** The current walkthrough. */
  walkthrough?: Walkthrough;
}

const AnnotationsDrawerContainer = (props: AnnotationsDrawerContainerProps) => {
  const {
    annotations = [],
    isLoading,
    isOpen,
    onAnnotationDelete,
    onAnnotationUpdate,
    onAnnotationView,
    onClose,
    onReplyCreate,
    onReplyDelete,
    onReplyUpdate,
    project,
    walkthrough,
  } = props;

  const userProfileQuery = useQuery({
    queryKey: [QueryTopics.AUTH, AuthQueryKeys.USER_PROFILE],
    queryFn: async ({ signal }) => (await AuthApi.getUserProfile({ signal })).data,
  });

  const toast = useToast();

  const annotationUpdateMutation = useMutation({
    mutationFn: ({ annotation }: { annotation: Annotation }) => AnnotationApi.update(annotation),
    onError: (error) => {
      console.error('[AnnotationsDrawerContainer] Failed to update annotation', error);
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => (
          <Toast
            {...props}
            title="Error"
            description="Failed to update markup. Please try again later."
            status="error"
          />
        ),
      });
    },
    onSuccess: (_, { annotation }) => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => <Toast {...props} title="Success" description="Markup updated." status="success" />,
      });

      onAnnotationUpdate(annotation);
    },
  });

  const annotationDeleteMutation = useMutation({
    mutationFn: ({ annotation }: { annotation: Annotation }) => AnnotationApi.delete(annotation),
    onError: (error) => {
      console.error('[AnnotationsDrawerContainer] Failed to delete annotation', error);
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => (
          <Toast
            {...props}
            title="Error"
            description="Failed to delete markup. Please try again later."
            status="error"
          />
        ),
      });
    },
    onSuccess: (_, { annotation }) => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => <Toast {...props} title="Success" description="Markup deleted." status="success" />,
      });

      onAnnotationDelete(annotation);
    },
  });

  const replyCreateMutation = useMutation({
    mutationFn: ({ annotation, content }: { annotation: Annotation; content: string }) => {
      const requestBody: ReplyCreateRequestBody = {
        annotation: annotation.id,
        content,
        safety_mitigation: null,
      };

      return ReplyApi.create(requestBody);
    },
    onError: (error) => {
      console.error('[AnnotationsDrawerContainer] Failed to create reply', error);
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => (
          <Toast
            {...props}
            title="Error"
            description="Failed to create new markup reply. Please try again later."
            status="error"
          />
        ),
      });
    },
    onSuccess: (response, { annotation }) => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => <Toast {...props} title="Success" description="Markup reply created." status="success" />,
      });

      onReplyCreate(annotation, response.data);
    },
  });

  const replyUpdateMutation = useMutation({
    mutationFn: ({ reply }: { annotation: Annotation; reply: Reply }) => ReplyApi.update(reply),
    onError: (error) => {
      console.error('[AnnotationsDrawerContainer] Failed to update reply', error);
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => (
          <Toast
            {...props}
            title="Error"
            description="Failed to update markup reply. Please try again later."
            status="error"
          />
        ),
      });
    },
    onSuccess: (_, { annotation, reply }) => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => <Toast {...props} title="Success" description="Markup reply updated." status="success" />,
      });

      onReplyUpdate(annotation, reply);
    },
  });

  const replyDeleteMutation = useMutation({
    mutationFn: ({ reply }: { annotation: Annotation; reply: Reply }) => ReplyApi.delete(reply),
    onError: (error) => {
      console.error('[AnnotationsDrawerContainer] Failed to delete reply', error);
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => (
          <Toast
            {...props}
            title="Error"
            description="Failed to delete markup reply. Please try again later."
            status="error"
          />
        ),
      });
    },
    onSuccess: (_, { annotation, reply }) => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => <Toast {...props} title="Success" description="Markup reply deleted." status="success" />,
      });

      onReplyDelete(annotation, reply);
    },
  });

  const isDataLoading = isLoading || userProfileQuery.isFetching;
  const isError = userProfileQuery.isError;
  const isSubmitting =
    annotationDeleteMutation.isLoading ||
    annotationUpdateMutation.isLoading ||
    replyCreateMutation.isLoading ||
    replyDeleteMutation.isLoading ||
    replyUpdateMutation.isLoading;
  const sortedAnnotations = orderBy(annotations, 'created', 'desc');

  return (
    <ChakraDrawer isOpen={Boolean(isOpen)} onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton>
          <Icon aria-hidden as={DoubleRightArrowIcon} height="1.5rem" width="1.5rem" />
        </DrawerCloseButton>
        <DrawerHeader>
          <Heading as="h1" size="lg">
            {project.name}
          </Heading>
          <Text color={theme.colors.brand.gray[600]} fontSize="0.875rem">
            {formatAddress(project.address_line, project.city, project.state, project.zip_code)}
          </Text>
          {walkthrough && (
            <>
              <Text color={theme.colors.brand.gray[600]} fontSize="0.875rem" paddingBlockStart="1rem">
                Current walkthrough
              </Text>
              <Text fontSize="1rem">{formatIsoDate(walkthrough?.when)?.formattedDateTime}</Text>
            </>
          )}
        </DrawerHeader>
        <DrawerBody>
          {isDataLoading && <LoadingIndicator />}
          {!isDataLoading && isError && (
            <Text color={theme.colors.brand.error[200]} textStyle="detail">
              Failed to load user data for markups. Please try again later.
            </Text>
          )}
          {!isDataLoading && !isError && sortedAnnotations.length === 0 && (
            <Text color={theme.colors.brand.gray[600]} textStyle="detail">
              No markups found at this location.
            </Text>
          )}
          {!isDataLoading && !isError && sortedAnnotations.length > 0 && (
            <Box role="list">
              {sortedAnnotations.map((annotation) => (
                <AnnotationListItem
                  key={annotation.id}
                  annotation={annotation}
                  isReadOnly={annotation.poster.id !== userProfileQuery.data?.id}
                  isSubmitting={isSubmitting}
                  onAnnotationDelete={(annotation) => annotationDeleteMutation.mutate({ annotation })}
                  onAnnotationUpdate={(annotationWithChanges) =>
                    annotationUpdateMutation.mutate({ annotation: annotationWithChanges })
                  }
                  onAnnotationView={onAnnotationView}
                  onReplyCreate={(content) => replyCreateMutation.mutate({ annotation, content })}
                  onReplyDelete={(reply) => replyDeleteMutation.mutate({ annotation, reply })}
                  onReplyUpdate={(reply) => replyUpdateMutation.mutate({ annotation, reply })}
                  userId={userProfileQuery.data?.id}
                />
              ))}
            </Box>
          )}
        </DrawerBody>
      </DrawerContent>
    </ChakraDrawer>
  );
};

export default AnnotationsDrawerContainer;
