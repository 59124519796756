import { useQuery } from '@tanstack/react-query';

import { AuthApi } from '../../api/v0/rest/AuthApi';
import { PortfolioDashboardApi } from '../../api/v1/bespoke/PortfolioDashboardApi';
import { Content, InternalLayout, LoadingIndicator, MessageCard } from '../../components';
import { AuthQueryKeys, PortfolioDashboardQueryKeys, QueryTopics } from '../../constants/queries';
import usePermissions from '../../hooks/usePermissions';
import ProjectListPage from './ProjectListPage';

const ProjectListContainer = () => {
  const projectsQuery = useQuery({
    queryKey: [QueryTopics.PORTFOLIO_DASHBOARD, PortfolioDashboardQueryKeys.PORTFOLIO_PROJECTS_LIST],
    queryFn: async ({ signal }) => (await PortfolioDashboardApi.listProjects({ signal })).data,
  });

  const userProfileQuery = useQuery({
    queryKey: [QueryTopics.AUTH, AuthQueryKeys.USER_PROFILE],
    queryFn: async ({ signal }) => (await AuthApi.getUserProfile({ signal })).data,
  });

  const { canInviteUsers, meQuery } = usePermissions();

  const isFetching = projectsQuery.isFetching || userProfileQuery.isFetching || meQuery.isFetching;
  const isError = projectsQuery.isError || userProfileQuery.isError || meQuery.isError;

  if (isFetching) {
    return (
      <InternalLayout>
        <LoadingIndicator fullPage />
      </InternalLayout>
    );
  }

  if (isError || !projectsQuery.data || !userProfileQuery.data) {
    return (
      <InternalLayout>
        <Content applyGutters>
          <MessageCard title="Failed to load projects">
            <p>
              An error occurred. Please try again later. If this issue persists, contact our Customer Success team at{' '}
              <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a>.
            </p>
          </MessageCard>
        </Content>
      </InternalLayout>
    );
  }

  if (projectsQuery.data.length === 0) {
    return (
      <InternalLayout>
        <Content applyGutters>
          <MessageCard title="No Projects Found">
            <p>
              We have not found any projects associated with your account. Please contact our Customer Success team at{' '}
              <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a>.
            </p>
          </MessageCard>
        </Content>
      </InternalLayout>
    );
  }

  return (
    <InternalLayout>
      <Content applyGutters constrainToPageHeight>
        <ProjectListPage
          canInviteUsers={canInviteUsers}
          projects={projectsQuery.data}
          userProfile={userProfileQuery.data}
        />
      </Content>
    </InternalLayout>
  );
};

export default ProjectListContainer;
