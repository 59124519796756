import { ReactElement } from 'react';

import { BaseLayout } from '../components';
import { ArrowBottomLeft } from '../components/Icon';

import classes from './404.module.scss';

const PageNotFound = (): ReactElement => (
  <BaseLayout className={classes.gridBackground}>
    <span aria-hidden className={classes.splash}>
      404
    </span>
    <div className={classes.content}>
      <h1>We can't find the page you're looking for.</h1>
      <p>We're sorry, the page you requested could not be found.</p>
    </div>
    <a className={classes.action} href="/">
      <ArrowBottomLeft aria-hidden />
      Back to dashboard
    </a>
  </BaseLayout>
);

export default PageNotFound;
