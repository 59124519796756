import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';

import Sidebar from '../Sidebar/Sidebar';

import classes from './Layout.module.scss';

export interface BaseLayoutProps {
  children: ReactNode;
  className?: string;
  sidebar?: boolean;
}

export interface ExternalLayoutProps {
  children: ReactNode;
  className?: string;
}

export interface InternalLayoutProps {
  children: ReactNode;
  className?: string;
}

const BaseLayout = ({ children, className, sidebar = false }: BaseLayoutProps): ReactElement => (
  <div className={classNames(classes.baseLayout, className)}>
    {sidebar && <Sidebar />}
    {children}
  </div>
);

export const ExternalLayout = ({ children, className }: ExternalLayoutProps): ReactElement => (
  <BaseLayout className={classNames(classes.externalLayout, className)} sidebar={false}>
    <img alt="OnsiteIQ" className={classes.logo} src="/resources/logo-with-brand.svg" />
    <div className={classes.card}>
      <div className={classes.grid}>
        <img alt="" className={classes.watermark} src="/resources/arrow-top-left.svg" />
        {children}
      </div>
    </div>
  </BaseLayout>
);

export const InternalLayout = ({ children, className }: InternalLayoutProps): ReactElement => (
  <BaseLayout className={classNames(className)} sidebar>
    {children}
  </BaseLayout>
);

export default BaseLayout;
