import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = definePartsStyle(() => {
  return {
    tab: {
      _active: {
        bg: 'transparent',
      },
      _selected: {
        color: (theme) => theme.colors.brand.primary[400],
      },
    },
    tabpanel: {
      padding: 0,
    },
  };
});

export const tabsTheme = defineMultiStyleConfig({ baseStyle });
