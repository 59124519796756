import axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import {
  AccountMembersResponse,
  AccountProjectsResponse,
  InviteMembersRequest,
  InviteMembersResponse,
  MeResponse,
  ProjectUsersResponse,
  ResendInvitationRequest,
  ResendInvitationResponse,
  SharedAccountsResponse,
  SharedProjectsResponse,
  UpdateMemberPermissionsRequest,
  UpdateMemberPermissionsResponse,
} from '../@types/UserManagement';
import { PaginatedResponseBody } from '../@types/api/v1/RestFramework';
import { getJWTAccessToken } from './';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {},
});

/**
 * Before network requests are made, verify that they would not cause a test to attempt a real request.
 * @param config The Axios request config object.
 */
const checkRequestEnvironment = (config: InternalAxiosRequestConfig) => {
  if (process.env.NODE_ENV === 'test') {
    console.error('Unexpected network request in test', {
      requestMethod: config.method,
      requestUrl: config.url,
    });

    const abortController = new AbortController();
    abortController.abort();

    return {
      ...config,
      signal: abortController.signal,
    };
  }

  return config;
};

client.interceptors.request.use(checkRequestEnvironment);

/**
 * Perform an HTTP GET request on the specified URL.
 * @param url URL of the request.
 * @param requestConfig Optional Axios request configuration.
 * @throws An AxiosError instance if the request failed.
 * @returns A promise wrapping an Axios response.
 */
export const get = async <ResponseBodyType = any>(url: string, requestConfig?: AxiosRequestConfig) => {
  const accessToken = await getJWTAccessToken();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    ...requestConfig?.headers,
  };
  return client.get<ResponseBodyType, AxiosResponse<ResponseBodyType>, never>(url, {
    ...requestConfig,
    headers,
  });
};

/**
 * Perform a series of HTTP GET requests for a paginated resource. This helper function should be used when the target
 * API endpoint returns a `PaginatedResponseBody<T>`.
 * @param initialUrl Initial URL of the request.
 * @param requestConfig Optional Axios request configuration.
 * @throws An AxiosError instance if the request failed.
 * @returns A promise wrapping a references to the compiled list of data and a list of all `AxiosResponse` objects.
 */
export const getPaginated = async <ResponseBodyType = any>(initialUrl: string, requestConfig?: AxiosRequestConfig) => {
  const result: { data: ResponseBodyType[]; responses: AxiosResponse<PaginatedResponseBody<ResponseBodyType>>[] } = {
    data: [],
    responses: [],
  };

  let nextPageUrl = initialUrl;
  do {
    const pageResponse = await get<PaginatedResponseBody<ResponseBodyType>>(nextPageUrl, requestConfig);
    result.data.push(...pageResponse.data.results);
    result.responses.push(pageResponse);

    if (!pageResponse.data.next) {
      break;
    }
    nextPageUrl = pageResponse.data.next;
  } while (nextPageUrl);

  return result;
};

/**
 * Perform an HTTP DELETE request on the specified URL. Delete is a reserved word and this isn't an object property, so
 * "http" is used as a prefix for this function.
 * @param url URL of the request.
 * @param requestConfig Optional Axios request configuration.
 * @throws An AxiosError instance if the request failed.
 * @returns A promise wrapping an Axios response.
 */
export const httpDelete = async <ResponseBodyType = any>(url: string, requestConfig?: AxiosRequestConfig) => {
  const accessToken = await getJWTAccessToken();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    ...requestConfig?.headers,
  };
  return client.delete<ResponseBodyType, AxiosResponse<ResponseBodyType>, never>(url, {
    ...requestConfig,
    headers,
  });
};

/**
 * Perform an HTTP PATCH request on the specified URL. This method should be used when performing a partial update to a
 * resource.
 * @param url URL of the request.
 * @param requestBody The request body to be included with the request.
 * @param requestConfig Optional Axios request configuration.
 * @throws An AxiosError instance if the request failed.
 * @returns A promise wrapping an Axios response.
 */
export const patch = async <RequestBodyType, ResponseBodyType>(
  url: string,
  requestBody: RequestBodyType,
  requestConfig?: AxiosRequestConfig
) => {
  const accessToken = await getJWTAccessToken();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    ...requestConfig?.headers,
  };
  return client.patch<ResponseBodyType, AxiosResponse<ResponseBodyType>, RequestBodyType>(url, requestBody, {
    ...requestConfig,
    headers,
  });
};

/**
 * Perform an HTTP POST request on the specified URL.
 * @param url URL of the request.
 * @param requestBody The request body to be included with the request.
 * @param requestConfig Optional Axios request configuration.
 * @throws An AxiosError instance if the request failed.
 * @returns A promise wrapping an Axios response.
 */
export const post = async <RequestBodyType = any, ResponseBodyType = any>(
  url: string,
  requestBody: RequestBodyType,
  requestConfig?: AxiosRequestConfig
) => {
  const accessToken = await getJWTAccessToken();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    ...requestConfig?.headers,
  };
  return client.post<ResponseBodyType, AxiosResponse<ResponseBodyType>, RequestBodyType>(url, requestBody, {
    ...requestConfig,
    headers,
  });
};

/**
 * Perform an HTTP PUT request on the specified URL. This method should be used when performing a replacement of a
 * resource.
 * @param url URL of the request.
 * @param requestBody The request body to be included with the request.
 * @param requestConfig Optional Axios request configuration.
 * @throws An AxiosError instance if the request failed.
 * @returns A promise wrapping an Axios response.
 */
export const put = async <RequestBodyType, ResponseBodyType>(
  url: string,
  requestBody: RequestBodyType,
  requestConfig?: AxiosRequestConfig
) => {
  const accessToken = await getJWTAccessToken();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    ...requestConfig?.headers,
  };
  return client.put<ResponseBodyType, AxiosResponse<ResponseBodyType>, RequestBodyType>(url, requestBody, {
    ...requestConfig,
    headers,
  });
};

/**
 * This object will be replaced with v0 API equivalents.
 * @deprecated
 */
export const UserManagementApi = {
  getAccountMembers: (accountId: number, includeInternalUsers: boolean, requestConfig?: AxiosRequestConfig) => {
    const queryParams = new URLSearchParams();
    if (includeInternalUsers) {
      queryParams.set('include_internal', 'true');
    }
    return get<AccountMembersResponse>(
      `api/user_management/accounts/${accountId}/users/?${queryParams.toString()}`,
      requestConfig
    );
  },
  getProjectUsers: (projectId: number, includeInternalUsers: boolean, requestConfig?: AxiosRequestConfig) => {
    const queryParams = new URLSearchParams();
    if (includeInternalUsers) {
      queryParams.set('include_internal', 'true');
    }

    return get<ProjectUsersResponse>(
      `api/user_management/projects/${projectId}/users/?${queryParams.toString()}`,
      requestConfig
    );
  },
  getAccountProjects: (accountId: number, requestConfig?: AxiosRequestConfig) =>
    get<AccountProjectsResponse>(`api/accounts/${accountId}/projects/`, requestConfig),
  getMe: (requestConfig?: AxiosRequestConfig) => get<MeResponse>('api/user_management/permissions/me/', requestConfig),
  getSharedAccounts: (userId: number, requestConfig?: AxiosRequestConfig) =>
    get<SharedAccountsResponse>(`api/user_management/users/${userId}/shared_accounts/`, requestConfig),
  getSharedProjects: (accountId: number, otherUserId: number, requestConfig?: AxiosRequestConfig) =>
    get<SharedProjectsResponse>(
      `api/user_management/accounts/${accountId}/users/${otherUserId}/shared_projects/`,
      requestConfig
    ),
  inviteMembers: (accountId: number, payload: InviteMembersRequest, requestConfig?: AxiosRequestConfig) =>
    post<InviteMembersRequest, InviteMembersResponse>(
      `api/user_management/accounts/${accountId}/invitation/`,
      payload,
      requestConfig
    ),
  removeMember: (accountId: number, userId: number, requestConfig?: AxiosRequestConfig) =>
    httpDelete(`api/user_management/accounts/${accountId}/users/${userId}`, requestConfig),
  resendInvitation: (
    accountId: number,
    userId: number,
    payload: ResendInvitationRequest,
    requestConfig?: AxiosRequestConfig
  ) =>
    post<ResendInvitationRequest, ResendInvitationResponse>(
      `api/user_management/accounts/${accountId}/user/${userId}/remind/`,
      payload,
      requestConfig
    ),
  updateMemberPermissions: (
    accountId: number,
    userId: number,
    payload: UpdateMemberPermissionsRequest,
    requestConfig?: AxiosRequestConfig
  ) =>
    put<UpdateMemberPermissionsRequest, UpdateMemberPermissionsResponse>(
      `api/user_management/accounts/${accountId}/users/${userId}`,
      payload,
      requestConfig
    ),
};
