import { FormControl, Stack } from '@chakra-ui/react';
import { Field, FieldProps } from 'formik';

import { List } from '../../List/List';
import { FormValues } from './InviteUserModalConstants';

import classes from './StepTwo.module.scss';

const StepTwo = () => (
  <div className={classes.inviteUserStepTwo}>
    <Stack direction="row" alignItems="center" className={classes.helperTextSection}>
      <div className={classes.helperText}>
        Grant users permission to invite others to their projects within your account.
      </div>
    </Stack>
    <Field name="inviteOthers">
      {({ field, form }: FieldProps<string[], FormValues>) => (
        <FormControl>
          <List
            {...field}
            searchPlaceholder="Search"
            options={
              form.values.to?.map((emailAddress) => ({
                id: emailAddress,
                text: emailAddress,
                isDisabled: false,
              })) ?? []
            }
            onChange={(selectedValues) => {
              form.setFieldValue('inviteOthers', selectedValues);
            }}
            mode="multiselect"
            isSearchable
          />
        </FormControl>
      )}
    </Field>
  </div>
);

export default StepTwo;
