import { useQuery } from '@tanstack/react-query';

import { Floorplan } from '../../../@types/api/v0/rest/Floorplan';
import { Milestone } from '../../../@types/api/v1/rest/Milestone';
import { ProgressTrackingApi } from '../../../api/v1/bespoke/ProgressTrackingApi';
import { ProgressTrackingQueryKeys, QueryTopics } from '../../../constants/queries';
import { MilestoneProgressDrawer } from './MilestoneProgressDrawer';

export interface MilestoneProgressDrawerContainerProps {
  /**
   * The current floorplan. Its value will be `undefined` when the drawer is closed or if a summary row cell is
   * clicked.
   */
  floorplan?: Floorplan;
  /** Flag indicating whether or not the floorplan/milestone combination has momentum. */
  hasMomentum?: boolean | null;
  /** Flag indicating whether or not the drawer is open. */
  isOpen?: boolean;
  /**
   * Flag indicating whether or not Progress Tracking is enabled for this project. Even though this component is part of
   * the Progress Tracking page, the feature might not be enabled for the current project.
   */
  isProgressTrackingEnabled?: boolean;
  /** The current milestone. Its value may be `undefined` when the drawer is closed. */
  milestone?: Milestone;
  /** Handler to call when the user wants to close the drawer. */
  onClose: () => void;
  /** ID of the current project. */
  projectId: number;
  /** The currently-selected Time Travel date. */
  selectedDate?: Date;
  /** Progress value for either the current milestone or the current milestone-floorplan combination. */
  value?: number;
  /** ID of the walkthrough which generated the most recent progress entry. Provided for milestone-floorplan
   * combinations. This value is expected to be `undefined` when the drawer is closed or a summary row cell was clicked.
   */
  walkthroughId?: number;
}

export const MilestoneProgressDrawerContainer = (props: MilestoneProgressDrawerContainerProps) => {
  const {
    floorplan,
    hasMomentum,
    isOpen,
    isProgressTrackingEnabled,
    milestone,
    onClose,
    projectId,
    selectedDate,
    value,
    walkthroughId,
  } = props;

  const isTotalsRow = floorplan === undefined;
  const floorplanId = floorplan && Number.isFinite(floorplan.id) ? floorplan.id : 0;
  const milestoneId = milestone && Number.isFinite(milestone.id) ? milestone.id : 0;

  const totalMilestoneProgressHistoryQuery = useQuery({
    enabled: isTotalsRow && milestoneId > 0,
    queryKey: [
      QueryTopics.PROGRESS_TRACKING,
      ProgressTrackingQueryKeys.MILESTONE_TOTAL_PROGRESS_HISTORY,
      projectId,
      milestoneId,
    ],
    queryFn: async ({ signal }) =>
      (await ProgressTrackingApi.getTotalMilestoneProgressHistory(projectId, milestoneId, { signal })).data,
  });

  const floorMilestoneProgressHistoryQuery = useQuery({
    enabled: !isTotalsRow && milestoneId > 0 && floorplanId > 0,
    queryKey: [
      QueryTopics.PROGRESS_TRACKING,
      ProgressTrackingQueryKeys.MILESTONE_FLOOR_PROGRESS_HISTORY,
      projectId,
      milestoneId,
      floorplanId,
    ],
    queryFn: async ({ signal }) =>
      (await ProgressTrackingApi.getFloorMilestoneProgressHistory(projectId, milestoneId, floorplanId, { signal }))
        .data,
  });

  const currentQuery = isTotalsRow ? totalMilestoneProgressHistoryQuery : floorMilestoneProgressHistoryQuery;

  return (
    <MilestoneProgressDrawer
      floorplan={floorplan}
      hasMomentum={hasMomentum}
      isError={currentQuery.isError}
      isFetching={currentQuery.isFetching}
      isOpen={Boolean(isOpen)}
      isProgressTrackingEnabled={isProgressTrackingEnabled}
      milestone={milestone}
      milestoneProgressHistory={currentQuery.data}
      onClose={onClose}
      projectId={projectId}
      selectedDate={selectedDate}
      value={value}
      walkthroughId={walkthroughId}
    />
  );
};
