import { Button, Flex, Icon, IconButton, Link, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Annotation } from '../../../@types/api/v0/rest/Annotation';
import { Reply as AnnotationReply } from '../../../@types/api/v0/rest/Reply';
import { Store } from '../../../@types/redux/store';
import { setActiveDrawerItem } from '../../../actions/app';
import theme from '../../../theme';
import { countDisplay } from '../../../utils';
import { formatIsoDate } from '../../../utils/dateUtils';
import { DeleteIcon, EditIcon, ExternalLinkIcon } from '../../Icon';
import AddReply from './AddReply';
import AnnotationImage from './AnnotationImage';
import ContentBox from './ContentBox';
import DeleteConfirmation from './DeleteConfirmation';
import Reply from './Reply';

export interface AnnotationListItemProps {
  /** The current annotation to show. */
  annotation: Annotation;
  /** Flag indicating whether or not the annotation is read-only. */
  isReadOnly: boolean;
  /** Flag indicating whether or not a change to an annotation or reply is being submitted. */
  isSubmitting?: boolean;
  /** Handler called when the user deletes an annotation. */
  onAnnotationDelete: (annotation: Annotation) => void;
  /** Handler called when the user updates an annotation. */
  onAnnotationUpdate: (annotation: Annotation) => void;
  /** Handler to call when the user attempts to view the annotation in 360. */
  onAnnotationView: (annotation: Annotation) => void;
  /** Handler to call when the user attempts to create a new reply to this annotation. */
  onReplyCreate: (content: string) => void;
  /** Handler called when the user deletes a reply. */
  onReplyDelete: (reply: AnnotationReply) => void;
  /** Handler called when the user updates a reply. */
  onReplyUpdate: (reply: AnnotationReply) => void;
  /** ID of the current user. */
  userId?: number;
}

const FOCUS_ANIMATION_DURATION = 1000;

const AnnotationListItem = (props: AnnotationListItemProps) => {
  const {
    annotation,
    isReadOnly,
    isSubmitting,
    onAnnotationDelete,
    onAnnotationUpdate,
    onAnnotationView,
    onReplyCreate,
    onReplyDelete,
    onReplyUpdate,
    userId,
  } = props;

  const dispatch = useDispatch();

  const activeDrawerItem = useSelector((state: Store) => state.app.activeDrawerItem);

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [annotationContent, setAnnotationContent] = useState<string>(annotation.content);
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [showReplies, setShowReplies] = useState<boolean>(false);

  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!cardRef.current || activeDrawerItem !== annotation.id) {
      return undefined;
    }

    cardRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    setHasFocus(true);

    const timeout = setTimeout(() => {
      setHasFocus(false);
      dispatch(setActiveDrawerItem(undefined));
    }, FOCUS_ANIMATION_DURATION);

    return () => {
      setHasFocus(false);
      clearTimeout(timeout);
    };
  }, [activeDrawerItem, annotation.id, dispatch]);

  const createReply = (newReplyContent: string) => {
    setShowReplies(true);
    onReplyCreate(newReplyContent);
  };

  const deleteAnnotation = () => {
    setIsDeleting(false);
    onAnnotationDelete(annotation);
  };

  const toggleIsDeleting = () => {
    setIsDeleting((current) => !current);
  };

  const toggleIsEditing = () => {
    setIsEditing((current) => !current);
    setAnnotationContent(annotation.content);
  };

  const updateAnnotation = () => {
    const updatedAnnotation: Annotation = { ...annotation, content: annotationContent };
    onAnnotationUpdate(updatedAnnotation);
    setIsEditing(false);

    // Temporarily restore the annotation content. Will be updated once the parent finishes its network operations.
    setAnnotationContent(annotation.content);
  };

  // When the annotation changes (e.g. when the parent finishes refreshing the list of annotations), update the text in
  // state.
  useEffect(() => {
    setAnnotationContent(annotation.content);
  }, [annotation]);

  return (
    <Flex
      borderRadius={theme.radii.card}
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.04), 4px 2px 8px rgba(0, 0, 0, 0.06)"
      flexDirection="column"
      data-pendo-label="Touch drawer item"
      data-pendo-topic="annotations"
      margin="1rem 0.5rem 0"
      padding="0.75rem 1rem 1rem"
      ref={cardRef}
      role="listitem"
      textAlign="left"
      transition="box-shadow 70ms ease-in-out, outline 70ms ease-in-out"
      width="calc(100% - 2 * 0.5rem)"
      _first={{
        marginBlockStart: 0,
      }}
      {...(hasFocus && {
        boxShadow: `0px 0px 2px ${theme.colors.brand.primary[200]}, 2px 1px 4px ${theme.colors.brand.primary[200]}`,
        outline: `1px solid ${theme.colors.brand.primary[200]}`,
      })}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Text color={theme.colors.brand.gray[800]} fontWeight="bold" textStyle="bodyMedium">
          {annotation.kind}
        </Text>
        {!isReadOnly && (
          <Flex>
            <IconButton
              aria-label="Edit"
              color={theme.colors.brand.gray[800]}
              height="auto"
              icon={<Icon as={EditIcon} height="1.25rem" width="1.25rem" />}
              isDisabled={isEditing || isSubmitting}
              minHeight="unset"
              minWidth="unset"
              onClick={toggleIsEditing}
              padding="0.375rem"
              size="xs"
              variant="ghost"
            />
            <IconButton
              aria-label="Delete"
              color={theme.colors.brand.error[500]}
              height="auto"
              icon={<Icon as={DeleteIcon} height="1.25rem" width="1.25rem" />}
              isDisabled={isEditing || isSubmitting}
              minHeight="unset"
              minWidth="unset"
              onClick={toggleIsDeleting}
              padding="0.375rem"
              size="xs"
              variant="ghost"
            />
          </Flex>
        )}
      </Flex>
      {annotation.procore_link && (
        <Link
          alignItems="center"
          display="flex"
          href={annotation.procore_link}
          target="_blank"
          textStyle="detail"
          rel="noreferrer"
        >
          View on Procore
          <Icon aria-hidden as={ExternalLinkIcon} height="1.25rem" width="1.25rem" />
        </Link>
      )}
      <AnnotationImage annotation={annotation} onAnnotationView={onAnnotationView} />
      <Text as="div" fontWeight="bold" padding="0.75rem 0 0.5rem" textStyle="bodySmall">
        {`${annotation.poster.first_name} ${annotation.poster.last_name}`}
      </Text>
      <Text
        as="div"
        borderBottom={`1px solid ${theme.colors.brand.gray[300]}`}
        color={theme.colors.brand.gray[600]}
        marginBlockEnd="0.5rem"
        paddingBlockEnd="0.75rem"
        textStyle="detail"
      >
        {`Posted ${formatIsoDate(annotation.created)?.formattedDateTime}`}
      </Text>
      <ContentBox
        content={annotationContent}
        isEditing={isEditing}
        isSubmitting={isSubmitting}
        onCancelEdit={toggleIsEditing}
        onChange={setAnnotationContent}
        onSave={updateAnnotation}
        textAreaLabel="Markup description"
      />
      {annotation.replies.length > 0 && !showReplies && (
        <Button onClick={() => setShowReplies(true)} size="sm" variant="lowEmphasisV2" width="100%">
          {`View ${countDisplay(annotation.replies.length, 'Comment')}`}
        </Button>
      )}
      {showReplies &&
        annotation.replies.map((reply) => (
          <Reply
            key={reply.id}
            isReadOnly={userId !== reply.poster.id}
            isSubmitting={isSubmitting}
            onReplyDelete={onReplyDelete}
            onReplyUpdate={onReplyUpdate}
            reply={reply}
          />
        ))}
      <AddReply isSubmitting={isSubmitting} onSubmit={createReply} />
      {isDeleting && (
        <DeleteConfirmation onClose={toggleIsDeleting} onConfirm={deleteAnnotation} title="Delete Markup">
          <Text paddingBlockEnd="0.5rem">Are you sure you want to delete this markup?</Text>
          <Text paddingBlockEnd="0.5rem">
            You will no longer be able to read the original markup and its replies, or view the marked point in the 360
            image.
          </Text>
        </DeleteConfirmation>
      )}
    </Flex>
  );
};

export default AnnotationListItem;
