import { useState } from 'react';

import { Annotation } from '../../@types/api/v0/rest/Annotation';
import { Floorplan } from '../../@types/api/v0/rest/Floorplan';
import { Node } from '../../@types/api/v0/rest/Node';
import FullScreenToggle from './FullScreenToggle';
import MiniMap from './MiniMap';

interface MapProps {
  annotations: Annotation[];
  currentNode?: Node;
  floorplan: Floorplan;
  mapDirection: { x: number; y: number };
  nodes: Node[];
  onClickNode: (node: Node) => void;
}

/**
 * This component is very much the minimap -- not the map. The names should be swapped.
 * @todo Swap component names.
 */
const Map = (props: MapProps) => {
  const { annotations, currentNode, floorplan, mapDirection, nodes } = props;

  const [expanded, setExpanded] = useState<boolean>(false);

  const onClickNode = (node: Node) => {
    setExpanded(false);
    props.onClickNode(node);
  };

  return (
    <MiniMap
      annotations={annotations}
      currentNode={currentNode}
      detectionList={[]}
      floorplan={floorplan}
      initiallyShowZoom={false}
      mapDirection={mapDirection}
      mini
      miniExpanded={expanded}
      nodes={nodes}
      onClickNode={onClickNode}
      showNodes
    >
      <FullScreenToggle full={expanded} onClick={() => setExpanded((currentExpanded) => !currentExpanded)} />
    </MiniMap>
  );
};

export default Map;
