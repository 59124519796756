import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface DeleteConfirmationProps {
  /** Contents to render inside the confirmation dialog. */
  children?: ReactNode;
  /** Handler to call when the user wants to close the confirmation dialog. */
  onClose: () => void;
  /** Handler to call when the user confirms the action. */
  onConfirm: () => void;
  /** Title to render as the heading for the confirmation dialog. */
  title: string;
}

const DeleteConfirmation = (props: DeleteConfirmationProps) => {
  const { children, onClose, onConfirm, title } = props;

  return (
    <Modal closeOnOverlayClick={false} isCentered isOpen onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button mr={2} onClick={onClose} size="md" variant="mediumEmphasisV2">
            Cancel
          </Button>
          <Button onClick={onConfirm} size="md" variant="highEmphasisV2">
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteConfirmation;
