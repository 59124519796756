import { Box, Card, CardBody, CardProps, Heading, Stack, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { Floorplan } from '../../@types/api/v0/rest/Floorplan';
import { Project } from '../../@types/api/v0/rest/Project';
import theme from '../../theme';
import { formatAddress } from '../../utils/stringUtils';

export interface ControlCenterProps {
  /** Optional props to pass to the outer-most `Card` component wrapping the Control Center. */
  cardProps?: CardProps;
  /** List of elements to render within the Control Center. */
  fields?: ReactNode[];
  /** The current floorplan. */
  floorplan?: Floorplan;
  /** The current project. */
  project: Project;
}

const ControlCenter = (props: ControlCenterProps) => {
  const { cardProps, fields = [], floorplan, project } = props;

  const secondaryContent = floorplan
    ? floorplan.name
    : formatAddress(project.address_line, project.city, project.state, project.zip_code);

  // Note on the responsive props below: in order to accomodate the width of the controls on the Walkthrough and 360
  // pages, the controls need to wrap. On mobile, the `fields` should stack vertically below the project/floorplan name.
  // On desktop and above -- implemented in ChakraUI as `lg` (~992px) -- the fields should stack horizontally.
  return (
    <Card {...cardProps}>
      <CardBody>
        <Stack direction={{ base: 'column', lg: 'row' }}>
          <Box>
            <Heading as="h1" size="lg">
              {project.name}
            </Heading>
            <Text color={theme.colors.brand.gray[600]} textStyle="detail">
              {secondaryContent}
            </Text>
          </Box>
          <Stack
            alignItems={{ base: 'normal', lg: 'center' }}
            direction={{ base: 'column', lg: 'row' }}
            marginInlineStart={{ base: 0, lg: 'auto' }}
          >
            {fields}
          </Stack>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default ControlCenter;
