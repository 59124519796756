import { Center, Flex, HStack, Progress, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';

import { MilestoneWithProgress } from '../../@types/api/v1/bespoke/PortfolioDashboard';
import { PendoTopic } from '../../constants/analytics';
import theme from '../../theme';
import { getRoundedProgressValue } from '../../utils/progressUtils';
import { PercentChange } from './PercentChange';

const ActivityRow = ({ milestone }: { milestone: MilestoneWithProgress }) => {
  const roundedProgress = getRoundedProgressValue(milestone.value);

  return (
    <HStack verticalAlign="center" marginTop="1rem" spacing="1rem" fontSize="0.875rem">
      <Text flex={3} noOfLines={1} data-testid="milestone-name">
        {milestone.name}
      </Text>
      <Flex flex={1} justifyContent="flex-end" data-testid="percent-change">
        <PercentChange value={milestone.change} />
      </Flex>
      <HStack flex={3} justifyContent="center" spacing="0.5rem" data-testid="progress">
        <Text flex={1} textAlign="right">
          {roundedProgress}%
        </Text>
        <Progress aria-hidden value={roundedProgress ?? 0} variant="purpleProgress" flex={3} />
      </HStack>
    </HStack>
  );
};

const NoActivity = () => (
  <Center
    height="10rem"
    backgroundColor={theme.colors.brand.gray[50]}
    borderRadius="0.5rem"
    marginTop="0.5rem"
    color={theme.colors.brand.gray[600]}
  >
    No Activity
  </Center>
);

interface MilestoneActivityProps {
  /** A list of milestones with the highest activity in the last 30 days. */
  active: MilestoneWithProgress[];
  /** A list of milestones that started activity in the last 30 days (progressed from zero). */
  started: MilestoneWithProgress[];
  /** A list of milestones that were completed in the last 30 days. */
  completed: MilestoneWithProgress[];
  /** The number of rows to show in each tab (if this value is greater than the length of any rows passed in, then all the rows for that section will be displayed). */
  numRows?: number;
}

/**
 * A set of tabs that displays milestones with highest activity in the past 30 days,
 * milestones that started activity in the last 30 days, and milestones that were completed
 * in the last 30 days.
 *
 * Milestones are sorted by % Change, descending.
 */
export const MilestoneActivity = ({ active, started, completed, numRows = 4 }: MilestoneActivityProps) => {
  const activeRows = active.sort((totalA, totalB) => totalB.change - totalA.change).slice(0, numRows);
  const startedRows = started.sort((totalA, totalB) => totalB.change - totalA.change).slice(0, numRows);
  const completedRows = completed.sort((totalA, totalB) => totalB.change - totalA.change).slice(0, numRows);

  const hasActiveRows = activeRows.length > 0;
  const hasStartedRows = startedRows.length > 0;
  const hasCompletedRows = completedRows.length > 0;

  return (
    <Tabs maxWidth="30rem" size="sm" variant="enclosed">
      <TabList>
        <Tab data-pendo-topic={PendoTopic.PORTFOLIO} data-pendo-label="Click active milestone activity tab">
          Active
        </Tab>
        <Tab data-pendo-topic={PendoTopic.PORTFOLIO} data-pendo-label="Click started milestone activity tab">
          Started
        </Tab>
        <Tab data-pendo-topic={PendoTopic.PORTFOLIO} data-pendo-label="Click completed milestone activity tab">
          Completed
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          {hasActiveRows && activeRows.map((milestone) => <ActivityRow key={milestone.id} milestone={milestone} />)}
          {!hasActiveRows && <NoActivity />}
        </TabPanel>
        <TabPanel>
          {hasStartedRows && startedRows.map((milestone) => <ActivityRow key={milestone.id} milestone={milestone} />)}
          {!hasStartedRows && <NoActivity />}
        </TabPanel>
        <TabPanel>
          {hasCompletedRows &&
            completedRows.map((milestone) => <ActivityRow key={milestone.id} milestone={milestone} />)}
          {!hasCompletedRows && <NoActivity />}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
