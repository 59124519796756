import { Box, BoxProps } from '@chakra-ui/react';
import classNames from 'classnames';
import { ReactNode } from 'react';

import classes from './Layout.module.scss';

export interface ContentProps {
  applyGutters?: boolean;
  boxProps?: BoxProps;
  children?: ReactNode;
  constrainToPageHeight?: boolean;
}

/**
 * Main content outlet. Lots of opportunity for cleanup here once we switch over to exclusively right drawers.
 */
const Content = (props: ContentProps) => {
  const { applyGutters, boxProps, children, constrainToPageHeight } = props;

  return (
    <Box
      className={classNames(constrainToPageHeight && classes.constrainToPageHeight)}
      display={applyGutters ? 'flex' : undefined}
      flexFlow="column nowrap"
      left="var(--width-sidebar)"
      padding={applyGutters ? 'var(--gutter-page-vertical) var(--gutter-page-horizontal)' : undefined}
      position="relative"
      top={0}
      width="calc(100% - var(--width-sidebar))"
      {...boxProps}
    >
      {children}
    </Box>
  );
};

export default Content;
