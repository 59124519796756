import { Box, Button, Icon } from '@chakra-ui/react';
import { ReactNode, useState } from 'react';

import { ChevronDownIcon, ChevronRightIcon } from '../Icon';

export interface CollapsibleListItemProps {
  /** Children to be rendered inside this item. Perhaps another `CollapsibleListItem`! Perhaps a `Leaf`! */
  children?: ReactNode;
  /** Item depth. Used for indentation. */
  depth: number;
  /** Flag indicating whether or not this item should start open or closed. Defaults to open. */
  isInitiallyOpen?: boolean;
  /** A unique ID for this item. Used for accessibility. */
  itemId: string;
  /** The name of the item. This is the one you can click on to show/hide its children. */
  name: string;
  /** Optional handler to render the list item's name. Takes priority over the `name` prop if provided. */
  renderName?: () => ReactNode;
}

const CollapsibleListItem = (props: CollapsibleListItemProps) => {
  const { children, depth, isInitiallyOpen = true, itemId, name, renderName } = props;

  const [isOpen, setIsOpen] = useState<boolean>(isInitiallyOpen);

  return (
    <Box marginInlineStart={`calc(1rem * ${depth})`} data-testid={name}>
      <Button
        alignItems="center"
        aria-controls={itemId}
        aria-expanded={isOpen ? 'true' : 'false'}
        display="flex"
        fontWeight="bold"
        height="auto"
        justifyContent="flex-start"
        lineHeight={1.2}
        onClick={() => setIsOpen(!isOpen)}
        padding="0.5rem 0"
        textAlign="left"
        textTransform="uppercase"
        variant="unstyled"
        whiteSpace="normal"
        width="100%"
      >
        <Icon
          aria-hidden
          as={isOpen ? ChevronDownIcon : ChevronRightIcon}
          margin="0.25rem"
          width="1.5rem"
          height="1.5rem"
        />
        {renderName ? renderName() : name}
      </Button>
      <Box id={itemId} marginInlineStart="2rem">
        {isOpen && children}
      </Box>
    </Box>
  );
};

export default CollapsibleListItem;
