import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  item: {
    _focus: { bg: 'var(--gray-100)' },
  },
});

export const menuTheme = defineMultiStyleConfig({ baseStyle });
