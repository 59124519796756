import { AppAction } from '../@types/redux/appActions';
import { App } from '../@types/redux/store';
import { SAVE_FROM_LOCATION, SET_ACTIVE_DRAWER_ITEM } from '../constants/actionTypes';

export const initialAppState: App = {
  activeDrawerItem: undefined,
  fromLocation: undefined,
};

export default function reducer(state: App | undefined, action: AppAction): App {
  if (!state) {
    return initialAppState;
  }

  switch (action.type) {
    case SAVE_FROM_LOCATION:
      return { ...state, fromLocation: action.fromLocation };

    case SET_ACTIVE_DRAWER_ITEM:
      return { ...state, activeDrawerItem: action.activeDrawerItem };

    default:
      return state;
  }
}
