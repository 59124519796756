import { Box } from '@chakra-ui/react';
import { differenceInDays, formatDistanceToNow } from 'date-fns';
import capitalize from 'lodash/capitalize';

import theme from '../../theme';
import { formatIsoDate } from '../../utils/dateUtils';

export const ProjectUpdatedColumn = ({ updatedDate }: { updatedDate?: string }) => {
  if (!updatedDate) {
    return (
      <Box color={theme.colors.brand.gray[800]} fontSize="0.875rem">
        N/A
      </Box>
    );
  }

  const projectUpdated = new Date(updatedDate);
  const distanceToToday = differenceInDays(new Date(), projectUpdated);

  if (distanceToToday >= 30) {
    return (
      <Box color={theme.colors.brand.gray[800]} fontSize="0.875rem">
        {formatIsoDate(updatedDate)?.formattedDate ?? 'N/A'}
      </Box>
    );
  }

  return (
    <Box color={theme.colors.brand.gray[800]} fontSize="0.875rem">
      <Box>{capitalize(formatDistanceToNow(projectUpdated, { addSuffix: true }))}</Box>
      <Box color={theme.colors.brand.gray[600]} paddingBlockStart="0.125rem">
        {formatIsoDate(updatedDate)?.formattedDate}
      </Box>
    </Box>
  );
};
