import { Box, BoxProps } from '@chakra-ui/react';
import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';

import classes from './Chip.module.scss';

export interface ChipProps {
  boxProps?: BoxProps;
  children?: ReactNode;
  className?: string;
  variant?: 'default' | 'primary' | 'secondary';
}

const Chip = ({ boxProps, children, className, variant = 'default' }: ChipProps): ReactElement => (
  <Box
    className={classNames(
      classes.chip,
      {
        [classes.chipPrimary]: variant === 'primary',
        [classes.chipSecondary]: variant === 'secondary',
      },
      className
    )}
    {...boxProps}
  >
    {children}
  </Box>
);

export default Chip;
