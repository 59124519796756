import { HStack, Image, Link, Text, VStack } from '@chakra-ui/react';
import { MouseEvent, ReactElement, SyntheticEvent, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { PendoTopic } from '../../constants/analytics';
import Routes from '../../routes';

import classes from './ProjectInfoWithThumbnail.module.scss';

interface Address {
  street: string;
  city: string;
  state: string;
  zip: string;
}

interface ProjectInfoWithThumbnailProps {
  projectId: number;
  projectName: string;
  projectAddress: Address;
  thumbnail?: string;
}

/**
 * Renders the project image, project name and project address.
 */
export const ProjectInfoWithThumbnail = ({
  projectId,
  projectName,
  projectAddress,
  thumbnail,
}: ProjectInfoWithThumbnailProps): ReactElement => {
  const initialImageSource = thumbnail?.toLowerCase()?.match(/\.(jpg|jpeg|png|gif|svg)$/iu)
    ? thumbnail
    : 'resources/project-default.jpg';

  const [imageSource, setImageSource] = useState(initialImageSource);

  const history = useHistory();

  const progressTrackingPath = generatePath(Routes.PROJECT_PROGRESS_TRACKING, { id: projectId });

  // Using an <a> tag allows users to interact normally with the link (e.g. being able to right click and open in a new
  // tab). Preventing the default behavior allows us to perform a client-side navigation.
  const goToProgressTracking = (event: MouseEvent<HTMLAnchorElement>) => {
    if (event.ctrlKey || event.metaKey) {
      return;
    }

    event.preventDefault();
    history.push(progressTrackingPath);
  };

  const onImageLoadError = (event: SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.onerror = null;
    // Alternatively, we could set currentTarget.src here, but it would not survive re-renders.
    setImageSource('resources/project-default.jpg');
  };

  return (
    <HStack className={classes.projectNameRowContainer}>
      <Link
        data-pendo-topic={PendoTopic.PORTFOLIO}
        data-pendo-label="Go to Progress Tracking from project thumbnail"
        href={progressTrackingPath}
        onClick={goToProgressTracking}
      >
        <Image alt={projectName} className={classes.projectImage} onError={onImageLoadError} src={imageSource} />
      </Link>
      <VStack alignItems="flex-start" flex={1} spacing={0}>
        <Link
          data-pendo-topic={PendoTopic.PORTFOLIO}
          data-pendo-label="Go to Progress Tracking from project name"
          className={classes.projectName}
          href={progressTrackingPath}
          onClick={goToProgressTracking}
        >
          {projectName}
        </Link>
        <Text className={classes.projectAddress}>
          {projectAddress.street}, {projectAddress.city}, {projectAddress.state} {projectAddress.zip}
        </Text>
      </VStack>
    </HStack>
  );
};
