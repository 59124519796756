import Cookies from 'js-cookie';
import { ReactElement, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { login } from '../../actions/auth';
import { getAuthToken as getProcoreAuth } from '../../actions/procore';
import api from '../../api';

const LoginCallback = (): ReactElement | null => {
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const oneTimeAuthToken = useMemo<string | null>(
    () => new URLSearchParams(location.search).get('one_time_token'),
    [location.search]
  );

  useEffect(() => {
    if (!oneTimeAuthToken) {
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('id');
    queryParams.delete('one_time_token');
    history.replace({
      search: queryParams.toString(),
    });
  }, [oneTimeAuthToken, location.search, history]);

  useEffect(() => {
    if (!oneTimeAuthToken) {
      return;
    }

    async function loginWithToken() {
      try {
        const tokens = await api.auth.getJWTfromOTT(oneTimeAuthToken);
        Cookies.set('onsitevision_access', tokens.access, { domain: window.location.hostname, expires: 1 });
        Cookies.set('onsitevision_refresh', tokens.refresh, { domain: window.location.hostname, expires: 30 });

        const userProfile = await api.auth.me();
        dispatch(login(userProfile));
        dispatch(getProcoreAuth());

        history.push('/');
      } catch (_) {
        console.warn('[LoginCallback] Token expired! Redirecting to login...');
        history.push('/login');
      }
    }

    loginWithToken();
  }, [dispatch, history, oneTimeAuthToken]);

  // Render nothing since we should redirect immediately.
  return null;
};

export default LoginCallback;
