import axios, { AxiosRequestConfig } from 'axios';

import {
  Location,
  PotentialRfiAssignee,
  PotentialRfiReceivedFrom,
  PotentialRfiResponsibleContractor,
} from '../../../@types/procore/v1/Project';
import { getProcoreRequestConfiguration } from '../../procore';

export const ProjectApi = {
  /**
   * Retrieve the list of Procore Locations associated with a given Procore Company and Project.
   * @see https://developers.procore.com/reference/rest/v1/locations?version=1.0#list-project-locations
   * @param companyId Unique identifier for the Procore Company.
   * @param projectId Unique identifier for the Procore Project.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the list of Procore Locations.
   */
  listLocations: (companyId: number, projectId: number, requestConfig?: AxiosRequestConfig) => {
    const baseRequestConfig = getProcoreRequestConfiguration(companyId);

    return axios.get<Location[]>(`/rest/v1.0/projects/${projectId}/locations`, {
      ...baseRequestConfig,
      ...requestConfig,
    });
  },
  /**
   * Retrieve the list of possible assignees for an RFI by Procore Company and Project.
   * @see https://developers.procore.com/reference/rest/v1/potential-assignees?version=1.0#get-a-list-of-possible-assignees-for-an-rfi
   * @param companyId Unique identifier for the Procore Company.
   * @param projectId Unique identifier for the Procore Project.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the list of potential RFI assignees.
   */
  listPotentialRfiAssignees: (companyId: number, projectId: number, requestConfig?: AxiosRequestConfig) => {
    const baseRequestConfig = getProcoreRequestConfiguration(companyId);

    return axios.get<PotentialRfiAssignee[]>(`/rest/v1.0/projects/${projectId}/rfis/potential_assignees`, {
      ...baseRequestConfig,
      ...requestConfig,
    });
  },
  /**
   * Retrieve the list of potential users from whom an RFI can be received.
   *
   * @remark
   * This is an undocumented endpoint. We should evaluate whether or not the "Received From" field on the RFI is needed
   * or if there is a better substitute. As of 2024-08-13, the API documentation website is not clear on this.
   *
   * @param companyId Unique identifier for the Procore Company.
   * @param projectId Unique identifier for the Procore Project.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the list of potential RFI "Received From" users.
   */
  listPotentialRfiReceivedFroms: (companyId: number, projectId: number, requestConfig?: AxiosRequestConfig) => {
    const baseRequestConfig = getProcoreRequestConfiguration(companyId);

    return axios.get<PotentialRfiReceivedFrom[]>(`/rest/v1.0/projects/${projectId}/rfis/potential_received_froms`, {
      ...baseRequestConfig,
      ...requestConfig,
    });
  },
  /**
   * Retrieve the list of potential responsible contractors for an RFI by Procore Company and Project.
   *
   * @remark
   * This is an undocumented endpoint. We should not be using it. Procore RFIs have an "Assigned From" field, and when
   * an RFI is created on the Procore website (app.procore.com), the "Responsible Contractor" field is automatically
   * populated with the company name of the "Assigned From" user. When a request is made to create a new RFI, the field
   * is similarly populated, so we don't need to even have a form field for it.
   *
   * @param companyId Unique identifier for the Procore Company.
   * @param projectId Unique identifier for the Procore Project.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the list of potential RFI responsible contractors.
   */
  listPotentialRfiResponsibleContractors: (
    companyId: number,
    projectId: number,
    requestConfig?: AxiosRequestConfig
  ) => {
    const baseRequestConfig = getProcoreRequestConfiguration(companyId);

    return axios.get<PotentialRfiResponsibleContractor[]>(
      `/rest/v1.0/projects/${projectId}/rfis/potential_responsible_contractors`,
      {
        ...baseRequestConfig,
        ...requestConfig,
      }
    );
  },
};
