import { Slider, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/react';
import { ReactElement } from 'react';

import { BrighteningIconFull, BrighteningIconPartial } from '../../Icon';

import classes from './Brightening.module.scss';

interface Props {
  onChange: (brightness: 1 | 2 | 3 | 4) => void;
}

const BrighteningSlider = ({ onChange }: Props): ReactElement => (
  <div className={classes.sliderContainer}>
    <BrighteningIconFull aria-hidden />
    <Slider
      aria-label="Brightness"
      defaultValue={3}
      max={4}
      min={1}
      onChange={onChange}
      orientation="vertical"
      step={1}
    >
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb />
    </Slider>
    <BrighteningIconPartial aria-hidden />
  </div>
);

export default BrighteningSlider;
