import { AxiosRequestConfig } from 'axios';

import {
  ProgressTrackingMilestoneProgressHistory,
  ProgressTrackingTableData,
} from '../../../@types/api/v1/bespoke/ProgressTracking';
import { get } from '../../next';

export const ProgressTrackingApi = {
  /**
   * Retrieve the current progress data (with momentum) for a project.
   * @param projectId The ID of the project.
   * @param date Date to request progress data up to, formatted as an RFC 3339 timestamp.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the progress data for the project.
   */
  getProgressData: (projectId: number, date: string, requestConfig?: AxiosRequestConfig) => {
    const queryParams = new URLSearchParams();
    queryParams.set('date', date);

    return get<ProgressTrackingTableData>(
      `api/v1/bespoke/progress_tracking/${projectId}/progress/?${queryParams.toString()}`,
      requestConfig
    );
  },
  /**
   * Get the progress history for a specific milestone within a project.
   * @param projectId The ID of the project.
   * @param milestoneId The ID of the milestone.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the progress history for a milestone.
   */
  getTotalMilestoneProgressHistory: (projectId: number, milestoneId: number, requestConfig?: AxiosRequestConfig) =>
    get<ProgressTrackingMilestoneProgressHistory>(
      `api/v1/bespoke/progress_tracking/${projectId}/milestone/${milestoneId}/progress_history/`,
      requestConfig
    ),
  /**
   * Get the progress history for a specific milestone, on a specific floor/floorplan, within a project.
   * @param projectId The ID of the project.
   * @param milestoneId The ID of the milestone.
   * @param floorplanId The ID of the floorplan.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the progress history for a milestone on a floor.
   */
  getFloorMilestoneProgressHistory: (
    projectId: number,
    milestoneId: number,
    floorplanId: number,
    requestConfig?: AxiosRequestConfig
  ) =>
    get<ProgressTrackingMilestoneProgressHistory>(
      `/api/v1/bespoke/progress_tracking/${projectId}/floorplan_milestone_progress_history/?floorplan_id=${floorplanId}&milestone_id=${milestoneId}`,
      requestConfig
    ),
  /**
   * Retrieve an export of the progress data for a project as of a date. At the moment, the API only sends a response in
   * CSV format.
   * @param projectId The ID of the project.
   * @param date The date to request data for. Expected to be a RFC 3339 timestamp.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping a `Blob` of data.
   */
  getProgressDataExport: (projectId: number, date: string, requestConfig?: AxiosRequestConfig) => {
    const queryParams = new URLSearchParams();
    queryParams.set('date', date);

    return get<Blob>(`/api/v1/bespoke/progress_tracking/${projectId}/progress/export/?${queryParams.toString()}`, {
      responseType: 'blob',
      ...requestConfig,
    });
  },
};
