import { Checkbox, FormControl, FormErrorMessage, FormLabel, Select, Stack, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { Field, FieldProps, useFormikContext } from 'formik';

import { UserManagementApi } from '../../../api/next';
import { QueryTopics, UserManagementQueryKeys } from '../../../constants/queries';
import usePermissions from '../../../hooks/usePermissions';
import theme from '../../../theme';
import { List, ListOption } from '../../List/List';
import LoadingIndicator from '../../LoadingIndicator';
import { MultipleInputField } from '../../MultipleInputField/MultipleInputField';
import { FormValues } from './InviteUserModalConstants';

import classes from './StepOne.module.scss';

// TODO: form field active/validation colors should be assigned to the list rather than the search box

const StepOne = () => {
  const formikContext = useFormikContext<FormValues>();

  const { accountsWithAccessAllProjectPermission, accountsWithInvitePermission } = usePermissions();

  const accountProjectsQuery = useQuery({
    enabled: Boolean(formikContext.values.account),
    queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.ACCOUNT_PROJECTS, formikContext.values.account],
    queryFn: async ({ signal }) =>
      (await UserManagementApi.getAccountProjects(formikContext.values.account ?? 0, { signal })).data,
  });

  const accountOptions: ListOption[] = accountsWithInvitePermission.map(({ id, name }) => ({
    id,
    text: name,
  }));
  const projectListOptions: ListOption[] = (accountProjectsQuery.data?.projects ?? []).map(({ id, name }) => ({
    id,
    text: name,
  }));
  const canGrantAccessAllProjectsPermission = accountsWithAccessAllProjectPermission.some(
    ({ id }) => id === Number(formikContext.values.account)
  );
  const selectedAccountName =
    accountOptions.find(({ id }) => id === Number(formikContext.values.account))?.text ?? 'this account';

  return (
    <div className={classes.inviteUserStepOne}>
      <Field name="account">
        {({ field, form }: FieldProps<string, FormValues>) => (
          <FormControl className={classes.formControl} isInvalid={Boolean(form.errors.account) && form.touched.account}>
            <FormLabel className={classes.formLabel}>Account</FormLabel>
            <Select
              placeholder="Select account"
              {...field}
              onChange={(event) => {
                form.setFieldValue('projects', []);
                field.onChange(event);
              }}
            >
              {accountOptions.map(({ id, text }) => (
                <option key={`account-option-${id}`} value={id}>
                  {text}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{form.errors.account}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Field name="to">
        {({ field, form }: FieldProps<string[], FormValues>) => (
          <FormControl className={classes.formControl} isInvalid={Boolean(form.errors.to) && form.touched.to}>
            <FormLabel id="multiple-input-label" className={classes.formLabel}>
              To
            </FormLabel>
            <MultipleInputField
              aria-labelledby="multiple-input-label"
              placeholder="Enter email addresses one at a time or separated by a comma"
              onPillsChange={(pills) => {
                form.setFieldValue('to', pills);
                form.setFieldValue('inviteOthers', pills);
                form.setFieldTouched('to', true, false);
              }}
              value={field.value}
            />
            <FormErrorMessage>
              {/* If there are multiple email validation errors (multiple invalid emails entered in the field) we want to only show one email validation error (instead of spamming the user with multiple 'Invalid emails' errors) */}
              {Array.isArray(form.errors.to) ? new Set(form.errors.to) : form.errors.to}
            </FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Field name="projects">
        {({ field, form }: FieldProps<string[], FormValues>) => (
          <FormControl
            isDisabled={!form.values.account || accountProjectsQuery.isFetching || form.values.accessAllProjects}
            className={classes.formControl}
            isInvalid={Boolean(form.values.account) && Boolean(form.errors.projects) && form.touched.projects}
          >
            <Stack direction="row" justifyContent="space-between">
              <FormLabel id="add-to-projects" className={classes.formLabel}>
                Add to project(s)
              </FormLabel>
              <div className={classNames(classes.numProjects, !form.values.account && classes.disabled)}>
                {form.values.projects?.length ?? 0} selected
              </div>
            </Stack>
            <List
              {...field}
              aria-labelledby="add-to-projects"
              isDisabled={!form.values.account || accountProjectsQuery.isFetching || form.values.accessAllProjects}
              emptyPlaceholder={!form.values.account ? '' : 'There are no projects for this account.'}
              searchPlaceholder="Search Projects"
              options={projectListOptions}
              onChange={(selectedValues) => {
                form.setFieldValue('projects', selectedValues, true);
                form.setFieldTouched('projects', true, false);
              }}
              mode="multiselect"
              isSearchable
            />
            {accountProjectsQuery.isFetching && <LoadingIndicator />}
            <FormErrorMessage>{form.errors.projects}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      {canGrantAccessAllProjectsPermission && (
        <Field name="accessAllProjects">
          {({ field, form }: FieldProps<string, FormValues>) => (
            <FormControl isDisabled={!form.values.account}>
              <Checkbox
                {...field}
                isChecked={form.values.accessAllProjects}
                className={classes.accessAllProjects}
                onChange={(event) => {
                  if (event.target.checked) {
                    form.setFieldValue('projects', []);
                  }
                  field.onChange(event);
                }}
              >
                Can access all projects added to {selectedAccountName}
              </Checkbox>
            </FormControl>
          )}
        </Field>
      )}
      {accountProjectsQuery.isError && (
        <Text color={theme.colors.brand.errorInternal} fontSize="0.875rem">
          Failed to retrieve projects for this account. Please try again later.
        </Text>
      )}
    </div>
  );
};

export default StepOne;
