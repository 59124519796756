/** Conditionally pluralizes an input string based on the provided count */
export const maybePluralize = (
  /** Input text in the singular form (i.e. not pluralized) */
  text: string,
  count: number,
  options?: { includeCount: boolean }
) => {
  let parsedText = text;

  if (count !== 1) {
    parsedText = `${text}s`;
  }
  if (options?.includeCount) {
    parsedText = `${count} ${parsedText}`;
  }

  return parsedText;
};
