import { maybePluralize } from './maybePluralize';

/**
 * Given a value representing progress, return either a hard-coded value or the rounded value.
 * This is the value the user will see for all Progress values on the site.
 *
 * If the input is null, return null. This is useful for non-applicable milestone cases.
 * If the input is below 0, return 0.
 * If the input is between 0 and 1, return 1.
 * If the input is between 99 and 100, return 99.
 * If the input is greater than or equal to 100, return 100.
 * For anything else, return the rounded (no decimals) input value.
 */
export const getRoundedProgressValue = (progress: number | null) => {
  if (typeof progress !== 'number' || !Number.isFinite(progress)) {
    return null;
  }

  if (progress < 0) {
    console.warn(`[utils/progressUtils.ts] Progress value < 0. Received value: ${progress}`);
    return 0;
  }

  // If the progress value is between 0 and 1, we show 1, as opposed to potentially rounding down to 0 - which would be misleading as it would make it seem like there is no progress.
  if (progress > 0 && progress < 1) {
    return 1;
  }

  // We don't round 99.5+ up to 100, as that would be misleading and make it seem like the project is complete.
  if (progress > 99 && progress < 100) {
    return 99;
  }

  // No need to round here - we already know we should return 100.
  if (progress === 100) {
    return 100;
  }

  if (progress > 100) {
    console.warn(`[utils/progressUtils.ts] Progress value > 100. Received value: ${progress}`);
    return 100;
  }

  return Math.round(progress);
};

export const getTimeElapsedText = (daysElapsed: number) => {
  if (daysElapsed >= 7) {
    const weeksElapsed = Math.ceil(daysElapsed / 7);
    return maybePluralize('week', weeksElapsed, { includeCount: true });
  } else {
    return maybePluralize('day', daysElapsed, { includeCount: true });
  }
};
