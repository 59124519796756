import { Box, Button, FormControl, FormLabel, Textarea, VisuallyHidden } from '@chakra-ui/react';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';

export interface AddReplyProps {
  /** Flag indicating whether or not a reply is being created. */
  isSubmitting?: boolean;
  /** Handler to call when the user submits a reply for creation. */
  onSubmit: (content: string) => void;
}

const AddReply = (props: AddReplyProps) => {
  const { isSubmitting, onSubmit } = props;

  const [replyContent, setReplyContent] = useState<string>('');

  const isSubmitDisabled = !replyContent;

  const handleReplyContentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setReplyContent(event.target.value);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit(replyContent);
  };

  // When submission completes, clear the text box.
  useEffect(() => {
    if (!isSubmitting) {
      setReplyContent('');
    }
  }, [isSubmitting]);

  return (
    <Box paddingBlockStart="0.5rem">
      <form onSubmit={handleSubmit}>
        <FormControl>
          <VisuallyHidden as={FormLabel}>Reply</VisuallyHidden>
          <Textarea placeholder="Write a reply" onChange={handleReplyContentChange} value={replyContent} />
        </FormControl>
        <Button
          data-pendo-label="Add reply"
          data-pendo-topic="annotations"
          isDisabled={isSubmitDisabled}
          isLoading={isSubmitting}
          marginBlockStart="0.5rem"
          size="sm"
          type="submit"
          variant="mediumEmphasisV2"
          width="100%"
        >
          Post
        </Button>
      </form>
    </Box>
  );
};

export default AddReply;
