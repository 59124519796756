import { Button } from '@chakra-ui/react';
import { ReactNode, useEffect, useRef } from 'react';

import theme from '../../theme';

type ListItemBase = { id: number | string; name: string };

export interface CollapsibleListLeafProps<T extends ListItemBase> {
  /**
   * List leaf nodes are rendered as buttons. If `children` is passed, they will be rendered within the button's inner
   * content. If omitted, the item's name will be rendered instead.
   */
  children?: ReactNode;
  /** Flag indicating whether or not this leaf is 'active' or not. Active changes the styling. */
  isActive?: boolean;
  /** The item being represented in the list. */
  item: T;
  /** Handler to call when this leaf is clicked. */
  onClick: (item: T) => void;
}

const CollapsibleListLeaf = <T extends ListItemBase>(props: CollapsibleListLeafProps<T>) => {
  const { children, isActive, item, onClick } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (buttonRef.current && isActive) {
      buttonRef.current.scrollIntoView({
        block: 'center',
      });
    }
  }, [buttonRef, isActive]);

  return (
    <Button
      alignItems="flex-start"
      border="2px solid transparent"
      display="flex"
      flexDirection="column"
      fontWeight="normal"
      height="fit-content"
      isActive={isActive}
      justifyContent="center"
      margin="0.125rem 0"
      onClick={() => onClick(item)}
      padding="0.75rem 1rem"
      ref={buttonRef}
      textAlign="left"
      variant="ghost"
      whiteSpace="normal"
      width="100%"
      _active={{
        backgroundColor: theme.colors.brand.primary[100],
        border: `2px solid ${theme.colors.brand.primary[500]}`,
        color: theme.colors.brand.gray[900],
      }}
      _first={{
        marginBlockStart: 0,
      }}
      _last={{
        marginBlockEnd: 0,
      }}
    >
      {item.name}
      {children}
    </Button>
  );
};

export default CollapsibleListLeaf;
