import classNames from 'classnames';
import { ReactElement } from 'react';

import { formatIsoDate } from '../../utils/dateUtils';

import classes from './PageTitleCard.module.scss';

export interface PageTitleCardProps {
  className?: string;
  /** ISO 8601 datetime string. Converted to a Date instance and formatted. */
  date?: string;
  /** Prefix to be placed in front of the formatted date and time. */
  datePrefix?: string;
  /** Line of text to display immediately below the title. */
  subtitle?: string;
  /** Title for this card. */
  title: string;
}

const PageTitleCard = (props: PageTitleCardProps): ReactElement => {
  const { className, date, datePrefix, subtitle, title } = props;
  const displayDateTime = formatIsoDate(date)?.formattedDateTime;
  return (
    <div className={classNames(classes.card, className)}>
      <h1 className={classes.title}>{title}</h1>
      {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
      {displayDateTime && (
        <>
          <hr className={classes.divider} />
          <div className={classes.date}>
            {datePrefix && <span className={classes.datePrefix}>{datePrefix}:</span>}
            {displayDateTime}
          </div>
        </>
      )}
    </div>
  );
};

export default PageTitleCard;
