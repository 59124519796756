import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { FormikProps } from 'formik';
import { useMemo, useRef } from 'react';

import { Floorplan } from '../../../@types/api/v0/rest/Floorplan';
import { Node, NodeSnapshotEmailRequestBody } from '../../../@types/api/v0/rest/Node';
import { Project } from '../../../@types/api/v0/rest/Project';
import { Walkthrough } from '../../../@types/api/v0/rest/Walkthrough';
import { UserManagementApi } from '../../../api/next';
import { AuthApi } from '../../../api/v0/rest/AuthApi';
import { NodeApi } from '../../../api/v0/rest/NodeApi';
import { Button, LoadingIndicator } from '../../../components';
import { SnapshotIcon } from '../../../components/Icon';
import Toast from '../../../components/Toast';
import { AuthQueryKeys, QueryTopics, UserManagementQueryKeys } from '../../../constants/queries';
import theme from '../../../theme';
import { formatIsoDate } from '../../../utils/dateUtils';
import SnapshotForm, { SnapshotFormValues } from './SnapshotForm';

import classes from './SnapshotModal.module.scss';

export interface SnapshotModalContainerProps {
  /** Optional brightness factor to apply to the image. */
  brightness?: number;
  /** Floorplan which was walked to produce the node for this snapshot image. */
  floorplan: Floorplan;
  /** Base 64 encoded image data to download or email. Generated by the user on the 360º viewer. */
  image?: string;
  /** Flag indicating whether or not the modal is open. */
  isOpen?: boolean;
  /** Walkthrough node whose image was used to generate this snapshot. */
  node?: Node;
  /** Handler to call when the user wants to close the modal. */
  onClose: () => void;
  /** Project associated with this node/walkthrough/floorplan. */
  project: Project;
  /** Walkthrough which produced this snapshot's node. */
  walkthrough?: Walkthrough;
}

const SnapshotModalContainer = (props: SnapshotModalContainerProps) => {
  const { brightness, floorplan, image, isOpen, node, onClose, project, walkthrough } = props;

  const projectUsersQuery = useQuery({
    enabled: Boolean(isOpen),
    queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.PROJECT_USERS, project.id],
    queryFn: async ({ signal }) => (await UserManagementApi.getProjectUsers(project.id, false, { signal })).data,
  });

  const userProfileQuery = useQuery({
    enabled: Boolean(isOpen),
    queryKey: [QueryTopics.AUTH, AuthQueryKeys.USER_PROFILE],
    queryFn: async ({ signal }) => (await AuthApi.getUserProfile({ signal })).data,
  });

  const sendEmailMutation = useMutation({
    mutationFn: ({ values }: { values: SnapshotFormValues }) => {
      const nodeId = node?.id ?? '';
      const requestBody: NodeSnapshotEmailRequestBody = {
        ...values,
        image: image!, // At this point, the image has to be a string.
        node: nodeId,
        recipients: values.recipients.map((recipient) => recipient.label),
      };
      return NodeApi.sendSnapshotEmail(nodeId, requestBody);
    },
    onError: () => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => {
          return (
            <Toast
              {...props}
              title="Error"
              description="Failed to send snapshot email. Please try again later."
              status="error"
            />
          );
        },
      });
    },
    onSuccess: () => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (toastProps) => (
          <Toast {...toastProps} title="Success" description="Snapshot shared." status="success" />
        ),
      });

      onClose();
    },
  });

  const formRef = useRef<FormikProps<SnapshotFormValues>>(null);

  const toast = useToast();

  const defaultSubject = useMemo(() => {
    const userFirstName = userProfileQuery.data?.first_name;
    const userLastName = userProfileQuery.data?.last_name;
    const userEmailAddress = userProfileQuery.data?.email;

    if (userFirstName && userLastName) {
      return `${userFirstName} ${userLastName} is sharing image from ${project.name}`;
    } else if (userEmailAddress) {
      return `${userEmailAddress} is sharing image from ${project.name}`;
    } else {
      return `An image has been shared from ${project.name}`;
    }
  }, [project, userProfileQuery.data]);

  const nodeIndex = node?.index ?? 0;
  const shortDate = walkthrough ? format(new Date(walkthrough.when), 'MMddyyyy') : 'pending';
  const messageDisplayDate = walkthrough ? formatIsoDate(walkthrough.when)?.formattedDate : '<date pending>';
  const defaultMessage = `I'm sharing an image of the ${project.name} project. It's a snapshot of Image #${nodeIndex} on ${floorplan.name}, which was originally captured on ${messageDisplayDate}.`;
  const filename = `snapshot-${project.name}-${floorplan.name}-${shortDate}-${nodeIndex}.jpg`;

  const attemptSubmitForm = () => {
    formRef.current?.submitForm();
  };

  const getModalBodyContents = () => {
    if (projectUsersQuery.isFetching || userProfileQuery.isFetching) {
      return <LoadingIndicator />;
    }
    if (projectUsersQuery.isError || userProfileQuery.isError) {
      return <Text color={theme.colors.brand.error[200]}>An error occurred. Please try again later.</Text>;
    }
    if (!image) {
      return <Text color={theme.colors.brand.gray[600]}>No image provided.</Text>;
    }

    return (
      <SnapshotForm
        brightness={brightness}
        filename={filename}
        image={image}
        initialValues={{
          body: defaultMessage,
          recipients: [],
          subject: defaultSubject,
        }}
        onSubmit={sendEmail}
        ref={formRef}
        users={projectUsersQuery.data?.users ?? []}
      />
    );
  };

  const sendEmail = (values: SnapshotFormValues) => {
    sendEmailMutation.mutate({ values });
  };

  return (
    <Modal closeOnOverlayClick={false} isCentered isOpen={Boolean(isOpen)} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent data-pendo-label="Modal" data-pendo-topic="snapshot">
        <ModalCloseButton data-pendo-label="Modal close" data-pendo-topic="snapshot" />
        <ModalHeader className={classes.modalHeader}>
          <Box className={classes.iconContainer}>
            <SnapshotIcon aria-hidden />
          </Box>
          {`Snapshot of Image #${nodeIndex}`}
        </ModalHeader>
        <ModalBody>{getModalBodyContents()}</ModalBody>
        <ModalFooter>
          <Button
            data-pendo-label="Cancel"
            data-pendo-topic="snapshot"
            mr={3}
            onClick={onClose}
            variant="mediumEmphasis"
          >
            Cancel
          </Button>
          <Button
            data-pendo-label="Send email"
            data-pendo-topic="snapshot"
            isDisabled={
              projectUsersQuery.isError ||
              userProfileQuery.isError ||
              projectUsersQuery.isFetching ||
              userProfileQuery.isFetching ||
              !image
            }
            isLoading={sendEmailMutation.isLoading}
            onClick={attemptSubmitForm}
            variant="highEmphasis"
          >
            Send Email
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SnapshotModalContainer;
