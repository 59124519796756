import { Box, ButtonGroup, ChakraProps, Checkbox } from '@chakra-ui/react';
import classNames from 'classnames';
import { ReactNode, useState } from 'react';

import Button from '../Button';
import SearchInput from '../SearchInput/SearchInput';

import classes from './List.module.scss';

export interface ListOption {
  /** The id of the option. */
  id: string | number;
  /** Text to be used in sorting/filtering. If no render function is provided, this is used as the display text for this option. */
  text: string;
  /** Whether or not this option is disabled. */
  isDisabled?: boolean;
  /** Optional style object to apply to an option */
  styles?: ChakraProps;
  /** Optional render function. If not provided, the text property is used as the display text for this option. */
  render?: () => ReactNode;
}

export interface ListProps {
  /** Array of options to render in the list */
  options: ListOption[];
  /** The mode of the list. "Default" allows one item to be selected at a time. "Multiselect" renders checkboxes and "select all"/"deselect all" buttons */
  mode?: 'default' | 'multiselect';
  /** Whether the List is disabled or not */
  isDisabled?: boolean;
  /** Whether to include a search input or not */
  isSearchable?: boolean;
  /** Placeholder text for the search input */
  searchPlaceholder?: string;
  /** Message to be shown when the list is empty. */
  emptyPlaceholder?: string;
  /** Called any time the selected values are changed, with the selected value ID's as a parameter */
  onChange: (selectedValues: (string | number)[]) => void;
  value: (string | number)[];
}

export const List = ({
  options = [],
  mode = 'default',
  onChange,
  isDisabled = false,
  isSearchable = false,
  searchPlaceholder = 'Search',
  emptyPlaceholder = 'No options provided',
  value = [],
}: ListProps) => {
  // ! note: all option ID's must be unique
  // TODO: add tests that test what happens when option IDs are not unique

  const [searchInputValue, setSearchInputValue] = useState('');

  /**
   * Called when an option is clicked/selected in Default mode
   * @param optionId The ID of the selected option
   */
  const handleClickDefaultModeOption = (optionId: string | number) => {
    onChange([optionId]);
  };

  const handleChangeOption = (optionId: string | number) => {
    const newValues = [...value];

    const optionIndex = newValues.indexOf(optionId);
    if (optionIndex < 0) {
      newValues.push(optionId);
    } else {
      newValues.splice(optionIndex, 1);
    }

    onChange(newValues);
  };

  /**
   * Set all values to the provided value (true or false)
   * @param value The value to which all values are set
   */
  const setAllValues = (value: boolean) => {
    let newValues: (string | number)[] = [];

    if (value) {
      newValues = options.map((option) => option.id);
    }

    onChange([...newValues]);
  };

  /**
   * Called when the Select All button is clicked
   */
  const handleClickSelectAll = () => {
    setAllValues(true);
  };

  /** Called when the Deselect All button is clicked */
  const handleClickDeselectAll = () => {
    setAllValues(false);
  };

  const filteredOptions = options.filter(({ text }) => text.toLowerCase().includes(searchInputValue.toLowerCase()));

  if (options.length === 0) {
    return <div>{emptyPlaceholder}</div>;
  }

  return (
    <div className={classes.outerListWrapper}>
      {isSearchable && (
        <SearchInput
          className={classes.searchInput}
          placeholder={searchPlaceholder}
          onChange={setSearchInputValue}
          value={searchInputValue}
        />
      )}
      <Box className={classes.innerListWrapper} overflow="hidden">
        <div className={classes.listOptions}>
          {filteredOptions.map((option) => (
            <Box
              className={classNames(classes.listOption, { [classes.clickable]: mode === 'multiselect' })}
              key={option.id}
              {...(option.styles ?? {})}
            >
              {mode === 'multiselect' ? (
                <Checkbox
                  variant="list"
                  isChecked={value.includes(option.id)}
                  onChange={() => handleChangeOption(option.id)}
                >
                  {option.text}
                </Checkbox>
              ) : (
                // TODO: fix accessibility on this
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div data-testid="list-option" onClick={() => handleClickDefaultModeOption(option.id)}>
                  {option.render instanceof Function ? option.render() : <>{option.text}</>}
                </div>
              )}
            </Box>
          ))}
        </div>
        {mode === 'multiselect' && (
          <ButtonGroup width="100%" isAttached isDisabled={isDisabled} variant="list" className={classes.buttonGroup}>
            <Button onClick={handleClickSelectAll}>Select all</Button>
            <Button onClick={handleClickDeselectAll}>Deselect all</Button>
          </ButtonGroup>
        )}
      </Box>
    </div>
  );
};
