import {
  Button,
  Checkbox,
  FormControl,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { differenceInDays, endOfDay } from 'date-fns';
import { useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';

import { maybePluralize } from '../../utils';
import { formatIsoDate } from '../../utils/dateUtils';

export interface DateWarningModalProps {
  /** Start date of the range for this warning. */
  fromDate?: string;
  /** Whether or not the modal is visible. */
  isOpen?: boolean;
  /** Handler to call when the user acknowledges the error but continues to change floorplans. */
  onAcknowledge: () => void;
  /** Handler to call when the user closes the modal. */
  onClose: () => void;
  /** End date of the range for this warning. */
  toDate?: string;
}

const DateWarningModal = (props: DateWarningModalProps) => {
  const { fromDate, isOpen, onAcknowledge, onClose, toDate } = props;

  const [ackChecked, setAckChecked] = useState(false);

  const timeDifference = useMemo(() => {
    if (!fromDate || !toDate) {
      return undefined;
    }

    const diff = differenceInDays(new Date(toDate), new Date(fromDate));
    const absDiff = Math.abs(diff);

    if (absDiff < 1) {
      return undefined;
    }

    const age = diff < 0 ? 'older' : 'more recent';
    if (absDiff <= 50) {
      return `${absDiff} ${maybePluralize(absDiff, 'day')} ${age}`;
    } else if (absDiff > 50 && absDiff <= 60) {
      return `almost 2 months ${age}`;
    } else if (absDiff > 60 && absDiff <= 75) {
      return `over 2 months ${age}`;
    } else if (absDiff > 75 && absDiff <= 90) {
      return `almost 3 months ${age}`;
    } else {
      return `several months ${age}`;
    }
  }, [fromDate, toDate]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [_, setCookies] = useCookies(['onsitevision_date_warning_acknowledged']);

  const onAccept = () => {
    if (ackChecked) {
      setCookies('onsitevision_date_warning_acknowledged', true, {
        path: '/',
        expires: endOfDay(new Date()),
        sameSite: 'strict',
      });
    }
    onAcknowledge();
  };

  // This component shouldn't even be rendered if the difference between the two dates is less than 1 day. Regardless,
  // add an extra layer of safety.
  if (!timeDifference) {
    return null;
  }

  return (
    <Modal closeOnOverlayClick={false} isCentered isOpen={Boolean(isOpen)} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Text>
            The nearest available date on the location you selected is <Text as="strong">{timeDifference}</Text> (
            {formatIsoDate(toDate)?.formattedDateTime}). Would you like to continue?
          </Text>
          <FormControl paddingBlockStart="1rem">
            <Checkbox isChecked={ackChecked} onChange={() => setAckChecked((current) => !current)}>
              Don't remind me again today
            </Checkbox>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button mr={2} onClick={onClose} size="md" variant="mediumEmphasisV2">
            Cancel
          </Button>
          <Button onClick={onAccept} size="md" variant="highEmphasisV2">
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DateWarningModal;
