import { composeWithDevTools } from '@redux-devtools/extension';
import { AnyAction, Middleware, Reducer, applyMiddleware, combineReducers, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import { Store } from './@types/redux/store';
import { LOGOUT } from './constants/actionTypes';
import * as reducers from './reducers';
import { initialAppState } from './reducers/app';
import { initialAuthState } from './reducers/auth';
import { initialNotificationsState } from './reducers/notifications';

const logger = createLogger({
  collapsed: true,
});

const composeEnhancers = composeWithDevTools({});

const combinedReducer = combineReducers({ ...reducers });

/* Reset the state of the ...state when a user logs out */
export const rootReducer: Reducer<Store, AnyAction> = (state: Store | undefined, action: AnyAction) => {
  if (action.type === LOGOUT) {
    // eslint-disable-next-line no-param-reassign
    state = {
      app: initialAppState,
      auth: { ...(state?.auth ?? initialAuthState) },
      notifications: initialNotificationsState,
    };
  }

  return combinedReducer(state, action);
};

export const configureStore = (preloadedState?: any) => {
  const middleware: Middleware[] = [thunk];
  if (process.env.NODE_ENV === 'development') {
    middleware.push(logger);
  }
  return createStore(rootReducer, preloadedState, composeEnhancers(applyMiddleware(...middleware)));
};

export const store = configureStore();
