import axios, { AxiosRequestConfig } from 'axios';

import { CostCodeCompact } from '../../../@types/procore/v1/CostCode';
import { getProcoreRequestConfiguration } from '../../procore';

export const CostCodeApi = {
  /**
   * Retrieve the list of compact (i.e. `'erp_compact'`) Procore Cost Codes associated with a given Procore Company and
   * Project.
   * @see https://developers.procore.com/reference/rest/v1/cost-codes?version=1.0#list-cost-codes
   * @param companyId Unique identifier for the Procore Company.
   * @param projectId Unique identifier for the Procore Project.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the list of Procore Cost Codes.
   */
  listCompact: (companyId: number, projectId: number, requestConfig?: AxiosRequestConfig) => {
    const baseRequestConfig = getProcoreRequestConfiguration(companyId);

    const queryParams = new URLSearchParams();
    queryParams.set('project_id', String(projectId));
    queryParams.set('view', 'erp_compact');

    return axios.get<CostCodeCompact[]>(`/rest/v1.0/cost_codes?${queryParams.toString()}`, {
      ...baseRequestConfig,
      ...requestConfig,
    });
  },
};
