import { Me, User } from '../@types/OnSiteIQ';

export const isMe = (u: User | Me): u is Me => (u as Me).first_name !== undefined;

export const getFullName = (user: User | Me): string => {
  if (isMe(user)) {
    return `${user.first_name} ${user.last_name}`;
  } else {
    return user.name;
  }
};

export const getInitials = (user: User | Me): string => {
  if (isMe(user)) {
    const first_initial = user.first_name[0] || '';
    const last_initial = user.last_name[0] || '';
    return [first_initial, last_initial].join('').toUpperCase();
  } else {
    const first_initial = user.name[0];
    const last_initial = user.name.slice(-1);
    return [first_initial, last_initial].join('').toUpperCase();
  }
};
