import { Box, Button, HStack, Show, Text } from '@chakra-ui/react';
import { useRef } from 'react';

import theme from '../../theme';

/**
 * Generate a list of numbers from the start number to the end number
 * @param start The first number in the list. Should be less than the `end` number.
 * @param end The last number in the list. Should be greater than the `start` number.
 */
const generateRangeArray = (start: number, end: number) =>
  Array(end - start + 1)
    .fill('')
    .map((value, index) => index + start);

export const getPageNumbers = (
  /** The total number of pages */
  pageCount: number,
  /** The displayed number of the current page. Note: usually this won't be the same as the page index. */
  currentPageNumber: number
) => {
  if (pageCount <= 0) {
    return [];
  }

  /**
   * The difference in indices between the largest continuous group of numbers displayed in the page numbers.
   * @example if the displayed page numbers are [1 2 3 4 5 6 7], `delta` should be 6.
   * @example if the displayed page numbers are [1 ... 4 5 6 ... 10], `delta` should be 2.
   * @example if the displayed page numbers are [1 2 3 4 5 ... 10], `delta` should be 4.
   */
  let delta: number;

  // Change these numbers if we want to show more or less actual page numbers between the potential ellipses.
  if (pageCount <= 7) {
    delta = 6;
  } else if (currentPageNumber > 4 && currentPageNumber < pageCount - 3) {
    delta = 2;
  } else {
    delta = 4;
  }

  /**
   * The range of page numbers to display in the continuous group of page numbers.
   * @example if the displayed page numbers are [1, 2, 3, 4, 5, 6, 7], `start` is 1, `end` is 7.
   * @example if the displayed page numbers are [1, '...', 4, 5, 6, '...', 10], `start` is 4, `end` is 6.
   * @example if the displayed page numbers are [1, 2, 3, 4, 5, '...', 10], `start` is 1, `end` is 5.
   */
  const range = {
    start: Math.round(currentPageNumber - delta / 2),
    end: Math.round(currentPageNumber + delta / 2),
  };

  /**
   * If the range starts on the second page, or ends on the second-to-last page, we need to
   * shift the range up by 1 in order to account for the upcoming ellipsis logic.
   * Otherwise, we would end up potentially adding an ellipsis between sequential numbers.
   * @example pageCount = 8, currentPageNumber = 5: range.start = 3, range.end = 7
   * In this example, we would generate the page numbers [1, ..., 3, 4, 5, 6, 7, ..., 8] unless
   * we adjusted the range to be range.start = 4, range.end = 8.
   *
   * The range.start === 2 case cannot happen with the current configuration of delta and range,
   * but the shifting logic is here in case that configuration is updated in the future.
   */
  if (range.start === 2) {
    range.start -= 1;
    range.end -= 1;
  } else if (range.end === pageCount - 1) {
    range.start += 1;
    range.end += 1;
  }

  /**
   * Generate an initial pass at the page numbers to display.
   *
   * If the current page number is greater than `delta`, the page numbers at this point should
   * be a range from the smaller of `range.start` and `pageCount - delta`, to the
   * smaller of `range.end` and `pageCount`.
   *
   * @example currentPage = 6, delta = 2, pageCount = 10, start = 5, end = 7: pageNumbers = [5,6,7]
   *
   * If the current page number is equal to, or lower than, the delta, the page numbers at this
   * point should be a range from 1 to the smaller number of `pageCount` and `delta + 1`.
   *
   * @example currentPage = 3, delta = 4, pageCount = 10, start = 1, end = 5, pageNumbers = [1,2,3,4,5]
   */
  const initialPageNumbers =
    currentPageNumber > delta
      ? generateRangeArray(Math.min(range.start, pageCount - delta), Math.min(range.end, pageCount))
      : generateRangeArray(1, Math.min(pageCount, delta + 1));

  let finalPageNumbers: (number | string)[] = [...initialPageNumbers];

  if (initialPageNumbers[0] !== 1) {
    finalPageNumbers = [1, '...'].concat(finalPageNumbers);
  }

  if (initialPageNumbers[initialPageNumbers.length - 1] < pageCount) {
    finalPageNumbers = finalPageNumbers.concat(['...', pageCount]);
  }

  return finalPageNumbers;
};

interface PaginationProps {
  /** The total number of pages. */
  pageCount: number;
  /** The number of currently-displayed rows. */
  displayedRowCount: number;
  /** The total number of rows. */
  totalRowCount: number;
  /** Function that sets the page index to the provided value. */
  setPageIndex: (index: number) => void;
  /** The current page index. */
  pageIndex: number;
  /** Whether or not the user can navigate to the previous page. */
  canPreviousPage: boolean;
  /** Whether or not the user can navigate to the next page. */
  canNextPage: boolean;
  /** Function that is called when clicking the left arrow button. */
  goToPreviousPage: () => void;
  /** Function that is called when clicking the right arrow button. */
  goToNextPage: () => void;
  /** Optional unit to show in the 'Showing X of Y `{rowUnit}`' text. */
  rowUnit?: string;
}

export const Pagination = ({
  pageCount,
  displayedRowCount,
  totalRowCount,
  setPageIndex,
  pageIndex,
  /**
   * TODO: canPreviousPage and canNextPage can likely be inferred from pageIndex and pageCount.
   * However, they're provided by tanstack table, so I'm passing them in for now.
   */
  canPreviousPage,
  canNextPage,
  goToPreviousPage,
  goToNextPage,
  rowUnit = '',
}: PaginationProps) => {
  const ref = useRef<HTMLDivElement>(null);

  // The current page number, as it appears on the page (as opposed to as represented by the zero-based index).
  const currentPageNumber = pageIndex + 1;

  const pageNumbers = getPageNumbers(pageCount > 0 ? pageCount : 1, currentPageNumber);

  return (
    <HStack height="2rem" ref={ref}>
      <Show above="md">
        <Text whiteSpace="pre">{`Showing ${displayedRowCount} of ${totalRowCount} ${rowUnit}  |`}</Text>
      </Show>
      <Text>Page:</Text>
      <Button
        aria-label="Previous Page"
        onClick={goToPreviousPage}
        isDisabled={!canPreviousPage}
        height="2rem"
        width="2rem"
        minWidth="2rem"
        _hover={{
          backgroundColor: theme.colors.brand.gray[200],
          _disabled: {
            backgroundColor: 'transparent',
          },
        }}
      >
        {/* TODO: left and right arrow icons */}
        <span aria-hidden="true">{'←'}</span>
      </Button>
      {pageNumbers.map((value, index) =>
        typeof value === 'number' ? (
          <Button
            key={`page-number-${value}`}
            onClick={() => setPageIndex(value - 1)}
            isActive={value === currentPageNumber}
            variant="roundPrimary"
          >
            {value}
          </Button>
        ) : (
          <Box key={`text-${index}`} width="1.5rem">
            {value}
          </Box>
        )
      )}
      <Button
        aria-label="Next Page"
        onClick={goToNextPage}
        isDisabled={!canNextPage}
        height="2rem"
        width="2rem"
        minWidth="2rem"
        _hover={{
          backgroundColor: theme.colors.brand.gray[200],
          _disabled: {
            backgroundColor: 'transparent',
          },
        }}
      >
        <span aria-hidden="true">{'→'}</span>
      </Button>
    </HStack>
  );
};
