import { Box, DrawerProps, Heading, StatGroup, Text } from '@chakra-ui/react';
import { generatePath, useHistory } from 'react-router-dom';

import {
  PortfolioProject,
  PortfolioProjectMilestoneProgress,
  PortfolioProjectProgressHistory,
} from '../../@types/api/v1/bespoke/PortfolioDashboard';
import { Drawer } from '../../components/Drawer/Drawer';
import { ProgressChart } from '../../components/ProgressChart/ProgressChart';
import { DurationStat } from '../../components/Statistics/DurationStat';
import { EstimatedProgress } from '../../components/Statistics/EstimatedProgress';
import { PercentChangeStat } from '../../components/Statistics/PercentChangeStat';
import { DrawerType, PendoTopic } from '../../constants/analytics';
import Routes from '../../routes';
import theme from '../../theme';
import { formatAddress } from '../../utils/stringUtils';
import { MilestoneActivity } from './MilestoneActivity';

type ProjectDrawerProps = Pick<DrawerProps, 'isOpen' | 'onClose'> & {
  project: PortfolioProject;
  progressHistory?: PortfolioProjectProgressHistory;
  milestoneProgress?: PortfolioProjectMilestoneProgress;
  isFetching?: boolean;
  isError: boolean;
};

export const ProjectDrawer = ({
  isOpen,
  onClose,
  project,
  progressHistory,
  milestoneProgress,
  isFetching,
  isError,
}: ProjectDrawerProps) => {
  const history = useHistory();

  const handleProgressButtonClick = () => {
    const progressTrackingPath = generatePath(Routes.PROJECT_PROGRESS_TRACKING, { id: project.id });
    history.push(progressTrackingPath);
  };

  const errorContent = (
    <Text>
      An error occurred while loading this project's progress history. Please try again later. If this issue persists,
      contact <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a> for assistance.
    </Text>
  );

  const isProgressTrackingEnabled = project.progress_tracking_enabled;

  const bodyContent = (
    <>
      <Box>
        <EstimatedProgress progress={isProgressTrackingEnabled ? project.total_progress : null} progressBarSize="lg" />
        <StatGroup marginTop="1.5rem">
          <PercentChangeStat value={isProgressTrackingEnabled ? project.percent_change : null} />
          <DurationStat daysElapsed={isProgressTrackingEnabled ? progressHistory?.days_elapsed : 0} />
        </StatGroup>
      </Box>
      <Box borderBottom={`1px solid ${theme.colors.brand.gray[200]}`} marginBlockStart="1rem" paddingBottom="1.5rem">
        <ProgressChart
          baselineCompletionDate={
            project.baseline_completion_date
              ? new Date(`${project.baseline_completion_date} 12:00:00`).getTime()
              : undefined
          }
          data={
            isProgressTrackingEnabled
              ? progressHistory?.progress_values.map(({ value, captured }) => ({
                  date: new Date(captured).getTime(),
                  value,
                })) ?? []
              : []
          }
          highlightInterval={30}
          highlightMode="duration"
          hasMomentum={project.momentum}
          minDurationToHighlight={30}
          minHeight="15rem"
        />
      </Box>
      {milestoneProgress && (
        <Box padding="1rem 0">
          <Heading size="lg" as="h2" color={theme.colors.brand.gray[600]} fontWeight={400} marginBottom="1rem">
            Milestone Activity
          </Heading>
          <MilestoneActivity
            active={isProgressTrackingEnabled ? milestoneProgress.active : []}
            started={isProgressTrackingEnabled ? milestoneProgress.started : []}
            completed={isProgressTrackingEnabled ? milestoneProgress.completed : []}
          />
        </Box>
      )}
    </>
  );

  const footerButtonProps = {
    pendoLabel: 'Go to Progress Tracking from project drawer',
    onClick: handleProgressButtonClick,
    text: 'Go to Progress',
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      pendoTopic={PendoTopic.PORTFOLIO}
      drawerType={DrawerType.PROJECT}
      primaryText={project.name}
      secondaryText={formatAddress(project.address_line, project.city, project.state, project.zip_code)}
      isFetching={isFetching}
      isError={isError}
      errorContent={errorContent}
      bodyContent={bodyContent}
      footerButtonProps={footerButtonProps}
    />
  );
};
