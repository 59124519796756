import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(accordionAnatomy.keys);

const accordionBaseStyle = definePartsStyle({
  button: {
    color: 'var(--primary-500)',
    fontSize: '18px',
  },
  icon: {
    color: 'var(--gray-900)',
  },
  container: {
    borderColor: 'var(--gray-200)',
  },
});

const userManagementStyle = definePartsStyle({
  root: {
    width: '100%',
  },
  button: {
    color: 'var(--gray-900)',
    fontSize: '1rem',
    letterSpacing: '0.1px',
  },
  container: {
    backgroundColor: 'var(--gray-50)',
    border: 'none',
    borderRadius: '0.5rem',
    marginBottom: '1rem',
  },
});

export const accordionTheme = defineMultiStyleConfig({
  baseStyle: accordionBaseStyle,
  variants: {
    userManagement: userManagementStyle,
  },
});
