import { AxiosRequestConfig } from 'axios';

import { Rfi, RfiCreateRequestBody } from '../../../@types/procore/v1/Rfi';
import { objectToFormData } from '../../../utils/network';
import { post } from '../../next';
import { getProcoreRequestConfiguration } from '../../procore';

export const RfiApi = {
  /**
   * Create a new Procore RFI.
   * @see https://developers.procore.com/reference/rest/v1/rfis?version=1.0#create-rfi
   * @param companyId Unique identifier for the Procore Company.
   * @param projectId Unique identifier for the Procore Project.
   * @param requestBody Request payload to be sent to Procore to create a new RFI.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the created RFI.
   */
  createRfi: (
    companyId: number,
    projectId: number,
    requestBody: RfiCreateRequestBody,
    requestConfig?: AxiosRequestConfig
  ) => {
    const baseRequestConfig = getProcoreRequestConfiguration(companyId);

    // Procore currently has a bug where requests to create RFIs, when sent as `FormData`, can't include indices on
    // array properties.
    const formData = objectToFormData(requestBody);

    return post<FormData, Rfi>(`rest/v1.0/projects/${projectId}/rfis`, formData, {
      ...baseRequestConfig,
      headers: {
        ...baseRequestConfig.headers,
        'Content-Type': 'multipart/form-data',
      },
      ...requestConfig,
    });
  },
};
