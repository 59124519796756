import { AspectRatio, Box, Button, Icon, Image } from '@chakra-ui/react';

import { Annotation } from '../../../@types/api/v0/rest/Annotation';
import theme from '../../../theme';
import { Degree360Icon } from '../../Icon';

export interface AnnotationImageProps {
  /** The current annotation. */
  annotation: Annotation;
  /** Handler to call when the user attempts to view the annotation in 360. */
  onAnnotationView: (annotation: Annotation) => void;
}

const AnnotationImage = (props: AnnotationImageProps) => {
  const { annotation, onAnnotationView } = props;

  const onView360Click = () => {
    onAnnotationView(annotation);
  };

  return (
    <Box marginBlockStart="1rem" position="relative">
      <AspectRatio ratio={4 / 3}>
        <Image alt="" borderRadius={theme.radii.card} minHeight="10rem" src={annotation?.image} />
      </AspectRatio>
      <Button
        backgroundColor={theme.colors.white}
        border="1px solid var(--primary-500)"
        borderRadius="100%"
        color={theme.colors.brand.primary[500]}
        data-pendo-label="Open 360"
        data-pendo-topic="annotations"
        height="2.375rem"
        lineHeight={1}
        minWidth="2.375rem"
        onClick={onView360Click}
        padding="0.375rem"
        position="absolute"
        right="0.5rem"
        top="0.5rem"
        variant="unstyled"
        width="2.375rem"
        _hover={{
          backgroundColor: theme.colors.white,
          borderColor: theme.colors.brand.primary[700],
          color: theme.colors.brand.primary[700],
        }}
      >
        <Icon aria-label="Open in 360 degree viewer" as={Degree360Icon} height="1.5rem" width="1.5rem" />
      </Button>
    </Box>
  );
};

export default AnnotationImage;
