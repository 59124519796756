import { Box, StatGroup, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { Floorplan } from '../../../@types/api/v0/rest/Floorplan';
import { ProgressTrackingMilestoneProgressHistory } from '../../../@types/api/v1/bespoke/ProgressTracking';
import { Milestone } from '../../../@types/api/v1/rest/Milestone';
import { Drawer } from '../../../components/Drawer/Drawer';
import { ProgressChart } from '../../../components/ProgressChart/ProgressChart';
import { DurationStat } from '../../../components/Statistics/DurationStat';
import { EstimatedProgress } from '../../../components/Statistics/EstimatedProgress';
import { PercentChangeStat } from '../../../components/Statistics/PercentChangeStat';
import { DrawerType, PendoTopic } from '../../../constants/analytics';
import theme from '../../../theme';
import { generateProjectPageUrl } from '../../../utils/navigationUtils';
import { getRoundedProgressValue } from '../../../utils/progressUtils';

export interface MilestoneProgressDrawerProps {
  /**
   * The current floorplan. Its value will be `undefined` when the drawer is closed or if a summary row cell is
   * clicked.
   */
  floorplan?: Floorplan;
  /** Flag indicating whether or not the floorplan/milestone combination has momentum. */
  hasMomentum?: boolean | null;
  /** Flag indicating whether or not a loading error has occurred. */
  isError?: boolean;
  /** Flag indicating whether or not data is fetching. */
  isFetching?: boolean;
  /** Flag indicating whether or not the drawer is open. */
  isOpen?: boolean;
  /**
   * Flag indicating whether or not Progress Tracking is enabled for this project. Even though this component is part of
   * the Progress Tracking page, the feature might not be enabled for the current project.
   */
  isProgressTrackingEnabled?: boolean;
  /** The current milestone. Its value may be `undefined` when the drawer is closed. */
  milestone?: Milestone;
  /**
   * An object containing summary statistics about the milestone (or milestone-floorplan combination) and a history of
   * progress values.
   */
  milestoneProgressHistory?: ProgressTrackingMilestoneProgressHistory;
  /** Handler to call when the user wants to close the drawer. */
  onClose: () => void;
  /** ID of the current project. */
  projectId: number;
  /** The currently-selected Time Travel date. */
  selectedDate?: Date;
  /** Progress value for either the current milestone or the current milestone-floorplan combination. */
  value?: number;
  /** ID of the walkthrough which generated the most recent progress entry. Provided for milestone-floorplan
   * combinations. This value is expected to be `undefined` when the drawer is closed or a summary row cell was clicked.
   */
  walkthroughId?: number;
}

export const MilestoneProgressDrawer = (props: MilestoneProgressDrawerProps) => {
  const {
    floorplan,
    hasMomentum,
    isError,
    isFetching,
    isOpen,
    isProgressTrackingEnabled,
    milestone,
    milestoneProgressHistory,
    onClose,
    projectId,
    selectedDate,
    value,
    walkthroughId,
  } = props;

  const history = useHistory();

  const errorContent = (
    <Text>
      {`An error occurred while loading the progress history for this ${floorplan ? 'floor' : 'milestone'}. Please try
      again later.`}{' '}
      If this issue persists, contact <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a> for
      assistance.
    </Text>
  );

  const bodyContent = (
    <>
      <Box>
        <EstimatedProgress progress={getRoundedProgressValue(value ?? 0)} progressBarSize="lg" />
        <StatGroup marginTop="1.5rem">
          <PercentChangeStat value={milestoneProgressHistory?.percent_change ?? null} />
          <DurationStat daysElapsed={milestoneProgressHistory?.days_elapsed} />
        </StatGroup>
      </Box>
      <Box borderBottom={`1px solid ${theme.colors.brand.gray[200]}`} marginBlockStart="1rem" paddingBottom="1.5rem">
        <ProgressChart
          data={
            isProgressTrackingEnabled
              ? milestoneProgressHistory?.progress_values.map(({ value, captured }) => ({
                  date: new Date(captured).getTime(),
                  value,
                })) ?? []
              : []
          }
          hasMomentum={hasMomentum}
          highlightLimit={3}
          highlightLimitMaxDate={selectedDate}
          highlightMode="limit"
          minDurationToHighlight={7}
          minHeight="15rem"
          noDataMessage="📈 Data coming soon!"
        />
      </Box>
    </>
  );

  let footerButtonProps;

  if (floorplan) {
    footerButtonProps = {
      pendoLabel: 'Go to 360 from Milestone Progress drawer',
      onClick: () => {
        history.push(generateProjectPageUrl(projectId, floorplan.id, walkthroughId));
      },
      text: 'Go to 360',
    };
  }

  return (
    <Drawer
      bodyContent={bodyContent}
      drawerType={DrawerType.MILESTONE_PROGRESS}
      errorContent={errorContent}
      footerButtonProps={footerButtonProps}
      isError={isError}
      isFetching={isFetching}
      isOpen={Boolean(isOpen)}
      onClose={onClose}
      pendoTopic={PendoTopic.PROGRESS_TRACKING}
      primaryText={milestone?.name}
      secondaryText={floorplan?.name}
    />
  );
};
