import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../@types/redux/store';
import { removeNotification } from '../actions/notifications';
import Toast from '../components/Toast';

const NotificationCenter = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state: Store) => state.notifications.notifications);

  const toast = useToast();

  useEffect(() => {
    if (!notifications.length) {
      return;
    }

    for (const notification of notifications) {
      if (toast.isActive(notification.id)) {
        continue;
      }

      toast({
        id: notification.id,
        position: 'top-right',
        render: ({ onClose }) => (
          <Toast
            description={notification.content}
            onClose={onClose}
            onCloseComplete={() => {
              dispatch(removeNotification(notification.id));
            }}
            status={notification.kind}
            title={notification.title}
          />
        ),
      });
    }
  }, [dispatch, notifications, toast]);

  return null;
};

export default NotificationCenter;
