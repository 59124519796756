import { Icon } from '@chakra-ui/react';
import {
  ClearIndicatorProps,
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  MultiValueGenericProps,
  chakraComponents,
} from 'chakra-react-select';

import { ChevronDownIcon, CloseIcon } from '../Icon';

export const ClearIndicator = <T, IsMulti extends boolean = boolean>(
  overrideProps: ClearIndicatorProps<T, IsMulti, GroupBase<T>>
) => (
  <chakraComponents.ClearIndicator {...overrideProps}>
    <CloseIcon />
  </chakraComponents.ClearIndicator>
);

export const Control = <T, IsMulti extends boolean = boolean>(
  overrideProps: ControlProps<T, IsMulti, GroupBase<T>>
) => (
  <chakraComponents.Control
    {...overrideProps}
    innerProps={{ ...overrideProps.innerProps, style: { height: 'auto' } }}
  />
);

export const DropdownIndicator = <T, IsMulti extends boolean = boolean>(
  overrideProps: DropdownIndicatorProps<T, IsMulti, GroupBase<T>>
) => (
  <chakraComponents.DropdownIndicator {...overrideProps}>
    <Icon aria-hidden as={ChevronDownIcon} />
  </chakraComponents.DropdownIndicator>
);

export const MultiValueLabel = <T,>(overrideProps: MultiValueGenericProps<T | null, true, GroupBase<T>>) => (
  <chakraComponents.MultiValueLabel {...overrideProps}>
    {overrideProps.data.prependLabel}: {overrideProps.data.label}
  </chakraComponents.MultiValueLabel>
);
