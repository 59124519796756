import { Box, Button, Center, Flex, Icon, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { format, parse } from 'date-fns';
import maxBy from 'lodash/maxBy';
import sortBy from 'lodash/sortBy';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Floorplan } from '../../@types/api/v0/rest/Floorplan';
import { Project } from '../../@types/api/v0/rest/Project';
import { ProgressTrackingTableData } from '../../@types/api/v1/bespoke/ProgressTracking';
import { Milestone } from '../../@types/api/v1/rest/Milestone';
import ControlCenter from '../../components/ControlCenter/ControlCenter';
import { DataTable } from '../../components/DataTable/DataTable';
import { TableCell } from '../../components/DataTable/TableCell';
import DatePicker from '../../components/DatePicker/DatePicker';
import { DownloadIcon } from '../../components/Icon';
import LoadingIndicator from '../../components/LoadingIndicator';
import SearchInput from '../../components/SearchInput/SearchInput';
import { PendoTopic } from '../../constants/analytics';
import theme from '../../theme';
import { generateProjectPageUrl } from '../../utils/navigationUtils';
import { FloorNameCell } from './FloorNameCell';
import { MilestoneProgressDrawerContainer } from './MilestoneProgressDrawer/MilestoneProgressDrawerContainer';
import { ProgressCell } from './ProgressCell';
import { PROGRESS_TABLE_HEADER_TOOLTIPS, PROGRESS_TRACKING_TOOLTIPS } from './constants';

export interface ProgressTrackingPageProps {
  /** The current text in the Floor search/filter field. */
  floorSearchTerm?: string;
  /** Whether or not the user is currently downloading a Progress data export. */
  isDownloadingProgressDataExport?: boolean;
  /** Flag indicating whether or not data needed for this component is fetching. */
  isFetching?: boolean;
  /** List of all known milestones. */
  milestones: Milestone[];
  /** Function called when the user clicks the Export button. */
  onProgressDataExport: (date: string) => void;
  /** Progress data for this project. */
  progress?: ProgressTrackingTableData;
  /** The current project being displayed. */
  project: Project;
  /** The currently-selected date. Used for time travel and in the URL. */
  selectedDate?: Date;
  /** Function called when the user types into the Floor search/filter field. */
  setFloorSearchTerm: (searchTerm: string) => void;
}

interface ProgressTrackingTableRow {
  key: string;
  name: string;
  floorplan?: Floorplan;
}

const DatePickerTooltipLabel = (
  <Box>
    <Text marginBlockEnd="1rem">{PROGRESS_TRACKING_TOOLTIPS.DATE_SELECTION_1}</Text>
    <Text marginBlockEnd="1rem">{PROGRESS_TRACKING_TOOLTIPS.DATE_SELECTION_2}</Text>
    <Text>{PROGRESS_TRACKING_TOOLTIPS.DATE_SELECTION_3}</Text>
  </Box>
);

const collator = new Intl.Collator('en-US', { numeric: true, sensitivity: 'base' });

const columnHelper = createColumnHelper<ProgressTrackingTableRow>();

const summaryRow = {
  key: 'ptd-row-totals',
  name: 'Total',
};

export const ProgressTrackingPage = (props: ProgressTrackingPageProps) => {
  const {
    floorSearchTerm,
    isDownloadingProgressDataExport,
    isFetching,
    milestones,
    onProgressDataExport,
    progress: serverProgress,
    project,
    selectedDate,
    setFloorSearchTerm,
  } = props;

  const history = useHistory();
  const location = useLocation<{ tableScrollX?: number; tableScrollY?: number }>();

  const walkthroughDates = useMemo<Date[]>(
    () =>
      project.floorplans
        .flatMap((floorplan) => floorplan.dated_walkthroughs)
        .map((walkthroughDate) => new Date(walkthroughDate.when)),
    [project]
  );
  const orderedMilestones = useMemo<Milestone[]>(() => sortBy(milestones, 'order'), [milestones]);

  const {
    isOpen: isMilestoneProgressDrawerOpen,
    onOpen: onMilestoneProgressDrawerOpen,
    onClose: onMilestoneProgressDrawerClose,
  } = useDisclosure();
  const [progressDrawerProps, setProgressDrawerProps] = useState<{
    floorplan?: Floorplan;
    hasMomentum?: boolean | null;
    milestone: Milestone;
    value: number;
    walkthroughId?: number;
  }>();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const handleProgressCellClick = useCallback(
    (nextProgressDrawerProps: {
      floorplan?: Floorplan;
      hasMomentum?: boolean | null;
      milestone: Milestone;
      value: number;
      walkthroughId?: number;
    }) => {
      setProgressDrawerProps(nextProgressDrawerProps);
      onMilestoneProgressDrawerOpen();
    },
    [onMilestoneProgressDrawerOpen]
  );

  /** List of milestones which should be used to render the table columns. */
  const milestonesToDisplay = useMemo<Milestone[]>(() => {
    // If the project has no totals entries, show all milestones.
    if (!serverProgress?.totals || serverProgress?.totals?.length === 0) {
      return orderedMilestones;
    }

    return orderedMilestones.filter(({ id }) =>
      serverProgress.totals.some((totalsEntry) => totalsEntry.milestone_id === id)
    );
  }, [orderedMilestones, serverProgress]);

  /** 2D array of progress entries. The first index is floorplan ID, the second is milestone ID. */
  const progress = useMemo<
    ({ hasMomentum: boolean | null; value: number; walkthroughId: number } | undefined)[][]
  >(() => {
    const result: ({ hasMomentum: boolean | null; value: number; walkthroughId: number } | undefined)[][] = [];
    for (const floorplan of project.floorplans) {
      result[floorplan.id] = [];
    }

    for (const progressEntry of serverProgress?.data ?? []) {
      if (!result[progressEntry.floor_id]) {
        console.warn('[ProgressTrackingPage] Progress data contains unknown floorplan');
        continue;
      }

      result[progressEntry.floor_id][progressEntry.milestone_id] = {
        hasMomentum: progressEntry.momentum,
        value: progressEntry.value,
        walkthroughId: progressEntry.walk_id,
      };
    }

    return result;
  }, [project, serverProgress]);

  /** List of progress totals by milestone ID. */
  const totalProgress = useMemo<(number | undefined)[]>(() => {
    const result: (number | undefined)[] = [];
    for (const totalsEntry of serverProgress?.totals ?? []) {
      result[totalsEntry.milestone_id] = totalsEntry.value;
    }

    return result;
  }, [serverProgress]);

  const columns = useMemo<ColumnDef<ProgressTrackingTableRow, any>[]>(() => {
    return [
      columnHelper.accessor('name', {
        header: 'Floor',
        cell: ({ row }) => {
          const floorplan = row.original.floorplan;
          if (!floorplan) {
            return (
              <TableCell reducedPadding>
                <FloorNameCell isSummaryCell>{row.original.name}</FloorNameCell>
              </TableCell>
            );
          }

          const mostRecentWalkthroughId = maxBy(floorplan?.dated_walkthroughs ?? [], 'when')?.id;
          const target = generateProjectPageUrl(project.id, floorplan.id, mostRecentWalkthroughId);
          return (
            <TableCell reducedPadding>
              <FloorNameCell href={target} onClick={() => history.push(target)}>
                {floorplan.name}
              </FloorNameCell>
            </TableCell>
          );
        },
        sortingFn: (rowA, rowB) => {
          // Summary row:
          if (!rowA.original.floorplan) {
            return -1;
          }
          if (!rowB.original.floorplan) {
            return 1;
          }

          // Special case: site rows
          if (rowA.original.floorplan.name.toLowerCase().includes('site')) {
            return -1;
          }
          if (rowB.original.floorplan.name.toLowerCase().includes('site')) {
            return 1;
          }

          return collator.compare(rowA.original.floorplan.name, rowB.original.floorplan.name);
        },
      }),
      ...milestonesToDisplay.map((milestone) =>
        // @ts-expect-error The columnHelper does not appreciate the dynamically-generated column names
        columnHelper.accessor(`column-${milestone.id}`, {
          header: () => (
            <Tooltip
              label={PROGRESS_TABLE_HEADER_TOOLTIPS[milestone.name.toUpperCase()]}
              openDelay={1000}
              placement="top"
            >
              <Text as="strong" minWidth="10rem" flex={1} lineHeight="1rem">
                {milestone.name}
              </Text>
            </Tooltip>
          ),
          cell: ({ row }) => {
            const floorplan = row.original.floorplan;
            if (!floorplan) {
              const value = totalProgress[milestone.id];
              return (
                <TableCell
                  onClick={value !== undefined ? () => handleProgressCellClick({ milestone, value }) : undefined}
                  pendoLabel="Open milestone progress drawer from total cell"
                  pendoTopic={PendoTopic.PROGRESS_TRACKING}
                >
                  <ProgressCell isSummaryCell value={value} />
                </TableCell>
              );
            }

            const { hasMomentum, value, walkthroughId } = progress[floorplan.id][milestone.id] ?? {};
            return (
              <TableCell
                boxProps={{
                  ...(hasMomentum === true && {
                    backgroundColor: theme.colors.brand.secondary[100],
                    _hover: { backgroundColor: theme.colors.brand.secondary[300] },
                  }),
                  ...(hasMomentum === false && {
                    backgroundColor: theme.colors.brand.warning[100],
                    _hover: { backgroundColor: theme.colors.brand.warning[200] },
                  }),
                }}
                onClick={
                  value !== undefined
                    ? () => handleProgressCellClick({ floorplan, hasMomentum, milestone, value, walkthroughId })
                    : undefined
                }
                pendoLabel="Open milestone progress drawer from floor cell"
                pendoTopic={PendoTopic.PROGRESS_TRACKING}
              >
                <ProgressCell
                  hasMomentum={progress[floorplan.id][milestone.id]?.hasMomentum}
                  value={progress[floorplan.id][milestone.id]?.value}
                />
              </TableCell>
            );
          },
          enableSorting: true,
          sortingFn: (rowA, rowB) => {
            const rowAFloorplan = rowA.original.floorplan;
            const rowBFloorplan = rowB.original.floorplan;

            // Summary row:
            if (!rowAFloorplan) {
              return -1;
            }
            if (!rowBFloorplan) {
              return 1;
            }

            // No data cases
            const rowAProgress = progress[rowAFloorplan.id][milestone.id];
            const rowBProgress = progress[rowBFloorplan.id][milestone.id];
            if (!rowAProgress) {
              return -1;
            }
            if (!rowBProgress) {
              return 1;
            }

            return rowAProgress.value - rowBProgress.value;
          },
          sortDescFirst: true,
        })
      ),
    ];
  }, [handleProgressCellClick, history, milestonesToDisplay, progress, project, totalProgress]);

  const rows = useMemo<ProgressTrackingTableRow[]>(() => {
    if (project.floorplans.length === 0) {
      return [];
    }

    return project.floorplans.map((floorplan) => ({
      key: `ptd-row-floorplan-${floorplan.id}`,
      name: floorplan.name,
      floorplan,
    }));
  }, [project]);

  useEffect(() => {
    if (
      !isFetching &&
      rows.length > 0 &&
      selectedDate &&
      tableContainerRef.current &&
      Number.isFinite(location.state?.tableScrollX) &&
      Number.isFinite(location.state?.tableScrollY)
    ) {
      // Chrome tends to make the scroll occur too quickly. Wait until an animation frame is available.
      requestAnimationFrame(() => {
        (tableContainerRef.current as HTMLDivElement).scroll({
          behavior: 'auto',
          left: location.state.tableScrollX,
          top: location.state.tableScrollY,
        });

        // To avoid triggering an extra re-render, clear the location state imperatively.
        window.history.replaceState({}, document.title);
      });
    }
  }, [isFetching, rows, selectedDate, tableContainerRef, location]);

  const handleDrawerClose = () => {
    onMilestoneProgressDrawerClose();
    setProgressDrawerProps(undefined);
  };

  const setTimeTravelDate = (nextDate: Date) => {
    const latestWalkOnSelectedDay = walkthroughDates
      .filter(
        (d) =>
          d.getDate() === nextDate.getDate() &&
          d.getMonth() === nextDate.getMonth() &&
          d.getFullYear() === nextDate.getFullYear()
      )
      .sort((a, b) => b.getTime() - a.getTime())[0];

    const searchParams = new URLSearchParams(location.search);
    searchParams.set('date', latestWalkOnSelectedDay?.toISOString());

    history.replace(`${location.pathname}?${searchParams.toString()}`, {
      tableScrollX: tableContainerRef.current?.scrollLeft ?? 0,
      tableScrollY: tableContainerRef.current?.scrollTop ?? 0,
    });
  };

  let pageContent: ReactNode | undefined;

  if (isFetching) {
    pageContent = <LoadingIndicator />;
  } else if (project.floorplans.length === 0) {
    pageContent = (
      <Center
        backgroundColor={theme.colors.white}
        borderRadius={theme.radii.card}
        marginBlockStart="1rem"
        padding="2.5rem 1rem"
        data-testid="error-message-no-floorplans"
      >
        <Text color={theme.colors.brand.gray[600]}>
          This project has no floorplans. Please contact our Customer Success team at{' '}
          <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a> for assistance.
        </Text>
      </Center>
    );
  } else {
    pageContent = (
      <>
        <Flex paddingBlock="1rem 0.5rem">
          <SearchInput
            onChange={setFloorSearchTerm}
            placeholder="Search floors"
            showClearButton={Boolean(floorSearchTerm)}
            value={floorSearchTerm ?? ''}
          />
        </Flex>
        <DataTable
          data={rows}
          columns={columns}
          defaultSort={{ id: 'name', desc: false }}
          getRowId={(row) => row.key}
          pinnedColumnId="name"
          pinnedRow={summaryRow}
          globalFilter={floorSearchTerm}
          globalFilterFn={(row) =>
            (row.original.floorplan?.name ?? '').toLowerCase().includes((floorSearchTerm ?? '').toLowerCase())
          }
          noDataMessage="No data found for this search."
          tableContainerRef={tableContainerRef}
        />
        <Text bottom="0.25rem" color={theme.colors.brand.gray[600]} fontSize="0.875rem" position="absolute">
          OnsiteIQ Progress Tracking can make mistakes. Consider verifying important information.
        </Text>
      </>
    );
  }

  const controlCenterFields = [
    ...(walkthroughDates.length > 0 && selectedDate
      ? [
          <DatePicker
            key="date-picker-field"
            buttonProps={{
              'aria-label': 'Time travel date',
              'data-pendo-label': 'Time travel',
              'data-pendo-topic': 'progress',
            }}
            includeDates={walkthroughDates}
            minDate={parse('2023-03-09', 'yyyy-MM-dd', new Date())}
            onChange={setTimeTravelDate}
            popoverPlacement="bottom-end"
            selected={selectedDate}
            showIcon
            tooltipProps={{ label: DatePickerTooltipLabel, placement: 'bottom-end' }}
            value={format(selectedDate, 'ccc, LLL dd, yyyy')}
            popoverVariant="searchInput"
          />,
          <Button
            data-pendo-label={`Download Progress data for project ${project.id}`}
            data-pendo-topic={PendoTopic.PROGRESS_TRACKING}
            isLoading={isDownloadingProgressDataExport}
            key="export-csv-button"
            leftIcon={<Icon as={DownloadIcon} fontSize="1.125rem" />}
            onClick={() => onProgressDataExport(selectedDate.toISOString())}
            variant="outline"
          >
            Export
          </Button>,
        ]
      : []),
  ];

  return (
    <>
      <ControlCenter project={project} fields={isFetching ? undefined : controlCenterFields} />
      {pageContent}
      <MilestoneProgressDrawerContainer
        floorplan={progressDrawerProps?.floorplan}
        hasMomentum={progressDrawerProps?.hasMomentum}
        isOpen={isMilestoneProgressDrawerOpen}
        isProgressTrackingEnabled={project.executive_dashboard_enabled}
        milestone={progressDrawerProps?.milestone}
        onClose={handleDrawerClose}
        projectId={project.id}
        selectedDate={props.selectedDate}
        value={progressDrawerProps?.value}
        walkthroughId={progressDrawerProps?.walkthroughId}
      />
    </>
  );
};
