import { AxiosRequestConfig } from 'axios';

import {
  Detection,
  DetectionType,
  DetectionVote,
  DetectionVoteCreateRequestBody,
} from '../../../@types/api/v0/rest/Detection';
import { get, post } from '../../next';

export const DetectionApi = {
  /**
   * Retrieve the list of detections of a specified type for a walkthrough.
   * @param walkthroughId Unique ID of the walkthrough on the server.
   * @param detectionTypeLabel Label of the detection type (e.g. "drywall") to retrieve in the walkthrough.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the list of detections on the walkthrough.
   */
  getWalkthroughDetections: (walkthroughId: number, detectionTypeLabel: string, requestConfig?: AxiosRequestConfig) => {
    const queryParams = new URLSearchParams();
    queryParams.set('label', detectionTypeLabel);
    queryParams.set('walkthrough', String(walkthroughId));
    return get<Detection[]>(`/api/detections/v2/?${queryParams.toString()}`, requestConfig);
  },
  /**
   * Retrieve the list of detection types for a walkthrough. These are objects which may be found rather than specific
   * instances of an object and its location.
   * @param walkthroughId Unique ID of the walkthrough on the server.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the list of detection types.
   */
  getWalkthroughDetectionTypes: (walkthroughId: number, requestConfig?: AxiosRequestConfig) => {
    const queryParams = new URLSearchParams();
    queryParams.set('walkthrough', String(walkthroughId));
    return get<DetectionType[]>(`/api/detectiontypes/?${queryParams.toString()}`, requestConfig);
  },
  /**
   * Create (or replace) a detection vote instance. Sadly, the backend does not provide a PUT endpoint for replacements.
   * @param requestBody Request payload to be sent to the server to create a detection vote.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the created (or replaced) detection vote.
   */
  createWalkthroughDetectionVote: (requestBody: DetectionVoteCreateRequestBody, requestConfig?: AxiosRequestConfig) => {
    return post<DetectionVoteCreateRequestBody, DetectionVote>('/api/detectionvotes/', requestBody, requestConfig);
  },
};
