import { Tooltip } from '@chakra-ui/react';
import { ReactElement } from 'react';

import { Button } from '../../';
import { BrighteningIconPartial } from '../../Icon';

interface Props {
  active: boolean;
  disabled: boolean;
  onClick: () => void;
}

const BrighteningButton = ({ active, disabled, onClick }: Props): ReactElement => (
  <Tooltip hasArrow isDisabled={active} label="Adjust Brightness" placement="left">
    <Button
      data-pendo-label="Adjust brightness"
      data-pendo-topic="view-360-action"
      isDisabled={disabled}
      id="view-360-brightening-button"
      isActive={active}
      onClick={onClick}
      variant="view360"
    >
      <BrighteningIconPartial aria-label="Adjust brightness" />
    </Button>
  </Tooltip>
);

export default BrighteningButton;
