import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ExternalLayout } from '../components';
import ForgotPasswordForm from '../components/Login/ForgotPasswordForm';
import LoginCallback from '../components/Login/LoginCallback';
import LoginForm from '../components/Login/LoginForm';
import MultiFactorAuthForm from '../components/Login/MultiFactorAuthForm';
import SetPasswordForm from '../components/Login/SetPasswordForm';
import { assertNever } from '../utils/typescript';

import classes from './Login.module.scss';

export enum AuthStep {
  Login,
  MultiFactor,
  ForgotPassword,
  SetPassword,
  SocialCallback,
}

export interface MultiStepData {
  email: string;
  password: string;
}

const Login = (): ReactElement => {
  const location = useLocation();

  const [authStep, setAuthStep] = useState(AuthStep.Login);
  const [multiStepData, setMultiStepData] = useState<MultiStepData>();

  // Since this container component renders for several URL patterns, we sometimes need to forcibly advance between
  // steps when the location pathname changes.
  useEffect(() => {
    if (authStep !== AuthStep.Login && authStep !== AuthStep.MultiFactor && location.pathname === '/login') {
      setAuthStep(AuthStep.Login);
    }
    if (authStep !== AuthStep.ForgotPassword && location.pathname === '/forgot-password') {
      setAuthStep(AuthStep.ForgotPassword);
    }
    if (authStep !== AuthStep.SetPassword && location.pathname.startsWith('/reset-password')) {
      setAuthStep(AuthStep.SetPassword);
    }
    if (authStep !== AuthStep.SocialCallback && location.pathname === '/login-callback') {
      setAuthStep(AuthStep.SocialCallback);
    }
  }, [authStep, location.pathname]);

  const renderForm = () => {
    switch (authStep) {
      case AuthStep.Login:
        return <LoginForm setAuthStep={setAuthStep} setMultiStepData={setMultiStepData} />;
      case AuthStep.MultiFactor:
        return <MultiFactorAuthForm multiStepData={multiStepData} />;
      case AuthStep.ForgotPassword:
        return <ForgotPasswordForm />;
      case AuthStep.SetPassword:
        return <SetPasswordForm />;
      case AuthStep.SocialCallback:
        return <LoginCallback />;
      default:
        return assertNever(authStep);
    }
  };

  const renderInstructions = () => {
    switch (authStep) {
      case AuthStep.Login:
        return (
          <>
            <h1>Sign in</h1>
            <p>Welcome back! Sign in to manage, track and verify your projects with OnsiteIQ.</p>
          </>
        );
      case AuthStep.MultiFactor:
        return (
          <>
            <h1>Login verification</h1>
            <p>For your security, please enter the verification code sent to your email.</p>
          </>
        );
      case AuthStep.ForgotPassword:
        return (
          <>
            <h1>Forgot password</h1>
            <p>Enter your email address to initiate a password reset.</p>
          </>
        );
      case AuthStep.SetPassword:
        return (
          <>
            <h1>Set password</h1>
          </>
        );
      case AuthStep.SocialCallback:
        return (
          <>
            <h1>Sign in</h1>
            <p>You should be redirected momentarily...</p>
          </>
        );
      default:
        return assertNever(authStep);
    }
  };

  return (
    <ExternalLayout>
      <div className={classes.form}>
        {renderInstructions()}
        {renderForm()}
      </div>
    </ExternalLayout>
  );
};

export default Login;
