import { Flex, Text, useToast } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { format, isBefore, isValid, parseISO, startOfDay } from 'date-fns';
import truncate from 'lodash/truncate';
import { useEffect, useMemo, useState } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

import { ProjectApi } from '../../api/v0/rest/ProjectApi';
import { ProgressTrackingApi } from '../../api/v1/bespoke/ProgressTrackingApi';
import { MilestoneApi } from '../../api/v1/rest/MilestoneApi';
import { Content, InternalLayout, LoadingIndicator } from '../../components';
import { ErrorIcon } from '../../components/Icon';
import Toast from '../../components/Toast';
import { MilestoneQueryKeys, ProgressTrackingQueryKeys, ProjectQueryKeys, QueryTopics } from '../../constants/queries';
import Routes from '../../routes';
import theme from '../../theme';
import { ProgressTrackingPage } from './ProgressTrackingPage';

import commonClasses from '../../Common.module.scss';

export const ProgressTrackingContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const urlParameters = useParams<{ id: string }>();
  const projectId = Number(urlParameters?.id);

  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [floorSearchTerm, setFloorSearchTerm] = useState<string>(searchParams.get('floorName') ?? '');
  const [selectedDate, setSelectedDate] = useState<Date>();

  const toast = useToast();

  const projectDetailsQuery = useQuery({
    queryKey: [QueryTopics.PROJECTS, ProjectQueryKeys.PROJECT_DETAILS, projectId],
    queryFn: async ({ signal }) => (await ProjectApi.getById(projectId, { signal })).data,
  });

  const milestonesQuery = useQuery({
    queryKey: [QueryTopics.MILESTONES, MilestoneQueryKeys.MILESTONES_LIST],
    queryFn: async ({ signal }) => (await MilestoneApi.list({ signal })).data,
  });

  const progressQuery = useQuery({
    enabled: Boolean(projectDetailsQuery.data?.executive_dashboard_enabled && selectedDate),
    queryKey: [QueryTopics.PROGRESS_TRACKING, ProgressTrackingQueryKeys.PROGRESS_TABLE_DATA, projectId, selectedDate],
    queryFn: async ({ signal }) =>
      (await ProgressTrackingApi.getProgressData(projectId, selectedDate!.toISOString(), { signal })).data,
  });

  const progressDataExportMutation = useMutation({
    mutationKey: [QueryTopics.PROGRESS_TRACKING, ProgressTrackingQueryKeys.PROGRESS_DATA_EXPORT],
    mutationFn: ({ projectId, date }: { projectId: number; date: string }) =>
      ProgressTrackingApi.getProgressDataExport(projectId, date),
    onSuccess: ({ data, headers }) => {
      const csvBlob = new Blob([data], { type: 'text/csv' });
      const tempUrl = window.URL.createObjectURL(csvBlob);
      const tempLink = document.createElement('a');
      tempLink.href = tempUrl;
      // ProjectName_YYYY-MM-DD_Progress.csv
      tempLink.setAttribute(
        'download',
        headers['Content-Disposition']?.split('filename=')[1] ??
          `${projectDetailsQuery.data?.name.replace(/ /g, '_')}_${format(selectedDate!, 'yyyy-MM-dd')}_Progress.csv`
      );

      document.body.appendChild(tempLink);
      tempLink.click();

      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(tempUrl);
    },
    onError: () => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => {
          return (
            <Toast {...props} title="Error" description="An error occurred. Please try again later." status="error" />
          );
        },
      });
    },
  });

  // When the project details load, validate the date in the URL query parameters. Change if invalid.
  useEffect(() => {
    if (!projectDetailsQuery.data) {
      return;
    }

    const walkthroughDates = projectDetailsQuery.data.floorplans
      .flatMap((floorplan) => floorplan.dated_walkthroughs)
      .map((walkthroughDate) => new Date(walkthroughDate.when))
      .sort((dateA, dateB) => dateA.getTime() - dateB.getTime());

    // Do nothing if there are no walkthrough dates. The project hasn't been walked yet and the page should just show
    // inapplicable dashes.
    if (walkthroughDates.length === 0) {
      return;
    }

    const earliestWalkDate = walkthroughDates[0];
    const mostRecentWalkDate = walkthroughDates[walkthroughDates.length - 1];

    // If the date is absent, set the date to the most recent walkthrough date.
    const rawUrlDate = searchParams.get('date');
    if (!rawUrlDate) {
      setSelectedDate(mostRecentWalkDate);
      return;
    }

    // If the date is not valid, set the date to the most recent walkthrough date.
    const parsedDateFromUrl = parseISO(rawUrlDate);
    if (!isValid(parsedDateFromUrl)) {
      setSelectedDate(mostRecentWalkDate);
      return;
    }

    // If the selected date is one of the walkthrough dates, it's already valid.
    if (walkthroughDates.some((date) => date.getTime() === parsedDateFromUrl.getTime())) {
      setSelectedDate(parsedDateFromUrl);
      return;
    }

    // If the date is earlier than the earliest walkthrough date, use the earliest.
    if (earliestWalkDate && isBefore(parsedDateFromUrl, startOfDay(earliestWalkDate))) {
      setSelectedDate(earliestWalkDate);
      return;
    }

    setSelectedDate(mostRecentWalkDate);
  }, [projectDetailsQuery.data, searchParams]);

  // When the search term changes, update the URL. Do not run until a selectedDate is present.
  useEffect(
    () => {
      if (!selectedDate) {
        return;
      }

      const nextSearchParams = new URLSearchParams({
        date: selectedDate.toISOString(),
      });

      if (floorSearchTerm) {
        nextSearchParams.set('floorName', truncate(floorSearchTerm, { length: 16 }));
      }

      if (nextSearchParams.toString() === location.search.replace('?', '')) {
        return;
      }

      const newUrl = `${location.pathname}?${nextSearchParams.toString()}`;
      // Replace the URL, instead of calling history.push, to not add a bunch of extra entries into history (which would mess with the browser's "Back" button)
      history.replace(newUrl);
    },
    // Note: we purposely exclude "location" and "searchParams" from this dependency array to prevent excessive reloads.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDate, history, floorSearchTerm]
  );

  const criticalQueries = [projectDetailsQuery, milestonesQuery];
  const lowPriorityQueries = [progressQuery];

  const isAnyCriticalQueryFetching = criticalQueries.some((query) => query.isFetching);
  if (isAnyCriticalQueryFetching) {
    return (
      <InternalLayout>
        <Content>
          <LoadingIndicator fullPage />
        </Content>
      </InternalLayout>
    );
  }

  const errorQueries = [...criticalQueries, ...lowPriorityQueries].filter((query) => query.isError);
  if (errorQueries.length > 0 || !projectDetailsQuery.data || !milestonesQuery.data) {
    switch ((errorQueries[0]?.error as AxiosError | undefined)?.response?.status) {
      case 403:
      case 404:
        // TODO: TS-219: replace with dedicated HTTP 403 page where user can request access.
        return <Redirect to={Routes.NOT_FOUND} />;
      case 500:
      default: {
        return (
          <InternalLayout>
            <Content constrainToPageHeight>
              <Flex alignItems="center" height="100%" justifyContent="center" flexDir="column">
                <ErrorIcon aria-hidden className={commonClasses.errorIcon} />
                <Text color={theme.colors.brand.gray[900]} textAlign="center">
                  Failed to load data for this project. Please try again later.
                </Text>
              </Flex>
            </Content>
          </InternalLayout>
        );
      }
    }
  }

  const isAnyLowPriorityQueryFetching = lowPriorityQueries.some((query) => query.isFetching);

  return (
    <InternalLayout>
      <Content
        applyGutters
        constrainToPageHeight
        boxProps={{
          // On mobile and tablet, add more padding to the bottom of the content outlet to support the disclaimer.
          paddingBlockEnd: {
            base: '3rem',
            sm: '2rem', // ~480px
            md: '1.5rem', // ~768px
          },
        }}
      >
        <ProgressTrackingPage
          floorSearchTerm={floorSearchTerm}
          isDownloadingProgressDataExport={progressDataExportMutation.isLoading}
          isFetching={isAnyLowPriorityQueryFetching}
          milestones={milestonesQuery.data}
          onProgressDataExport={(date: string) => progressDataExportMutation.mutate({ projectId, date })}
          progress={progressQuery.data}
          project={projectDetailsQuery.data}
          selectedDate={selectedDate}
          setFloorSearchTerm={setFloorSearchTerm}
        />
      </Content>
    </InternalLayout>
  );
};
