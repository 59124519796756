import { ReactElement } from 'react';
import ReactDatePicker, { ReactDatePickerCustomHeaderProps, ReactDatePickerProps } from 'react-datepicker';

import CustomHeader from './CustomHeader';

import classes from './DatePicker.module.scss';

const DatePickerInline = (props: ReactDatePickerProps): ReactElement => (
  <ReactDatePicker
    calendarClassName={classes.datePicker}
    inline
    renderCustomHeader={(headerProps: ReactDatePickerCustomHeaderProps) => (
      <CustomHeader {...headerProps} minDate={props.minDate} />
    )}
    {...props}
  />
);

export default DatePickerInline;
