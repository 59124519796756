class GeometryHelper {
  /**
   * Compute the most lowest-magnitude change in angle measure required to pan from a start angle to a target angle.
   * @remark The result can be positive or negative.
   * @todo Unit tests
   */
  static getPanAngle = (startAngle: number, endAngle: number) => {
    const panDiff = endAngle - startAngle;
    let panAngle = panDiff;
    if (panDiff > Math.PI) {
      panAngle = panDiff - 2 * Math.PI;
    } else if (panDiff <= -Math.PI) {
      panAngle = panDiff + 2 * Math.PI;
    }

    return panAngle;
  };
}

export default GeometryHelper;
