import classNames from 'classnames';
import { ReactElement, useEffect } from 'react';

import { Button } from '../';
import { CheckIcon, CloseIcon, ErrorIcon } from '../Icon';

import classes from './Toast.module.scss';

interface Props {
  description?: string;
  onClose: () => void;
  onCloseComplete?: () => void;
  status: 'success' | 'warning' | 'error';
  title: string;
}

const Toast = ({ description, onClose, onCloseComplete, status, title }: Props): ReactElement => {
  useEffect(() => {
    // When the component will unmount, call onCloseComplete if provided.
    return () => onCloseComplete?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span
          className={classNames(classes.title, {
            [classes.titleSuccess]: status === 'success',
            [classes.titleWarning]: status === 'warning',
            [classes.titleError]: status === 'error',
          })}
        >
          {status === 'success' && <CheckIcon />}
          {status === 'warning' && <ErrorIcon />}
          {status === 'error' && <ErrorIcon />}
          {title}
        </span>
        <Button className={classes.closeButton} onClick={onClose} variant="ghost">
          <CloseIcon aria-label="Close" />
        </Button>
      </div>
      <p className={classes.body}>{description}</p>
    </div>
  );
};

export default Toast;
