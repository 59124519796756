import * as Yup from 'yup';

export const getPasswordValidationSchema = (fieldName = 'Password') =>
  Yup.string()
    .required(`${fieldName} is required`)
    .min(8, `${fieldName} must contain at least 8 characters`)
    .matches(/(?=.*[a-z])/u, `${fieldName} must contain at least one lowercase letter`)
    .matches(/(?=.*[A-Z])/u, `${fieldName} must contain at least one uppercase letter`)
    .matches(/(?=.*[0-9])/u, `${fieldName} must contain at least one number`)
    .matches(/(?=.*[^0-9a-zA-Z])/u, `${fieldName} must contain at least one symbol (such as !?#$%)`);

export const passwordRequirements = [
  `must contain at least 8 characters`,
  `must contain at least one lowercase letter`,
  `must contain at least one uppercase letter`,
  `must contain at least one number`,
  `must contain at least one symbol (such as !?#$%)`,
];
