import { ReactElement, ReactNode } from 'react';

import classes from './Caption.module.scss';

export interface CaptionProps {
  as?: ((props: { className?: string | undefined; children?: ReactNode }) => ReactElement) | string;
  children?: ReactNode;
}

const Caption = ({ as = 'span', children }: CaptionProps) => {
  const TagName = as;
  return <TagName className={classes.srOnly}>{children}</TagName>;
};

export default Caption;
