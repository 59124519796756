import { AxiosHeaders } from 'axios';
import 'whatwg-fetch';

import { ProcoreMe } from '../@types/Procore';
import { store } from '../store';
import { deleteRequest as deleteBase, getRequest as getBase, withAuthToken, withProcoreAuth } from '../utils/network';

/**
 * Retrieve a common Axios request configuration to be used for Procore requests.
 *
 * @see
 * https://developers.procore.com/documentation/mpz-headers
 */
export const getProcoreRequestConfiguration = (procoreCompanyId: number) => {
  const state = store.getState();

  const headers = new AxiosHeaders();

  headers.setAccept('application/json');
  headers.set('Procore-Company-Id', procoreCompanyId);

  if (state.auth.procoreToken) {
    headers.setAuthorization(`Bearer ${state.auth.procoreToken}`);
  } else {
    console.warn('Attempting to make a request with Procore authorization, but token not set');
  }

  return { baseURL: window.PROCORE_API_PREFIX, headers };
};

const get = (url: string, procoreCompanyId?: number) =>
  getBase(`${window.PROCORE_API_PREFIX}${url}`, withProcoreAuth(procoreCompanyId));

// Fetching resources
const getMe = () => get('rest/v1.0/me') as Promise<ProcoreMe>;

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const getAuth = async () => {
  const headers = await withAuthToken();
  return getBase(`${apiBaseUrl}/api/auth/sso/procore/token`, headers);
};

const clearAuth = async () => {
  const headers = await withAuthToken();
  return deleteBase(`${apiBaseUrl}/api/auth/sso/procore/token`, headers);
};

export { getMe, getAuth, clearAuth };
