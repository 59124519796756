const CLIENT_ERROR_FALLBACK_MESSAGE = 'Please verify your submission and try again.';
const SERVER_ERROR_FALLBACK_MESSAGE = 'An error occurred. Please try again later.';

interface FormErrorResult {
  message: string;
  fieldErrors?: { [fieldName: string]: string };
}

export async function transformErrorResponse(
  errorResponse: Response,
  formFields: { [key: string]: any },
  genericFallbackMessage: string = SERVER_ERROR_FALLBACK_MESSAGE
): Promise<FormErrorResult> {
  if (errorResponse.status >= 500) {
    // 5xx errors aren't guaranteed to even be JSON, so don't try to parse out anything useful.
    return { message: genericFallbackMessage };
  }

  try {
    const parsedBody = await (errorResponse as Response).json();
    // Sometimes, the backend raises a DRF ValidationError as an array of string messages.
    if (Array.isArray(parsedBody) && typeof parsedBody[0] === 'string') {
      return { message: parsedBody[0] };
    }

    for (const [key, value] of Object.entries(parsedBody)) {
      // Some endpoints return errors that aren't specific to a given form field.
      if (key === 'non_field_errors' && Array.isArray(value) && typeof value[0] === 'string') {
        return { message: value[0] };
      }
      // Most of the time, we would expect 4xx responses for form submissions to include field-by-field feedback. When
      // at least one field-level error is present, return the full structure.
      if (Object.keys(formFields).includes(key) && Array.isArray(value) && typeof value[0] === 'string') {
        console.warn('[utils/error] Validation possibly out of sync.', { field: key, source: errorResponse.url });
        return {
          message: CLIENT_ERROR_FALLBACK_MESSAGE,
          fieldErrors: Object.entries(parsedBody as { [entryKey: string]: string[] }).reduce(
            (acc, [entryKey, entryValue]) => ({ ...acc, [entryKey]: entryValue[0] }),
            {}
          ),
        };
      }
    }

    // We had a 4xx but the response didn't have a well-known shape. The user should verify that they entered their
    // submission correctly.
    return { message: CLIENT_ERROR_FALLBACK_MESSAGE };
  } catch (parseError) {
    console.error('[utils/error] Failed to parse response body as JSON!', parseError);
    return { message: genericFallbackMessage };
  }
}
