import { Component } from 'react';

interface Props {
  canvasRef?: (ref: HTMLCanvasElement) => void;
  contextRef?: (ref: CanvasRenderingContext2D | null) => void;
  height: number;
  updateOnResize: boolean;
  width: number;
}

/**
 * A canvas component which exposes refs to its HTMLCanvasElement and CanvasRenderingContext2D instances. Optionally, it
 * can also be configured to update when its dimensions change.
 * @see https://stackoverflow.com/questions/49786505/what-is-correct-lifecycle-method-in-react-16-3-to-update-canvas-from-props/49803151#49803151
 * @see https://philna.sh/blog/2018/09/27/techniques-for-animating-on-the-canvas-in-react/
 */
class PureCanvas extends Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    if (!nextProps.updateOnResize && !this.props.updateOnResize) {
      return false;
    }

    return this.props.width !== nextProps.width || this.props.height !== nextProps.height;
  }

  setRefs = (ref: HTMLCanvasElement) => {
    if (!ref) {
      return;
    }

    if (this.props.canvasRef) {
      this.props.canvasRef(ref);
    }
    if (this.props.contextRef) {
      this.props.contextRef(ref.getContext('2d'));
    }
  };

  render() {
    const { width, height } = this.props;

    return <canvas width={width} height={height} ref={this.setRefs} />;
  }
}

export default PureCanvas;
