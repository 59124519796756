import { FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik';
import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Button, FormControl } from '../';
import { Store } from '../../@types/redux/store';
import { login } from '../../actions/auth';
import { notifySuccess } from '../../actions/notifications';
import api from '../../api';
import { transformErrorResponse } from '../../utils/error';
import ChangePasswordModal from './ChangePasswordModal';

import classes from './Settings.module.scss';

interface FormValues {
  first_name: string;
  last_name: string;
  title: string;
  email: string;
  phone?: string;
}

// Note that this schema varies slightly from the onboarding registration schema.
const validationSchema = Yup.object({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  title: Yup.string().required('Title is required'),
  email: Yup.string().email('Email address invalid').required('Email is required'),
  phone: Yup.string().matches(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Phone number must match format XXX-XXX-XXXX'),
});

const ProfileSettings = (): ReactElement => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state: Store) => state.auth.user);

  const [changePassModalOpen, setChangePassModalOpen] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string | undefined>();

  const initialValues: FormValues = {
    first_name: userDetails?.first_name ?? '',
    last_name: userDetails?.last_name ?? '',
    title: userDetails?.title ?? '',
    email: userDetails?.email ?? '',
    phone: userDetails?.phone ?? '',
  };

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      const updatedProfile = await api.auth.setProfile({ ...values, update_source: 'settings_page' });
      dispatch(login(updatedProfile));
      dispatch(
        notifySuccess({
          title: 'Profile updated!',
          content: 'Your profile changes have been saved.',
        })
      );
    } catch (error) {
      console.error('[ProfileSettings] Failed to set user account information!', error);
      const { message, fieldErrors } = await transformErrorResponse(
        error as Response,
        values,
        'Failed to update profile. Please try again later.'
      );
      setSubmitError(message);
      if (fieldErrors) {
        formikHelpers.setErrors(fieldErrors);
      }
    }
  }

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
          <Form className={classes.formControlGrid}>
            <FormControl isInvalid={Boolean(errors.first_name) && touched.first_name}>
              <FormLabel>First Name</FormLabel>
              <Input
                name="first_name"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="First name"
                value={values.first_name}
              />
              <ErrorMessage component={FormErrorMessage} name="first_name" />
            </FormControl>
            <FormControl isInvalid={Boolean(errors.last_name) && touched.last_name}>
              <FormLabel>Last Name</FormLabel>
              <Input
                name="last_name"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Last name"
                value={values.last_name}
              />
              <ErrorMessage component={FormErrorMessage} name="last_name" />
            </FormControl>
            <FormControl isInvalid={Boolean(errors.title) && touched.title}>
              <FormLabel>Title</FormLabel>
              <Input
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Title"
                value={values.title}
              />
              <ErrorMessage component={FormErrorMessage} name="title" />
            </FormControl>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input disabled readOnly value={values.email} />
            </FormControl>
            <FormControl isInvalid={Boolean(errors.phone) && touched.phone}>
              <FormLabel>Phone Number</FormLabel>
              <Input
                name="phone"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Phone number (XXX-XXX-XXXX)"
                type="tel"
                value={values.phone}
              />
              <ErrorMessage component={FormErrorMessage} name="phone" />
            </FormControl>
            <div className={classes.formFooter}>
              {!isSubmitting && submitError && <p className={classes.errorMessage}>{submitError}</p>}
              <Button
                className={classes.submitButton}
                isDisabled={isSubmitting}
                onClick={() => setChangePassModalOpen(true)}
                type="button"
                variant="mediumEmphasis"
              >
                Change password
              </Button>
              <Button className={classes.submitButton} isLoading={isSubmitting} type="submit" variant="highEmphasis">
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <ChangePasswordModal close={() => setChangePassModalOpen(false)} open={changePassModalOpen} />
    </>
  );
};

export default ProfileSettings;
