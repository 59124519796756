import { FormErrorMessage, FormHelperText, FormLabel, Input } from '@chakra-ui/react';
import classNames from 'classnames';
import { ErrorMessage, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import capitalize from 'lodash/capitalize';
import { ChangeEvent, ReactElement, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import api from '../api';
import { Button, Caption, ExternalLayout, FormControl } from '../components';
import { CheckIcon, DotIcon, ErrorIcon } from '../components/Icon';
import { transformErrorResponse } from '../utils/error';
import { getPasswordValidationSchema, passwordRequirements } from '../utils/validation';

import classes from './SetFirstPassword.module.scss';

interface FormValues {
  old_password: string;
  new_password1: string;
  new_password2: string;
}

const validationSchema = Yup.object({
  old_password: Yup.string().required('Current password is required'),
  new_password1: getPasswordValidationSchema('New password'),
  new_password2: Yup.string()
    .required('Password confirmation required')
    .oneOf([Yup.ref('new_password1')], 'Passwords must match'),
});

const SetFirstPassword = (): ReactElement => {
  const history = useHistory();

  const [passwordValidationMessages, setPasswordValidationMessages] = useState<string[] | undefined>();
  const [submitError, setSubmitError] = useState<string | null>(null);

  const formRef = useRef<FormikProps<FormValues>>(null);

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      await api.auth.changePassword(values);
      setPasswordValidationMessages(undefined);
      history.push('/');
    } catch (error) {
      console.error('[SetFirstPassword] Failed to set password!', error);
      const { message, fieldErrors } = await transformErrorResponse(
        error as Response,
        values,
        'Failed to set password. Please try again later.'
      );
      setSubmitError(message);
      if (fieldErrors) {
        formikHelpers.setErrors(fieldErrors);
      }
    }
  }

  function handleSkip() {
    setPasswordValidationMessages(undefined);
    history.push('/');
  }

  async function onChangePassword(
    event: ChangeEvent<HTMLInputElement>,
    formikChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void
  ): Promise<void> {
    formikChangeHandler(event);
    await updatePasswordValidationMessages(event.target.value);
  }

  async function onSubmit() {
    // Note: we purposely don't prevent the default submit behavior here.
    await updatePasswordValidationMessages(formRef.current?.values?.new_password1);
  }

  async function updatePasswordValidationMessages(value = '') {
    try {
      await validationSchema.validateAt(
        'new_password1',
        { old_password: '', new_password1: value, new_password2: '' },
        { abortEarly: false }
      );
      setPasswordValidationMessages([]);
    } catch (error) {
      const validationError = error as Yup.ValidationError;
      setPasswordValidationMessages(validationError.errors);
    }
  }

  return (
    <ExternalLayout>
      <div className={classes.form}>
        <h1>Set password</h1>
        <p>This is your first time signing in. Would you like to change your password?</p>
        <Formik
          initialValues={{
            old_password: '',
            new_password1: '',
            new_password2: '',
          }}
          innerRef={formRef}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
            <Form className={classes.form}>
              <FormControl isInvalid={Boolean(errors.old_password) && touched.old_password} variant="external">
                <Caption as={FormLabel}>Current password</Caption>
                <Input
                  autoComplete="password"
                  name="old_password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Current password"
                  type="password"
                  value={values.old_password}
                />
                <ErrorMessage component={FormErrorMessage} name="old_password" />
              </FormControl>
              <FormControl isInvalid={Boolean(errors.new_password1) && touched.new_password1} variant="external">
                <Caption as={FormLabel}>New password</Caption>
                <Input
                  autoComplete="new-password"
                  name="new_password1"
                  onBlur={handleBlur}
                  onChange={(event) => onChangePassword(event, handleChange)}
                  placeholder="New password"
                  type="password"
                  value={values.new_password1}
                />
                <FormHelperText className={classes.passwordHelpText}>
                  {passwordRequirements.map((requirement) => {
                    let requirementStatus = 'indeterminate';
                    if (passwordValidationMessages?.some((message) => message.endsWith(requirement))) {
                      requirementStatus = 'failed';
                    } else if (passwordValidationMessages) {
                      requirementStatus = 'passed';
                    }

                    const requirementClassNames = classNames(classes.requirement, {
                      [classes.requirementIndeterminate]: requirementStatus === 'indeterminate',
                      [classes.requirementPassed]: requirementStatus === 'passed',
                      [classes.requirementFailed]: requirementStatus === 'failed',
                    });
                    return (
                      <div
                        className={requirementClassNames}
                        data-testid={`password-requirement-${requirementStatus}`}
                        key={requirement}
                      >
                        {requirementStatus === 'indeterminate' && <DotIcon aria-hidden />}
                        {requirementStatus === 'failed' && <ErrorIcon aria-label="Requirement failed" />}
                        {requirementStatus === 'passed' && <CheckIcon aria-label="Requirement passed" />}
                        <span>{capitalize(requirement)}</span>
                      </div>
                    );
                  })}
                </FormHelperText>
                {/* Only show an error message if it's distinct from all other requirement messages */}
                {passwordRequirements.every((requirement) => !errors.new_password1?.includes(requirement)) && (
                  <ErrorMessage component={FormErrorMessage} name="new_password1" />
                )}
              </FormControl>
              <FormControl isInvalid={Boolean(errors.new_password2) && touched.new_password2} variant="external">
                <Caption as={FormLabel}>Confirm new password</Caption>
                <Input
                  autoComplete="new-password"
                  name="new_password2"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Confirm new password"
                  type="password"
                  value={values.new_password2}
                />
                <ErrorMessage component={FormErrorMessage} name="new_password2" />
              </FormControl>
              {!isSubmitting && submitError && <p className={classes.errorMessage}>{submitError}</p>}
              <Button
                className={classes.submitButton}
                isLoading={isSubmitting}
                onClick={onSubmit}
                type="submit"
                variant="primary"
              >
                Set password
              </Button>
              <Button
                className={classes.skipButton}
                isDisabled={isSubmitting}
                onClick={handleSkip}
                type="button"
                variant="outlined"
              >
                Skip
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </ExternalLayout>
  );
};

export default SetFirstPassword;
