import { FormLabel, Input, Select } from '@chakra-ui/react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import pick from 'lodash/pick';
import { ForwardedRef, ReactElement, ReactNode, forwardRef, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { FormControl, FormControlErrorMessage } from '../';
import { Store } from '../../@types/redux/store';
import { FormFieldName, FormFields } from './constants';

import classes from './UserProfileForm.module.scss';

export type FormValues = Partial<{
  first_name: string;
  last_name: string;
  userpersona: string;
  title: string;
  company_name: string;
  phone?: string;
}>;

interface OptionLoaderStatus {
  data?: Record<string, unknown>[];
  error?: Error;
  loading: boolean;
}

interface Props {
  children?: ReactNode;
  fields: FormFieldName[];
  nonFieldErrors: string[];
  onSubmit: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => Promise<void>;
}

const UserProfileForm = forwardRef((props: Props, formRef: ForwardedRef<FormikProps<FormValues>>): ReactElement => {
  const { children, fields, nonFieldErrors = [], onSubmit } = props;

  const userProfile = useSelector((state: Store) => state.auth.user);

  const [formOptions, setFormOptions] = useState<Record<FormFieldName, OptionLoaderStatus>>(
    {} as Record<FormFieldName, OptionLoaderStatus>
  );

  const loadOptions = async (formFieldNames: FormFieldName[]) => {
    for (const formFieldName of formFieldNames) {
      const loader = FormFields[formFieldName].optionLoader;
      if (!loader) {
        throw new Error('Form field loader absent');
      }

      setFormOptions((currentFormOptions) => ({
        ...currentFormOptions,
        [formFieldName]: {
          data: undefined,
          error: undefined,
          loading: true,
        },
      }));
      try {
        const result = await loader();
        setFormOptions((currentFormOptions) => ({
          ...currentFormOptions,
          [formFieldName]: {
            data: result,
            error: undefined,
            loading: false,
          },
        }));
      } catch (error) {
        console.error(`[UserProfileForm] Failed to load options for field ${formFieldName}!`, error);
        setFormOptions((currentFormOptions) => ({
          ...currentFormOptions,
          [formFieldName]: {
            data: undefined,
            error,
            loading: false,
          },
        }));
      }
    }
  };

  const initialValues = useMemo<FormValues>(() => {
    const values: FormValues = {};
    const selectedFields = pick(FormFields, fields);
    for (const { htmlName, initialValueAccessor } of Object.values(selectedFields)) {
      // If the server value was an object, an initialValueAccessor should be used to retrieve the initial value for the
      // field. Otherwise, expect the value to be a string.
      values[htmlName] = (
        initialValueAccessor ? initialValueAccessor(userProfile?.[htmlName]) : userProfile?.[htmlName] ?? ''
      ) as string;
    }

    return values;
  }, [fields, userProfile]);

  const validationSchema = useMemo(() => {
    const selectedFields = pick(FormFields, fields);
    const validators = Object.values(selectedFields).reduce(
      (acc, { htmlName, validator }) => ({ ...acc, [htmlName]: validator }),
      {}
    );
    return Yup.object(validators);
  }, [fields]);

  useEffect(() => {
    const pendingLoaders = fields.filter((field) => FormFields[field].optionLoader && !formOptions[field]);
    if (!pendingLoaders) {
      return;
    }

    loadOptions(pendingLoaders);
  }, [fields, formOptions]);

  const anyOptionLoadError = Object.values(formOptions).some((formOption) => formOption.error);

  return (
    <Formik innerRef={formRef} initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ errors, handleBlur, handleChange, submitCount, touched, values }) => (
        <Form className={classes.formControlGrid}>
          {fields.map((fieldName) => {
            const { htmlName, displayName, placeholder, htmlInputType, htmlTagName, optionValueKey, optionNameKey } =
              FormFields[fieldName];
            return (
              <FormControl
                key={htmlName}
                isInvalid={Boolean(errors[htmlName] && (touched[htmlName] || submitCount > 0)) && touched[htmlName]}
              >
                <FormLabel>{displayName}</FormLabel>
                {htmlTagName === 'input' && (htmlInputType === 'text' || htmlInputType === 'tel') && (
                  <Input
                    name={htmlName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={placeholder}
                    type={htmlInputType}
                    value={values[htmlName]}
                  />
                )}
                {htmlTagName === 'select' && (
                  <Select
                    data-defaultselected={!values[htmlName]}
                    isDisabled={formOptions?.[htmlName]?.loading}
                    name={htmlName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={formOptions?.[htmlName]?.loading ? 'Loading...' : placeholder}
                    value={values[htmlName]}
                  >
                    {formOptions?.[htmlName]?.data?.map((selectOption) => {
                      const key = `${htmlName}-option-${selectOption?.[optionValueKey ?? 'id']}`;
                      const value = selectOption?.[optionValueKey ?? 'id'] as string;
                      const name = selectOption?.[optionNameKey ?? 'name'] as string;
                      return (
                        <option key={key} value={value}>
                          {name}
                        </option>
                      );
                    })}
                  </Select>
                )}
                <FormControlErrorMessage>
                  {(touched[htmlName] || submitCount > 0) && errors[htmlName]}
                </FormControlErrorMessage>
              </FormControl>
            );
          })}
          {anyOptionLoadError && (
            <FormControlErrorMessage>Failed to load form data. Please try again later.</FormControlErrorMessage>
          )}
          {nonFieldErrors.map((errorMessage) => (
            // Not ideal, but the field-level error messages should be unique, so use them as keys.
            <FormControlErrorMessage key={errorMessage}>{errorMessage}</FormControlErrorMessage>
          ))}
          {children}
        </Form>
      )}
    </Formik>
  );
});

export default UserProfileForm;
