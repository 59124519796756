import axios, { AxiosRequestConfig } from 'axios';

import { SpecificationSection } from '../../../@types/procore/v1/SpecificationSection';
import { getProcoreRequestConfiguration } from '../../procore';

export const SpecificationSectionApi = {
  /**
   * Retrieve the list of Procore Specification Sections associated with a given Procore Company and Project.
   * @see https://developers.procore.com/reference/rest/v1/specification-sections?version=1.0#list-specification-sections
   * @param companyId Unique identifier for the Procore Company.
   * @param projectId Unique identifier for the Procore Project.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the list of Procore Specification Sections.
   */
  list: (companyId: number, projectId: number, requestConfig?: AxiosRequestConfig) => {
    const baseRequestConfig = getProcoreRequestConfiguration(companyId);

    const queryParams = new URLSearchParams();
    queryParams.set('project_id', String(projectId));

    return axios.get<SpecificationSection[]>(`/rest/v1.0/specification_sections?${queryParams.toString()}`, {
      ...baseRequestConfig,
      ...requestConfig,
    });
  },
};
