import { AxiosRequestConfig } from 'axios';

import { Annotation } from '../../../@types/api/v0/rest/Annotation';
import { Walkthrough } from '../../../@types/api/v0/rest/Walkthrough';
import { get } from '../../next';

export const WalkthroughApi = {
  /**
   * Retrieve a walkthrough instance by ID.
   * @param id Unique ID of the desired walkthrough on the server.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the requested walkthrough.
   */
  getById: (id: number, requestConfig?: AxiosRequestConfig) =>
    get<Walkthrough>(`/api/walkthroughs/${id}/`, requestConfig),
  /**
   * Retrieve the set of annotations for a walkthrough instance.
   * @param id Unique ID of the target walkthrough on the server to retrieve annotations for.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the requested walkthrough.
   */
  getAnnotationsByWalkthroughId: (id: number, requestConfig?: AxiosRequestConfig) =>
    get<Annotation[]>(`/api/walkthroughs/${id}/annotations/`, requestConfig),
};
