import { formAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const formHelpers = createMultiStyleConfigHelpers(formAnatomy.keys);

export const formTheme = formHelpers.defineMultiStyleConfig({
  baseStyle: {
    helperText: {
      color: 'var(--gray-600)',
    },
  },
});
