import { Icon, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { MinusIcon, TriangleUpIcon } from '../../components/Icon';
import theme from '../../theme';
import { getRoundedProgressValue } from '../../utils/progressUtils';

interface PercentChangeCellProps {
  /** Flag indicating whether or not to render the percent change without the icon. */
  isPlainText?: boolean;
  /** Value to display. */
  value: number | null;
}

// TODO: this component is now being reused; where should it live?
export const PercentChange = (props: PercentChangeCellProps) => {
  const { isPlainText, value } = props;

  let displayValue: string;
  let icon: ReactNode;

  const positiveIcon = (
    <Icon
      aria-hidden
      as={TriangleUpIcon}
      color={theme.colors.brand.secondary[500]}
      data-testid="positive-progress"
      height="1.25rem"
      width="1.25rem"
    />
  );
  const neutralIcon = (
    <Icon
      aria-hidden
      as={MinusIcon}
      color={theme.colors.brand.gray[200]}
      data-testid="no-progress"
      height="1.5rem"
      width="1.5rem"
    />
  );

  if (value === null || !Number.isFinite(value)) {
    displayValue = '-';
  } else if (value === 0) {
    displayValue = '0%';
    icon = neutralIcon;
  } else if (value > 0 && value < 1) {
    displayValue = '1%';
    icon = positiveIcon;
  } else {
    displayValue = `${getRoundedProgressValue(value)}%`;
    icon = positiveIcon;
  }

  return (
    <>
      <Text paddingInlineEnd="0.25rem">{displayValue}</Text>
      {isPlainText ? null : icon}
    </>
  );
};
