import { useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import sortBy from 'lodash/sortBy';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { Account } from '../../@types/OnSiteIQ';
import { AccountUser } from '../../@types/UserManagement';
import { Store } from '../../@types/redux/store';
import { UserManagementApi } from '../../api/next';
import Toast from '../../components/Toast';
import { QueryTopics, UserManagementQueryKeys } from '../../constants/queries';
import usePermissions from '../../hooks/usePermissions';
import Routes from '../../routes';
import { AccountUsersPage } from './AccountUsersPage';

export enum InviteStatus {
  Pending = 'PENDING',
  Registered = 'REGISTERED',
}

/** Container component which performs API requests and passes data to the presentational component */
export const AccountUsersContainer = () => {
  const history = useHistory();
  const urlParameters = useParams<{ accountId?: string }>();
  const accountId = Number(urlParameters?.accountId);

  const isInternal = useSelector((state: Store) => state.auth.user?.is_internal);
  const [showInternalUsers, setShowInternalUsers] = useState(false);

  const toast = useToast();

  const { accountsWithInvitePermission, accountsWithOneOrMoreProject, meQuery } = usePermissions();
  const accountMembersQuery = useQuery({
    enabled: isFinite(accountId) && accountId > 0,
    queryKey: showInternalUsers
      ? [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.ACCOUNT_MEMBERS_WITH_INTERNAL_USERS, accountId]
      : [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.ACCOUNT_MEMBERS, accountId],
    queryFn: async ({ signal }) =>
      (await UserManagementApi.getAccountMembers(accountId, showInternalUsers, { signal })).data,
  });

  const accounts: Account[] = useMemo(() => {
    return sortBy(accountsWithOneOrMoreProject, ({ name }) => name.toLocaleLowerCase());
  }, [accountsWithOneOrMoreProject]);

  // When the account list loads, auto-select the first account if one is not selected.
  useEffect(() => {
    if (accounts.length === 0) {
      return;
    }

    // Guard against the user entering an account ID they don't belong to into the URL: if the ID matches one of their
    // accounts, no need to redirect.
    const matchingAccount = accounts.some(({ id }) => id === accountId);
    if (matchingAccount) {
      return;
    }

    // Redirect to their first account.
    const destination = generatePath(Routes.ACCOUNT_USERS, { accountId: accounts[0].id });
    history.push(destination);
  }, [accountId, accounts, history]);

  const handleAccountChange = (nextAccountId: number) => {
    const destination = generatePath(Routes.ACCOUNT_USERS, {
      accountId: nextAccountId > 0 ? String(nextAccountId) : undefined,
    });
    history.push(destination);
  };

  const handleInviteComplete = () => {
    toast({
      duration: 5000,
      isClosable: true,
      render: (toastProps) => (
        <Toast {...toastProps} title="Success" description="Successfully invited user(s)." status="success" />
      ),
    });
  };

  const accountUsers: AccountUser[] = accountMembersQuery.data?.users ?? [];
  const canInviteToAccount = accountsWithInvitePermission.some(({ id }) => id === accountId);

  const handleShowInternalUsersChange = (nextShowInternalUsers: boolean) => {
    setShowInternalUsers(nextShowInternalUsers);
  };

  const isError = meQuery.isError || accountMembersQuery.isError;
  const isFetching = meQuery.isFetching || accountMembersQuery.isFetching;

  return (
    <AccountUsersPage
      accountId={accountId}
      accountUsers={accountUsers}
      accounts={accounts}
      canInviteToAccount={canInviteToAccount}
      canToggleInternalUsers={isInternal}
      showInternalUsers={showInternalUsers}
      isError={isError}
      isFetching={isFetching}
      onAccountChange={handleAccountChange}
      onShowInternalUsersChange={handleShowInternalUsersChange}
      onInviteComplete={handleInviteComplete}
    />
  );
};
