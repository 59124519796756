import { Progress, Stat, StatLabel, StatNumber } from '@chakra-ui/react';

import { getRoundedProgressValue } from '../../utils/progressUtils';

interface EstimatedProgressProps {
  /** The progress value to display as a percentage and in a progress bar. */
  progress: number | null;
  /** The size of the progress bar, for use with the Chakra theme. */
  progressBarSize?: 'sm' | 'lg';
}

export const EstimatedProgress = ({ progress, progressBarSize = 'sm' }: EstimatedProgressProps) => {
  const roundedProgress = getRoundedProgressValue(progress);

  const displayValue = typeof progress === 'number' && Number.isFinite(progress) ? `${roundedProgress}%` : '-';

  return (
    <>
      <Stat>
        <StatLabel>Estimated Progress</StatLabel>
        <StatNumber>{displayValue}</StatNumber>
      </Stat>
      <Progress value={roundedProgress ?? 0} variant="purpleProgress" size={progressBarSize} />
    </>
  );
};
