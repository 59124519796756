import {
  Center,
  Divider,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../';
import { Store } from '../../@types/redux/store';
import { clearProcoreIntegration, getProcoreMe, setAuthToken } from '../../actions/procore';
import theme from '../../theme';
import { LogoIcon, ProcoreIcon } from '../Icon';

enum ConnectionState {
  CONFIRMING,
  WAITING,
}

const IntegrationSettings = (): ReactElement => {
  const dispatch = useDispatch();

  const [connectionState, setConnectionState] = useState(ConnectionState.CONFIRMING);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const procoreToken = useSelector((state: Store) => state.auth.procoreToken);

  function onAuthorize() {
    setConnectionState(ConnectionState.WAITING);

    const clientId = window.PROCORE_CLIENT_ID;
    const authEndpoint = `${window.PROCORE_API_PREFIX}oauth/authorize`;
    const redirect = window.PROCORE_CALLBACK;

    window.open(`${authEndpoint}?response_type=code&client_id=${clientId}&redirect_uri=${redirect}`, '_blank');
  }

  function onCloseModal() {
    setIsModalOpen(false);
    setConnectionState(ConnectionState.CONFIRMING);
  }

  function watchLocalStorage(event: StorageEvent) {
    if (event.key !== 'procoreAccessToken') {
      return;
    }
    if (!event.newValue) {
      console.warn('[IntegrationSettings] Procore access token not provided!');
      return;
    }

    console.debug('[IntegrationSettings] Procore integrated successfully!');
    dispatch(setAuthToken(event.newValue));
    dispatch(getProcoreMe());
    setIsModalOpen(false);
    setConnectionState(ConnectionState.CONFIRMING);
  }

  useEffect(() => {
    if (!procoreToken && connectionState === ConnectionState.WAITING) {
      window.addEventListener('storage', watchLocalStorage);
    }

    return () => {
      window.removeEventListener('storage', watchLocalStorage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionState]);

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" paddingBlock="0.5rem">
        <VStack>
          <Icon aria-hidden as={ProcoreIcon} height="2.625rem" width="2.875rem" />
          <Text>Procore</Text>
        </VStack>
        {procoreToken ? (
          <Button onClick={() => dispatch(clearProcoreIntegration())} variant="lowEmphasis">
            Disconnect
          </Button>
        ) : (
          <Button
            data-pendo-label="Procore connect"
            data-pendo-topic="social"
            onClick={() => setIsModalOpen(true)}
            variant="lowEmphasis"
          >
            Connect
          </Button>
        )}
      </Flex>
      <Modal closeOnOverlayClick={false} isCentered isOpen={isModalOpen} onClose={onCloseModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Integrations</ModalHeader>
          <ModalBody>
            <Center aria-hidden marginBlockEnd="0.5rem" paddingBlock="1rem">
              <Icon as={ProcoreIcon} height="2.5rem" width="2.5rem" />
              <Divider
                borderBlock="0.0625rem solid var(--gray-800)"
                height="0.375rem"
                marginBlock={0}
                marginInline="1rem"
                width="7.5rem"
              />
              <Icon as={LogoIcon} color={theme.colors.brand.primary[600]} height="3.25rem" width="3.25rem" />
            </Center>
            {connectionState === ConnectionState.CONFIRMING && (
              <Text paddingBlockEnd="1.5rem">You are about to sign in with your Procore account.</Text>
            )}
            {connectionState === ConnectionState.WAITING && (
              <Text paddingBlockEnd="1.5rem">Waiting for Procore sign-in...</Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onCloseModal} variant="mediumEmphasis">
              Cancel
            </Button>
            <Button
              isLoading={connectionState === ConnectionState.WAITING}
              onClick={onAuthorize}
              variant="highEmphasis"
            >
              Authorize
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default IntegrationSettings;
