import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
  body: {
    padding: '1rem',
  },
  container: {
    boxShadow: 'none',
  },
});

export const cardTheme = defineMultiStyleConfig({ baseStyle });
