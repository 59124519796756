import {
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import classNames from 'classnames';
import { ErrorMessage, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import capitalize from 'lodash/capitalize';
import { ChangeEvent, ReactElement, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { Button, FormControl } from '../';
import { logout } from '../../actions/auth';
import api from '../../api';
import { transformErrorResponse } from '../../utils/error';
import { getPasswordValidationSchema, passwordRequirements } from '../../utils/validation';
import { CheckIcon, DotIcon, ErrorIcon } from '../Icon';

import classes from './Settings.module.scss';

interface Props {
  open: boolean;
  close: () => void;
}

interface FormValues {
  old_password: string;
  new_password1: string;
  new_password2: string;
}

const validationSchema = Yup.object({
  old_password: Yup.string().required('Current password is required'),
  new_password1: getPasswordValidationSchema('New password'),
  new_password2: Yup.string()
    .required('New password confirmation required')
    .oneOf([Yup.ref('new_password1')], 'Passwords must match'),
});

const ChangePasswordModal = (props: Props): ReactElement => {
  const dispatch = useDispatch();

  const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
  const [passwordValidationMessages, setPasswordValidationMessages] = useState<string[] | undefined>();
  const [submitError, setSubmitError] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const formRef = useRef<FormikProps<FormValues>>(null);

  function close() {
    setPasswordValidationMessages(undefined);
    props.close();
  }

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    setSubmitError(undefined);
    setSubmitting(true);
    try {
      await api.auth.changePassword(values);
      setPasswordChanged(true);

      setTimeout(() => {
        setPasswordValidationMessages(undefined);
        dispatch(logout());
      }, 5000);
    } catch (error) {
      console.error('[ChangePasswordModal] Failed to change password!', error);
      const { message, fieldErrors } = await transformErrorResponse(
        error as Response,
        values,
        'Failed to change password. Please try again later.'
      );
      setSubmitError(message);
      if (fieldErrors) {
        formikHelpers.setErrors(fieldErrors);
      }
      setSubmitting(false);
    }
  }

  async function onChangePassword(
    event: ChangeEvent<HTMLInputElement>,
    formikChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void
  ): Promise<void> {
    formikChangeHandler(event);
    await updatePasswordValidationMessages(event.target.value);
  }

  async function submit() {
    await updatePasswordValidationMessages(formRef.current?.values?.new_password1);
    formRef.current?.handleSubmit();
  }

  async function updatePasswordValidationMessages(value = '') {
    try {
      await validationSchema.validateAt(
        'new_password1',
        { old_password: '', new_password1: value, new_password2: '' },
        { abortEarly: false }
      );
      setPasswordValidationMessages([]);
    } catch (error) {
      const validationError = error as Yup.ValidationError;
      setPasswordValidationMessages(validationError.errors);
    }
  }

  return (
    <Modal closeOnOverlayClick={false} isCentered isOpen={props.open} onClose={close} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Change Password</ModalHeader>
        <ModalBody>
          {passwordChanged ? (
            <>
              <p className={classes.successMessage}>
                <CheckIcon />
                Your password has been changed.
              </p>
              <p className={classes.instructions}>
                You will be redirected to the Sign In page momentarily. Please enter your new password to continue.
              </p>
            </>
          ) : (
            <Formik
              initialValues={{
                old_password: '',
                new_password1: '',
                new_password2: '',
              }}
              innerRef={formRef}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ errors, handleBlur, handleChange, touched, values }) => (
                <Form className={classes.formVertical}>
                  <FormControl isInvalid={Boolean(errors.old_password) && touched.old_password}>
                    <FormLabel>Current password</FormLabel>
                    <Input
                      autoComplete="current-password"
                      name="old_password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Current password"
                      type="password"
                      value={values.old_password}
                    />
                    <ErrorMessage component={FormErrorMessage} name="old_password" />
                  </FormControl>
                  <FormControl isInvalid={Boolean(errors.new_password1) && touched.new_password1}>
                    <FormLabel>New password</FormLabel>
                    <Input
                      autoComplete="new-password"
                      name="new_password1"
                      onBlur={handleBlur}
                      onChange={(event) => onChangePassword(event, handleChange)}
                      placeholder="New password"
                      type="password"
                      value={values.new_password1}
                    />
                    <FormHelperText className={classes.passwordHelpText}>
                      {passwordRequirements.map((requirement) => {
                        let requirementStatus = 'indeterminate';
                        if (passwordValidationMessages?.some((message) => message.endsWith(requirement))) {
                          requirementStatus = 'failed';
                        } else if (passwordValidationMessages) {
                          requirementStatus = 'passed';
                        }

                        const requirementClassNames = classNames(classes.requirement, {
                          [classes.requirementIndeterminate]: requirementStatus === 'indeterminate',
                          [classes.requirementPassed]: requirementStatus === 'passed',
                          [classes.requirementFailed]: requirementStatus === 'failed',
                        });
                        return (
                          <div
                            className={requirementClassNames}
                            data-testid={`password-requirement-${requirementStatus}`}
                            key={requirement}
                          >
                            {requirementStatus === 'indeterminate' && <DotIcon aria-hidden />}
                            {requirementStatus === 'failed' && <ErrorIcon aria-label="Requirement failed" />}
                            {requirementStatus === 'passed' && <CheckIcon aria-label="Requirement passed" />}
                            <span>{capitalize(requirement)}</span>
                          </div>
                        );
                      })}
                    </FormHelperText>
                    {/* Only show an error message if it's distinct from all other requirement messages */}
                    {passwordRequirements.every((requirement) => !errors.new_password1?.includes(requirement)) && (
                      <ErrorMessage component={FormErrorMessage} name="new_password1" />
                    )}
                  </FormControl>
                  <FormControl isInvalid={Boolean(errors.new_password2) && touched.new_password2}>
                    <FormLabel>Confirm new password</FormLabel>
                    <Input
                      autoComplete="new-password"
                      name="new_password2"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Confirm new password"
                      type="password"
                      value={values.new_password2}
                    />
                    <ErrorMessage component={FormErrorMessage} name="new_password2" />
                  </FormControl>
                  {!submitting && submitError && <p className={classes.errorMessage}>{submitError}</p>}
                </Form>
              )}
            </Formik>
          )}
        </ModalBody>
        {!passwordChanged && (
          <ModalFooter>
            <Button mr={3} isDisabled={submitting} onClick={close} variant="mediumEmphasis">
              Cancel
            </Button>
            <Button isLoading={submitting} onClick={submit} variant="highEmphasis">
              Save
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ChangePasswordModal;
