import { Button, Image, Text } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

import theme from '../../../theme';
import { formatIsoDate } from '../../../utils/dateUtils';
import { TimeTravelOption } from './TimeTravelDrawer';

export interface TimeTravelListItemProps {
  /** Flag indicating whether or not this Time Travel option is active. */
  isActive?: boolean;
  /** Handler to call when the user selects a time travel option. */
  onSelectOption: (option: TimeTravelOption) => void;
  /** The current time travel option. */
  option: TimeTravelOption;
  /** Flag indicating whether or not to render the time travel option's thumbnail. */
  showThumbnail?: boolean;
}

const TimeTravelListItem = (props: TimeTravelListItemProps) => {
  const { isActive, onSelectOption, option, showThumbnail } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);

  const formattedDateSegments = formatIsoDate(option.when);

  const onClick = () => {
    if (isActive) {
      return;
    }

    onSelectOption(option);
  };

  useEffect(() => {
    if (buttonRef.current && isActive) {
      buttonRef.current.scrollIntoView({
        block: 'center',
      });
    }
  }, [isActive, buttonRef]);

  return (
    <Button
      alignItems="flex-start"
      data-pendo-label="Select time travel date"
      data-pendo-topic="drawer"
      color={theme.colors.brand.gray[600]}
      display="flex"
      flexDirection="column"
      height="fit-content"
      isActive={isActive}
      margin="0.125rem 0"
      onClick={onClick}
      padding="0.75rem 1rem"
      ref={buttonRef}
      textAlign="left"
      variant="ghost"
      width="100%"
      _active={{
        backgroundColor: theme.colors.brand.gray[100],
        color: theme.colors.brand.gray[800],
      }}
      _first={{
        marginBlockStart: 0,
      }}
      _last={{
        marginBlockEnd: 0,
      }}
    >
      {showThumbnail && 'thumbnail' in option && (
        <Image
          alt=""
          display="block"
          height="10rem"
          objectFit="cover"
          marginBlockEnd="0.5rem"
          width="100%"
          src={option.thumbnail}
        />
      )}
      <Text
        as="span"
        color={theme.colors.brand.gray[800]}
        fontSize="1.125rem"
        fontWeight="bold"
        noOfLines={2}
        marginBlockEnd="0.5rem"
        whiteSpace="normal"
        width="100%"
      >
        {formattedDateSegments?.formattedDate}
      </Text>
      <Text as="span" fontSize="0.875rem">
        {formattedDateSegments?.formattedTime}
      </Text>
    </Button>
  );
};

export default TimeTravelListItem;
