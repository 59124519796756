import { Dispatch } from 'redux';

import { ProcoreMe } from '../@types/Procore';
import { clearAuth, getAuth, getMe } from '../api/procore';
import { CLEAR_PROCORE, SET_PROCORE_ME, SET_PROCORE_TOKEN } from '../constants/actionTypes';

export const getAuthToken =
  () =>
  (dispatch: Dispatch<{ type: string; token: string } | { type: string; procoreMe: ProcoreMe }>): Promise<void> =>
    getAuth()
      .then((resp: any) => {
        // TODO What should be done in this error case?
        if (!resp || !resp.access_token) {
          throw new Error('Expected procore auth token, but response body was empty');
        }

        dispatch({
          type: SET_PROCORE_TOKEN,
          token: resp.access_token,
        });
        return getMe();
      })
      .then((procoreMe) => {
        dispatch({
          type: SET_PROCORE_ME,
          procoreMe,
        });
      })
      .catch((err) => console.info(`Failed to get Procore auth token ${err}`));

export const getProcoreMe = () => (dispatch: any) => {
  getMe()
    .then((me) => {
      dispatch({
        type: SET_PROCORE_ME,
        procoreMe: me,
      });
    })
    .catch((err) => console.info(`Failed to get Procore profile: ${err}`));
};

export const setAuthToken = (token: string) => (dispatch: any) =>
  dispatch({
    type: SET_PROCORE_TOKEN,
    token,
  });

export const clearProcoreIntegration = () => (dispatch: any) =>
  clearAuth()
    .then(() =>
      dispatch({
        type: CLEAR_PROCORE,
      })
    )
    .catch((err) => console.info(`Failed to clear Procore profile: ${err}`));
