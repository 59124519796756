import { Tooltip } from '@chakra-ui/react';
import { ReactElement } from 'react';

import { Button } from '../';
import { SnapshotIcon } from '../Icon';

interface Props {
  active: boolean;
  disabled: boolean;
  onClick: () => void;
}

const SnapshotButton = ({ active, disabled, onClick }: Props): ReactElement => (
  <Tooltip hasArrow label="Take a Screenshot" placement="left">
    <Button
      data-pendo-label="Take screenshot"
      data-pendo-topic="view-360-action"
      isDisabled={disabled}
      id="view-360-create-snapshot-button"
      isActive={active}
      onClick={onClick}
      variant="view360"
    >
      <SnapshotIcon aria-label="Take a screenshot" />
    </Button>
  </Tooltip>
);

export default SnapshotButton;
