import axios, { AxiosRequestConfig } from 'axios';

import {
  Observation,
  ObservationAssignee,
  ObservationCreateRequestBody,
  ObservationDefaultDistributionMember,
  ObservationPotentialDistributionMember,
  ObservationType,
} from '../../../@types/procore/v1/Observation';
import { post } from '../../next';
import { getProcoreRequestConfiguration } from '../../procore';

export const ObservationApi = {
  /**
   * Create a new Procore Observation Item.
   * @see https://developers.procore.com/reference/rest/v1/observations?version=1.0#create-observation-item
   * @param companyId Unique identifier for the Procore Company.
   * @param requestBody Request payload to be sent to Procore to create a new Observation Item.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the created Observation Item.
   */
  createObservationItem: (
    companyId: number,
    requestBody: ObservationCreateRequestBody,
    requestConfig?: AxiosRequestConfig
  ) => {
    const baseRequestConfig = getProcoreRequestConfiguration(companyId);

    // Axios will automatically map the `requestBody` passed here as form data.
    return post<ObservationCreateRequestBody, Observation>('rest/v1.0/observations/items', requestBody, {
      ...baseRequestConfig,
      headers: {
        ...baseRequestConfig.headers,
        'Content-Type': 'multipart/form-data',
      },
      ...requestConfig,
    });
  },
  /**
   * Retrieve the list of Procore users who are on the Observations Default Distribution list.
   * @see https://developers.procore.com/reference/rest/v1/observations?version=1.0#list-observation-default-distribution-members
   * @param companyId Unique identifier for the Procore Company.
   * @param projectId Unique identifier for the Procore Project.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the list of Procore Observation Default Distribution members.
   */
  listDefaultDistributionMembers: (companyId: number, projectId: number, requestConfig?: AxiosRequestConfig) => {
    const baseRequestConfig = getProcoreRequestConfiguration(companyId);

    const queryParams = new URLSearchParams();
    queryParams.set('project_id', String(projectId));

    return axios.get<ObservationDefaultDistributionMember[]>(
      `rest/v1.0/observations/default_distribution?${queryParams.toString()}`,
      {
        ...baseRequestConfig,
        ...requestConfig,
      }
    );
  },
  /**
   * Retrieve the list of Procore users to whom an Observation may be distributed.
   * @see https://developers.procore.com/reference/rest/v1/observations?version=1.0#list-observation-potential-distribution-members
   * @param companyId Unique identifier for the Procore Company.
   * @param projectId Unique identifier for the Procore Project.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the list of Procore Observation Potential Distribution members.
   */
  listPotentialDistributionMembers: (companyId: number, projectId: number, requestConfig?: AxiosRequestConfig) => {
    const baseRequestConfig = getProcoreRequestConfiguration(companyId);

    const queryParams = new URLSearchParams();
    queryParams.set('project_id', String(projectId));

    return axios.get<ObservationPotentialDistributionMember[]>(
      `rest/v1.0/observations/potential_distribution_members?${queryParams.toString()}`,
      {
        ...baseRequestConfig,
        ...requestConfig,
      }
    );
  },
  /**
   * Retrieve the list of Procore users assignable to Observations within a Procore Project.
   * @see https://developers.procore.com/reference/rest/v1/observations-assignees?version=1.0#list-observation-assignee-options
   * @param companyId Unique identifier for the Procore Company.
   * @param projectId Unique identifier for the Procore Project.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the list of Procore Observation Assignee options.
   */
  listAssigneeOptions: (companyId: number, projectId: number, requestConfig?: AxiosRequestConfig) => {
    const baseRequestConfig = getProcoreRequestConfiguration(companyId);

    const queryParams = new URLSearchParams();
    queryParams.set('project_id', String(projectId));

    return axios.get<ObservationAssignee[]>(`rest/v1.0/observations/assignees?${queryParams.toString()}`, {
      ...baseRequestConfig,
      ...requestConfig,
    });
  },
  /**
   * Retrieve the list of supported Procore Observation Types for a given Procore Company and Project.
   * @see https://developers.procore.com/reference/rest/v1/observations?version=1.0#list-observation-types
   * @param companyId Unique identifier for the Procore Company.
   * @param projectId Unique identifier for the Procore Project.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the list of Procore Observation Types.
   */
  listObservationTypes: (companyId: number, projectId: number, requestConfig?: AxiosRequestConfig) => {
    const baseRequestConfig = getProcoreRequestConfiguration(companyId);

    const queryParams = new URLSearchParams();
    queryParams.set('company_id', String(companyId));
    queryParams.set('project_id', String(projectId));

    return axios.get<ObservationType[]>(`rest/v1.0/observations/types?${queryParams.toString()}`, {
      ...baseRequestConfig,
      ...requestConfig,
    });
  },
};
