// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck Ignore this file completely; it needs a rewrite.
import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import classNames from 'classnames';
import assign from 'lodash/assign';
import clamp from 'lodash/clamp';
import findLast from 'lodash/findLast';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import { Component, MouseEvent, ReactNode, WheelEvent } from 'react';

import { Annotation } from '../../@types/api/v0/rest/Annotation';
import { Detection } from '../../@types/api/v0/rest/Detection';
import { Floorplan } from '../../@types/api/v0/rest/Floorplan';
import { Node } from '../../@types/api/v0/rest/Node';
import theme from '../../theme';
import { pixelsToRem } from '../../utils/device';
import PureCanvas from '../PureCanvas';
import { ZoomIn, ZoomOut } from '../ZoomControls/ZoomControls';
import { createDetectionPaths, drawDetection, drawNode, isDetectionHovered, isNodeHovered } from './Minimap.helpers';

import commonClasses from '../../Common.module.scss';
import classes from './MiniMap.module.scss';

export interface MiniMapProps {
  activeDetectionId?: number;
  annotations?: Annotation[];
  children?: ReactNode;
  className?: string;
  currentNode?: Node;
  detectionList: any; // TODO: type this here and in the helpers file.
  floorplan: Floorplan;
  initiallyShowZoom?: boolean;
  /** Current orientation of the 360 viewer camera on its x-z axis (i.e. the x-y axis in 2D). */
  mapDirection?: { x: number; y: number };
  mini?: boolean;
  miniExpanded?: boolean;
  nodes: Node[];
  onClickDetection?: (detection: Detection) => void;
  onClickNode?: (node: Node) => void;
  onImageLoaded?: () => void;
  onToggleShowNodes?: (nextShowValue: boolean) => void;
  /** Flag indicating whether or not nodes should be rendered. */
  showNodes?: boolean;
}

interface State {
  currentNode?: Node;
  expanding: boolean;
  imageLoaded: boolean;
  isClickingNode: boolean;
  isOverNode: boolean;
  isPanning: boolean;
  maxZoom: number;
  minZoom: number;
  mouseX: number;
  mouseY: number;
  nearestNode: number;
  previousNodeId?: string;
  scrollX: number;
  scrollY: number;
  showZoom: boolean;
  zoom: number;
}

const annotationHoverFill = '#39A0ED';
const annotationHoverStroke = '#1E5884';
const annotationNormalFill = '#39A0ED';
const annotationNormalStroke = '#1E5884';

const detectionFill = '#ffffff';
const detectionHoverFill = '#ffffff';
const detectionHoverStroke = theme.colors.brand.primary['700'];
const detectionStroke = theme.colors.brand.primary['500'];

const regularNodeFill = theme.colors.brand.primary['500'];
const regularNodeHoverFill = theme.colors.brand.primary['700'];
const regularNodeStroke = theme.colors.brand.primary['500'];
const regularNodeHoverStroke = theme.colors.brand.primary['700'];

const highQualityNodeFill = '#ffffff';
const highQualityNodeStroke = theme.colors.brand.primary['500'];
const highQualityHoverNodeFill = theme.colors.brand.primary['500'];
const highQualityHoverNodeStroke = theme.colors.brand.primary['700'];

const m = new DOMMatrix();

const smallNodeStyle = {
  fill: regularNodeFill,
  stroke: regularNodeStroke,
  hoverStroke: regularNodeHoverStroke,
  hoverFill: regularNodeHoverFill,
};

const highQualityNodeStyle = {
  fill: highQualityNodeFill,
  stroke: highQualityNodeStroke,
  hoverStroke: highQualityHoverNodeStroke,
  hoverFill: highQualityHoverNodeFill,
};

const annotatedNodeStyle = {
  fill: annotationNormalFill,
  stroke: annotationNormalStroke,
  hoverStroke: annotationHoverStroke,
  hoverFill: annotationHoverFill,
};

const detectionStyle = {
  fill: detectionFill,
  stroke: detectionStroke,
  hoverStroke: detectionHoverStroke,
  hoverFill: detectionHoverFill,
  scale: 1.5,
};

const activeDetectionStyle = {
  fill: detectionStroke,
  stroke: detectionFill,
  hoverFill: detectionStroke,
  hoverStroke: detectionFill,
  scale: 2,
};

/**
 * @todo This should really be called "Map" and the other component should be "MiniMap"...
 */
class MiniMap extends Component<MiniMapProps, State> {
  canvasRef: HTMLCanvasElement | null;
  domRef: HTMLDivElement | null;
  imageRef: HTMLImageElement | null;

  ctx: CanvasRenderingContext2D | null;
  nodeCtx: CanvasRenderingContext2D | null;
  highlightCtx: CanvasRenderingContext2D | null;
  detectionCtx: CanvasRenderingContext2D | null;
  activeDetectionCtx: CanvasRenderingContext2D | null;
  currentNodeCtx: CanvasRenderingContext2D | null;

  /**
   * Initial x-position of the pointer during a click (and drag). Used to compute how much to pan the minimap.
   * @todo Is there any advantage to this being here vs. in state?
   */
  screenX: number | null;

  /**
   * Initial y-position of the pointer during a click (and drag). Used to compute how much to pan the minimap.
   * @todo Is there any advantage to this being here vs. in state?
   */
  screenY: number | null;

  constructor(props: MiniMapProps) {
    super(props);

    this.canvasRef = null;
    this.domRef = null;
    this.imageRef = null;

    this.ctx = null;
    this.nodeCtx = null;
    this.highlightCtx = null;
    this.detectionCtx = null;
    this.activeDetectionCtx = null;
    this.currentNodeCtx = null;

    this.screenX = null;
    this.screenY = null;

    this.state = {
      expanding: false,
      imageLoaded: false,
      isClickingNode: false,
      isOverNode: false,
      isPanning: false,
      maxZoom: 50,
      minZoom: 0.25,
      mouseX: 0,
      mouseY: 0,
      nearestNode: 0,
      previousNodeId: undefined,
      scrollX: 0,
      scrollY: 0,
      showZoom: Boolean(props.initiallyShowZoom),
      zoom: 4.2,
    };
  }

  componentDidMount() {
    window.addEventListener('mouseup', this.onMouseUp);
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('resize', this.resize);
  }

  // Canvas' render function; will be called on change to state or props
  componentDidUpdate(prevProps: MiniMapProps) {
    if (!this.state.imageLoaded) {
      return;
    }

    const {
      currentNode,
      nodes,
      annotations = [],
      mapDirection,
      detectionList,
      activeDetectionId,
      showNodes,
    } = this.props;
    const { scrollX, scrollY, zoom, nearestNode, mouseX, mouseY } = this.state;

    if (prevProps.miniExpanded !== this.props.miniExpanded) {
      this.setState({ expanding: true });
      setTimeout(() => {
        this.setState({ showZoom: Boolean(this.props.miniExpanded), expanding: false });
        this.forceUpdate();
      }, 500);
    }

    const width = this.ctx.canvas.width;
    const height = this.ctx.canvas.height;

    const currentX = width / 2 + scrollX;
    const currentY = height / 2 + scrollY;

    // Multiple canvases for different node types.
    // Avoids worrying about rendering in correct order.
    this.ctx.clearRect(0, 0, width, height);
    this.nodeCtx.clearRect(0, 0, width, height);
    this.highlightCtx.clearRect(0, 0, width, height);
    this.detectionCtx.clearRect(0, 0, width, height);
    this.activeDetectionCtx.clearRect(0, 0, width, height);
    this.currentNodeCtx.clearRect(0, 0, width, height);

    // Render the floorplan
    const imageWidth = this.imageRef.naturalWidth / zoom;
    const imageHeight = this.imageRef.naturalHeight / zoom;
    const focusX = currentNode ? currentNode.xpercent : 0.5;
    const focusY = currentNode ? currentNode.ypercent : 0.5;

    const nodeRadius = 40 / clamp(zoom, 3, 7);

    const annotatedNodeRadius = 40 / clamp(zoom, 3, 7);
    const annotatedNodeHoverRadius = annotatedNodeRadius * 1.25;

    const smallNodeRadius = nodeRadius / 2.4;
    const smallNodeHoverRadius = smallNodeRadius * 1.5;

    const highQualityHoverNodeRadius = nodeRadius * 1.25;

    this.nodeCtx.lineWidth = 12 / clamp(zoom, 3, 7);
    this.detectionCtx.lineWidth = 2;
    this.activeDetectionCtx.lineWidth = 2;
    this.currentNodeCtx.lineWidth = 4;

    // Create paths for drawing nodes before drawing.
    // This allows checking for hover before drawing.
    // x and y are used kept around for hover detection later.
    const createNodePath = (node, radius = nodeRadius) => {
      const diffX = (node.xpercent - focusX) * imageWidth;
      const diffY = (node.ypercent - focusY) * imageHeight;
      const x = currentX + diffX;
      const y = currentY + diffY;
      const path = new Path2D();
      path.arc(x, y, radius, 0, 2 * Math.PI);
      return { node, path, x, y };
    };

    // Maps for quick node type lookup when assigning groups.
    const annotatedNodeIds = new Set(annotations.map((a) => a.node));
    const nodeIdDetectionMap = keyBy(detectionList, 'node_id');
    const detectionIdMap = keyBy(detectionList, 'id');

    // Node groups in hover priority top highest priority.
    const nodeGroups = {
      currentNodes: [],
      activeDetections: [],
      detections: [],
      annotations: [],
      highlight: [],
      small: [],
    };

    // Groups nodes by type to make it easier to check for
    // hover priority and draw correct node type.
    assign(
      nodeGroups,
      groupBy(nodes, (n) => {
        if (currentNode && currentNode.id === n.id) {
          return 'currentNodes';
        } else if (
          activeDetectionId &&
          nodeIdDetectionMap[n.id] &&
          detectionIdMap[activeDetectionId].node_id === n.id
        ) {
          return 'activeDetections';
        } else if (nodeIdDetectionMap[n.id]) {
          return 'detections';
        } else if (annotatedNodeIds.has(n.id)) {
          return 'annotations';
        } else if (n.highlight !== false) {
          return 'highlight';
        } else {
          return 'small';
        }
      })
    );

    // Map nodes to paths.
    nodeGroups.currentNodes = nodeGroups.currentNodes.map((node) => createNodePath(node, 12));
    nodeGroups.activeDetections = nodeGroups.activeDetections.map((node) =>
      createDetectionPaths({
        node,
        style: activeDetectionStyle,
        detectionList,
        imageWidth,
        imageHeight,
        currentX,
        currentY,
        focusX,
        focusY,
        domMatrix: m,
      })
    );
    nodeGroups.detections = nodeGroups.detections.map((node) =>
      createDetectionPaths({
        node,
        style: detectionStyle,
        detectionList,
        imageWidth,
        imageHeight,
        currentX,
        currentY,
        focusX,
        focusY,
        domMatrix: m,
      })
    );
    nodeGroups.annotations = nodeGroups.annotations.map((node) => createNodePath(node, annotatedNodeRadius));
    nodeGroups.highlight = nodeGroups.highlight.map((node) => createNodePath(node));
    nodeGroups.small = nodeGroups.small.map((node) => createNodePath(node, smallNodeRadius));

    // Finds hovered node based on priority order of groups and
    // finds last in order to highlight the highest drawn node.
    // Makes sure that only one node at most is highlighted.
    // Repetive right now, but probably good enough.
    const currHoverNode = (() => {
      if (showNodes) {
        const hoveredCurrent = findLast(nodeGroups.currentNodes, (n) =>
          isNodeHovered(this.currentNodeCtx, n, mouseX, mouseY, nodeRadius)
        );
        if (hoveredCurrent) {
          return hoveredCurrent.node;
        }
      }

      const hoveredActive = findLast(nodeGroups.activeDetections, (n) =>
        isDetectionHovered(this.activeDetectionCtx, n)
      );
      if (hoveredActive) {
        return hoveredActive.node;
      }

      const hoveredDetection = findLast(nodeGroups.detections, (n) =>
        isDetectionHovered(this.detectionCtx, n, mouseX, mouseY)
      );
      if (hoveredDetection) {
        return hoveredDetection.node;
      }

      if (showNodes) {
        const hoveredAnnotation = findLast(nodeGroups.annotations, (n) =>
          isNodeHovered(this.nodeCtx, n, mouseX, mouseY, nodeRadius)
        );
        if (hoveredAnnotation) {
          return hoveredAnnotation.node;
        }

        const hoveredHighlight = findLast(nodeGroups.highlight, (n) =>
          isNodeHovered(this.nodeCtx, n, mouseX, mouseY, nodeRadius)
        );
        if (hoveredHighlight) {
          return hoveredHighlight.node;
        }

        const hoveredSmall = findLast(nodeGroups.small, (n) => isNodeHovered(this.ctx, n, mouseX, mouseY, nodeRadius));
        if (hoveredSmall) {
          return hoveredSmall.node;
        }
      }

      return null;
    })();

    // Nodes are drawn on multiple canvas

    // first draw active detections
    // Should only be possible to have one
    nodeGroups.activeDetections.forEach((nodePaths) =>
      drawDetection(this.activeDetectionCtx, nodePaths, activeDetectionStyle, currHoverNode, true)
    );

    // then draw detections
    nodeGroups.detections.forEach((nodePaths) =>
      drawDetection(this.detectionCtx, nodePaths, detectionStyle, currHoverNode)
    );

    if (showNodes) {
      nodeGroups.currentNodes.forEach((n) => {
        if (annotatedNodeIds.has(currentNode.id)) {
          drawNode(this.currentNodeCtx, n, annotatedNodeStyle, currHoverNode);
        } else {
          drawNode(this.currentNodeCtx, n, highQualityNodeStyle, currHoverNode);
        }
      });

      nodeGroups.annotations.forEach((n) => {
        drawNode(this.nodeCtx, n, annotatedNodeStyle, currHoverNode);

        if (isNodeHovered(this.nodeCtx, n, mouseX, mouseY, nodeRadius)) {
          const hoverPath = createNodePath(n.node, annotatedNodeHoverRadius);
          drawNode(this.nodeCtx, hoverPath, annotatedNodeStyle, currHoverNode);
        }
      });

      // Render each individual node
      nodeGroups.highlight.forEach((n) => {
        drawNode(this.nodeCtx, n, highQualityNodeStyle, currHoverNode);

        if (isNodeHovered(this.nodeCtx, n, mouseX, mouseY, nodeRadius)) {
          const hoverPath = createNodePath(n.node, highQualityHoverNodeRadius);
          drawNode(this.nodeCtx, hoverPath, highQualityNodeStyle, currHoverNode);
        }
      });

      // Render the small demphasized nodes
      nodeGroups.small.forEach((n) => {
        drawNode(this.ctx, n, smallNodeStyle, currHoverNode);

        if (isNodeHovered(this.ctx, n, mouseX, mouseY, nodeRadius)) {
          const hoverPath = createNodePath(n.node, smallNodeHoverRadius);
          drawNode(this.ctx, hoverPath, smallNodeStyle, currHoverNode);
        }
      });
    }

    // Render the view cone
    if (mapDirection) {
      const theta = Math.atan2(mapDirection.y, mapDirection.x);
      const sin = Math.sin(theta);
      const cos = Math.cos(theta);

      // Dimensions of the view cone (an isosceles trapezoid)
      const width = 45;
      const nearH = 12;
      const farH = 22;

      const gradient = this.currentNodeCtx.createLinearGradient(
        currentX,
        currentY,
        currentX + width * cos,
        currentY + width * sin
      );

      // The view cone becomes less transparent farther out
      // TODO: use SVG from Figma designs
      gradient.addColorStop(0, 'rgba(113, 66, 241, 0)');
      gradient.addColorStop(1, 'rgba(113, 66, 241, 0.8)');

      // This is the trapezoidal form of the view cone
      const points = [
        [0, nearH],
        [0, -nearH],
        [width, -farH],
        [width, farH],
        [0, nearH],
      ];

      this.currentNodeCtx.fillStyle = gradient;
      this.currentNodeCtx.beginPath();
      this.currentNodeCtx.moveTo(
        currentX + points[0][0] * cos - points[0][1] * sin,
        currentY + points[0][0] * sin + points[0][1] * cos
      );

      points
        .slice(1)
        .forEach((p) =>
          this.currentNodeCtx.lineTo(currentX + p[0] * cos - p[1] * sin, currentY + p[0] * sin + p[1] * cos)
        );

      this.currentNodeCtx.fill();
    }
    // Causes an extra canvas draw, but does not seem to be an issue.
    if (nearestNode !== currHoverNode) {
      this.setState({ isOverNode: currHoverNode !== null, nearestNode: currHoverNode });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.onMouseUp);
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('resize', this.resize);
  }

  static getDerivedStateFromProps(nextProps: MiniMapProps, prevState: State) {
    if (nextProps.currentNode && nextProps.currentNode.id !== prevState.previousNodeId) {
      return {
        ...prevState,
        scrollX: 0,
        scrollY: 0,
        previousNodeId: nextProps.currentNode.id,
      };
    }

    // NOTE returning null represents no change in state
    return null;
  }

  isInsideCanvas = (e: MouseEvent) => e.target === this.canvasRef;

  /**
   * Calculate the dimensions and zoom level based on the size of the image when loaded.
   * @todo Much of this isn't necessary anymore. We should use the dimensions that come from the floorplan API call.
   * @todo This function and the `onImageLoaded` prop shouldn't have the same name.
   */
  onImageLoaded = () => {
    const { mini, onImageLoaded } = this.props;
    const width = this.domRef.offsetWidth;
    const height = this.domRef.offsetHeight;
    const imageWidth = this.imageRef.naturalWidth;
    const imageHeight = this.imageRef.naturalHeight;
    // maxZoom buffer needs to be larger on minimap due to
    // way that current node gets centered.
    const buffer = mini ? 1.5 : 1.05;
    const maxZoomWidth = (imageWidth * buffer) / width;
    const maxZoomHeight = (imageHeight * buffer) / height;
    const maxZoom = clamp(Math.max(maxZoomWidth, maxZoomHeight), 0, 20);

    const minZoomWidth = imageWidth / (3 * width);
    const minZoomHeight = imageHeight / (3 * height);
    const minZoom = Math.min(minZoomWidth, minZoomHeight);
    // Mini should start out zoomed in.
    if (mini) {
      this.setState({ imageLoaded: true, maxZoom, minZoom: minZoom / 3 });
    } else {
      this.setState({ imageLoaded: true, zoom: maxZoom, maxZoom, minZoom });
    }
    if (onImageLoaded) {
      onImageLoaded();
    }
  };

  onMouseDown = (e: MouseEvent) => {
    e.preventDefault();
    // Prevent clicks besides left mouse
    if (e.button !== 0) {
      return;
    }

    if (this.state.isOverNode) {
      this.setState({ isClickingNode: true });
      return;
    }

    const ev = e.touches ? e.touches[0] : e;
    this.screenX = ev.screenX;
    this.screenY = ev.screenY;
    this.setState({ isPanning: true });
  };

  onMouseMove = (e: MouseEvent) => {
    e.preventDefault();

    if (this.state.isPanning) {
      const ev = e.touches ? e.touches[0] : e;

      const deltaX = ev.screenX - this.screenX;
      const deltaY = ev.screenY - this.screenY;

      this.screenX = ev.screenX;
      this.screenY = ev.screenY;
      this.setState((prevState) => ({
        scrollX: prevState.scrollX + deltaX,
        scrollY: prevState.scrollY + deltaY,
      }));
    }

    // Find nearest
    // Skip updating mouseX and Y during panning for performance
    if (!this.state.isPanning && this.isInsideCanvas(e)) {
      const rect = e.target.getClientRects()[0];
      this.setState({
        mouseX: e.clientX - rect.x,
        mouseY: e.clientY - rect.y,
      });
    }
  };

  onMouseUp = (e: MouseEvent) => {
    const { detectionList, onClickDetection, onClickNode, showNodes } = this.props;
    const { isPanning, nearestNode, isOverNode, isClickingNode } = this.state;

    e.preventDefault();
    // Prevent clicks besides left mouse
    if (e.button !== 0) {
      return;
    }

    if (isClickingNode && isOverNode) {
      this.setState({ isClickingNode: false }, () => {
        const detection = detectionList?.find((d) => d.node_id === nearestNode.id);
        if (showNodes && onClickNode) {
          onClickNode(nearestNode);
        } else if (detection && onClickDetection) {
          onClickDetection(detection);
        }
      });
      return;
    }

    // This case basically happens when a user 'cancels' clicking a node
    if (!isPanning) {
      return;
    }

    this.screenX = null;
    this.screenY = null;
    this.setState({ isPanning: false });

    if (this.state.isOverNode) {
      this.setState({ isClickingNode: true });
    }
  };

  onWheel = (e: WheelEvent) => {
    const { currentNode, mouseX, mouseY, scrollX, scrollY, zoom } = this.state;
    e.preventDefault();
    const scaleBy = 1.15;
    const newZoomRaw = e.deltaY > 0 ? zoom * scaleBy : zoom / scaleBy;
    const newZoom = clamp(newZoomRaw, this.state.minZoom, this.state.maxZoom);

    const width = this.ctx.canvas.width;
    const height = this.ctx.canvas.height;
    const imageWidth = this.imageRef.naturalWidth / zoom;
    const imageHeight = this.imageRef.naturalHeight / zoom;
    const focusX = currentNode?.xpercent ?? 0.5;
    const focusY = currentNode?.ypercent ?? 0.5;
    const centerX = imageWidth * focusX - width / 2;
    const centerY = imageHeight * focusY - height / 2;

    const imageLeft = -centerX + scrollX;
    const imageTop = -centerY + scrollY;

    const nw = this.imageRef.naturalWidth / newZoom;
    const nh = this.imageRef.naturalHeight / newZoom;
    const newOriginX = -(nw * focusX - width / 2);
    const newOriginY = -(nh * focusY - height / 2);

    const mouse = {
      x: mouseX * zoom - imageLeft * zoom,
      y: mouseY * zoom - imageTop * zoom,
    };

    const newX = -(newOriginX + mouse.x / newZoom - mouseX);
    const newY = -(newOriginY + mouse.y / newZoom - mouseY);

    this.setState(() => ({
      zoom: newZoom,
      scrollX: newX,
      scrollY: newY,
    }));
  };

  // Needs to rerender to resize the canvas on screen resize when the mini map is fullscreen.
  resize = () => this.props.mini === false && this.forceUpdate();

  scroll = (scrollX = 0, scrollY = 0, zoom = 4) => this.setState({ scrollX, scrollY, zoom });

  zoom = (amount: number) => {
    const { zoom, scrollX, scrollY } = this.state;
    const zoomSpeed = 1 / 50;
    const zoomFactor = Math.exp(amount * zoomSpeed);
    const newZoom = clamp(zoom * zoomFactor, this.state.minZoom, this.state.maxZoom);
    const newScale = newZoom / zoom;
    const newX = scrollX / newScale;
    const newY = scrollY / newScale;

    // NOTE updating the scroll here makes sure that we keep the same center while zooming
    this.setState(() => ({
      zoom: newZoom,
      scrollX: newX,
      scrollY: newY,
    }));
  };

  zoomIn = () => {
    this.zoom(-18);
  };

  zoomOut = () => {
    this.zoom(18);
  };

  render() {
    const { children, className, currentNode, detectionList, mini, miniExpanded, onToggleShowNodes, showNodes } =
      this.props;
    const { expanding, isOverNode, isPanning, maxZoom, minZoom, scrollX, scrollY, showZoom, zoom } = this.state;

    const stackingContainerClassNames = classNames(className, {
      [classes.floorPlanMap]: !mini,
      [classes.miniMap]: mini,
      [classes.miniMapExpanded]: miniExpanded,
      [classes.overNode]: isOverNode,
      [classes.panning]: isPanning,
      [classes.transitionActive]: expanding,
    });
    const dataAttributes = {
      'data-pendo-label': isOverNode ? `Select ${miniExpanded ? 'expanded node' : 'node'}` : '',
      'data-pendo-topic': 'minimap',
    };

    const [width, height] = (() => {
      if ((mini === false || miniExpanded) && this.domRef) {
        return [this.domRef.offsetWidth, this.domRef.offsetHeight];
      }
      return [320, 350];
    })();

    const getImageStyle = () => {
      if (!this.ctx || !this.imageRef) {
        return { display: 'none' };
      }

      const canvasWidth = width;
      const canvasHeight = height;

      const imageWidth = this.imageRef.naturalWidth / zoom;
      const imageHeight = this.imageRef.naturalHeight / zoom;
      const focusX = currentNode?.xpercent ?? 0.5;
      const focusY = currentNode?.ypercent ?? 0.5;
      const centerX = imageWidth * focusX - canvasWidth / 2;
      const centerY = imageHeight * focusY - canvasHeight / 2;

      const imageLeft = `${pixelsToRem(-centerX + scrollX)}rem`;
      const imageTop = `${pixelsToRem(-centerY + scrollY)}rem`;
      const imageWidthRems = `${pixelsToRem(imageWidth)}rem`;

      return { left: imageLeft, top: imageTop, width: imageWidthRems };
    };

    return (
      <div className={classNames(classes.maskContainer, miniExpanded && classes.active)}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className={stackingContainerClassNames}
          {...dataAttributes}
          onMouseDown={this.onMouseDown}
          onWheel={this.onWheel}
          ref={(ref) => (this.domRef = ref)}
        >
          <div className={classes.floorplanImageContainer}>
            <img
              alt=""
              onLoad={this.onImageLoaded}
              ref={(ref) => (this.imageRef = ref)}
              src={this.props.floorplan.image}
              style={getImageStyle()}
            />
          </div>
          <PureCanvas
            contextRef={(ctx) => (this.ctx = ctx)}
            width={width}
            height={height}
            updateOnResize={Boolean(!mini || miniExpanded)}
          />
          <PureCanvas
            contextRef={(ctx) => (this.nodeCtx = ctx)}
            width={width}
            height={height}
            updateOnResize={Boolean(!mini || miniExpanded)}
          />
          <PureCanvas
            contextRef={(ctx) => (this.highlightCtx = ctx)}
            width={width}
            height={height}
            updateOnResize={Boolean(!mini || miniExpanded)}
          />
          <PureCanvas
            contextRef={(ctx) => (this.detectionCtx = ctx)}
            width={width}
            height={height}
            updateOnResize={Boolean(!mini || miniExpanded)}
          />
          <PureCanvas
            contextRef={(ctx) => (this.activeDetectionCtx = ctx)}
            width={width}
            height={height}
            updateOnResize={Boolean(!mini || miniExpanded)}
          />
          <PureCanvas
            canvasRef={(ref) => (this.canvasRef = ref)}
            contextRef={(ctx) => (this.currentNodeCtx = ctx)}
            width={width}
            height={height}
            updateOnResize={Boolean(!mini || miniExpanded)}
          />
          {(!mini || miniExpanded) && (
            <div className={classes.overlayControlGroup}>
              {detectionList?.length > 0 && (
                <div className={classNames(commonClasses.view360ButtonGroup, classes.pathToggleGroup)}>
                  <FormControl className={classNames(commonClasses.view360Button, classes.pathToggleFormControl)}>
                    <FormLabel htmlFor="minimap-show-path">Path</FormLabel>
                    <Switch
                      id="minimap-show-path"
                      isChecked={showNodes}
                      onChange={(event) => onToggleShowNodes?.(event.currentTarget.checked)}
                    />
                  </FormControl>
                </div>
              )}
              {showZoom && (
                <div className={commonClasses.view360ButtonGroup}>
                  <ZoomIn
                    buttonProps={{ 'data-pendo-label': 'Zoom in', 'data-pendo-topic': 'minimap' }}
                    disabled={zoom === minZoom}
                    onClick={this.zoomIn}
                  />
                  <ZoomOut
                    buttonProps={{ 'data-pendo-label': 'Zoom out', 'data-pendo-topic': 'minimap' }}
                    disabled={zoom === maxZoom}
                    onClick={this.zoomOut}
                  />
                </div>
              )}
            </div>
          )}
          {children}
        </div>
      </div>
    );
  }
}

export default MiniMap;
