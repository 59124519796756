export const applyFilter = (base64Image: string, brightness: number, action: (arg: string) => void) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const image = new Image();
  image.onload = () => {
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
    if (ctx) {
      ctx.filter = `brightness(${brightness})`;
      ctx.drawImage(image, 0, 0);
      action(canvas.toDataURL());
    }
  };
  image.src = base64Image;
};
