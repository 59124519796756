import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import api from '../api';
import { ExternalLayout } from '../components';
import LoadingIndicator from '../components/LoadingIndicator';

import classes from './ProcoreCallback.module.scss';

enum IntegrationStatus {
  Waiting,
  Complete,
  Error,
}

const ProcoreCallback = (): ReactElement => {
  const [status, setStatus] = useState<IntegrationStatus>(IntegrationStatus.Waiting);

  const location = useLocation();

  async function getProcoreAccessToken(code: string) {
    try {
      const { access_token: accessToken } = await api.auth.setProcoreAccessToken({ code });
      localStorage.setItem('procoreAccessToken', accessToken);

      // Sometimes, the browser fails to close the window. Just in case, instruct the user to close the tab if it
      // sticks around.
      setStatus(IntegrationStatus.Complete);
      window.close();
    } catch (error) {
      console.error('[ProcoreCallback] Failed to exchange code for Procore access token!', error);
      setStatus(IntegrationStatus.Error);
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    if (!code) {
      console.warn('[ProcoreCallback] No code provided!');
      setStatus(IntegrationStatus.Error);
      return;
    }

    getProcoreAccessToken(code);
  }, [location]);

  return (
    <ExternalLayout>
      <div className={classes.resultDetails}>
        <h1>Procore integration</h1>
        {status === IntegrationStatus.Waiting && <LoadingIndicator />}
        {status === IntegrationStatus.Error && (
          <>
            <h2>Error</h2>
            <p>An unexpected error occurred. Please try again later.</p>
          </>
        )}
        {status === IntegrationStatus.Complete && (
          <>
            <h2>Success</h2>
            <p>
              This tab should close automatically. If you see this for more than a few seconds, please close this tab.
            </p>
          </>
        )}
      </div>
    </ExternalLayout>
  );
};

export default ProcoreCallback;
