import Cookies from 'js-cookie';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { login } from '../actions/auth';
import { getAuthToken as getProcoreAuth } from '../actions/procore';
import api from '../api/';
import { ExternalLayout } from '../components';
import LoadingIndicator from '../components/LoadingIndicator';
import GeneralInfoForm, { FormValues as GeneralInfoFormValues } from '../components/Onboarding/GeneralInfoForm';
import PasswordForm, { FormValues as PasswordFormValues } from '../components/Onboarding/PasswordForm';

import classes from './Onboarding.module.scss';

interface QueryParams {
  token?: string;
  email?: string;
}

interface AggregateFormValues extends GeneralInfoFormValues, PasswordFormValues {}

export enum OnboardingStep {
  GENERIC_ERROR = 0,
  TOKEN_EXPIRED = 1,
  GENERAL_INFO = 2,
  PASSWORD = 3,
  FINAL = 4,
}

const Onboarding = (): ReactElement => {
  const dispatch = useDispatch();

  const [data, setData] = useState<AggregateFormValues>({
    first_name: '',
    last_name: '',
    persona_id: '',
    title: '',
    company_name: '',
    phone: '',
    password: '',
    confirm_password: '',
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [personaOptions, setPersonaOptions] = useState<Array<{ id: number; persona: string }>>([]);
  const [step, setStep] = useState<OnboardingStep>(OnboardingStep.GENERAL_INFO);

  const history = useHistory();
  const location = useLocation();

  const { email, token: onboardingToken } = useMemo<QueryParams>(() => {
    const searchParams = new URLSearchParams(location.search);
    return {
      email: searchParams.get('email'),
      token: searchParams.get('token'),
    } as QueryParams;
  }, [location.search]);

  function stashFormData(formValues: GeneralInfoFormValues | PasswordFormValues) {
    setData((current) => ({
      ...current,
      ...formValues,
    }));
  }

  async function load() {
    try {
      const [validationResponse, personaOptionsResponse] = await Promise.all([
        api.userToken.validateToken(onboardingToken),
        api.userToken.getPersonas(onboardingToken),
      ]);
      if (validationResponse?.basic_info_changed && validationResponse?.password_changed) {
        history.push('/login');
      }

      setPersonaOptions(personaOptionsResponse);
    } catch (error: any) {
      if (error?.status >= 400 && error?.status < 500) {
        setStep(OnboardingStep.TOKEN_EXPIRED);
      } else {
        console.error('[Onboarding] Failed to validate token or load persona options!', error);
        setStep(OnboardingStep.GENERIC_ERROR);
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (email && onboardingToken) {
      load();
    } else {
      console.info('[Onboarding] Onboarding token or user email address missing! Redirecting to login page...');
      history.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, email, history, onboardingToken]);

  useEffect(() => {
    if (step !== OnboardingStep.FINAL) {
      return;
    }

    async function loginAndRedirect() {
      try {
        const jwtRes = await api.auth.jwtLogin({
          email,
          password: data.password,
        });

        if (jwtRes.need_mfa) {
          const message = encodeURIComponent(
            'Onboarding complete but multi-factor authentication is required. Please sign in again.'
          );
          history.push(`/login?errormsg=${message}`);
          return;
        }

        Cookies.set('onsitevision_access', jwtRes.access, { domain: window.location.hostname, expires: 1 });
        Cookies.set('onsitevision_refresh', jwtRes.refresh, { domain: window.location.hostname, expires: 30 });

        const userProfile = await api.auth.me();
        dispatch(login(userProfile));
        dispatch(getProcoreAuth());

        history.push('/');
      } catch (error) {
        console.error('[Onboarding] Failed to login and redirect user!', error);
        history.push('/login');
      }
    }

    loginAndRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, step]);

  const renderForm = () => {
    switch (step) {
      case OnboardingStep.GENERIC_ERROR:
        return (
          <>
            <h2>An error occurred</h2>
            <p>
              Please contact our Customer Success team at{' '}
              <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a> for assistance.
            </p>
          </>
        );
      case OnboardingStep.TOKEN_EXPIRED:
        return (
          <>
            <h2>Invitation expired</h2>
            <p>
              Your invitation code has expired. Please contact our Customer Success team at{' '}
              <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a> for assistance.
            </p>
          </>
        );
      case OnboardingStep.GENERAL_INFO:
        return (
          <>
            <h2>Fill in your profile details to set up your account</h2>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <GeneralInfoForm
                nextAction={setStep}
                onboardingToken={onboardingToken}
                personaOptions={personaOptions}
                setData={stashFormData}
              />
            )}
          </>
        );
      case OnboardingStep.PASSWORD:
        return (
          <>
            <h2>Choose a password</h2>
            <p className="text">
              You’ll use your email address (<strong>{email}</strong>) and password to sign into your OnsiteIQ account.
            </p>
            <PasswordForm nextAction={setStep} onboardingToken={onboardingToken} setData={stashFormData} />
          </>
        );
      case OnboardingStep.FINAL:
        return <LoadingIndicator />;
      default:
        return null;
    }
  };

  return (
    <ExternalLayout>
      <div className={classes.form}>
        <h1>Welcome!</h1>
        <p>OnsiteIQ is a visual construction documentation software for owners and developers.</p>
        {renderForm()}
      </div>
    </ExternalLayout>
  );
};

export default Onboarding;
