import { FormErrorMessage, FormHelperText, FormLabel, Input } from '@chakra-ui/react';
import classNames from 'classnames';
import { ErrorMessage, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import capitalize from 'lodash/capitalize';
import { ChangeEvent, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Button, Caption, FormControl } from '../';
import api from '../../api';
import { transformErrorResponse } from '../../utils/error';
import { getPasswordValidationSchema, passwordRequirements } from '../../utils/validation';
import { CheckIcon, DotIcon, ErrorIcon } from '../Icon';

import classes from './Login.module.scss';

interface FormValues {
  new_password1: string;
  new_password2: string;
}

const validationSchema = Yup.object({
  new_password1: getPasswordValidationSchema('New password'),
  new_password2: Yup.string()
    .required('Password confirmation required')
    .oneOf([Yup.ref('new_password1')], 'Passwords must match'),
});

const SetPasswordForm = () => {
  const [passwordValidationMessages, setPasswordValidationMessages] = useState<string[] | undefined>();
  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState<string | undefined>();

  const history = useHistory();
  const { uid, token } = useParams<{ uid: string; token: string }>();

  const formRef = useRef<FormikProps<FormValues>>(null);

  function goToLoginPage() {
    history.push('/login');
  }

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      await api.auth.setPassword({
        ...values,
        token,
        uid,
      });
      setSubmitted(true);
      setPasswordValidationMessages(undefined);
    } catch (error) {
      console.error('[SetPasswordForm] Failed to set password!', error);
      const { message, fieldErrors } = await transformErrorResponse(
        error as Response,
        values,
        'Failed to set password. Please try again later.'
      );
      setSubmitError(message);
      if (fieldErrors) {
        formikHelpers.setErrors(fieldErrors);
      }
    }
  }

  async function onChangePassword(
    event: ChangeEvent<HTMLInputElement>,
    formikChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void
  ): Promise<void> {
    formikChangeHandler(event);
    await updatePasswordValidationMessages(event.target.value);
  }

  async function onSubmit() {
    // Note: we purposely don't prevent the default submit behavior here.
    await updatePasswordValidationMessages(formRef.current?.values?.new_password1);
  }

  async function updatePasswordValidationMessages(value = '') {
    try {
      await validationSchema.validateAt(
        'new_password1',
        { new_password1: value, new_password2: '' },
        { abortEarly: false }
      );
      setPasswordValidationMessages([]);
    } catch (error) {
      const validationError = error as Yup.ValidationError;
      setPasswordValidationMessages(validationError.errors);
    }
  }

  if (submitted) {
    return (
      <>
        <p>You’ve successfully changed your password. Please sign in to continue with the platform.</p>
        <Button className={classes.submitButton} onClick={goToLoginPage} variant="primary">
          Sign in
        </Button>
      </>
    );
  }

  return (
    <>
      <h2>Enter your new password</h2>
      <Formik
        initialValues={{
          new_password1: '',
          new_password2: '',
        }}
        innerRef={formRef}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
          <Form className={classes.form}>
            <FormControl isInvalid={Boolean(errors.new_password1) && touched.new_password1} variant="external">
              <Caption as={FormLabel}>Password</Caption>
              <Input
                autoComplete="new-password"
                name="new_password1"
                onBlur={handleBlur}
                onChange={(event) => onChangePassword(event, handleChange)}
                placeholder="Password"
                type="password"
                value={values.new_password1}
              />
              <FormHelperText className={classes.passwordHelpText}>
                {passwordRequirements.map((requirement) => {
                  let requirementStatus = 'indeterminate';
                  if (passwordValidationMessages?.some((message) => message.endsWith(requirement))) {
                    requirementStatus = 'failed';
                  } else if (passwordValidationMessages) {
                    requirementStatus = 'passed';
                  }

                  const requirementClassNames = classNames(classes.requirement, {
                    [classes.requirementIndeterminate]: requirementStatus === 'indeterminate',
                    [classes.requirementPassed]: requirementStatus === 'passed',
                    [classes.requirementFailed]: requirementStatus === 'failed',
                  });
                  return (
                    <div
                      className={requirementClassNames}
                      data-testid={`password-requirement-${requirementStatus}`}
                      key={requirement}
                    >
                      {requirementStatus === 'indeterminate' && <DotIcon aria-hidden />}
                      {requirementStatus === 'failed' && <ErrorIcon aria-label="Requirement failed" />}
                      {requirementStatus === 'passed' && <CheckIcon aria-label="Requirement passed" />}
                      <span>{capitalize(requirement)}</span>
                    </div>
                  );
                })}
              </FormHelperText>
              {/* Only show an error message if it's distinct from all other requirement messages */}
              {passwordRequirements.every((requirement) => !errors.new_password1?.includes(requirement)) && (
                <ErrorMessage component={FormErrorMessage} name="new_password1" />
              )}
            </FormControl>
            <FormControl isInvalid={Boolean(errors.new_password2) && touched.new_password2} variant="external">
              <Caption as={FormLabel}>Confirm password</Caption>
              <Input
                autoComplete="new-password"
                name="new_password2"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Confirm password"
                type="password"
                value={values.new_password2}
              />
              <ErrorMessage component={FormErrorMessage} name="new_password2" />
            </FormControl>
            {!isSubmitting && submitError && <p className={classes.errorMessage}>{submitError}</p>}
            <Button
              className={classes.submitButton}
              isLoading={isSubmitting}
              onClick={onSubmit}
              type="submit"
              variant="primary"
            >
              Set password
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SetPasswordForm;
