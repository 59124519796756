import { Box, Button, Flex, FormControl, FormLabel, Text, Textarea, VisuallyHidden } from '@chakra-ui/react';
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';

export interface ContentBoxProps {
  /** Content of the annotation or reply. */
  content: string;
  /** Flag indicating whether or not the user is editing the annotation or reply. */
  isEditing?: boolean;
  /** Flag indicating whether or not a change to an annotation or reply is being submitted. */
  isSubmitting?: boolean;
  /** Handler to call when the user no longer wants to edit the annotation or reply. */
  onCancelEdit: () => void;
  /** Handler to call when the user changes the content of the annotation or reply. */
  onChange: (content: string) => void;
  /** Handler to call when the user wants to save their changes to the annotation or reply. */
  onSave: () => void;
  /** Visually-hidden accessible label for the annotation or reply's text box. */
  textAreaLabel: string;
}

const ContentBox = (props: ContentBoxProps) => {
  const { content, isEditing, isSubmitting, onCancelEdit, onChange, onSave, textAreaLabel } = props;

  const [condensed, setCondensed] = useState<boolean>(true);

  const readOnlyTextRef = useRef<HTMLDivElement>(null);

  const expandText = () => {
    setCondensed(false);
  };

  const onCancel = () => {
    onCancelEdit();
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSave();
  };

  useEffect(() => {
    if (!condensed || !readOnlyTextRef?.current) {
      return;
    }

    // Check to see if the annotation text currently overflows. If not, there's no need to show the "View more" button.
    if (readOnlyTextRef.current.scrollHeight === readOnlyTextRef.current.offsetHeight) {
      setCondensed(false);
    }
  }, [condensed, readOnlyTextRef]);

  useEffect(() => {
    if (isEditing && condensed) {
      setCondensed(false);
    }
  }, [condensed, isEditing]);

  return (
    <Box paddingBlock="0.5rem">
      {!isEditing && (
        <>
          <Text noOfLines={condensed ? 3 : undefined} ref={readOnlyTextRef} textStyle="detail">
            {content}
          </Text>
          {condensed && (
            // Screen readers can see the whole region of text since it's only masked with CSS, but don't mark this as
            // aria-hidden for keyboard users.
            <Button marginBlockStart="0.5rem" onClick={expandText} size="sm" variant="lowEmphasisV2" width="100%">
              Show more...
            </Button>
          )}
        </>
      )}
      {isEditing && (
        <form onSubmit={onSubmit}>
          <FormControl>
            <VisuallyHidden as={FormLabel}>{textAreaLabel}</VisuallyHidden>
            <Textarea
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onChange(event.target.value)}
              resize="vertical"
              rows={5}
              size="lg"
              value={content}
            />
          </FormControl>
          <Flex justifyContent="space-between" paddingBlockStart="0.5rem">
            <Button
              flex={1}
              marginInlineEnd="0.25rem"
              onClick={onCancel}
              size="sm"
              type="reset"
              variant="lowEmphasisV2"
            >
              Cancel
            </Button>
            <Button
              flex={1}
              isDisabled={!content}
              isLoading={isSubmitting}
              marginInlineStart="0.25rem"
              size="sm"
              type="submit"
              variant="mediumEmphasisV2"
            >
              Save
            </Button>
          </Flex>
        </form>
      )}
    </Box>
  );
};

export default ContentBox;
