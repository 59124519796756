import { ReactNode, createContext } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

import { Store } from './@types/redux/store';
import { FeatureCookieNames } from './constants/features';
import { useAnalyticsProvider } from './hooks/useAnalytics';

interface AppContextProviderProps {
  analyticsEnabled?: boolean;
  children: ReactNode;
}

interface AppContextContents {
  analytics?: { pendoUser?: pendo.InitOptions };
  enabledFeatures: { [key in keyof typeof FeatureCookieNames]?: boolean };
}

export const AppContext = createContext<AppContextContents>({ enabledFeatures: {} });

/**
 * React Context API provider component. Some custom hooks may require global state, for example to prevent analytics
 * from being initialized multiple times. Call any "provider" hooks here.
 */
export function AppContextProvider({ analyticsEnabled = true, children }: AppContextProviderProps) {
  const currentUser = useSelector((state: Store) => state.auth.user);
  const loaded = useSelector((state: Store) => state.auth.loaded);

  const analyticsHook = analyticsEnabled ? useAnalyticsProvider : () => undefined;
  const analytics = analyticsHook({ ready: loaded, user: currentUser });

  const [cookies] = useCookies(['onsitevision_features']);

  const enabledFeatures: Record<string, boolean> = {};
  for (const [key, value] of Object.entries(FeatureCookieNames)) {
    enabledFeatures[key] = cookies.onsitevision_features?.[value]?.toLowerCase() === 'true' ?? false;
  }

  return <AppContext.Provider value={{ analytics, enabledFeatures }}>{children}</AppContext.Provider>;
}
