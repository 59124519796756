import { AxiosRequestConfig } from 'axios';
import pick from 'lodash/pick';

import { Reply, ReplyCreateRequestBody, ReplyUpdateRequestBody } from '../../../@types/api/v0/rest/Reply';
import { httpDelete, patch, post } from '../../next';

export const ReplyApi = {
  /**
   * Create a reply instance. These are associated with annotations.
   * @param requestBody Request payload to be sent to the server to create a new reply.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the created reply.
   */
  create: (requestBody: ReplyCreateRequestBody, requestConfig?: AxiosRequestConfig) =>
    post<ReplyCreateRequestBody, Reply>('api/replies/', requestBody, requestConfig),
  /**
   * Delete a reply instance.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping an empty response.
   */
  delete: (reply: Reply, requestConfig?: AxiosRequestConfig) => httpDelete(`api/replies/${reply.id}/`, requestConfig),
  /**
   * Update a reply instance.
   * @param requestBody The `Reply` instance (with changes already applied to it) to update on the server.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping an empty response.
   */
  update: (reply: Reply, requestConfig?: AxiosRequestConfig) => {
    const changes: ReplyUpdateRequestBody = pick(reply, 'content');
    return patch<ReplyUpdateRequestBody, Reply>(`api/replies/${reply.id}/`, changes, requestConfig);
  },
};
