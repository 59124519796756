import { progressAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(progressAnatomy.keys);

const progressBaseStyle = definePartsStyle({
  label: {},
  filledTrack: {
    backgroundColor: 'var(--gray-600)',
    height: '0.25rem',
  },
  track: {
    backgroundColor: 'var(--gray-200)',
    borderRadius: 'var(--rounding-progress-bar)',
    height: '0.25rem',
    maxHeight: '0.25rem',
  },
});

const progressLargeStyle = definePartsStyle({
  filledTrack: {
    height: '0.625rem',
  },
  track: {
    height: '0.625rem',
    maxHeight: '0.625rem',
  },
});

const purpleProgress = definePartsStyle({
  track: {
    backgroundColor: (theme) => theme.colors.brand.gray[200],
  },
  filledTrack: {
    backgroundColor: (theme) => theme.colors.brand.primary[400],
  },
});

export const progressTheme = defineMultiStyleConfig({
  baseStyle: progressBaseStyle,
  sizes: {
    lg: progressLargeStyle,
  },
  variants: {
    purpleProgress,
  },
});
