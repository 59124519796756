import { FormControl as ChakraFormControl, FormControlProps as ChakraFormControlProps } from '@chakra-ui/react';
import classNames from 'classnames';
import { ReactElement } from 'react';

import classes from './FormControl.module.scss';

export interface FormControlProps extends ChakraFormControlProps {
  variant?: 'external' | 'internal';
}

const FormControl = (props: FormControlProps): ReactElement => {
  const { variant = 'internal' } = props;
  return (
    <ChakraFormControl
      {...props}
      className={classNames({ [classes.external]: variant === 'external' }, props.className)}
    >
      {props.children}
    </ChakraFormControl>
  );
};

export default FormControl;
