import { AxiosRequestConfig } from 'axios';

import { ProjectHierarchyItem } from '../../../@types/api/v1/bespoke/ProjectHierarchy';
import { get } from '../../next';

export const ProjectHierarchyApi = {
  /**
   * Retrieve the hierarchy representation for a given project.
   * @param projectId The ID of the project whose hierarchy is requested.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping a flat list of all hierarchy items.
   */
  getHierarchyByProjectId: (projectId: number, requestConfig?: AxiosRequestConfig) =>
    get<ProjectHierarchyItem[]>(`/api/v1/bespoke/projects/${projectId}/hierarchy/`, requestConfig),
};
