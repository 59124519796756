// Creating this file to gradually migrate the MiniMap component to TS

interface StyleParams {
  fill: string;
  stroke: string;
  hoverFill: string;
  hoverStroke: string;
  scale?: number;
}

interface NodePath {
  id: string;
  node: Node;
  path: Path2D;
  x: number;
  y: number;
  rotation?: number;
}

interface DetectionPath extends NodePath {
  conePath: Path2D;
  outerCirclePath: Path2D;
  innerCirclePath: Path2D;
}

interface Node {
  id: string;
}

interface DetectionNode extends Node {
  xpercent: number;
  ypercent: number;
}

export const coneSVGPath = new Path2D(
  'M -14.02 18.71 L -14.02 18.72 C -14.49 19.48 -14.56 20.04 -14.46 20.47 C -14.35 20.92 -14 21.45 -13.15 22.07 L -13.73 22.88 L -13.15 22.07 C -11.56 23.21 -9.55 23.84 -7.32 24.3 L -7.32 24.3 C -6.44 24.48 -5.55 24.62 -4.64 24.71 C -3.48 24.82 -2.33 24.89 -1.43 24.95 C -1.13 24.97 -0.87 24.98 0 25 C 3.33 24.93 6.49 24.65 9.48 23.76 L 9.48 23.76 C 11.04 23.29 12.39 22.7 13.45 21.82 C 14.11 21.28 14.39 20.79 14.47 20.38 C 14.55 19.98 14.48 19.49 14.09 18.86 L 14.09 18.86 L 0 -4 L -14.09 18.86'
);

export const drawNode = (
  ctx: CanvasRenderingContext2D,
  nodePath: NodePath,
  style: StyleParams,
  currentHoverNode: Node
) => {
  const { node, path } = nodePath;
  if (currentHoverNode && currentHoverNode.id === node.id) {
    ctx.fillStyle = style.hoverFill;
    ctx.strokeStyle = style.hoverStroke;
  } else {
    ctx.fillStyle = style.fill;
    ctx.strokeStyle = style.stroke;
  }
  ctx.fill(path);
  ctx.stroke(path);
};

export const drawDetection = (
  ctx: CanvasRenderingContext2D,
  nodePath: DetectionPath,
  style: StyleParams,
  currentHoverNode: Node,
  isActiveNode: boolean
) => {
  const { node, conePath, x, y, rotation } = nodePath;

  const isNodeHovered = currentHoverNode && currentHoverNode.id === node.id;
  if (isNodeHovered) {
    ctx.strokeStyle = style.hoverStroke;
    ctx.fillStyle = style.hoverFill;
  } else {
    ctx.strokeStyle = style.stroke;
    ctx.fillStyle = style.fill;
  }

  if (rotation && (isNodeHovered || isActiveNode)) {
    const newX = x + Math.sin(rotation) * 30;
    const newY = y + Math.cos(rotation) * 30;

    const gradient = ctx.createLinearGradient(x, y, newX, newY);
    gradient.addColorStop(0, '#7142F1');
    gradient.addColorStop(0.5, '#7142F1');
    gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');

    ctx.beginPath();
    ctx.fillStyle = gradient;
    ctx.fill(conePath);
    ctx.closePath();

    ctx.beginPath();
    ctx.fillStyle = '#500BC1';
    ctx.arc(x, y, 12, 0, 2 * Math.PI);
    ctx.fill();
    ctx.closePath();

    ctx.beginPath();
    ctx.fillStyle = '#330790';
    ctx.arc(x, y, 10, 0, 2 * Math.PI);
    ctx.fill();
    ctx.closePath();

    ctx.beginPath();
    ctx.fillStyle = '#9572F8';
    ctx.arc(x, y, 6, 0, 2 * Math.PI);
    ctx.fill();
    ctx.closePath();
  } else {
    ctx.lineWidth = 1;

    ctx.beginPath();
    ctx.fillStyle = '#500BC1';
    ctx.arc(x, y, 10, 0, 2 * Math.PI);
    ctx.fill();
    ctx.closePath();

    ctx.beginPath();
    ctx.fillStyle = '#E8E4FF';
    ctx.arc(x, y, 8, 0, 2 * Math.PI);
    ctx.fill();
    ctx.closePath();

    ctx.beginPath();
    ctx.fillStyle = '#9572F8';
    ctx.arc(x, y, 6, 0, 2 * Math.PI);
    ctx.fill();
    ctx.closePath();
  }
};

// Rough hover check used to prevent excess calls to isPointInStroke
// Which is relatively expensive. ~40% of componentDidUpdate time without
// the rough check. Simple square check around radius.
export const roughHoverCheck = (mouseX: number, mouseY: number, x: number, y: number, radius: number) =>
  mouseX < x + radius && mouseX > x - radius && mouseY < y + radius && mouseY > y - radius;

export const isNodeHovered = (
  ctx: CanvasRenderingContext2D,
  nodePath: NodePath,
  mouseX: number,
  mouseY: number,
  nodeRadius: number
) => {
  const { path, x, y } = nodePath;
  return (
    // Rough hover check based on rough hover check and largest possible stroke size.
    roughHoverCheck(mouseX, mouseY, x, y, nodeRadius + 4) &&
    (ctx.isPointInPath(path, mouseX, mouseY) || ctx.isPointInStroke(path, mouseX, mouseY))
  );
};

export const isDetectionHovered = (
  ctx: CanvasRenderingContext2D,
  nodePath: DetectionPath,
  mouseX: number,
  mouseY: number
) => {
  const { outerCirclePath, x, y } = nodePath;

  return (
    // Very rough check for detection due to large size
    roughHoverCheck(mouseX, mouseY, x, y, 100) &&
    (ctx.isPointInStroke(outerCirclePath, mouseX, mouseY) ||
      ctx.isPointInPath(outerCirclePath, mouseX, mouseY) ||
      ctx.isPointInStroke(outerCirclePath, mouseX, mouseY))
  );
};

export const createDetectionPaths = ({
  node,
  style,
  focusX,
  focusY,
  currentX,
  currentY,
  imageWidth,
  imageHeight,
  detectionList,
  domMatrix,
}: {
  node: DetectionNode;
  style: StyleParams;
  focusX: number;
  focusY: number;
  currentX: number;
  currentY: number;
  imageWidth: number;
  imageHeight: number;
  detectionList: { node_id: string; rotation: number }[]; // TODO: better typings for detectionList
  domMatrix: DOMMatrix;
}) => {
  const detection = detectionList.find((detection) => detection.node_id === node.id);

  const diffX = (node.xpercent - focusX) * imageWidth;
  const diffY = (node.ypercent - focusY) * imageHeight;

  const x = currentX + diffX;
  const y = currentY + diffY;

  // rotate and scale the path - this is how we determine where to draw each node and how to rotate it
  const transform = domMatrix
    .translate(x, y)
    .rotate(-(((detection?.rotation ?? 1) * 180) / Math.PI))
    .scale(style.scale, style.scale);
  const conePath = new Path2D();
  conePath.addPath(coneSVGPath, transform);

  const outerCirclePath = new Path2D();
  outerCirclePath.arc(x, y, 8.5 * (style.scale ?? 1), 0, 2 * Math.PI);

  const innerCirclePath = new Path2D();
  innerCirclePath.arc(x, y, 4 * (style.scale ?? 1), 0, 2 * Math.PI);

  return {
    node,
    detection,
    conePath,
    outerCirclePath,
    innerCirclePath,
    x,
    y,
    rotation: detection?.rotation,
  };
};
