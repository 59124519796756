import {
  Box,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Icon,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { LoadingIndicator, SearchInput } from '../..';
import { Me } from '../../../@types/OnSiteIQ';
import { Detection, DetectionType } from '../../../@types/api/v0/rest/Detection';
import { Project } from '../../../@types/api/v0/rest/Project';
import { Walkthrough } from '../../../@types/api/v0/rest/Walkthrough';
import theme from '../../../theme';
import { formatIsoDate } from '../../../utils/dateUtils';
import { formatAddress } from '../../../utils/stringUtils';
import { DoubleRightArrowIcon } from '../../Icon';
import SearchDetectionList from './SearchDetectionList';
import SearchDetectionTypeList from './SearchDetectionTypeList';

export interface SearchDrawerProps {
  /** Currently-selected search detection. */
  currentDetection?: Detection;
  /** Currently-selected search detection type. */
  currentDetectionType?: DetectionType;
  /** List of search detections of `currentDetectionType` for this walkthrough. */
  detections: Detection[];
  /** List of search detection types for this walkthrough. */
  detectionTypes: DetectionType[];
  /** Flag indicating whether or not data is loading for this drawer. */
  isLoading?: boolean;
  /** Whether or not the drawer is visible. */
  isOpen?: boolean;
  /** Handler called when the user clicks the "View 360" button on a search detection. */
  on360Click: (detection: Detection) => void;
  /** Handler to call when the user wants to close the drawer. */
  onClose: () => void;
  /** Handler called when the user selects a detection. */
  onDetectionSelect: (detection: Detection) => void;
  /** Handler called when the user selects a detection type or clears their selection. */
  onDetectionTypeSelect: (detectionType: DetectionType | undefined) => void;
  /** Handler called when the user votes on a detection. A vote value of `L` means "like", `D` means "dislike". */
  onDetectionVote: (detection: Detection, vote: 'L' | 'D') => void;
  /** Current project instance. */
  project: Project;
  /** Current walkthrough instance. */
  walkthrough?: Walkthrough;
  /** Current user details. */
  user?: Me;
}

const SearchDrawer = (props: SearchDrawerProps) => {
  const {
    currentDetection,
    currentDetectionType,
    detections,
    detectionTypes,
    isLoading,
    isOpen,
    on360Click,
    onClose,
    onDetectionSelect,
    onDetectionTypeSelect,
    onDetectionVote,
    project,
    user,
    walkthrough,
  } = props;

  const [searchText, setSearchInputValue] = useState<string>('');

  /**
   * When the user selects a detection type, set the current detection type to the selection. This advances the user to
   * step 2 (selecting a detection of the chosen type).
   */
  const handleDetectionTypeSelect = (detectionType: DetectionType) => {
    onDetectionTypeSelect(detectionType);
    setSearchInputValue(detectionType.label);
  };

  /**
   * If the user has already selected a detection type then changes the search text, clear the current detection type.
   * This kicks them back to step 1 (selecting a detection type from the filtered list).
   */
  const handleSearchTextChange = (nextSearchText: string) => {
    if (currentDetectionType) {
      onDetectionTypeSelect(undefined);
    }

    setSearchInputValue(nextSearchText);
  };

  // If the current detection is cleared by the parent (e.g. when changing floorplans or time travel), clear the search value.
  useEffect(() => {
    if (!currentDetection) {
      setSearchInputValue('');
    }
  }, [currentDetection]);

  return (
    <ChakraDrawer isOpen={Boolean(isOpen)} onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton>
          <Icon aria-hidden as={DoubleRightArrowIcon} height="1.5rem" width="1.5rem" />
        </DrawerCloseButton>
        <DrawerHeader>
          <Heading as="h1" size="lg">
            {project.name}
          </Heading>
          <Text color={theme.colors.brand.gray[600]} fontSize="0.875rem">
            {formatAddress(project.address_line, project.city, project.state, project.zip_code)}
          </Text>
          {walkthrough && (
            <>
              <Text color={theme.colors.brand.gray[600]} fontSize="0.875rem" paddingBlockStart="1rem">
                Current walkthrough
              </Text>
              <Text fontSize="1rem">{formatIsoDate(walkthrough?.when)?.formattedDateTime}</Text>
            </>
          )}
        </DrawerHeader>
        <DrawerBody>
          {isLoading && <LoadingIndicator />}
          {!isLoading && (
            <>
              <SearchInput
                inputProps={{ 'data-pendo-label': 'Use textbox', 'data-pendo-topic': 'search' }}
                onChange={handleSearchTextChange}
                value={searchText}
                variant="white"
              />
              <Box paddingBlock="0.75rem">
                {!currentDetectionType && (
                  <SearchDetectionTypeList
                    detectionTypes={detectionTypes}
                    onDetectionTypeSelect={handleDetectionTypeSelect}
                    searchText={searchText}
                  />
                )}
                {currentDetectionType && (
                  <SearchDetectionList
                    currentDetection={currentDetection}
                    detections={detections}
                    on360Click={on360Click}
                    onDetectionSelect={onDetectionSelect}
                    onDetectionVote={onDetectionVote}
                    user={user}
                  />
                )}
              </Box>
            </>
          )}
        </DrawerBody>
      </DrawerContent>
    </ChakraDrawer>
  );
};

export default SearchDrawer;
