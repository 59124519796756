import { FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik';
import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { Button, Caption, FormControl } from '../';
import api from '../../api';
import { transformErrorResponse } from '../../utils/error';

import classes from './Login.module.scss';

interface FormValues {
  email: string;
}

const validationSchema = Yup.object({
  email: Yup.string().email('Provide a valid email address').required('Email address is required'),
});

const ForgotPasswordForm = (): ReactElement => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      await api.auth.resetPassword(values);
      setSubmitted(true);
    } catch (error) {
      console.error('[ForgotPasswordForm] Failed to initiate password reset!', error);
      const { message, fieldErrors } = await transformErrorResponse(
        error as Response,
        values,
        'Failed to initiate password reset. Please try again later.'
      );
      setSubmitError(message);
      if (fieldErrors) {
        formikHelpers.setErrors(fieldErrors);
      }
    }
  }

  if (submitted) {
    return (
      <p>
        You’ve successfully initiated a password reset. Please check your email to finish the reset process. Click{' '}
        <Link className="Link" to="/login">
          here to go back
        </Link>{' '}
        to Sign in.
      </p>
    );
  }

  return (
    <>
      {/* h2-level subtitle probably not required here */}
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
          <Form className={classes.form}>
            <FormControl isInvalid={Boolean(errors.email) && touched.email} variant="external">
              <Caption as={FormLabel}>Email address</Caption>
              <Input
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Email address"
                type="email"
                value={values.email}
              />
              <ErrorMessage component={FormErrorMessage} name="email" />
            </FormControl>
            {!isSubmitting && submitError && <p className={classes.errorMessage}>{submitError}</p>}
            <Button className={classes.submitButton} isLoading={isSubmitting} type="submit" variant="primary">
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ForgotPasswordForm;
