import { useQuery } from '@tanstack/react-query';

import { Account } from '../../../../@types/OnSiteIQ';
import { AccountUser } from '../../../../@types/UserManagement';
import { UserManagementApi } from '../../../../api/next';
import { QueryTopics, UserManagementQueryKeys } from '../../../../constants/queries';
import usePermissions from '../../../../hooks/usePermissions';
import { AccountAccordionItem } from './AccountAccordionItem';

interface AccountAccordionItemContainerProps {
  account: Account;
  user: AccountUser;
  onConfirmAccessChange: () => void;
}

export const AccountAccordionItemContainer = ({
  account,
  user,
  onConfirmAccessChange,
}: AccountAccordionItemContainerProps) => {
  const { accountsWithManagePermission } = usePermissions();

  const canLoggedInUserManageAccount = accountsWithManagePermission.some(({ id }) => id === account.id);

  /** If the logged-in user can manage users for this account, retrieve the list of all projects for this account. */
  const accountProjectsQuery = useQuery({
    enabled: Boolean(account.id) && canLoggedInUserManageAccount,
    queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.ACCOUNT_PROJECTS, account.id],
    queryFn: async ({ signal }) => (await UserManagementApi.getAccountProjects(account.id, { signal })).data,
  });

  /** Always retrieve the list of projects that both the logged-in user and the selected user have access to. */
  const sharedProjectsQuery = useQuery({
    enabled: Boolean(user.id) && Boolean(account.id),
    queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.SHARED_PROJECTS, account.id, user.id],
    queryFn: async ({ signal }) => (await UserManagementApi.getSharedProjects(account.id, user.id, { signal })).data,
  });

  const allAccountProjects = accountProjectsQuery.data?.projects ?? [];
  const sharedProjects = sharedProjectsQuery.data?.projects ?? [];
  const isFetchingSharedProjects = sharedProjectsQuery.isFetching;

  const canSelectedUserInvite = sharedProjectsQuery.data?.can_invite ?? false;
  const hasAccessToAllProjects = sharedProjectsQuery.data?.all_projects ?? false;

  return (
    <AccountAccordionItem
      account={account}
      hasAccessToAllProjects={hasAccessToAllProjects}
      canSelectedUserInvite={canSelectedUserInvite}
      user={user}
      isFetchingSharedProjects={isFetchingSharedProjects}
      canManageAccount={canLoggedInUserManageAccount}
      allAccountProjects={allAccountProjects}
      sharedProjects={sharedProjects}
      onConfirmAccessChange={onConfirmAccessChange}
    />
  );
};
