import Cookies from 'js-cookie';

import api from '../api';
import { LOGIN, LOGOUT, SET_LOGIN_REQUIRED, SET_USER_PROFILE } from '../constants/actionTypes';

// Redux action creators. Side effects go here until they are implemented as hooks.
const setLoggedIn = (user) => {
  const APP_ID = 'pw548b0r';
  const intercomSettings = {
    api_base: 'https://api-iam.intercom.io',
    app_id: APP_ID,
    hide_default_launcher: true,
    custom_launcher_selector: '#help-chat-button',
    alignment: 'left',
    horizontal_padding: 75,
    vertical_padding: 75,
    user_id: process.env.REACT_APP_ENVIRONMENT !== 'production' ? `TEST-${user?.id?.toString()}` : user?.id?.toString(),
    name: `${user?.first_name} ${user?.last_name}`,
    email: user?.email,
  };

  window.Intercom?.('boot', intercomSettings);

  return { type: LOGIN, user };
};

export const setLoggedOut = () => {
  Cookies.remove('onsitevision_access', { domain: window.location.hostname });
  Cookies.remove('onsitevision_refresh', { domain: window.location.hostname });
  window.Intercom?.('shutdown'); // Log out of Intercom
  return { type: LOGOUT };
};

const setLoginRequired = () => {
  return { type: SET_LOGIN_REQUIRED };
};

const setUserProfile = (user) => {
  return { type: SET_USER_PROFILE, user };
};

// Redux actions.
export const attemptLoadMe = async (dispatch) => {
  try {
    const user = await api.auth.me();
    dispatch(setLoggedIn(user));
    return user;
  } catch (error) {
    dispatch(setLoginRequired());
    return null;
  }
};

export const logout = () => (dispatch) => dispatch(setLoggedOut());

export const me = () => (dispatch) =>
  api.auth
    .me()
    .then((user) => {
      dispatch(setUserProfile(user));
    })
    .catch((error) => {
      console.error('[auth] Failed to load user profile details!', error);
    });

export const login = (user) => {
  return setLoggedIn(user);
};

export const updateMe = (userDetails) => (dispatch) =>
  api.auth.setProfile(userDetails).then((user) => {
    dispatch(setLoggedIn(user));
  });
