import { popoverAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const searchInput = definePartsStyle({
  content: defineStyle({
    bg: 'var(--gray-50)',
  }),
  popper: defineStyle({
    zIndex: 50,
  }),
});

export const popoverTheme = defineMultiStyleConfig({
  variants: { searchInput },
});
