import { FormControl, FormErrorMessage, FormLabel, Textarea } from '@chakra-ui/react';
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import { ForwardedRef, forwardRef } from 'react';
import * as Yup from 'yup';

export interface StandardAnnotationFormValues {
  content: string;
}

export interface StandardAnnotationFormProps {
  onSubmit: (values: StandardAnnotationFormValues) => void;
}

const validationSchema = Yup.object({
  content: Yup.string().required('Markup description is required'),
});

const StandardAnnotationForm = forwardRef(
  (props: StandardAnnotationFormProps, ref: ForwardedRef<FormikProps<StandardAnnotationFormValues>>) => {
    const { onSubmit } = props;

    return (
      <Formik
        innerRef={ref}
        initialValues={{ content: '' }}
        onSubmit={onSubmit}
        validateOnBlur
        validationSchema={validationSchema}
      >
        <Form>
          <Field name="content">
            {({ field, meta }: FieldProps<string, StandardAnnotationFormValues>) => (
              <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                <FormLabel>Description</FormLabel>
                <Textarea {...field} placeholder="Write a description" />
                <ErrorMessage component={FormErrorMessage} name={field.name} />
              </FormControl>
            )}
          </Field>
        </Form>
      </Formik>
    );
  }
);

export default StandardAnnotationForm;
