import { Text } from '@chakra-ui/react';
import { ReactElement, ReactNode } from 'react';

export interface FormControlErrorMessageProps {
  children?: ReactNode;
  variant?: 'external' | 'internal';
}

const FormControlErrorMessage = (props: FormControlErrorMessageProps): ReactElement | null => {
  const { children, variant = 'internal' } = props;

  if (!children) {
    return null;
  }

  return (
    <Text color={variant === 'external' ? 'brand.errorExternal' : 'brand.errorInternal'} fontSize="sm" pt="1">
      {children}
    </Text>
  );
};

export default FormControlErrorMessage;
