import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);

// TODO: 'valid' should really just be the default Tag theme
const valid = definePartsStyle({
  container: {
    bg: 'var(--primary-100)',
    color: 'var(--primary-400)',
    borderRadius: '1.25rem',
    padding: '0 0.75rem',
  },
});

const invalid = definePartsStyle({
  container: {
    bg: 'var(--error-100)',
    color: 'var(--error-500)',
    borderRadius: '1.25rem',
    padding: '0 0.75rem',
  },
});

export const tagTheme = defineMultiStyleConfig({
  variants: {
    valid,
    invalid,
  },
});
