import { Box, Flex, FlexProps } from '@chakra-ui/react';

import theme from '../../theme';

export interface PaneHeadProps extends FlexProps {
  /**
   * Optional heading level for the pane head's text. If present, the `title`'s text element will receive
   * `role="heading"` and the assigned heading level.
   *
   * @example
   *
   * ```jsx
   * <PaneHead headingLevel={1} title="Test" />
   * ```
   *
   * will generate:
   *
   * ```html
   * <div>
   *   <div level="1" role="heading">Test</div>
   * </div>
   * ```
   */
  headingLevel?: number;
  /** Text to render as the title of this pane. */
  title: string;
}

const PaneHead = (props: PaneHeadProps) => {
  const { headingLevel, title, ...flexProps } = props;
  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.colors.brand.gray['50']}
      borderTopLeftRadius={theme.radii.card}
      borderTopRightRadius={theme.radii.card}
      flexWrap="nowrap"
      justifyContent="space-between"
      padding="1rem"
      {...flexProps}
    >
      <Box
        aria-level={headingLevel}
        color={theme.colors.gray['800']}
        fontSize="1.125rem"
        fontWeight="bold"
        lineHeight="1.889"
        role={headingLevel ? 'heading' : undefined}
      >
        {title}
      </Box>
      {flexProps.children}
    </Flex>
  );
};

export default PaneHead;
