import { Icon, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import { ReactElement, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { AddIcon, CloseIcon } from './Icon';

import classes from './AddAttachment.module.scss';

interface Attachment {
  /** File data. */
  file: File;
  /** Image URL used to preview the file contents. */
  image?: string;
}

export interface AddAttachmentProps {
  onChange: (files: File[]) => void;
  defaultValue: Attachment[];
}

const AddAttachment = ({ defaultValue, onChange }: AddAttachmentProps): ReactElement => {
  const [attachments, setAttachments] = useState<Attachment[]>(defaultValue);

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: (files: File[]): void => {
      addAttachments(files);
    },
    multiple: true,
  });

  function fileToAttachment(file: File): Promise<Attachment> {
    return new Promise((resolve, reject) => {
      // We don't need to make images for non image files
      if (!['image/png', 'image/jpeg'].includes(file.type)) {
        resolve({ file });
      }

      const fr: FileReader = new FileReader();
      fr.readAsDataURL(file);
      fr.onload = () => resolve({ file, image: fr.result as string });
      fr.onerror = () => reject(fr.error);
    });
  }

  function addAttachments(newFiles: File[]): void {
    Promise.all(
      newFiles.filter((file) => !attachments.find((a) => a.file.name === file.name)).map(fileToAttachment)
    ).then((newAttachments: Attachment[]) => update(attachments.concat(newAttachments)));
  }

  function removeAttachment(file: File): void {
    update(attachments.filter((a) => a.file !== file));
  }

  function update(attachments: Attachment[]) {
    setAttachments(attachments);
    onChange(attachments.map((a) => a.file));
  }

  return (
    <div className={classes.attachmentGrid}>
      <InputGroup aria-label="Add Attachment" className={classes.attachment} role="button" {...getRootProps()}>
        <InputLeftElement className={classes.attachmentPreview}>
          <Icon aria-hidden as={AddIcon} fontSize="1.5rem" />
        </InputLeftElement>
        <Input
          aria-hidden
          className={classes.attachmentName}
          pointerEvents="none"
          readOnly
          tabIndex={-1}
          value="Add Attachment"
        />
        <input aria-hidden tabIndex={-1} type="file" {...getInputProps()} />
      </InputGroup>
      {attachments.map((attachment) => (
        <InputGroup className={classes.attachment} key={attachment.file.name} tabIndex={-1}>
          <InputLeftElement className={classes.attachmentPreview}>
            <img alt="" src={attachment.image} />
          </InputLeftElement>
          <Input
            aria-hidden
            className={classes.attachmentName}
            pointerEvents="none"
            readOnly
            tabIndex={-1}
            value={attachment.file.name}
          />
          <InputRightElement
            className={classes.attachmentControls}
            onClick={() => removeAttachment(attachment.file)}
            role="button"
            tabIndex={0}
          >
            <CloseIcon aria-label="Remove attachment" />
          </InputRightElement>
        </InputGroup>
      ))}
    </div>
  );
};

export default AddAttachment;
