import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';

import { Account } from '../../../../@types/OnSiteIQ';
import { AccountUser } from '../../../../@types/UserManagement';
import theme from '../../../../theme';
import { HelpIcon } from '../../../Icon';
import { List } from '../../../List/List';
import LoadingIndicator from '../../../LoadingIndicator';
import { Tooltip } from '../../../Tooltip/Tooltip';
import { ProjectChangeConfirmationModal } from '../../../UserManagement/ProjectChangeConfirmationModal/ProjectChangeConfirmationModal';
import { UserRemovalConfirmationModal } from '../../../UserManagement/UserRemovalConfirmationModal/UserRemovalConfirmationModal';

import classes from './AccountAccordionItem.module.scss';

export interface AccountAccordionItemProps {
  account: Account;
  // TODO: swap to Project type?
  /** List of all projects for this account */
  allAccountProjects: Account[];
  /** List of projects that both the logged-in user and the selected user can access. */
  sharedProjects: {
    /** OnsiteIQ ID of the project. */
    id: number;
    /** Name of the project. */
    name: string;
  }[];
  /** True if the user has access to all projects for the selected account. */
  hasAccessToAllProjects: boolean;
  /** True if the selected user can invite other users. */
  canSelectedUserInvite: boolean;
  /** Current user */
  user: AccountUser;
  /** Function called when a change is made to the project access for a user (via clicking Confirm while in Edit Mode) */
  onConfirmAccessChange: () => void;
  /** True if the logged in user can manage this account. */
  canManageAccount: boolean;
  /** True if the shared projects for this user and account are being fetched. */
  isFetchingSharedProjects?: boolean;
}

interface FormValues {
  canInviteOthers: boolean;
  projects: number[];
  accessAllProjects: boolean;
}

const helperTextTooltip =
  'Users with invite permissions can invite users to any projects they have access to within the selected account.';

export const AccountAccordionItem = ({
  account,
  allAccountProjects,
  sharedProjects,
  hasAccessToAllProjects,
  canSelectedUserInvite,
  user,
  onConfirmAccessChange,
  canManageAccount,
  isFetchingSharedProjects,
}: AccountAccordionItemProps) => {
  const [isEditMode, setIsEditMode] = useState(false);

  /** State variable to track the projects selected in the list. Initialized with the list of projects that both the logged-in user and the selected user can access. */
  const [selectedProjectIds, setSelectedProjectIds] = useState(sharedProjects.map(({ id }) => id));

  useEffect(() => {
    setSelectedProjectIds(sharedProjects.map(({ id }) => id));
  }, [sharedProjects]);

  const [isProjectChangeConfirmationOpen, setIsProjectChangeConfirmationOpen] = useState(false);

  const [isUserRemovalConfirmationOpen, setIsUserRemovalConfirmationOpen] = useState(false);

  const initialFormValues: FormValues = {
    canInviteOthers: canSelectedUserInvite,
    projects: sharedProjects.map(({ id }) => id),
    accessAllProjects: hasAccessToAllProjects,
  };

  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);

  const onEditClick = () => {
    setIsEditMode(true);
  };

  const onRemoveUserBtnClick = () => {
    setIsUserRemovalConfirmationOpen(true);
  };

  const onCancelClick = () => {
    setIsEditMode(false);
    setSelectedProjectIds(sharedProjects.map(({ id }) => id));
  };

  const onSaveClick = (values: FormValues) => {
    setFormValues(values);
    setIsProjectChangeConfirmationOpen(true);
  };

  const onConfirmClick = () => {
    setIsEditMode(false);
    onConfirmAccessChange();
  };

  const numProjectsWithAccess = sharedProjects.length;

  return (
    <>
      <AccordionItem className={classes.accountAccordionItem} data-testid="accordion-item">
        <Heading as="h2">
          <AccordionButton
            data-pendo-topic="user-management"
            data-pendo-label="Toggle account accordion from user profile drawer"
          >
            <Box as="span" flex="1" textAlign="left">
              <Text fontWeight={700}>{account.name}</Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </Heading>
        <AccordionPanel>
          {isFetchingSharedProjects ? (
            <LoadingIndicator />
          ) : (
            <>
              <Formik initialValues={initialFormValues} onSubmit={onSaveClick} enableReinitialize>
                {() => (
                  <Form>
                    <Field name="canInviteOthers">
                      {({ field, form }: FieldProps<string, FormValues>) => (
                        <FormControl className={classes.formControl} isDisabled={!isEditMode}>
                          <FormLabel className={classes.formLabel}>Permission</FormLabel>
                          <Stack direction="row" alignItems="center">
                            <Checkbox {...field} isChecked={form.values.canInviteOthers}>
                              Can invite other users
                            </Checkbox>
                            <Tooltip label={helperTextTooltip}>
                              <Icon
                                aria-label="More information"
                                as={HelpIcon}
                                color={theme.colors.brand.gray[400]}
                                height="1.125rem"
                                tabIndex={0}
                                width="1.125rem"
                              />
                            </Tooltip>
                          </Stack>
                        </FormControl>
                      )}
                    </Field>

                    <Stack direction="row">
                      <Text fontSize="0.875rem" color={theme.colors.brand.gray[600]}>
                        {isEditMode ? 'Add to project(s)' : `Projects: ${numProjectsWithAccess}`}
                      </Text>
                      <Spacer />
                      {hasAccessToAllProjects && (
                        <Text fontSize="0.875rem" color={theme.colors.brand.gray[600]}>
                          User has access to all projects on this account
                        </Text>
                      )}
                      {!hasAccessToAllProjects && isEditMode && (
                        <Text fontSize="0.875rem" color={theme.colors.brand.gray[600]}>
                          {selectedProjectIds.length} selected
                        </Text>
                      )}
                    </Stack>
                    <Field name="projects">
                      {({ field, form }: FieldProps<string[], FormValues>) => (
                        <FormControl isDisabled={form.values.accessAllProjects}>
                          <List
                            {...field}
                            isDisabled={form.values.accessAllProjects}
                            emptyPlaceholder="No shared projects with this user."
                            options={
                              isEditMode
                                ? allAccountProjects.map(({ id, name: text }) => ({ id, text }))
                                : sharedProjects.map(({ id, name: text }) => ({ id, text }))
                            }
                            onChange={(selectedValues) => {
                              setSelectedProjectIds(selectedValues.map((value) => Number(value)));
                              form.setFieldValue('projects', selectedValues, true);
                              form.setFieldTouched('projects', true, false);
                            }}
                            isSearchable
                            mode={isEditMode ? 'multiselect' : 'default'}
                            searchPlaceholder="Search Projects"
                          />
                        </FormControl>
                      )}
                    </Field>
                    {(hasAccessToAllProjects || isEditMode) && (
                      <Field name="accessAllProjects">
                        {({ field, form }: FieldProps<string, FormValues>) => (
                          <FormControl className={classes.formControl} isDisabled={!isEditMode}>
                            <Checkbox
                              marginTop="0.875rem"
                              {...field}
                              isChecked={form.values.accessAllProjects}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  form.setFieldValue('projects', []);
                                }
                                field.onChange(event);
                              }}
                            >
                              Can access all projects added to {account.name}
                            </Checkbox>
                          </FormControl>
                        )}
                      </Field>
                    )}
                    {isEditMode && (
                      <div>
                        <Stack direction="row" marginTop="2rem">
                          <Button
                            borderColor={theme.colors.brand.error[200]}
                            color={theme.colors.brand.error[200]}
                            data-pendo-label="Remove user from account from user profile drawer"
                            data-pendo-topic="user-management"
                            onClick={onRemoveUserBtnClick}
                            size="sm"
                            variant="lowEmphasisV2"
                            _hover={{
                              color: theme.colors.brand.error[500],
                              borderColor: theme.colors.brand.error[500],
                            }}
                          >
                            Remove from account
                          </Button>
                          <Spacer />
                          <Button
                            data-pendo-label="Cancel editing account user permissions from user profile drawer"
                            data-pendo-topic="user-management"
                            onClick={onCancelClick}
                            size="sm"
                            variant="lowEmphasisV2"
                          >
                            Cancel
                          </Button>
                          <Button
                            data-pendo-topic="user-management"
                            data-pendo-label="Save edits to account user permissions from user profile drawer"
                            size="sm"
                            type="submit"
                            variant="highEmphasisV2"
                          >
                            Save
                          </Button>
                        </Stack>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
              {!isEditMode && canManageAccount && (
                <Button
                  data-pendo-label="Edit account user permissions from user profile drawer"
                  data-pendo-topic="user-management"
                  marginTop="2rem"
                  onClick={onEditClick}
                  size="sm"
                  variant="lowEmphasisV2"
                  width="100%"
                >
                  Edit
                </Button>
              )}
            </>
          )}
        </AccordionPanel>
      </AccordionItem>
      <ProjectChangeConfirmationModal
        isOpen={isProjectChangeConfirmationOpen}
        onClose={() => setIsProjectChangeConfirmationOpen(false)}
        user={user}
        account={account}
        numProjects={selectedProjectIds.length}
        onConfirm={onConfirmClick}
        updatedPermissions={formValues}
      />
      <UserRemovalConfirmationModal
        isOpen={isUserRemovalConfirmationOpen}
        onClose={() => setIsUserRemovalConfirmationOpen(false)}
        account={account}
        user={user}
        onConfirm={onConfirmClick}
      />
    </>
  );
};
