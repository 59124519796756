import { AxiosRequestConfig } from 'axios';

import { Project } from '../../../@types/api/v0/rest/Project';
import { get } from '../../next';

export const ProjectApi = {
  /**
   * Retrieve a project instance by ID.
   * @param id Unique ID of the desired project on the server.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the requested project.
   */
  getById: (id: number, requestConfig?: AxiosRequestConfig) => get<Project>(`/api/projects/${id}/`, requestConfig),
};
