import { Box, BoxProps } from '@chakra-ui/react';

import theme from '../../theme';

const PaneBody = (props: BoxProps) => (
  <Box
    backgroundColor={theme.colors.white}
    borderBottomLeftRadius={theme.radii.card}
    borderBottomRightRadius={theme.radii.card}
    padding="1rem"
    {...props}
  />
);

export default PaneBody;
