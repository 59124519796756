import { HTMLInputTypeAttribute } from 'react';
import * as Yup from 'yup';

import api from '../../api';

export type FormFieldName = 'first_name' | 'last_name' | 'userpersona' | 'title' | 'company_name' | 'phone';

export interface FormField {
  displayName: string;
  htmlInputType?: HTMLInputTypeAttribute;
  htmlName: FormFieldName;
  htmlTagName?: 'input' | 'select';
  initialValueAccessor?: (serverValue: any) => string;
  optionLoader?: (...args: unknown[]) => Promise<unknown>;
  optionNameKey?: string;
  optionValueKey?: string;
  placeholder: string;
  validator?: Yup.StringSchema;
}

// Note: these keys and HTML names correspond to the attribute names on the server.
export const FormFields: Record<FormFieldName, FormField> = {
  first_name: {
    displayName: 'First Name',
    htmlInputType: 'text',
    htmlName: 'first_name',
    htmlTagName: 'input',
    placeholder: 'First name',
    validator: Yup.string().trim().required('First name is required'),
  },
  last_name: {
    displayName: 'Last Name',
    htmlInputType: 'text',
    htmlName: 'last_name',
    htmlTagName: 'input',
    placeholder: 'Last name',
    validator: Yup.string().trim().required('Last name is required'),
  },
  userpersona: {
    displayName: 'Job Role',
    htmlName: 'userpersona',
    htmlTagName: 'select',
    initialValueAccessor: (serverValue: { persona: number }) => String(serverValue?.persona ?? ''),
    optionLoader: api.userPersona.getPersonaOptions,
    optionNameKey: 'persona',
    optionValueKey: 'id',
    placeholder: 'Job Role',
    validator: Yup.string().nullable(true).required('Job role is required'),
  },
  title: {
    displayName: 'Title',
    htmlInputType: 'text',
    htmlName: 'title',
    htmlTagName: 'input',
    placeholder: 'Title',
    validator: Yup.string().trim().required('Title is required'),
  },
  company_name: {
    displayName: 'Company',
    htmlInputType: 'text',
    htmlName: 'company_name',
    htmlTagName: 'input',
    placeholder: 'Company',
    validator: Yup.string().nullable(true).trim().required('Company name is required'),
  },
  phone: {
    displayName: 'Phone Number',
    htmlInputType: 'tel',
    htmlName: 'phone',
    htmlTagName: 'input',
    placeholder: 'Phone number (XXX-XXX-XXXX)',
    validator: Yup.string().matches(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Phone number must match format XXX-XXX-XXXX'),
  },
};

export const DEFAULT_MODAL_CLOSE_DELAY = 3000;

export const ModalText = {
  STEP_1_TITLE: 'Hold on!',
  STEP_2_TITLE: 'Thank you!',
  STEP_1_DESCRIPTION:
    "There's some information missing from your profile. In order for us to service your needs and provide a better experience, please fill out the missing fields.",
  STEP_2_DESCRIPTION: 'Your information has been saved.',
};
