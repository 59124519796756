import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Account } from '../../../@types/OnSiteIQ';
import { AccountUser, UpdateMemberPermissionsRequest } from '../../../@types/UserManagement';
import { UserManagementApi } from '../../../api/next';
import { QueryTopics, UserManagementQueryKeys } from '../../../constants/queries';
import { maybePluralize } from '../../../utils/maybePluralize';
import Toast from '../../Toast';

// TODO: consolidate this with AccountAccordionItem
interface FormValues {
  canInviteOthers: boolean;
  projects: number[];
  accessAllProjects: boolean;
}

type ProjectChangeConfirmationModalProps = Omit<ModalProps, 'children'> & {
  /** The current user */
  user: AccountUser;
  /** The account associated with the user being updated */
  account: Account;
  /** Number of projects the user will have access to within this account */
  numProjects: number;
  /** Function called when the Confirm button is clicked */
  onConfirm: () => void;
  /** True if this user has been given 'access all projects' permission */
  updatedPermissions: FormValues;
};

export const ProjectChangeConfirmationModal = (props: ProjectChangeConfirmationModalProps) => {
  const { isOpen, onClose, user, account, numProjects, onConfirm, updatedPermissions } = props;
  const { canInviteOthers, accessAllProjects, projects } = updatedPermissions;

  const queryClient = useQueryClient();

  const toast = useToast();

  const updateUserPermissions = useMutation({
    mutationKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.UPDATE_PERMISSIONS, account.id, user.id],
    mutationFn: ({
      account,
      user,
      payload,
    }: {
      account: Account;
      user: AccountUser;
      payload: UpdateMemberPermissionsRequest;
    }) => UserManagementApi.updateMemberPermissions(account.id, user.id, payload),
    onSuccess: (_, variables) => {
      onConfirm();
      onClose();

      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => (
          <Toast
            {...props}
            title="Success"
            description={`Project access for ${variables.user.name} was successfully updated.`}
            status="success"
          />
        ),
      });

      // TODO: invalidate more stuff -- this should also clear the shared projects
      // TODO: do this better by using a predicate

      queryClient.invalidateQueries({
        queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.ACCOUNT_MEMBERS, variables.account.id],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.SHARED_PROJECTS, account.id, user.id],
      });
    },
    onError: (error) => {
      // TODO: error state
      console.error('[AccountAccordionItemContainer] Failed to update user permissions', error);
    },
  });

  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = () => {
    updateUserPermissions.mutate({
      account,
      user,
      payload: {
        project_ids: projects,
        access_account_projects: accessAllProjects,
        invite_to_account_projects: canInviteOthers,
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="lg">
      <ModalOverlay />
      {/* TODO: move this padding to the Chakra theme/default  */}
      <ModalContent padding="1rem 1.5rem !important">
        <ModalHeader>Project change</ModalHeader>
        <ModalBody>
          <Text marginBottom="1rem">Are you sure you want to make this change?</Text>
          <div data-testid="numProjectsText">
            {/* TODO: error state */}
            {updatedPermissions.accessAllProjects ? (
              <>
                <Text fontWeight={700} as="span">
                  {user.name ? user.name : user.email}
                </Text>
                {' will have access to'}
                <Text fontWeight={700} as="span">
                  {' all projects '}
                </Text>
                {'added to '}
                <Text fontWeight={700} as="span">
                  {account.name}
                </Text>
                {'.'}
              </>
            ) : (
              <>
                <Text fontWeight={700} as="span">
                  {user.name ? user.name : user.email}
                </Text>
                {' will now have access to '}
                <Text fontWeight={700} as="span">
                  {maybePluralize('project', numProjects, { includeCount: true })}
                </Text>
                {' at '}
                <Text fontWeight={700} as="span">
                  {account.name}
                </Text>
                {'.'}
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter marginTop="4rem">
          {updateUserPermissions.isError && (
            <Button onClick={handleCancel} size="sm" variant="mediumEmphasisV2">
              Close
            </Button>
          )}
          {!updateUserPermissions.isError && (
            <>
              <Button
                isDisabled={updateUserPermissions.isLoading}
                marginRight="0.75rem"
                onClick={handleCancel}
                size="sm"
                variant="mediumEmphasisV2"
              >
                Cancel
              </Button>
              <Button
                isLoading={updateUserPermissions.isLoading}
                onClick={handleConfirm}
                size="sm"
                variant="highEmphasisV2"
              >
                Confirm
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
