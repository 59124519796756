import { Grid, Text } from '@chakra-ui/react';

import { Me } from '../../../@types/OnSiteIQ';
import { Detection, DetectionVote } from '../../../@types/api/v0/rest/Detection';
import theme from '../../../theme';
import SearchDetectionItem from './SearchDetectionItem';

export interface SearchDetectionListProps {
  /** Currently-selected search detection. */
  currentDetection?: Detection;
  /** List of search detections of the previously-selected detection type for this walkthrough. */
  detections: Detection[];
  /** Handler called when the user clicks the "View 360" button on a search detection. */
  on360Click: (detection: Detection) => void;
  /** Handler called when the user selects a detection. */
  onDetectionSelect: (detection: Detection) => void;
  /** Handler called when the user votes on a detection. A vote value of `L` means "like", `D` means "dislike". */
  onDetectionVote: (detection: Detection, vote: 'L' | 'D') => void;
  /** Current user details. */
  user?: Me;
}

const SearchDetectionList = (props: SearchDetectionListProps) => {
  const { currentDetection, detections, on360Click, onDetectionSelect, onDetectionVote, user } = props;

  const getUserVotes = (votes: DetectionVote[]) => votes.find((vote) => vote.user_id === user?.id);

  if (detections.length === 0) {
    return (
      <Text color={theme.colors.brand.gray[600]} textStyle="detail">
        No results found.
      </Text>
    );
  }

  // The CSS below can also be thought of as `grid: auto-flow dense 9.375rem / repeat(2, 1fr);` but Chakra doesn't
  // currently have a `grid` prop for the shorthand.
  return (
    <Grid
      gap="1rem"
      templateRows="none"
      templateColumns="repeat(2, 1fr)"
      templateAreas="none"
      autoRows="9.375rem"
      autoColumns="auto"
      autoFlow="dense"
    >
      {detections.map((detection, index) => (
        <SearchDetectionItem
          detection={detection}
          isActive={currentDetection ? currentDetection?.id === detection.id : index === 0}
          key={detection.id}
          on360Click={() => on360Click(detection)}
          onDetectionSelect={onDetectionSelect}
          onDetectionVote={onDetectionVote}
          userVote={getUserVotes(detection.votes)}
        />
      ))}
    </Grid>
  );
};

export default SearchDetectionList;
