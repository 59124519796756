import { Notification } from '../@types/OnSiteIQ';
import { NotificationActions } from '../@types/redux/notificationActions';
import { Notifications } from '../@types/redux/store';
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '../constants/actionTypes';

export const initialNotificationsState: Notifications = { notifications: [], id: 0 };

function addNotification(state: Notifications, notification: Notification) {
  notification.id = state.id;
  const id = state.id + 1;
  return { notifications: [...state.notifications, notification], id };
}

function removeNotification(state: Notifications, id: number) {
  const notifications = state.notifications.filter((n) => n.id !== id);
  return { ...state, notifications };
}

export default function reducer(state: Notifications | undefined, action: NotificationActions): Notifications {
  if (!state) {
    return initialNotificationsState;
  }

  switch (action.type) {
    case ADD_NOTIFICATION:
      return addNotification(state, action.notification);

    case REMOVE_NOTIFICATION:
      return removeNotification(state, action.id);

    default:
      return state;
  }
}
