import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import * as Yup from 'yup';

import { InviteMembersRequest } from '../../../@types/UserManagement';
import { UserManagementApi } from '../../../api/next';
import { QueryTopics, UserManagementQueryKeys } from '../../../constants/queries';
import theme from '../../../theme';
import Button from '../../Button';
import { CheckIcon } from '../../Icon';
import LoadingIndicator from '../../LoadingIndicator';
import { FormStep, MultiStepForm } from '../../MultiStepForm/MultiStepForm';
import { INVITE_LIMIT_PROJECTS, INVITE_LIMIT_USERS } from './InviteUserModalConstants';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

import classes from './InviteUserModal.module.scss';

export interface InviteUserModalContainerProps {
  /**
   * Optional ID of the account being interacted with (for example, the account currently selected on the Account
   * Users page). Used as the initial `account` value in this component's form.
   */
  currentAccountId?: number;
  /**
   * Optional ID of the project currently being interacted with (for example, the project from the Users Drawer).
   * Used as the initial `projects` value in this component's form.
   */
  initialProjectValue?: number;
  /** Flag indicating whether or not the modal is open. */
  isOpen?: boolean;
  /** Handler to call when the user wants to close the modal. */
  onClose: () => void;
  /**
   * Optional function to be called when the modal's form is submitted.
   * @param values Form submission data.
   */
  onSubmitForm?: (values: Record<string, any>) => void;
  /**
   * True if the modal should close upon successful invitation of user(s).
   */
  shouldCloseOnSuccess?: boolean;
}

export const formStepOneValidationSchema = Yup.object({
  accessAllProjects: Yup.boolean(),
  account: Yup.string().required('Account selection is required.'),
  to: Yup.array()
    .of(Yup.string().email('Please enter valid email addresses.'))
    .min(1, 'Please enter at least one email address.')
    .max(INVITE_LIMIT_USERS, `Up to ${INVITE_LIMIT_USERS} email addresses may be provided.`)
    .required(),
  projects: Yup.array()
    .of(Yup.number())
    .max(INVITE_LIMIT_PROJECTS, `Up to ${INVITE_LIMIT_PROJECTS} projects may be selected.`)
    .when('accessAllProjects', (accessAllProjects, schema) => {
      return accessAllProjects ? schema : schema.min(1, 'Please select at least one project.');
    }),
});

const InviteUserModalContainer = (props: InviteUserModalContainerProps) => {
  const { currentAccountId, isOpen, initialProjectValue, onClose, onSubmitForm, shouldCloseOnSuccess } = props;

  const [currentStepNumber, setCurrentStepNumber] = useState(0);

  const queryClient = useQueryClient();

  const submitMutation = useMutation({
    mutationFn: (values: Record<string, any>) => {
      const payload: InviteMembersRequest = {
        grant_access_account_projects: values.accessAllProjects,
        project_subset: values.accessAllProjects ? [] : values.projects,
        users: values.to.map((email: string) => ({
          email,
          grant_invite_to_account_projects: values.inviteOthers.includes(email),
        })),
      };
      return UserManagementApi.inviteMembers(values.account, payload);
    },
    onSuccess: (_, variables) => {
      onSubmitForm?.(variables);
      queryClient.invalidateQueries({
        queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.ACCOUNT_MEMBERS, Number(variables.account)],
      });
      if (shouldCloseOnSuccess) {
        handleClose();
      }
    },
  });

  const handleClose = () => {
    onClose();
    setCurrentStepNumber(0);
    submitMutation.reset();
  };

  const handleSubmit = (values: Record<string, any>) => {
    submitMutation.mutate(values);
  };

  const formSteps: FormStep[] = [
    {
      validationSchema: formStepOneValidationSchema,
      content: <StepOne />,
    },
    {
      content: <StepTwo />,
    },
  ];

  return (
    <Modal closeOnOverlayClick={false} isCentered isOpen={Boolean(isOpen)} onClose={handleClose} size="2xl">
      <ModalOverlay />
      {/* TODO: move this padding to the Chakra theme/default  */}
      <ModalContent className={classes.inviteUserModalContent} padding="1rem 1.5rem !important">
        {submitMutation.isIdle && (
          <>
            <ModalHeader display="flex" alignItems="center" justifyContent="space-between">
              <Heading as="h1" size="lg">
                Invite Users
              </Heading>
              <Text color={theme.colors.brand.gray['600']} fontSize="0.875rem" letterSpacing="0">
                Step {currentStepNumber + 1} / 2
              </Text>
            </ModalHeader>
            <ModalBody data-pendo-topic="user-management" data-pendo-label="Invite user modal">
              <MultiStepForm
                initialValues={{
                  account: currentAccountId,
                  to: [],
                  projects: initialProjectValue ? [initialProjectValue] : [],
                  accessAllProjects: false,
                  inviteOthers: [],
                }}
                onCancel={handleClose}
                onChangeStep={setCurrentStepNumber}
                onSubmit={handleSubmit}
                submitBtnText="Send Invite"
                steps={formSteps}
              />
            </ModalBody>
          </>
        )}
        {submitMutation.isLoading && (
          <>
            <ModalHeader display="flex" alignItems="center" justifyContent="space-between">
              <Heading as="h1" size="lg">
                Invite Users
              </Heading>
              <Text color={theme.colors.brand.gray['600']} fontSize="0.875rem" letterSpacing="0">
                Step {currentStepNumber + 1} / 2
              </Text>
            </ModalHeader>
            <ModalBody>
              <LoadingIndicator />
            </ModalBody>
          </>
        )}
        {submitMutation.isError && (
          <>
            <ModalHeader>
              <Heading as="h1" size="lg">
                Invite Users
              </Heading>
            </ModalHeader>
            <ModalBody>
              <Text>
                An error occurred while inviting users. Please try again later. If this issue persists, contact{' '}
                <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a> for assistance.
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="mediumEmphasis"
                data-pendo-topic="user-management"
                data-pendo-label="Close invite user modal on error"
                onClick={handleClose}
              >
                Close
              </Button>
            </ModalFooter>
          </>
        )}
        {submitMutation.isSuccess && (
          <>
            <ModalHeader className={classes.confirmationHeader} display="flex" alignItems="center">
              <CheckIcon aria-hidden className={classes.checkIcon} />
              <Text color={theme.colors.brand.secondary['800']} fontWeight={700}>
                Invitation sent!
              </Text>
            </ModalHeader>
            <ModalBody>
              <Text>New users will receive an invitation in their inbox shortly.</Text>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="mediumEmphasis"
                data-pendo-topic="user-management"
                data-pendo-label="Close invite user modal on success"
                onClick={handleClose}
              >
                Done
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default InviteUserModalContainer;
