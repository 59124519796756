import { DrawerProps, useToast } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { AccountUser, ResendInvitationRequest } from '../../../../@types/UserManagement';
import { UserManagementApi } from '../../../../api/next';
import { QueryTopics, UserManagementQueryKeys } from '../../../../constants/queries';
import usePermissions from '../../../../hooks/usePermissions';
import Toast from '../../../Toast';
import { UserProfileDrawer } from './UserProfileDrawer';

type UserProfileDrawerContainerProps = Pick<DrawerProps, 'isOpen' | 'onClose'> & {
  user: AccountUser;
  accountId?: number;
};

/** Container component for data fetching */
export const UserProfileDrawerContainer = (props: UserProfileDrawerContainerProps) => {
  const { isOpen, user, accountId, onClose } = props;

  const toast = useToast();

  const { accountsWithInvitePermission } = usePermissions();
  const canInviteToCurrentAccount = accountsWithInvitePermission.some(({ id }) => id === accountId);

  const sharedAccountsQuery = useQuery({
    enabled: Number.isFinite(user.id),
    queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.SHARED_ACCOUNTS, user.id],
    queryFn: async ({ signal }) => (await UserManagementApi.getSharedAccounts(user.id, { signal })).data,
  });

  const sharedAccounts = sharedAccountsQuery.data?.shared_accounts ?? [];

  const resendInvitationMutation = useMutation({
    mutationFn: () => {
      const payload: ResendInvitationRequest = {};

      return UserManagementApi.resendInvitation(accountId ?? 0, user.id, payload);
    },
    onError: (error) => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => {
          let description = 'An error occurred. Please try again later.';
          if (error instanceof AxiosError) {
            if (error.response?.status === 429) {
              description = 'User has been invited recently. Please try again later.';
            }
          }
          return <Toast {...props} title="Error" description={description} status="error" />;
        },
      });
    },
    onSuccess: () => {
      onClose();

      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => (
          <Toast
            {...props}
            title="Success"
            description={`A reminder has been sent to ${user.email ?? 'the selected user.'}`}
            status="success"
          />
        ),
      });
    },
  });

  const handleResendInviteClick = () => {
    resendInvitationMutation.mutate();
  };

  return (
    <UserProfileDrawer
      errors={{
        sharedAccounts: sharedAccountsQuery.isError,
        resendInvitation: resendInvitationMutation.isError,
      }}
      loading={{ sharedAccounts: sharedAccountsQuery.isLoading, resendInvitation: resendInvitationMutation.isLoading }}
      onResendInvite={handleResendInviteClick}
      isOpen={isOpen}
      onClose={onClose}
      user={user}
      userAccounts={sharedAccounts}
      currentAccountId={accountId}
      canResendInvitation={canInviteToCurrentAccount}
    />
  );
};
