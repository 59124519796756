import { Box } from '@chakra-ui/react';

import theme from '../../theme';
import { getRoundedProgressValue } from '../../utils/progressUtils';

export interface ChartTooltipProps {
  label: string;
  value?: number;
  baselineValue?: number;
}

const ChartTooltip = (props: ChartTooltipProps) => {
  const { label, value, baselineValue } = props;

  if (value !== undefined && value >= 0) {
    const displayValue = getRoundedProgressValue(value);
    return (
      <Box
        backgroundColor={theme.colors.white}
        borderRadius="0.25rem"
        boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
        padding="1rem"
      >
        <Box color={theme.colors.brand.gray[800]} fontWeight="bold">{`${displayValue}% Complete`}</Box>
        <Box color={theme.colors.brand.gray[600]}>{label}</Box>
      </Box>
    );
  } else if (baselineValue === 100) {
    return (
      <Box
        backgroundColor={theme.colors.white}
        borderRadius="0.25rem"
        boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
        padding="1rem"
      >
        <Box color={theme.colors.brand.gray[800]} fontWeight="bold">
          Baseline Completion Date
        </Box>
        <Box color={theme.colors.brand.gray[600]}>{label}</Box>
      </Box>
    );
  }
  return null;
};

export default ChartTooltip;
