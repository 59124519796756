import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

export const xs = defineStyle({
  fontSize: '1rem',
});

export const sm = defineStyle({
  fontSize: '1.125rem',
});

export const md = defineStyle({
  fontSize: '1.25rem',
});

export const lg = defineStyle({
  fontSize: '1.5rem',
  fontWeight: 'bold', // TODO: this should maybe be a variant; as designed, the font has normal and bold versions
});

export const headingTheme = defineStyleConfig({
  baseStyle: {
    color: 'var(--gray-800)',
    fontFamily: 'APK Protocol, sans-serif',
    fontStyle: 'normal',
    letterSpacing: 0,
    lineHeight: 1.333,
  },
  sizes: { xs, sm, md, lg },
});
