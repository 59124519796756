import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const list = definePartsStyle({
  control: defineStyle({
    _checked: {
      _hover: {
        backgroundColor: 'var(--primary-300)',
        borderColor: 'var(--primary-300)',
      },
    },
  }),
  icon: defineStyle({}),
  container: defineStyle({
    width: '100%',
  }),
});

export const checkboxTheme = defineMultiStyleConfig({
  variants: { list },
});
