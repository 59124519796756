import { Select } from '@chakra-ui/react';
import { getMonth, getYear } from 'date-fns';
import range from 'lodash/range';
import { ReactElement } from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

import classes from './DatePicker.module.scss';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

interface DatePickerCustomHeaderProps extends ReactDatePickerCustomHeaderProps {
  minDate?: Date | null;
}

const CustomHeader = ({ date, changeYear, changeMonth, minDate }: DatePickerCustomHeaderProps): ReactElement => {
  const minYear = minDate ? getYear(minDate) : 2019;
  const yearOptions = range(minYear, getYear(new Date()) + 1, 1);

  return (
    <div className={classes.customHeader}>
      <Select onChange={(event) => changeMonth(months.indexOf(event.target.value))} value={months[getMonth(date)]}>
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
      <Select onChange={(event) => changeYear(Number(event.target.value))} value={getYear(date)}>
        {yearOptions.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
    </div>
  );
};

export default CustomHeader;
