import { AuthAction } from '../@types/redux/authActions';
import { Auth } from '../@types/redux/store';
import {
  CLEAR_PROCORE,
  LOGIN,
  LOGOUT,
  SET_LOGIN_REQUIRED,
  SET_PROCORE_ME,
  SET_PROCORE_TOKEN,
  SET_USER_PROFILE,
} from '../constants/actionTypes';

export const initialAuthState: Auth = { isAuthenticated: false, loaded: false };

export default function reducer(state: Auth | undefined, action: AuthAction): Auth {
  if (!state) {
    return initialAuthState;
  }

  switch (action.type) {
    case LOGIN:
      return { ...state, user: action.user, isAuthenticated: true, loaded: true };

    case LOGOUT:
      return { ...initialAuthState, loaded: true };

    case SET_LOGIN_REQUIRED:
      return { ...state, isAuthenticated: false, loaded: true };

    case SET_USER_PROFILE:
      return { ...state, user: action.user };

    case SET_PROCORE_TOKEN:
      return { ...state, procoreToken: action.token };

    case SET_PROCORE_ME:
      return { ...state, procoreMe: action.procoreMe };

    case CLEAR_PROCORE:
      return { ...state, procoreMe: undefined, procoreToken: undefined };

    default:
      return state;
  }
}
