import { LinearSRGBColorSpace, Texture, TextureLoader } from 'three';

import { TextureLoadingStatus, TextureQuality } from './constants';

class TextureManager {
  globalTextures: Map<string, Texture>;
  /** Texture quality to be used during ordinary scene navigation. */
  maxTextureQuality: '2k' | '4k' | '8k';
  /** Texture quality to be used for previews, for example during arrow key navigation. */
  previewTextureQuality: '1k' | '2k';
  sceneBaseUrl?: string;
  sceneTextureLoadingStatus: Map<string, TextureLoadingStatus>;
  sceneTextures: Map<string, Texture>;
  textureLoader: TextureLoader;

  constructor() {
    this.globalTextures = new Map();
    this.maxTextureQuality = '8k';
    this.previewTextureQuality = '2k';
    this.sceneTextureLoadingStatus = new Map();
    this.sceneTextures = new Map();
    this.textureLoader = new TextureLoader();
  }

  /**
   * Clear the base URL on which scene texture URLs are suffixed for network requests.
   */
  clearSceneBaseUrl = () => {
    this.sceneBaseUrl = undefined;
  };

  /**
   * Clear all scene-specific textures from memory.
   */
  clearSceneTextures = () => {
    // Need to use Array.from because of the downLevelIteration warning.
    const textures = Array.from(this.sceneTextures.values());
    for (const texture of textures) {
      texture.dispose();
    }
    this.sceneTextureLoadingStatus.clear();
    this.sceneTextures.clear();
  };

  /**
   * Retrieve a global texture by its name (i.e. key).
   */
  getGlobalTexture = (key: string): Texture | undefined => {
    return this.globalTextures.get(key);
  };

  /**
   * Load a global texture, i.e. a critical texture not specific to the scene.
   */
  loadGlobalTexture = (key: string, url: string): Promise<Texture> => {
    const cachedTexture = this.globalTextures.get(key);
    if (cachedTexture) {
      return Promise.resolve(cachedTexture);
    }

    return new Promise((resolve, reject) => {
      this.textureLoader.load(
        url,
        (texture: Texture) => {
          texture.colorSpace = LinearSRGBColorSpace;

          this.globalTextures.set(key, texture);
          resolve(texture);
        },
        undefined,
        (errorEvent: ErrorEvent) => {
          reject(new Error('Failed to load global texture', { cause: errorEvent }));
        }
      );
    });
  };

  /**
   * Retrieve a scene-specific texture (or undefined) at a specified quality. Throws an error if not found.
   */
  getSceneTexture = (filename: string, quality: TextureQuality): Texture => {
    const resolution = quality === TextureQuality.MAX ? this.maxTextureQuality : this.previewTextureQuality;
    const texture = this.sceneTextures.get(`${resolution}/${filename}`);
    if (!texture) {
      throw new Error('Failed to retrieve texture by filename');
    }

    return texture;
  };

  /**
   * Retrieve the loading status for a scene-specific texture.
   */
  getSceneTextureLoadingStatus = (filename: string, quality: TextureQuality): TextureLoadingStatus => {
    const resolution = quality === TextureQuality.MAX ? this.maxTextureQuality : this.previewTextureQuality;
    return this.sceneTextureLoadingStatus.get(`${resolution}/${filename}`) ?? TextureLoadingStatus.NOT_STARTED;
  };

  /**
   * Load a scene-specific texture at a specified quality. If the texture has already been loaded, it is returned
   * without making a redundant network request.
   */
  loadSceneTexture = (filename: string, quality: TextureQuality): Promise<Texture> => {
    if (!this.sceneBaseUrl) {
      return Promise.reject(new Error('No scene base URL set'));
    }

    const resolution = quality === TextureQuality.MAX ? this.maxTextureQuality : this.previewTextureQuality;
    const cacheKey = `${resolution}/${filename}`;
    const cachedTexture = this.sceneTextures.get(cacheKey);
    if (cachedTexture) {
      return Promise.resolve(cachedTexture);
    }

    this.sceneTextureLoadingStatus.set(cacheKey, TextureLoadingStatus.LOADING);
    return new Promise((resolve, reject) => {
      this.textureLoader.load(
        `${this.sceneBaseUrl}/${resolution}/${filename}`,
        (texture: Texture) => {
          texture.colorSpace = LinearSRGBColorSpace;

          this.sceneTextures.set(cacheKey, texture);
          this.sceneTextureLoadingStatus.set(cacheKey, TextureLoadingStatus.LOADED);
          resolve(texture);
        },
        undefined,
        (errorEvent: ErrorEvent) => {
          console.error('[TextureManager] Failed to load texture', errorEvent.message);
          reject(new Error('Failed to load texture', { cause: errorEvent }));
        }
      );
    });
  };

  /**
   * Set the base URL on which scene texture URLs are suffixed for network requests.
   */
  setSceneBaseUrl = (baseUrl: string) => {
    if (!baseUrl) {
      throw new Error('Attempted to set empty base URL for scene textures');
    }

    this.sceneBaseUrl = baseUrl;
  };

  /**
   * Given a parameter whose value contains the maximum device-supported texture size, set the quality characteristics
   * for the scene. While the vast majority of devices support 8K textures in WebGL, we need to account for devices that
   * only support 4K. Support down to 2K is provided for an extremely small number of old devices.
   * @param maxTextureSize Length of the longest image dimension in pixels.
   * @remark We use Math.log2 here because we need to count the number of factors of 2 in the parameter value. For
   * example, suppose maxTextureSize = 8192. Since 8192 = 2^13, log2 can be applied to both side to isolate the power 2
   * is raised to: 13.
   */
  setSceneTextureQuality = (maxTextureSize: number) => {
    if (Math.log2(maxTextureSize) < 12) {
      this.maxTextureQuality = '2k';
      this.previewTextureQuality = '1k';
    } else if (Math.log2(maxTextureSize) < 13) {
      this.maxTextureQuality = '4k';
      this.previewTextureQuality = '1k';
    } else {
      this.maxTextureQuality = '8k';
      this.previewTextureQuality = '2k';
    }
  };
}

export default TextureManager;
