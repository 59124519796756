import { AxiosRequestConfig } from 'axios';

import { Floorplan } from '../../../@types/api/v0/rest/Floorplan';
import { Project } from '../../../@types/api/v0/rest/Project';
import { get } from '../../next';

export const FloorplanApi = {
  /**
   * Retrieve a floorplan instance by ID.
   * @param id Unique ID of the desired floorplan on the server.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the requested floorplan.
   */
  getById: (id: number, requestConfig?: AxiosRequestConfig) => get<Floorplan>(`/api/floorplans/${id}/`, requestConfig),
  /**
   * Retrieve a project instance given a floorplan ID.
   * @param id Unique ID of the desired project on the server.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the requested project.
   */
  getProjectByFloorplanId: (id: number, requestConfig?: AxiosRequestConfig) =>
    get<Project>(`/api/floorplans/${id}/project/`, requestConfig),
};
