import { StyleProps, cssVar, defineStyleConfig } from '@chakra-ui/react';

const $arrowBg = cssVar('popper-arrow-bg');

const tooltipBaseStyle: StyleProps = {
  maxWidth: '16rem',
  backgroundColor: 'gray.800',
  borderRadius: 'md',
};

const infoVariantBaseStyle: StyleProps = {
  [$arrowBg.variable]: 'var(--white)',
  backgroundColor: 'var(--white)',
  color: 'var(--gray-900)',
};

export const tooltipTheme = defineStyleConfig({
  baseStyle: tooltipBaseStyle,
  variants: { info: infoVariantBaseStyle },
});
