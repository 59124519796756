import { Stat, StatLabel, StatNumber } from '@chakra-ui/react';

import { getTimeElapsedText } from '../../utils/progressUtils';

interface DurationStatProps {
  daysElapsed: number | undefined;
}

export const DurationStat = ({ daysElapsed }: DurationStatProps) => {
  const hasDaysElapsed = typeof daysElapsed === 'number' && Number.isFinite(daysElapsed) && daysElapsed > 0;

  const displayValue = hasDaysElapsed ? getTimeElapsedText(daysElapsed) : '-';

  return (
    <Stat>
      <StatLabel>Total Duration</StatLabel>
      <StatNumber>{displayValue}</StatNumber>
    </Stat>
  );
};
