import {
  Box,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Icon,
  Text,
} from '@chakra-ui/react';
import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';

import { LoadingIndicator } from '../..';
import { TimeTravelPair } from '../../../@types/api/v0/rest/Node';
import { Project } from '../../../@types/api/v0/rest/Project';
import { Walkthrough } from '../../../@types/api/v0/rest/Walkthrough';
import theme from '../../../theme';
import { formatIsoDate } from '../../../utils/dateUtils';
import { formatAddress } from '../../../utils/stringUtils';
import { DoubleRightArrowIcon } from '../../Icon';
import TimeTravelListItem from './TimeTravelListItem';

/**
 * This component may be used to show a list of options which are either:
 * - From the walkthrough page, walkthroughs of the same floorplan.
 * - From the 360º page, nodes close to the current position on other walkthroughs of the same floorplan.
 */
export type TimeTravelOption = { id: number; when: string } | TimeTravelPair;

export interface TimeTravelDrawerProps {
  /**
   * Flag indicating whether or not to show a loading indicator.
   *
   * Useful in cases when the parent component will change the drawer's content soon, preventing the user from changing
   * the current time travel selection. For example, when the 360º viewer is transitioning between ground nodes on a
   * slow connection, the user should not be able to change the current walkthrough mid-stream.
   */
  isLoading?: boolean;
  /** Whether or not the drawer is visible. */
  isOpen?: boolean;
  /** Handler to call when the user wants to close the drawer. */
  onClose: () => void;
  /** Handler to call when the user selects a time travel option. */
  onSelectOption: (option: TimeTravelOption) => void;
  /** List of time travel options to show to the user. */
  options: TimeTravelOption[];
  /** Current project details. Used to render a header on the top of the drawer. */
  project: Project;
  /** Flag indicating whether or not to render thumbnails for each time travel list item. */
  showThumbnails?: boolean;
  /** Current walkthrough visible to the user. */
  walkthrough?: Walkthrough;
}

const TimeTravelDrawer = (props: TimeTravelDrawerProps) => {
  const { isLoading, isOpen, options, onClose, onSelectOption, project, showThumbnails, walkthrough } = props;

  const sortedOptions = useMemo(() => orderBy(options, 'when', 'desc'), [options]);

  return (
    <ChakraDrawer isOpen={Boolean(isOpen)} onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton>
          <Icon aria-hidden as={DoubleRightArrowIcon} height="1.5rem" width="1.5rem" />
        </DrawerCloseButton>
        <DrawerHeader>
          <Heading as="h1" size="lg">
            {project.name}
          </Heading>
          <Text color={theme.colors.brand.gray[600]} fontSize="0.875rem">
            {formatAddress(project.address_line, project.city, project.state, project.zip_code)}
          </Text>
          {walkthrough && (
            <>
              <Text color={theme.colors.brand.gray[600]} fontSize="0.875rem" paddingBlockStart="1rem">
                Current walkthrough
              </Text>
              <Text fontSize="1rem">{formatIsoDate(walkthrough?.when)?.formattedDateTime}</Text>
            </>
          )}
        </DrawerHeader>
        <DrawerBody>
          {isLoading && <LoadingIndicator />}
          {!isLoading && (
            <Box>
              {sortedOptions.map((option) => (
                <TimeTravelListItem
                  isActive={
                    // When the option is a `TimeTravelPair` instance, it will have a `walkthrough` property. Otherwise,
                    // the option is a `Walkthrough` instance.
                    'walkthrough' in option ? option.walkthrough === walkthrough?.id : option.id === walkthrough?.id
                  }
                  key={option.id}
                  onSelectOption={onSelectOption}
                  option={option}
                  showThumbnail={showThumbnails}
                />
              ))}
            </Box>
          )}
        </DrawerBody>
      </DrawerContent>
    </ChakraDrawer>
  );
};

export default TimeTravelDrawer;
