import { AxiosRequestConfig } from 'axios';

import { UserProfile } from '../../../@types/api/v0/rest/UserProfile';
import { get } from '../../next';

export const AuthApi = {
  /**
   * Retrieve the current user's profile.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the current user's profile.
   */
  getUserProfile: (requestConfig?: AxiosRequestConfig) => get<UserProfile>('/api/auth/user', requestConfig),
};
