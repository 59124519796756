import { statAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(statAnatomy.keys);

const baseStyle = definePartsStyle({
  label: {
    color: 'var(--gray-600)',
  },
  helpText: {},
  icon: {},
  number: {
    fontWeight: 400,
  },
});

export const statTheme = defineMultiStyleConfig({ baseStyle });
