export const formatAddress = (street?: string, city?: string, state?: string, zip?: string) => {
  let address = street ?? '';

  if (city) {
    address += `, ${city}`;
  }
  if (state) {
    address += `, ${state}`;
  }
  if (zip) {
    address += ` ${zip}`;
  }

  return address;
};
