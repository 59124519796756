import { Accordion, Box, Button, DrawerProps, Flex, HStack, Stack, Text } from '@chakra-ui/react';

import { Account } from '../../../../@types/OnSiteIQ';
import { AccountUser } from '../../../../@types/UserManagement';
import { DrawerType, PendoTopic } from '../../../../constants/analytics';
import theme from '../../../../theme';
import { formatIsoDate } from '../../../../utils/dateUtils';
import { Drawer } from '../../../Drawer/Drawer';
import { AccountAccordionItemContainer } from './AccountAccordionItemContainer';

export type UserProfileDrawerProps = Pick<DrawerProps, 'isOpen' | 'onClose'> & {
  user: AccountUser;
  /** Accounts for this user */
  userAccounts: Account[];
  errors?: {
    /** Whether or not the list of the user's accounts is loading. */
    sharedAccounts?: boolean;
    resendInvitation?: boolean;
  };
  loading?: {
    /** Whether or not the list of the user's accounts had a loading error. */
    sharedAccounts?: boolean;
    resendInvitation?: boolean;
  };
  /** Currently-selected account ID (from the account selector on the Users page) */
  currentAccountId: number | undefined;
  /** Function called when the 'Resend invite' button is clicked */
  onResendInvite: () => void;
  /** Whether or not the current user has adequate permissions to resend invitations. */
  canResendInvitation: boolean;
};

export const UserProfileDrawer = ({
  errors,
  loading,
  isOpen,
  onClose,
  user,
  userAccounts,
  currentAccountId,
  onResendInvite,
  canResendInvitation,
}: UserProfileDrawerProps) => {
  const isRegistered = Boolean(user.last_login);
  const isPending = !isRegistered;

  const handleResendInviteClick = () => {
    onResendInvite();
  };

  const additionalHeaderContent = (
    <>
      <Flex margin="1rem 0">
        {isPending ? (
          <HStack alignItems="center" spacing="2rem">
            <Stack spacing={0} flex={1}>
              <Text color={theme.colors.brand.gray[600]} fontSize="0.875rem">
                Email
              </Text>
              <Text color={theme.colors.brand.gray[900]}>{user.email}</Text>
            </Stack>
            {canResendInvitation && (
              <Button
                data-pendo-label="Resend invite from user profile drawer"
                data-pendo-topic={PendoTopic.USER_MANAGEMENT}
                isDisabled={errors?.resendInvitation}
                isLoading={loading?.resendInvitation}
                onClick={handleResendInviteClick}
                size="sm"
                variant="lowEmphasisV2"
              >
                Resend Invite
              </Button>
            )}
          </HStack>
        ) : (
          <Flex width="100%">
            <Stack spacing={0} flex={1}>
              <Text color={theme.colors.brand.gray[600]} fontSize="0.875rem">
                Title
              </Text>
              <Text color={theme.colors.brand.gray[900]} fontSize="1rem">
                {user.title !== '' ? user.title : '-'}
              </Text>
            </Stack>
            <Stack spacing={0} flex={1}>
              <Text color={theme.colors.brand.gray[600]} fontSize="0.875rem">
                Email
              </Text>
              <Text color={theme.colors.brand.gray[900]} fontSize="1rem">
                {user.email}
              </Text>
            </Stack>
          </Flex>
        )}
      </Flex>
      <Flex>
        {isRegistered && (
          <Stack spacing={0} flex={1}>
            <>
              <Text color={theme.colors.brand.gray[600]} fontSize="0.875rem">
                Company
              </Text>
              <Text color={theme.colors.brand.gray[900]} fontSize="1rem">
                {user.company_name ?? '-'}
              </Text>
            </>
          </Stack>
        )}
        <Stack spacing={0} flex={1}>
          <Text color={theme.colors.brand.gray[600]} fontSize="0.875rem">
            Invited on
          </Text>
          <Text color={theme.colors.brand.gray[900]} fontSize="1rem">
            {formatIsoDate(user.created)?.fullMonthNoDay ?? ''}
          </Text>
        </Stack>
      </Flex>
    </>
  );

  const errorContent = (
    <Text>
      An error occurred while loading accounts. Please try again later. If this issue persists, contact{' '}
      <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a> for assistance.
    </Text>
  );

  const bodyContent = (
    <Box>
      {/* TODO: move the Accordion into AccountAccordionItem and rename to just AccountAccordion */}
      <Accordion
        variant="userManagement"
        allowToggle
        defaultIndex={userAccounts.findIndex((account) => account.id === currentAccountId)}
      >
        {userAccounts.map((account) => (
          <AccountAccordionItemContainer
            key={account.id}
            account={account}
            user={user}
            onConfirmAccessChange={onClose}
          />
        ))}
      </Accordion>
    </Box>
  );

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      pendoTopic={PendoTopic.USER_MANAGEMENT}
      drawerType={DrawerType.USER_PROFILE}
      primaryText={isPending ? 'Pending user' : user.name}
      additionalHeaderContent={additionalHeaderContent}
      isFetching={isRegistered && loading?.sharedAccounts}
      isError={isRegistered && errors?.sharedAccounts}
      errorContent={errorContent}
      bodyContent={bodyContent}
    />
  );
};
