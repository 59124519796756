import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  Select,
  SimpleGrid,
  Textarea,
} from '@chakra-ui/react';
import { Select as MultiSelect } from 'chakra-react-select';
import { format, parseISO } from 'date-fns';
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import groupBy from 'lodash/groupBy';
import unionBy from 'lodash/unionBy';
import { ChangeEvent, ForwardedRef, forwardRef } from 'react';
import * as Yup from 'yup';

import { ContributingBehavior, ContributingCondition, Hazard, Trade } from '../../../@types/procore/v1/Company';
import {
  ObservationAssignee,
  ObservationCreateRequestBody,
  ObservationDefaultDistributionMember,
  ObservationPotentialDistributionMember,
  ObservationType,
} from '../../../@types/procore/v1/Observation';
import { Location } from '../../../@types/procore/v1/Project';
import AddAttachment from '../../../components/AddAttachment';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { ChevronDownIcon } from '../../../components/Icon';
import { ClearIndicator, Control, DropdownIndicator } from '../../../components/Select/ChakraReactSelectOverrides';
import theme from '../../../theme';

interface SingleSelectOption {
  label: string;
  value?: string;
}

interface SingleSelectOptionGroup {
  label: string;
  options: SingleSelectOption[];
}

interface MultiSelectOption {
  id: number;
  label: string;
  value: string;
}

export type ProcoreObservationFormValues = ObservationCreateRequestBody['observation'] & {
  /** An array of the attachments of the Observation Item. */
  attachments: File[];
};

export interface ProcoreObservationFormProps {
  /** Procore Assignee options to show in the form. */
  assignees?: ObservationAssignee[];
  /** Procore Contributing Behavior options to show in the form. */
  contributingBehaviors?: ContributingBehavior[];
  /** Procore Contributing Condition options to show in the form. */
  contributingConditions?: ContributingCondition[];
  /** Attachments to be used as the initial values in the form. */
  defaultAttachments: {
    /** File data. */
    file: File;
    /** Image URL used to preview the file contents. */
    image?: string;
  }[];
  /** Procore Distribution Members to pre-populate in the form. */
  defaultDistributionMembers?: ObservationDefaultDistributionMember[];
  /** Procore Hazard options to show in the form. */
  hazards?: Hazard[];
  /** Procore Location options to show in the form. */
  locations?: Location[];
  /** Procore Observation Type options to show in the form. */
  observationTypes?: ObservationType[];
  /** Handler to call when the user submits the form without validation errors. */
  onSubmit: (values: ProcoreObservationFormValues) => void;
  /** Procore Distribution Members options to show in the form. */
  potentialDistributionMembers?: ObservationPotentialDistributionMember[];
  /** Procore Trade options to show in the form. */
  trades?: Trade[];
}

const validationSchema = Yup.object({
  assignee_id: Yup.number().nullable(),
  attachments: Yup.array(Yup.mixed<File>()),
  contributing_behavior_id: Yup.number().nullable(),
  contributing_condition_id: Yup.number().nullable(),
  description: Yup.string().nullable(),
  distribution_member_ids: Yup.array(Yup.number()),
  due_date: Yup.string().nullable(),
  hazard_id: Yup.number().nullable(),
  location_id: Yup.number().nullable(),
  name: Yup.string().required('Title is required'),
  personal: Yup.boolean(),
  priority: Yup.string().oneOf(['Low', 'Medium', 'High', 'Urgent']).nullable(),
  status: Yup.string().oneOf(['initiated', 'ready_for_review', 'not_accepted', 'closed']).nullable(),
  trade_id: Yup.number().nullable(),
  type_id: Yup.number().required('Observation type is required'),
});

const ProcoreObservationForm = forwardRef(
  (props: ProcoreObservationFormProps, ref: ForwardedRef<FormikProps<ProcoreObservationFormValues>>) => {
    const {
      assignees = [],
      contributingBehaviors = [],
      contributingConditions = [],
      defaultAttachments,
      defaultDistributionMembers = [],
      hazards = [],
      locations = [],
      observationTypes = [],
      onSubmit,
      potentialDistributionMembers = [],
      trades = [],
    } = props;

    const distributionMembers = unionBy(defaultDistributionMembers, potentialDistributionMembers, 'id');
    /**
     * Initial values for the form. In order for the `name` and `type_id` fields to be detected on a submission of the
     * form without touching any fields, their initial values need to be set to something.
     */
    const initialValues: ProcoreObservationFormValues = {
      name: '',
      attachments: defaultAttachments.map((a) => a.file),
      distribution_member_ids: defaultDistributionMembers.map(({ id }) => id),
      description: `[This Observation was made with OnsiteIQ. Check it out here: ${window.location.origin}${window.location.pathname}]`,
      personal: false,
      type_id: undefined,
    };

    const emptyOption: SingleSelectOption = { label: '' };

    const observationTypeOptionGroups: SingleSelectOptionGroup[] = Object.entries(
      groupBy(observationTypes, 'category')
    ).map((entry) => ({
      label: entry[0],
      options: entry[1].map((observationType) => ({
        label: observationType.name,
        value: observationType.id.toString(),
      })),
    }));

    const tradeOptions: SingleSelectOption[] = [emptyOption].concat(
      trades.map((trade) => ({
        label: trade.name,
        value: trade.id.toString(),
      }))
    );

    const locationOptions: SingleSelectOption[] = [emptyOption].concat(
      locations.map((location) => ({
        label: location.name,
        value: location.id.toString(),
      }))
    );

    const hazardOptions: SingleSelectOption[] = [emptyOption].concat(
      hazards.map((hazard) => ({
        label: hazard.name,
        value: hazard.id.toString(),
      }))
    );

    const contributingConditionOptions: SingleSelectOption[] = [emptyOption].concat(
      contributingConditions.map((ccondition) => ({
        label: ccondition.name,
        value: ccondition.id.toString(),
      }))
    );

    const contributingBehaviorOptions: SingleSelectOption[] = [emptyOption].concat(
      contributingBehaviors.map((cbehavior) => ({
        label: cbehavior.name,
        value: cbehavior.id.toString(),
      }))
    );

    const assigneeOptions: SingleSelectOption[] = [emptyOption].concat(
      assignees.map((assignee) => ({
        label: assignee.vendor ? `${assignee.name} (${assignee.vendor.name})` : assignee.name,
        value: assignee.id.toString(),
      }))
    );

    const distributionMemberOptions: MultiSelectOption[] = distributionMembers.map((d) => ({
      id: d.id,
      label: `${d.name} (${d.login})`,
      value: d.id.toString(),
    }));

    const statusOptions: SingleSelectOption[] = [
      {
        label: '',
      },
      {
        label: 'Initiated',
        value: 'initiated',
      },
      {
        label: 'Ready for Review',
        value: 'ready_for_review',
      },
      {
        label: 'Not Accepted',
        value: 'not_accepted',
      },
      {
        label: 'Closed',
        value: 'closed',
      },
    ];

    const priorityOptions: SingleSelectOption[] = [
      {
        label: '',
      },
      {
        label: 'Low',
        value: 'Low',
      },
      {
        label: 'Medium',
        value: 'Medium',
      },
      {
        label: 'High',
        value: 'High',
      },
      {
        label: 'Urgent',
        value: 'Urgent',
      },
    ];

    const toId = (val?: string) => (val ? Number(val) : undefined);

    return (
      <Formik
        innerRef={ref}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur
        validationSchema={validationSchema}
      >
        <Form>
          <SimpleGrid columns={{ base: 1, md: 2 }} gap="1rem" paddingBlockEnd="1rem">
            <Field name="type_id">
              {({ field, form, meta }: FieldProps<number, ProcoreObservationFormValues>) => (
                <GridItem>
                  <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                    <FormLabel>Type</FormLabel>
                    <Select
                      {...field}
                      defaultValue={0}
                      onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                        form.setFieldValue(field.name, toId(event.target.value))
                      }
                    >
                      <option disabled value={0} />
                      {observationTypeOptionGroups.map(({ label, options }) => (
                        <optgroup key={`type-id-optgroup-${label}`} label={label}>
                          {options.map(({ label: optionLabel, value }) => (
                            <option key={`type-${value}`} value={value}>
                              {optionLabel}
                            </option>
                          ))}
                        </optgroup>
                      ))}
                    </Select>
                    <ErrorMessage component={FormErrorMessage} name={field.name} />
                  </FormControl>
                </GridItem>
              )}
            </Field>
            <Field name="status">
              {({ field, meta }: FieldProps<string, ProcoreObservationFormValues>) => (
                <GridItem>
                  <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                    <FormLabel>Status</FormLabel>
                    <Select {...field}>
                      {statusOptions.map(({ label, value }) => (
                        <option key={`status-option-${value}`} value={value}>
                          {label}
                        </option>
                      ))}
                    </Select>
                    <ErrorMessage component={FormErrorMessage} name={field.name} />
                  </FormControl>
                </GridItem>
              )}
            </Field>
            <Field name="name">
              {({ field, meta }: FieldProps<string, ProcoreObservationFormValues>) => (
                <GridItem colSpan={{ base: 1, md: 2 }}>
                  <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                    <FormLabel>Title</FormLabel>
                    <Input {...field} />
                    <ErrorMessage component={FormErrorMessage} name={field.name} />
                  </FormControl>
                </GridItem>
              )}
            </Field>
            <Field name="personal">
              {({ field, meta }: FieldProps<string, ProcoreObservationFormValues>) => (
                <GridItem colSpan={{ base: 1, md: 2 }}>
                  <Checkbox {...field} isInvalid={meta.touched && Boolean(meta.error)}>
                    Private
                  </Checkbox>
                  <ErrorMessage component={FormErrorMessage} name={field.name} />
                </GridItem>
              )}
            </Field>
            <Field name="description">
              {({ field, meta }: FieldProps<string, ProcoreObservationFormValues>) => (
                <GridItem colSpan={{ base: 1, md: 2 }}>
                  <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                    <FormLabel>Description</FormLabel>
                    <Textarea {...field} rows={6} />
                    <ErrorMessage component={FormErrorMessage} name={field.name} />
                  </FormControl>
                </GridItem>
              )}
            </Field>
            <Field name="attachments">
              {({ field, form, meta }: FieldProps<File[], ProcoreObservationFormValues>) => (
                <GridItem colSpan={{ base: 1, md: 2 }}>
                  <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                    <FormLabel>Attachments</FormLabel>
                    <AddAttachment
                      defaultValue={defaultAttachments}
                      {...field}
                      onChange={(attachments) => form.setFieldValue(field.name, attachments)}
                    />
                    <ErrorMessage component={FormErrorMessage} name={field.name} />
                  </FormControl>
                </GridItem>
              )}
            </Field>
          </SimpleGrid>
          <Accordion allowToggle reduceMotion>
            <AccordionItem>
              <AccordionButton fontSize="1rem" paddingInline="0.125rem">
                More Options
                <AccordionIcon as={ChevronDownIcon} color={theme.colors.brand.gray[600]} marginInlineStart="0.25rem" />
              </AccordionButton>
              <AccordionPanel paddingInline={0}>
                <SimpleGrid columns={{ base: 1, md: 2 }} gap="1rem" paddingBlockEnd="1rem">
                  <Field name="priority">
                    {({ field, meta }: FieldProps<string, ProcoreObservationFormValues>) => (
                      <GridItem>
                        <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                          <FormLabel>Priority</FormLabel>
                          <Select {...field}>
                            {priorityOptions.map(({ label, value }) => (
                              <option key={`priority-option-${value}`} value={value}>
                                {label}
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage component={FormErrorMessage} name={field.name} />
                        </FormControl>
                      </GridItem>
                    )}
                  </Field>
                  <Field name="trade_id">
                    {({ field, form, meta }: FieldProps<number, ProcoreObservationFormValues>) => (
                      <GridItem>
                        <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                          <FormLabel>Trade</FormLabel>
                          <Select
                            {...field}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                              form.setFieldValue(field.name, toId(event.target.value))
                            }
                          >
                            {tradeOptions.map(({ label, value }) => (
                              <option key={`trade-option-${value}`} value={value}>
                                {label}
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage component={FormErrorMessage} name={field.name} />
                        </FormControl>
                      </GridItem>
                    )}
                  </Field>
                  <Field name="location_id">
                    {({ field, form, meta }: FieldProps<number, ProcoreObservationFormValues>) => (
                      <GridItem>
                        <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                          <FormLabel>Location</FormLabel>
                          <Select
                            {...field}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                              form.setFieldValue(field.name, toId(event.target.value))
                            }
                          >
                            {locationOptions.map(({ label, value }) => (
                              <option key={`location-options-${value}`} value={value}>
                                {label}
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage component={FormErrorMessage} name={field.name} />
                        </FormControl>
                      </GridItem>
                    )}
                  </Field>
                  <Field name="hazard_id">
                    {({ field, form, meta }: FieldProps<number, ProcoreObservationFormValues>) => (
                      <GridItem>
                        <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                          <FormLabel>Hazard</FormLabel>
                          <Select
                            {...field}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                              form.setFieldValue(field.name, toId(event.target.value))
                            }
                          >
                            {hazardOptions.map(({ label, value }) => (
                              <option key={`hazard-option-${value}`} value={value}>
                                {label}
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage component={FormErrorMessage} name={field.name} />
                        </FormControl>
                      </GridItem>
                    )}
                  </Field>
                  <Field name="assignee_id">
                    {({ field, form, meta }: FieldProps<number, ProcoreObservationFormValues>) => (
                      <GridItem>
                        <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                          <FormLabel>Assignee</FormLabel>
                          <Select
                            {...field}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                              form.setFieldValue(field.name, toId(event.target.value))
                            }
                          >
                            {assigneeOptions.map(({ label, value }) => (
                              <option key={`assignee-option-${value}`} value={value}>
                                {label}
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage component={FormErrorMessage} name={field.name} />
                        </FormControl>
                      </GridItem>
                    )}
                  </Field>
                  <Field name="due_date">
                    {({ field, form, meta }: FieldProps<string, number>) => (
                      <GridItem>
                        <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                          <FormLabel>Due Date</FormLabel>
                          <DatePicker
                            {...field}
                            buttonProps={{
                              'aria-label': 'Due Date',
                              fontSize: '0.875rem',
                              justifyContent: 'flex-start',
                              minWidth: '100%',
                            }}
                            onChange={(date) =>
                              form.setFieldValue(field.name, date ? format(date, 'yyyy-MM-dd') : null)
                            }
                            popoverPlacement="top-end"
                            selected={field.value ? parseISO(field.value) : null}
                            value={field.value}
                          />
                          <ErrorMessage component={FormErrorMessage} name={field.name} />
                        </FormControl>
                      </GridItem>
                    )}
                  </Field>
                  <Field name="contributing_condition_id">
                    {({ field, form, meta }: FieldProps<number, ProcoreObservationFormValues>) => (
                      <GridItem>
                        <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                          <FormLabel>Contributing Condition</FormLabel>
                          <Select
                            {...field}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                              form.setFieldValue(field.name, toId(event.target.value))
                            }
                          >
                            {contributingConditionOptions.map(({ label, value }) => (
                              <option key={`ccondition-option-${value}`} value={value}>
                                {label}
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage component={FormErrorMessage} name={field.name} />
                        </FormControl>
                      </GridItem>
                    )}
                  </Field>
                  <Field name="contributing_behavior_id">
                    {({ field, form, meta }: FieldProps<number, ProcoreObservationFormValues>) => (
                      <GridItem>
                        <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                          <FormLabel>Contributing Behavior</FormLabel>
                          <Select
                            {...field}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                              form.setFieldValue(field.name, toId(event.target.value))
                            }
                          >
                            {contributingBehaviorOptions.map(({ label, value }) => (
                              <option key={`cbehavior-option-${value}`} value={value}>
                                {label}
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage component={FormErrorMessage} name={field.name} />
                        </FormControl>
                      </GridItem>
                    )}
                  </Field>
                  <Field name="distribution_member_ids">
                    {({ field, form, meta }: FieldProps<number[], ProcoreObservationFormValues>) => (
                      <GridItem colSpan={{ base: 1, md: 2 }}>
                        <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                          <FormLabel>Distribution</FormLabel>
                          <MultiSelect
                            {...field}
                            chakraStyles={{
                              option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused ? 'var(--primary-100)' : 'white',
                                color: 'var(--gray-800)',
                              }),
                            }}
                            classNamePrefix="chakra-react-select"
                            colorScheme="purple"
                            components={{ ClearIndicator, Control, DropdownIndicator }}
                            isMulti
                            menuPlacement="top"
                            onChange={(nextDistributionMembers) =>
                              form.setFieldValue(
                                field.name,
                                nextDistributionMembers.map(({ id }) => id)
                              )
                            }
                            options={distributionMemberOptions}
                            placeholder="Select distribution recipients"
                            selectedOptionStyle="color"
                            selectedOptionColor="purple"
                            useBasicStyles
                            value={distributionMemberOptions.filter(({ id }) => field.value.includes(Number(id)))}
                          />
                          <ErrorMessage component={FormErrorMessage} name={field.name} />
                        </FormControl>
                      </GridItem>
                    )}
                  </Field>
                </SimpleGrid>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Form>
      </Formik>
    );
  }
);

export default ProcoreObservationForm;
