import { AxiosRequestConfig } from 'axios';
import pick from 'lodash/pick';

import {
  Annotation,
  AnnotationCreateRequestBody,
  AnnotationUpdateRequestBody,
} from '../../../@types/api/v0/rest/Annotation';
import { httpDelete, patch, post } from '../../next';

export const AnnotationApi = {
  /**
   * Create an annotation instance.
   * @param requestBody Request payload to be sent to the server to create a new annotation.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the created annotation.
   */
  create: (requestBody: AnnotationCreateRequestBody, requestConfig?: AxiosRequestConfig) =>
    // Axios will automatically map the `requestBody` passed here as form data.
    post<AnnotationCreateRequestBody, Annotation>('/api/annotations/', requestBody, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...requestConfig,
    }),
  /**
   * Delete an annotation instance.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping an empty response.
   */
  delete: (annotation: Annotation, requestConfig?: AxiosRequestConfig) =>
    httpDelete(`api/annotations/${annotation.id}/`, requestConfig),
  /**
   * Update an annotation instance.
   * @param requestBody The `Annotation` instance (with changes already applied to it) to update on the server.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping an empty response.
   */
  update: (annotation: Annotation, requestConfig?: AxiosRequestConfig) => {
    const changes: AnnotationUpdateRequestBody = pick(annotation, 'content');
    return patch<AnnotationUpdateRequestBody, Annotation>(`api/annotations/${annotation.id}/`, changes, requestConfig);
  },
};
