import { Button, Text } from '@chakra-ui/react';

import { DetectionType } from '../../../@types/api/v0/rest/Detection';
import theme from '../../../theme';
import { countDisplay } from '../../../utils';

export interface SearchDetectionTypeListProps {
  /** List of search detection types for the current walkthrough. */
  detectionTypes: DetectionType[];
  /** Handler to call when the user selects a detection type. */
  onDetectionTypeSelect: (detectionType: DetectionType) => void;
  /** Current search text to filter detection types by. */
  searchText: string;
}

const SearchDetectionTypeList = (props: SearchDetectionTypeListProps) => {
  const { detectionTypes, onDetectionTypeSelect, searchText } = props;

  const filteredDetectionTypes = detectionTypes.filter((detectionType: DetectionType) => {
    const label = detectionType.label.toLocaleLowerCase();
    const input = searchText.toLocaleLowerCase();
    return label.includes(input);
  });

  if (filteredDetectionTypes.length === 0) {
    return (
      <Text color={theme.colors.brand.gray[600]} marginBlock="0.5rem" textStyle="detail">
        No results found.
      </Text>
    );
  }

  return (
    <>
      {filteredDetectionTypes.map((detectionType) => (
        <Button
          alignItems="flex-start"
          data-pendo-label="Select item"
          data-pendo-topic="search"
          color={theme.colors.brand.gray[600]}
          display="flex"
          flexDirection="column"
          height="fit-content"
          key={`search-drawer-detection-${detectionType.id}`}
          margin="0.125rem 0"
          onClick={() => onDetectionTypeSelect(detectionType)}
          padding="0.75rem 1rem"
          textAlign="left"
          variant="ghost"
          width="100%"
          _active={{
            backgroundColor: theme.colors.brand.gray[100],
            color: theme.colors.brand.gray[800],
          }}
          _first={{
            marginBlockStart: 0,
          }}
          _last={{
            marginBlockEnd: 0,
          }}
        >
          <Text
            as="span"
            color={theme.colors.brand.gray[800]}
            fontSize="1.125rem"
            fontWeight="bold"
            noOfLines={2}
            marginBlockEnd="0.5rem"
            textTransform="uppercase"
            whiteSpace="normal"
            width="100%"
          >
            {detectionType.label}
          </Text>
          <Text as="span" fontSize="0.875rem">
            {countDisplay(detectionType.detection_count, 'detection')}
          </Text>
        </Button>
      ))}
    </>
  );
};

export default SearchDetectionTypeList;
