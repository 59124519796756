import { Checkbox } from '@chakra-ui/react';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Button } from '../';
import { Store } from '../../@types/redux/store';
import { login } from '../../actions/auth';
import { notifySuccess } from '../../actions/notifications';
import api from '../../api';
import { transformErrorResponse } from '../../utils/error';

import classes from './Settings.module.scss';

interface FormValues {
  new_feature_announcement: boolean;
  new_walkthrough_notification: boolean;
}

const validationSchema = Yup.object({
  new_feature_announcement: Yup.boolean().required(),
  new_walkthrough_notification: Yup.boolean().required(),
});

function NotificationSettings(): ReactElement {
  const dispatch = useDispatch();
  const userDetails = useSelector((state: Store) => state.auth.user);

  const [submitError, setSubmitError] = useState<string | undefined>();

  async function handleSubmit(values: FormValues) {
    try {
      const updatedProfile = await api.auth.setProfile({ ...values, update_source: 'notification_settings' });
      dispatch(login(updatedProfile));
      dispatch(
        notifySuccess({
          title: 'Preferences updated!',
          content: 'Your notification preferences have been saved.',
        })
      );
    } catch (error) {
      console.error('[NotificationSettings] Failed to save notification preferences!', error);
      const { message } = await transformErrorResponse(
        error as Response,
        values,
        'Failed to save notification preferences. Please try again later.'
      );
      setSubmitError(message);
    }
  }

  return (
    <Formik
      initialValues={{
        new_feature_announcement: userDetails?.new_feature_announcement ?? false,
        new_walkthrough_notification: userDetails?.new_walkthrough_notification ?? false,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ handleChange, isSubmitting, values }) => (
        <Form className={classNames(classes.formVertical, classes.notificationForm)}>
          <p className={classes.instructions}>
            Help us improve your experience with OnsiteIQ through better communication. Please adjust your email
            preferences below.
          </p>
          <Checkbox
            isChecked={values.new_walkthrough_notification}
            name="new_walkthrough_notification"
            onChange={handleChange}
          >
            New walkthrough updates
          </Checkbox>
          <Checkbox isChecked={values.new_feature_announcement} name="new_feature_announcement" onChange={handleChange}>
            New feature announcements
          </Checkbox>
          <div className={classes.formFooter}>
            {!isSubmitting && submitError && <p className={classes.errorMessage}>{submitError}</p>}
            <Button isLoading={isSubmitting} type="submit" variant="highEmphasis">
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default NotificationSettings;
