import { BoxProps, Flex } from '@chakra-ui/react';
import noop from 'lodash/noop';
import { KeyboardEvent, ReactNode } from 'react';

import { PendoTopic } from '../../constants/analytics';
import theme from '../../theme';

interface TableCellProps {
  boxProps?: BoxProps;
  children: ReactNode;
  // TODO: look into making the pendo props depend on the presence of onClick.
  onClick?: () => void;
  pendoTopic?: PendoTopic;
  pendoLabel?: string;
  reducedPadding?: boolean;
}

export const TableCell = ({ boxProps, children, onClick, pendoTopic, pendoLabel, reducedPadding }: TableCellProps) => {
  const isClickable = onClick instanceof Function;

  const handleKeyEvent = (event: KeyboardEvent<HTMLDivElement>) => {
    if (isClickable && (event.key === ' ' || event.key === 'Enter')) {
      onClick();
    }
  };

  return (
    <Flex
      alignItems="center"
      cursor={isClickable ? 'pointer' : 'auto'}
      data-pendo-label={pendoLabel}
      data-pendo-topic={pendoTopic}
      height="100%"
      onClick={onClick}
      onKeyDown={isClickable ? handleKeyEvent : noop}
      padding={reducedPadding ? '.5rem' : ['.5rem .625rem !important', '.5rem .625rem !important', '1rem !important']}
      role={isClickable ? 'button' : undefined}
      tabIndex={isClickable ? 0 : undefined}
      _hover={{ backgroundColor: isClickable ? theme.colors.brand.gray[100] : 'transparent' }}
      {...boxProps}
    >
      {children}
    </Flex>
  );
};
