import { AxiosRequestConfig } from 'axios';

import {
  PortfolioProject,
  PortfolioProjectMilestoneProgress,
  PortfolioProjectProgressHistory,
} from '../../../@types/api/v1/bespoke/PortfolioDashboard';
import { get } from '../../next';

export const PortfolioDashboardApi = {
  /**
   * List all projects in the current user's Portfolio.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the list of portfolio projects.
   */
  listProjects: (requestConfig?: AxiosRequestConfig) =>
    get<PortfolioProject[]>(`api/v1/bespoke/portfolio/projects/`, requestConfig),
  /**
   * Get the progress history for a project by its ID.
   * @param projectId The ID of the project.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the progress history for a project.
   */
  getProgressHistory: (projectId: number, requestConfig?: AxiosRequestConfig) =>
    get<PortfolioProjectProgressHistory>(
      `api/v1/bespoke/portfolio/statistics/${projectId}/progress_history/`,
      requestConfig
    ),
  /**
   * Get the milestone progress for a project by its ID.
   * @param projectId The ID of the project.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the milestone progress (active, started, completed) for a project.
   */
  getMilestoneProgress: (projectId: number, requestConfig?: AxiosRequestConfig) =>
    get<PortfolioProjectMilestoneProgress>(
      `/api/v1/bespoke/portfolio/statistics/${projectId}/milestone_progress/`,
      requestConfig
    ),
};
