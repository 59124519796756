import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { FormikHelpers, FormikProps } from 'formik';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from '../';
import { Store } from '../../@types/redux/store';
import { login } from '../../actions/auth';
import api from '../../api';
import Routes from '../../routes';
import { transformErrorResponse } from '../../utils/error';
import UserProfileForm, { FormValues } from './UserProfileForm';
import { DEFAULT_MODAL_CLOSE_DELAY, ModalText } from './constants';

interface Props {
  closeDelay?: number;
}

const UserProfileCompletionModal = ({ closeDelay = DEFAULT_MODAL_CLOSE_DELAY }: Props): ReactElement => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state: Store) => state.auth.user);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string | undefined>();
  const [profileUpdated, setProfileUpdated] = useState<boolean>(false);

  const formRef = useRef<FormikProps<FormValues>>(null);

  const history = useHistory();
  const location = useLocation();

  const attemptSubmitForm = () => {
    formRef?.current?.submitForm();
  };

  const close = () => {
    setIsOpen(false);
  };

  const handleSubmit = async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
    setIsSubmitting(true);
    setSubmitError(undefined);
    try {
      const payload = {
        ...values,
        userpersona: {
          persona: Number(values.userpersona),
        },
        update_source: 'incomplete_profile_modal',
      };
      const updatedProfile = await api.auth.setProfile(payload);
      setProfileUpdated(true);
      dispatch(login(updatedProfile));
    } catch (error) {
      console.error('[UserProfileCompletionModal] Failed to set user account information!', error);
      const { message, fieldErrors } = await transformErrorResponse(
        error as Response,
        values,
        'Failed to update profile. Please try again later.'
      );
      setSubmitError(message);
      if (fieldErrors) {
        formikHelpers.setErrors(fieldErrors);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    // Don't show the modal to unauthenticated users.
    if (!currentUser) {
      return;
    }

    // Don't show the modal when the user has been prompted to set a new password.
    if (location.pathname === Routes.SET_PASSWORD) {
      return;
    }

    // Only show if the user profile is already complete.
    if (!currentUser.profile_complete && !isOpen) {
      setIsOpen(true);
    }
  }, [currentUser, location.pathname, isOpen]);

  useEffect(() => {
    // Only continue if the user completed their profile via this workflow.
    if (!currentUser?.profile_complete && !profileUpdated) {
      return;
    }

    const closeTimeout = setTimeout(close, closeDelay);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(closeTimeout);
    };
  }, [closeDelay, currentUser, profileUpdated]);

  useEffect(() => {
    // When open/visible, add "profileModalOpen" to the URL query parameters. Remove when the modal closes.
    const queryParams = new URLSearchParams(location.search);
    if (isOpen && !queryParams.has('profileModalOpen')) {
      queryParams.append('profileModalOpen', 'Y');
      history.replace({ search: queryParams.toString() });
    }
    if (!isOpen && queryParams.has('profileModalOpen')) {
      queryParams.delete('profileModalOpen');
      history.replace({ search: queryParams.toString() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isCentered
      isOpen={isOpen}
      onClose={() => null}
      scrollBehavior="inside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{!profileUpdated ? ModalText.STEP_1_TITLE : ModalText.STEP_2_TITLE}</ModalHeader>
        <ModalBody>
          <p>{!profileUpdated ? ModalText.STEP_1_DESCRIPTION : ModalText.STEP_2_DESCRIPTION}</p>
          {!profileUpdated && (
            <UserProfileForm
              fields={['first_name', 'last_name', 'userpersona', 'title', 'company_name', 'phone']}
              nonFieldErrors={!isSubmitting && submitError ? [submitError] : []}
              onSubmit={handleSubmit}
              ref={formRef}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            data-pendo-label={profileUpdated ? 'Close' : 'Save'}
            data-pendo-topic="Profile modal"
            isLoading={isSubmitting}
            onClick={profileUpdated ? close : attemptSubmitForm}
            variant={profileUpdated ? 'mediumEmphasis' : 'highEmphasis'}
          >
            {profileUpdated ? 'Close' : 'Save'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UserProfileCompletionModal;
