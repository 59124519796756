import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from '@chakra-ui/react';
import classNames from 'classnames';
import { ForwardedRef, ReactElement, forwardRef } from 'react';

import classes from './Button.module.scss';

export interface ButtonProps extends ChakraButtonProps {
  /** URL that the user will navigate to on click. The button must also be rendered as an anchor via `as="a"`. */
  href?: string;
  variant?:
    | 'primary'
    | 'highEmphasis'
    | 'mediumEmphasis'
    | 'lowEmphasis'
    | 'ghost'
    | 'outlined'
    | 'view360'
    | 'list'
    | 'default';
}

const Button = forwardRef(
  (props: ButtonProps = { variant: 'default' }, ref: ForwardedRef<HTMLButtonElement>): ReactElement => {
    return (
      <ChakraButton
        {...props}
        className={classNames(
          {
            [classes.primary]: props.variant === 'primary',
            [classes.highEmphasis]: props.variant === 'highEmphasis',
            [classes.mediumEmphasis]: props.variant === 'mediumEmphasis',
            [classes.lowEmphasis]: props.variant === 'lowEmphasis',
            [classes.ghost]: props.variant === 'ghost',
            [classes.outlined]: props.variant === 'outlined',
            [classes.view360]: props.variant === 'view360',
          },
          props.className
        )}
        ref={ref}
      >
        {props.children}
      </ChakraButton>
    );
  }
);

export default Button;
