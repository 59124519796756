import { ActionSaveFromLocation, ActionSetActiveDrawerItem } from '../@types/redux/appActions';
import { SAVE_FROM_LOCATION, SET_ACTIVE_DRAWER_ITEM } from '../constants/actionTypes';

export const saveFromLocation = (fromLocation?: string): ActionSaveFromLocation => ({
  type: SAVE_FROM_LOCATION,
  fromLocation,
});

export const setActiveDrawerItem = (activeDrawerItem?: number | string): ActionSetActiveDrawerItem => ({
  type: SET_ACTIVE_DRAWER_ITEM,
  activeDrawerItem,
});
