import { Matrix3, Vector2 } from 'three';

import { Annotation } from '../../@types/api/v0/rest/Annotation';
import { Floorplan } from '../../@types/api/v0/rest/Floorplan';
import { Node } from '../../@types/api/v0/rest/Node';
import { GeometryConstants } from './constants';

const CULL_MIN_SEPARATION_SQUARED = Math.pow(GeometryConstants.GROUND_NODE_CULL_MIN_SEPARATION_DISTANCE, 2);

/**
 * Given a set of ground nodes, the current walkthrough node, its index, the walkthrough's annotations/markups, and the
 * walkthrough's floorplan (used for scaling geometry), retrieve a list of ground nodes which should be rendered into
 * the scene. Begin by considering some number of nodes (GROUND_NODE_MAX_INDEX_DISTANCE) adjacent to the current.
 * Keeping all nodes that have at least one annotation, cull any which are not separated by at least some configurable
 * minimum distance (GROUND_NODE_CULL_MIN_SEPARATION_DISTANCE) from each other or the current ground node. Finally,
 * remove the current ground node, as it should not be rendered.
 */
export function cullGroundNodes(
  nodes: Node[],
  currentNode: Node,
  currentNodeIndex: number,
  annotations: Annotation[],
  floorplan: Floorplan
) {
  const worldScale = new Matrix3().makeScale(
    floorplan.image_width * floorplan.scale_factor,
    floorplan.image_height * floorplan.scale_factor
  );
  const nodePosition = new Vector2();
  const nearbyNodePosition = new Vector2();

  // Add the current node to the result list: ensures that nodes too close to the current are culled.
  const resultNodes = [currentNode];

  // Loop over the nodes before and after the current along the walkthrough path.
  const minIndex = Math.max(currentNodeIndex - GeometryConstants.GROUND_NODE_MAX_INDEX_DISTANCE, 0);
  const maxIndex = Math.min(currentNodeIndex + GeometryConstants.GROUND_NODE_MAX_INDEX_DISTANCE, nodes.length - 1);
  for (let i = minIndex; i <= maxIndex; i++) {
    const node = nodes[i];

    // Ensure the current node isn't added twice.
    if (node.id === currentNode.id) {
      continue;
    }

    nodePosition.set(node.xpercent, node.ypercent).applyMatrix3(worldScale);

    // If the node is annotated, it should have priority over other non-annotated nodes.
    const isAnnotated = annotations.some((annotation) => annotation.node === node.id);
    if (isAnnotated) {
      resultNodes.push(node);
      continue;
    }

    // Cull nodes that are too close to any already in the result list.
    let tooClose = false;
    for (const nearbyNode of resultNodes) {
      nearbyNodePosition.set(nearbyNode.xpercent, nearbyNode.ypercent).applyMatrix3(worldScale);

      if (nodePosition.distanceToSquared(nearbyNodePosition) <= CULL_MIN_SEPARATION_SQUARED) {
        tooClose = true;
        break;
      }
    }
    if (tooClose) {
      continue;
    }

    resultNodes.push(node);
  }

  // Return all result nodes except the current (first to be added).
  return resultNodes.slice(1);
}
