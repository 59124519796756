import { Flex, Progress, VisuallyHidden } from '@chakra-ui/react';

import Chip from '../../components/Chip';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import theme from '../../theme';
import { getRoundedProgressValue } from '../../utils/progressUtils';

export interface ProgressCellProps {
  /**
   * Flag indicating whether or not this cell has momentum. A value of `true` will be interpreted as the cell having
   * momentum, `false` as possible risk indicators having been identified, and `undefined` as indeterminate.
   */
  hasMomentum?: boolean | null;
  /** Flag indicating whether or not this cell is within the summary row. */
  isSummaryCell?: boolean;
  /**
   * Progress value for this cell. May be `undefined`, for example when no walkthroughs have been processed for this
   * cell's corresponding floorplan yet.
   */
  value?: number;
}

export const ProgressCell = (props: ProgressCellProps) => {
  const { hasMomentum, isSummaryCell, value } = props;

  // Summary cell cases:
  // -  When summary cell has no value, render a dash. For this test case, load a project with PT disabled. No tooltip.
  // -  When summary cell is not complete, render its value and a progress bar. No tooltip.
  // -  When summary cell is complete, render a "Completed" chip. No tooltip.
  if (isSummaryCell && (value === undefined || !Number.isFinite(value))) {
    return (
      <Flex
        color={theme.colors.brand.gray[300]}
        flexDirection="column"
        height="100%"
        justifyContent="center"
        textStyle="detail"
        width="100%"
      >
        {'—'}
      </Flex>
    );
  }

  // The `value !== undefined` check is here mainly to satisfy TS.
  if (isSummaryCell && value !== undefined && Number.isFinite(value)) {
    return (
      <Flex
        color={theme.colors.brand.gray[800]}
        flexDirection="column"
        height="100%"
        justifyContent="center"
        textStyle="detail"
        width="100%"
      >
        {value >= 100 ? (
          <Chip boxProps={{ width: 'fit-content' }} variant="secondary">
            Completed
          </Chip>
        ) : (
          <>
            {`${getRoundedProgressValue(value)}%`}
            <Progress aria-hidden value={value} marginTop="0.25rem" />
          </>
        )}
      </Flex>
    );
  }

  // "No data available" cell cases:
  // -  When a non-summary cell has no value, render a dash. For this test case, either inspect a cell for a milestone
  //    that isn't applicable to the floorplan, or find a project where one of the floorplans has applicability and
  //    weights set but has not been walked yet.
  if (value === undefined) {
    return (
      <Tooltip label="No data available" openDelay={1000}>
        <Flex
          color={theme.colors.brand.gray[300]}
          flexDirection="column"
          height="100%"
          justifyContent="center"
          textStyle="detail"
          width="100%"
        >
          {'—'}
        </Flex>
      </Tooltip>
    );
  }

  // Regular data cell cases:
  // -  When summary cell is not complete, render its value and a progress bar. No tooltip.
  // -  When summary cell is complete, render a "Completed" chip. No tooltip.
  return (
    <Flex flexDirection="column" height="100%" justifyContent="center" textStyle="detail" width="100%">
      {value >= 100 ? (
        <Chip boxProps={{ width: 'fit-content' }} variant="secondary">
          Completed
        </Chip>
      ) : (
        <>
          {`${getRoundedProgressValue(value)}%`}
          {hasMomentum === true && <VisuallyHidden>Positive momentum</VisuallyHidden>}
          {hasMomentum === false && <VisuallyHidden>Delay warning</VisuallyHidden>}
          <Progress aria-hidden value={value} marginTop="0.25rem" />
        </>
      )}
    </Flex>
  );
};
