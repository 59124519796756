import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { saveFromLocation } from '../actions/app';

export class AuthenticatedRoute extends Component {
  handleRedirect = (location) => {
    const { fromLocation, saveFromLocation } = this.props;
    if (!fromLocation) {
      saveFromLocation(location);
    }
    return true;
  };

  render() {
    const { component: Component, isAuthenticated, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            this.handleRedirect(props.location.pathname) && (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: props.location },
                }}
              />
            )
          )
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.isAuthenticated ? state.auth.user : null,
  fromLocation: state.app.fromLocation,
});

const mapDispatchToProps = (dispatch) => ({
  saveFromLocation: (fromLocation) => dispatch(saveFromLocation(fromLocation)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoute);
