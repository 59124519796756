export enum HTTP_METHOD {
  GET = 'GET',
  HEAD = 'HEAD',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  CONNECT = 'CONNECT',
  OPTIONS = 'OPTIONS',
  TRACE = 'TRACE',
  PATCH = 'PATCH',
}

export enum HTTP_CREDENTIALS {
  SAME_ORIGIN = 'same-origin',
  INCLUDE = 'include',
  OMIT = 'omit',
}

export enum HTTP_MODE {
  SAME_ORIGIN = 'same-origin',
  CORS = 'cors',
  NAVIGATE = 'navigate',
  NO_CORS = 'no-cors',
}
