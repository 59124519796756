import { Popover, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import classNames from 'classnames';
import { MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, generatePath } from 'react-router-dom';

import { Store } from '../../@types/redux/store';
import { logout } from '../../actions/auth';
import Routes from '../../routes';
import { remToPixels } from '../../utils/device';
import { getInitials } from '../../utils/user';

import classes from './AccountDropdown.module.scss';

interface AccountDropdownProps {
  triggerClassName?: string;
}

const popoverGutter = remToPixels('--gutter-popover') ?? 24;

const AccountDropdown = ({ triggerClassName }: AccountDropdownProps) => {
  const dispatch = useDispatch();
  const user = useSelector((state: Store) => state.auth.user);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function handleLogout(event: MouseEvent) {
    event.preventDefault();

    dispatch(logout());
    setIsMenuOpen(false);
  }

  function toggleMenu() {
    setIsMenuOpen((val) => !val);
    window.Intercom?.('hide');
  }

  return (
    <Popover offset={[0, popoverGutter]} placement="right-start">
      <PopoverTrigger>
        <button
          aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
          className={classNames(isMenuOpen && classes.menuOpen, triggerClassName)}
          data-pendo-label={isMenuOpen ? 'Close menu' : 'Open menu'}
          data-pendo-topic="sidebar"
          onClick={toggleMenu}
          type="button"
        >
          <span aria-hidden>{user && getInitials(user)}</span>
        </button>
      </PopoverTrigger>
      <PopoverContent className={classes.popoverContent}>
        <PopoverBody className={classes.popoverBody}>
          <Link
            data-pendo-topic="user-management"
            data-pendo-label="View account users"
            // Navigate to the "Users" page, but with no particular account ID set. The container should automatically
            // choose the first account.
            to={generatePath(Routes.ACCOUNT_USERS, { id: undefined })}
          >
            Users
          </Link>
          <Link to="/settings">Settings</Link>
          <a href="/" data-pendo-label="Log out" data-pendo-topic="auth" onClick={handleLogout}>
            Log out
          </a>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default AccountDropdown;
