import { Box, Flex, Icon, IconButton, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { Reply as AnnotationReply } from '../../../@types/api/v0/rest/Reply';
import theme from '../../../theme';
import { DeleteIcon, EditIcon } from '../../Icon';
import ContentBox from './ContentBox';
import DeleteConfirmation from './DeleteConfirmation';

export interface ReplyProps {
  /** Flag indicating whether or not the annotation is read-only. */
  isReadOnly: boolean;
  /** Flag indicating whether or not a change to an annotation or reply is being submitted. */
  isSubmitting?: boolean;
  /** Handler called when the user deletes a reply. */
  onReplyDelete: (reply: AnnotationReply) => void;
  /** Handler called when the user updates a reply. */
  onReplyUpdate: (reply: AnnotationReply) => void;
  /** The current reply. */
  reply: AnnotationReply;
}

const Reply = (props: ReplyProps) => {
  const { isReadOnly, isSubmitting, onReplyDelete, onReplyUpdate, reply } = props;

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [replyContent, setReplyContent] = useState<string>(reply.content);

  const toggleIsDeleting = () => {
    setIsDeleting((currentIsDeleting) => !currentIsDeleting);
  };

  const toggleIsEditing = () => {
    setIsEditing((currentIsEditing) => !currentIsEditing);
    setReplyContent(reply.content);
  };

  const updateReply = () => {
    const updatedReply: AnnotationReply = { ...reply, content: replyContent };
    onReplyUpdate(updatedReply);
    setIsEditing(false);

    // Temporarily restore the reply content. Will be updated once the parent finishes its network operations.
    setReplyContent(reply.content);
  };

  const deleteReply = () => {
    onReplyDelete(reply);
    setIsDeleting(false);
  };

  // When the reply changes (e.g. when the parent finishes refreshing the list of annotations/replies), update the text
  // in state.
  useEffect(() => {
    setReplyContent(reply.content);
  }, [reply.content]);

  return (
    <>
      <Flex paddingBlockStart="0.75rem">
        <Flex aria-hidden flex={0} flexDirection="column" marginInlineEnd="0.5rem">
          <Box
            backgroundColor={theme.colors.brand.primary[100]}
            borderRadius="100%"
            height="2rem"
            lineHeight="2rem"
            textAlign="center"
            width="2rem"
          >
            {reply.poster.name[0]}
          </Box>
          <Box backgroundColor={theme.colors.brand.gray[200]} margin="0.5rem auto" height="100%" width="1px" />
        </Flex>
        <Flex flex={1} flexDirection="column" width="100%">
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Text
              as="div"
              fontWeight="bold"
              noOfLines={1}
              textStyle="bodySmall"
              title={`${reply.poster.first_name} ${reply.poster.last_name}`}
            >
              {reply.poster.first_name} {reply.poster.last_name}
            </Text>
            {!isReadOnly && (
              <Flex>
                <IconButton
                  aria-label="Edit"
                  color={theme.colors.brand.gray[800]}
                  height="auto"
                  icon={<Icon as={EditIcon} height="1.25rem" width="1.25rem" />}
                  isDisabled={isEditing || isSubmitting}
                  minHeight="unset"
                  minWidth="unset"
                  onClick={toggleIsEditing}
                  padding="0.375rem"
                  size="xs"
                  variant="ghost"
                />
                <IconButton
                  aria-label="Delete"
                  color={theme.colors.brand.error[500]}
                  height="auto"
                  icon={<Icon as={DeleteIcon} height="1.25rem" width="1.25rem" />}
                  isDisabled={isEditing || isSubmitting}
                  minHeight="unset"
                  minWidth="unset"
                  onClick={toggleIsDeleting}
                  padding="0.375rem"
                  size="xs"
                  variant="ghost"
                />
              </Flex>
            )}
          </Flex>
          <ContentBox
            content={replyContent}
            isEditing={isEditing}
            isSubmitting={isSubmitting}
            onCancelEdit={toggleIsEditing}
            onChange={setReplyContent}
            onSave={updateReply}
            textAreaLabel="Reply"
          />
        </Flex>
      </Flex>
      {isDeleting && (
        <DeleteConfirmation onClose={toggleIsDeleting} onConfirm={deleteReply} title="Delete Reply">
          <Text paddingBlockEnd="0.5rem">Are you sure you want to delete this reply?</Text>
        </DeleteConfirmation>
      )}
    </>
  );
};

export default Reply;
