import { Box, Button, Flex, Text } from '@chakra-ui/react';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import { Annotation } from '../../@types/api/v0/rest/Annotation';
import { Floorplan } from '../../@types/api/v0/rest/Floorplan';
import { Node } from '../../@types/api/v0/rest/Node';
import { Walkthrough } from '../../@types/api/v0/rest/Walkthrough';
import theme from '../../theme';
import { ErrorIcon } from '../Icon';
import LoadingIndicator from '../LoadingIndicator';
import Renderer, { RendererInstance, SceneParams } from './Renderer';
import TextureManager from './TextureManager';
import { TimingConstants, ViewerEvents, ViewerUpdateSource } from './constants';

import commonClasses from '../../Common.module.scss';
import classes from './PanographViewer.module.scss';

export interface PanographViewerProps {
  annotations: Annotation[];
  floorplan: Floorplan;
  /**
   * Flag indicating whether or not the 360 viewer's scene is loading. This value may be `true` if either required data
   * or imagery is being loaded. For example, walkthrough/annotation data is loaded when the user time travels. Loading
   * events within the viewer itself may also trigger this flag to be `true`, for example when an image is loaded due to
   * the user clicking on a ground node.
   */
  isLoadingScene: boolean;
  lookTarget?: { x: number; y: number; z: number };
  node?: Node;
  onAnnotationView: (annotation: Annotation) => void;
  onSnapshotAvailable: (dataUrl: string) => void;
  setAnnotationTarget: (nextAnnotationTarget?: { x: number; y: number; z: number }) => void;
  /** Handler to call when the viewer starts or finishes loading imagery to render. */
  setIsLoadingSceneImage: (isLoadingSceneImage: boolean) => void;
  setLookTarget: (nextLookTarget?: { x: number; y: number; z: number }) => void;
  setMapDirection: (nextMapDirection: { x: number; y: number }) => void;
  setNode: (node: Node) => void;
  walkthrough?: Walkthrough;
  zoomFieldOfView: number;
  zoomIn: (increment: number) => void;
  zoomOut: (increment: number) => void;
}

export interface PanographViewerInstance {
  clearBrightness: () => void;
  focus: () => void;
  requestSnapshot: () => void;
  setBrightness: (level: number) => void;
  setMarking: (marking: boolean) => void;
}

const PanographViewer = forwardRef(
  (props: PanographViewerProps, forwardedRef: ForwardedRef<PanographViewerInstance>) => {
    const {
      annotations,
      floorplan,
      isLoadingScene,
      lookTarget,
      node,
      onAnnotationView,
      onSnapshotAvailable,
      setAnnotationTarget,
      setIsLoadingSceneImage,
      setLookTarget,
      setMapDirection,
      setNode,
      walkthrough,
      zoomFieldOfView,
      zoomIn,
      zoomOut,
    } = props;

    const history = useHistory();

    const [errorMessage, setErrorMessage] = useState<string>();
    const [renderer, setRenderer] = useState<RendererInstance>();
    const [sceneParams, setSceneParams] = useState<SceneParams>();
    // Flag indicating whether or not arrow key navigation is active. When scene data or imagery is being loaded, we
    // should stack a loading indicator on top of the viewer except when performing arrow key nav.
    const [isArrowKeyNavActive, setIsArrowKeyNavActive] = useState<boolean>(false);
    // Whether or not the loader is visible. Most often visible during walkthrough load during time travel or image
    // loads initiated by ground node clicks (on throttled connections).
    const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(true);

    const canvasRef = useRef<HTMLCanvasElement>(null);

    const setMapDirectionThrottled = useMemo(
      () =>
        throttle(
          (mapDirection: { x: number; y: number }) => setMapDirection(mapDirection),
          TimingConstants.RATE_LIMIT_MAP_DIRECTION_CHANGE_EVENT,
          { trailing: true }
        ),
      [setMapDirection]
    );

    const onChangeCameraDirection = useCallback(
      (mapDirection: { x: number; y: number }) => setMapDirectionThrottled(mapDirection),
      [setMapDirectionThrottled]
    );

    const onError = useCallback(() => {
      setErrorMessage('Failed to load walkthrough image. Please try again later.');
      renderer?.stopRendering();
    }, [renderer]);

    const onLookTransitionComplete = useCallback(() => setLookTarget(undefined), [setLookTarget]);

    const onMarkPoint = useCallback(
      (point: { x: number; y: number; z: number }) => {
        setAnnotationTarget(point);
        setLookTarget(point);
      },
      [setAnnotationTarget, setLookTarget]
    );

    const onSceneUpdateComplete = useCallback(() => {
      setIsLoadingSceneImage(false);
      setIsArrowKeyNavActive(false);
    }, [setIsLoadingSceneImage]);

    const onSceneUpdateStart = useCallback(
      (updateSource: ViewerUpdateSource) => {
        setIsLoadingSceneImage(true);

        // If the scene update was triggered by keyboard navigation, set this flag so that the loader is not stacked on
        // top of the viewer.
        setIsArrowKeyNavActive(updateSource === ViewerUpdateSource.KEYBOARD_NAVIGATION);
      },
      [setIsLoadingSceneImage]
    );

    const onZoom = useCallback(
      (fieldOfViewDiff: number) => {
        const handler = fieldOfViewDiff < 0 ? zoomIn : zoomOut;
        handler(Math.abs(fieldOfViewDiff));
      },
      [zoomIn, zoomOut]
    );

    const reload = () => {
      history.go(0);
    };

    useEffect(() => {
      if (!canvasRef.current) {
        return undefined;
      }

      const webGLContext = canvasRef.current.getContext('webgl') ?? canvasRef.current.getContext('experimental-webgl');
      const webGLSupported = webGLContext?.constructor?.name === 'WebGLRenderingContext';
      if (!webGLSupported) {
        setErrorMessage(
          'Failed to load 360º viewer. Your device does not support WebGL. Please enable WebGL if it is disabled, or try again with a different browser.'
        );
        return undefined;
      }

      const textureManager = new TextureManager();
      const rendererInstance = new Renderer({ canvas: canvasRef.current, textureManager });

      (async () => {
        try {
          await rendererInstance.initialize();
          setRenderer(rendererInstance);
        } catch (error) {
          console.error('[PanographViewer] Failed to initialize scene', error);
          setErrorMessage(
            'Failed to load 360º viewer. Please try again later or reach out to our Customer Success team at customersuccess@onsiteiq.io.'
          );
        }
      })();

      return () => {
        if (rendererInstance) {
          rendererInstance.dispose();
          console.debug('[PanographViewer] Renderer disposed.');
        }
      };
    }, []);

    useEffect(() => {
      if (!renderer) {
        return;
      }

      const handlers = {
        [ViewerEvents.ON_CHANGE_CAMERA_DIRECTION]: onChangeCameraDirection,
        [ViewerEvents.ON_CHANGE_NODE]: setNode,
        [ViewerEvents.ON_CLICK_ANNOTATION]: onAnnotationView,
        [ViewerEvents.ON_ERROR]: onError,
        [ViewerEvents.ON_LOOK_TRANSITION_COMPLETE]: onLookTransitionComplete,
        [ViewerEvents.ON_MARK_POINT]: onMarkPoint,
        [ViewerEvents.ON_SCENE_UPDATE_COMPLETE]: onSceneUpdateComplete,
        [ViewerEvents.ON_SCENE_UPDATE_START]: onSceneUpdateStart,
        [ViewerEvents.ON_SNAPSHOT_AVAILABLE]: onSnapshotAvailable,
        [ViewerEvents.ON_ZOOM]: onZoom,
      };
      for (const [eventName, eventHandler] of Object.entries(handlers)) {
        renderer.setHandler(eventName, eventHandler);
      }
    }, [
      onChangeCameraDirection,
      onAnnotationView,
      onError,
      onLookTransitionComplete,
      onMarkPoint,
      onSceneUpdateComplete,
      onSceneUpdateStart,
      onSnapshotAvailable,
      onZoom,
      renderer,
      setNode,
    ]);

    useEffect(() => {
      if (!renderer || !walkthrough || !node) {
        return;
      }

      // If all data exactly matches the current render parameters, the scene does not need to change.
      if (
        floorplan.id === sceneParams?.floorplan?.id &&
        walkthrough.id === sceneParams?.walkthrough?.id &&
        node.id === sceneParams?.node?.id &&
        annotations.length === sceneParams?.annotations?.length
      ) {
        return;
      }

      // If object ownership is not correct, do not change the scene yet. Loaders/state changes are pending.
      if (walkthrough.floorplan !== floorplan.id || node.walkthrough !== walkthrough.id) {
        return;
      }

      const nextSceneParams = { annotations, floorplan, node, walkthrough };
      setSceneParams(nextSceneParams);

      (async () => {
        if (!sceneParams || sceneParams?.walkthrough?.id !== nextSceneParams.walkthrough.id) {
          console.debug('[PanographViewer] Loading walkthrough scene...', nextSceneParams);
          await renderer.loadWalkthroughScene(nextSceneParams);
          renderer.startRendering();
        } else if (sceneParams.node?.id !== nextSceneParams.node.id) {
          console.debug('[PanographViewer] Changing node...', nextSceneParams);
          await renderer.moveToNode(nextSceneParams.node, nextSceneParams.annotations);
        } else if (sceneParams.annotations.length !== nextSceneParams.annotations.length) {
          console.debug('[PanographViewer] Updating annotations...', nextSceneParams);
          renderer.updateAnnotations(nextSceneParams.annotations);
        }
      })();
    }, [annotations, floorplan, sceneParams, renderer, walkthrough, node]);

    useEffect(() => {
      if (lookTarget) {
        renderer?.lookAt(lookTarget.x, lookTarget.y, lookTarget.z);
      }
    }, [lookTarget, renderer]);

    useEffect(() => {
      renderer?.setFieldOfView(zoomFieldOfView);
    }, [renderer, zoomFieldOfView]);

    // Show the loader if either scene data or imagery is loading, except during arrow key navigation.
    useEffect(() => {
      if (isArrowKeyNavActive || !isLoadingScene) {
        setIsLoaderVisible(false);
        return undefined;
      }

      const timer = setTimeout(() => {
        setIsLoaderVisible(true);
      }, TimingConstants.IDLE_WAIT_DURATION);
      return () => clearTimeout(timer);
    }, [isArrowKeyNavActive, isLoadingScene]);

    // If the loader is visible, make the viewer slightly transparent.
    useEffect(() => {
      if (!canvasRef.current) {
        return;
      }

      canvasRef.current.style.opacity = isLoaderVisible ? '0.5' : '1';
    }, [isLoaderVisible]);

    useImperativeHandle(
      forwardedRef,
      () => ({
        clearBrightness: () => {
          if (!canvasRef.current) {
            throw new Error('Cannot clear brightness: missing canvas');
          }
          canvasRef.current.style.filter = '';
        },
        focus: () => {
          if (!canvasRef.current) {
            throw new Error('Cannot focus: missing canvas');
          }
          canvasRef.current.focus();
        },
        requestSnapshot: () => {
          renderer?.requestSnapshot();
        },
        setBrightness: (level: number) => {
          if (!canvasRef.current) {
            throw new Error('Cannot set brightness: missing canvas');
          }
          canvasRef.current.style.filter = `brightness(${level})`;
        },
        setMarking: (marking: boolean) => {
          if (!canvasRef.current) {
            throw new Error('Cannot set marking status: missing canvas');
          }

          renderer?.setMarking(marking);
          if (marking) {
            canvasRef.current.classList.add(classes.cursorCrosshair);
          } else {
            canvasRef.current.classList.remove(classes.cursorCrosshair);
          }
        },
      }),
      [canvasRef, renderer]
    );

    return (
      <Box className={classes.viewerContainer}>
        {!errorMessage && isLoaderVisible && <LoadingIndicator className={classes.loadingIndicator} fullPage />}
        {errorMessage && (
          <Flex alignItems="center" height="100%" justifyContent="center" flexDir="column">
            <ErrorIcon aria-hidden className={commonClasses.errorIcon} />
            <Text color={theme.colors.brand.gray[800]} marginBottom="1rem" textAlign="center">
              {errorMessage}
            </Text>
            <Button onClick={reload} size="md" variant="mediumEmphasisV2">
              Try again
            </Button>
          </Flex>
        )}
        <canvas
          className={classNames(classes.canvas, { [classes.hidden]: errorMessage })}
          ref={canvasRef}
          tabIndex={0}
        />
      </Box>
    );
  }
);

export default PanographViewer;
