/** Maximum number of projects to which users may be granted access at once via the "Invite Users" modal. */
export const INVITE_LIMIT_PROJECTS = 200;

/** Maximum number of users invitable at once via the "Invite Users" modal. */
export const INVITE_LIMIT_USERS = 200;

export interface FormValues {
  account?: number;
  to?: string[];
  projects?: string[];
  accessAllProjects?: boolean;
  inviteOthers?: string;
}
