import { Project } from '../../@types/api/v0/rest/Project';
import {
  NestedProjectHierarchyItem,
  ProjectHierarchyItem,
  TreeElement,
} from '../../@types/api/v1/bespoke/ProjectHierarchy';

export const FLOORPLAN_ID_PREFIX = 'floorplan-';

/**
 * Given the flat list of hierarchy items provided by the API, construct and return them as a nested tree. Note that we
 * transform the ID from the number PK to `hierarchy-{id}`. We were worried about IDs colliding between hierarchy items
 * and floorplans.
 */
export const nestHierarchyItems = (hierarchyItems: ProjectHierarchyItem[]): NestedProjectHierarchyItem[] => {
  if (!hierarchyItems) {
    return [];
  }

  const tree: NestedProjectHierarchyItem[] = [];
  const childOf: Record<string, NestedProjectHierarchyItem[]> = {};

  for (const hierarchyItem of hierarchyItems) {
    const { id, parent_id: parentId, floorplans, name, order } = hierarchyItem;

    childOf[`hierarchy-${id}`] = childOf[`hierarchy-${id}`] ?? [];

    const newTreeElement: NestedProjectHierarchyItem = {
      id: `hierarchy-${id}`,
      name,
      order,
      floorplans,
      children: childOf[`hierarchy-${id}`],
    };

    if (parentId) {
      childOf[`hierarchy-${parentId}`] = childOf[`hierarchy-${parentId}`] ?? [];
      childOf[`hierarchy-${parentId}`].push(newTreeElement);
    } else {
      tree.push(newTreeElement);
    }
  }

  return tree;
};

/**
 * Transform a representation of project display configuration provided by Core API into the format expected by this
 * application.
 */
export const getTreeElements = (
  nestedHierarchyItems: NestedProjectHierarchyItem[],
  project: Project,
  depth = 0
): TreeElement[] => {
  const result: TreeElement[] = [];

  for (const { name, children, floorplans, id } of nestedHierarchyItems) {
    const treeElement: TreeElement = { id: `node-${depth}-${id}`, name };

    if (floorplans.length > 0) {
      treeElement.children = [];
      for (const floorplanId of floorplans) {
        const floorplan = project.floorplans.find(({ id }) => id === floorplanId);
        if (!floorplan) {
          console.warn('[treeUtils] Floorplan referenced in hierarchy but not found in server-side floorplan list', {
            projectId: project.id,
            floorplanId,
          });
          continue;
        }

        treeElement.children.push({
          id: `${FLOORPLAN_ID_PREFIX}${floorplan.id}`,
          name: floorplan.name,
        });
      }
    } else if (children && children.length > 0) {
      treeElement.children = getTreeElements(children, project, depth + 1);
    }

    result.push(treeElement);
  }

  return result;
};
