import { AxiosRequestConfig } from 'axios';

import { Milestone } from '../../../@types/api/v1/rest/Milestone';
import { get } from '../../next';

export const MilestoneApi = {
  /**
   * Retrieve the list of supported milestones.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the list of milestones.
   */
  list: (requestConfig?: AxiosRequestConfig) => get<Milestone[]>('api/v1/rest/milestones/', requestConfig),
};
