import axios, { AxiosRequestConfig } from 'axios';

import { ContributingBehavior, Hazard, Trade } from '../../../@types/procore/v1/Company';
import { getProcoreRequestConfiguration } from '../../procore';

export const CompanyApi = {
  /**
   * Retrieve the list of Procore Contributing Behaviors associated with a Procore Company.
   * @see https://developers.procore.com/reference/rest/v1/contributing-behaviors?version=1.0#list-contributing-behaviors
   * @param companyId Unique identifier for the Procore Company.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the list of Procore Contributing Behaviors.
   */
  listContributingBehaviors: (companyId: number, requestConfig?: AxiosRequestConfig) => {
    const baseRequestConfig = getProcoreRequestConfiguration(companyId);

    return axios.get<ContributingBehavior[]>(`/rest/v1.0/companies/${companyId}/contributing_behaviors`, {
      ...baseRequestConfig,
      ...requestConfig,
    });
  },
  /**
   * Retrieve the list of Procore Contributing Conditions associated with a Procore Company.
   * @see https://developers.procore.com/reference/rest/v1/contributing-conditions?version=1.0#list-contributing-conditions
   * @param companyId Unique identifier for the Procore Company.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the list of Procore Contributing Conditions.
   */
  listContributingConditions: (companyId: number, requestConfig?: AxiosRequestConfig) => {
    const baseRequestConfig = getProcoreRequestConfiguration(companyId);

    return axios.get<ContributingBehavior[]>(`/rest/v1.0/companies/${companyId}/contributing_conditions`, {
      ...baseRequestConfig,
      ...requestConfig,
    });
  },
  /**
   * Retrieve the list of Procore Hazards associated with a Procore Company.
   * @see https://developers.procore.com/reference/rest/v1/hazards?version=1.0#list-hazards
   * @param companyId Unique identifier for the Procore Company.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the list of Procore Hazards.
   */
  listHazards: (companyId: number, requestConfig?: AxiosRequestConfig) => {
    const baseRequestConfig = getProcoreRequestConfiguration(companyId);

    return axios.get<Hazard[]>(`/rest/v1.0/companies/${companyId}/hazards`, {
      ...baseRequestConfig,
      ...requestConfig,
    });
  },
  /**
   * Retrieve the list of Procore Trades associated with a Procore Company.
   * @see https://developers.procore.com/reference/rest/v1/trades?version=1.0#list-trades
   * @param companyId Unique identifier for the Procore Company.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the list of Procore Trades.
   */
  listTrades: (companyId: number, requestConfig?: AxiosRequestConfig) => {
    const baseRequestConfig = getProcoreRequestConfiguration(companyId);

    return axios.get<Trade[]>(`/rest/v1.0/companies/${companyId}/trades`, {
      ...baseRequestConfig,
      ...requestConfig,
    });
  },
};
