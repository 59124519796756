import classNames from 'classnames';
import { useEffect, useState } from 'react';

import classes from './LoadingIndicator.module.scss';

export interface LoadingIndicatorProps {
  className?: string;
  /** Time (in milliseconds) to wait before rendering the loading indicator. */
  delay?: number;
  /** Render the loading indicator at full page height. */
  fullPage?: boolean;
}

const LoadingIndicator = (props: LoadingIndicatorProps) => {
  const { className, delay = 0, fullPage = false } = props;

  // Loading indicator is initially visible if delay is falsey.
  const [isVisible, setIsVisible] = useState(!delay);

  useEffect(() => {
    if (!delay) {
      setIsVisible(true);
      return undefined;
    }

    setIsVisible(false);
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, delay);

    return () => clearTimeout(timeout);
  }, [delay]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={classNames(classes.loadingIndicator, { [classes.fullPage]: fullPage }, className)}>
      <img alt="Loading..." className={classes.spinner} src="/resources/loading-animation.gif" />
    </div>
  );
};

export default LoadingIndicator;
