import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  Select,
  SimpleGrid,
  Textarea,
} from '@chakra-ui/react';
import { Select as MultiSelect } from 'chakra-react-select';
import { format, parseISO } from 'date-fns';
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import { ChangeEvent, ForwardedRef, forwardRef } from 'react';
import * as Yup from 'yup';

import { CostCodeCompact } from '../../../@types/procore/v1/CostCode';
import {
  Location,
  PotentialRfiAssignee,
  PotentialRfiReceivedFrom,
  PotentialRfiResponsibleContractor,
} from '../../../@types/procore/v1/Project';
import { RfiCreateRequestBody } from '../../../@types/procore/v1/Rfi';
import { SpecificationSection } from '../../../@types/procore/v1/SpecificationSection';
import AddAttachment from '../../../components/AddAttachment';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { ChevronDownIcon } from '../../../components/Icon';
import { ClearIndicator, Control, DropdownIndicator } from '../../../components/Select/ChakraReactSelectOverrides';
import theme from '../../../theme';

interface SingleSelectOption {
  label: string;
  value?: string;
}

interface MultiSelectOption {
  id: number;
  label: string;
  value: string;
}

export type ProcoreRfiFormValues = Pick<
  RfiCreateRequestBody['rfi'],
  | 'subject'
  | 'assignee_ids'
  | 'due_date'
  | 'responsible_contractor_id'
  | 'received_from_login_information_id'
  | 'drawing_number'
  | 'location_id'
  | 'cost_code_id'
  | 'specification_section_id'
  | 'private'
  | 'reference'
> &
  Pick<RfiCreateRequestBody['rfi']['question'], 'attachments' | 'body'>;

export interface ProcoreRfiFormProps {
  /** Procore Assignee options to show in the form. */
  assignees?: PotentialRfiAssignee[];
  /** Procore Cost Code options to show in the form. */
  costCodes?: CostCodeCompact[];
  /** Attachments to be used as the initial values in the form. */
  defaultAttachments: {
    /** File data. */
    file: File;
    /** Image URL used to preview the file contents. */
    image?: string;
  }[];
  /** Procore Location options to show in the form. */
  locations?: Location[];
  /** Handler to call when the user submits the form without validation errors. */
  onSubmit: (values: ProcoreRfiFormValues) => void;
  /** Procore Received Froms to show in the form. */
  receivedFroms?: PotentialRfiReceivedFrom[];
  /** Procore Responsible Contractors to show in the form. */
  responsibleContractors?: PotentialRfiResponsibleContractor[];
  /** Procore Specification Sections to show in the form. */
  specificationSections?: SpecificationSection[];
}

const validationSchema = Yup.object({
  subject: Yup.string().required('Subject is required'),
  assignee_ids: Yup.array(Yup.number()).required('Assignee is required').min(1, 'Select at least one assignee'),
  body: Yup.string().required('Question is required'),
  attachments: Yup.array(Yup.mixed<File>()),
  due_date: Yup.string().nullable(),
  responsible_contractor_id: Yup.number().nullable(),
  received_from_login_information_id: Yup.number().nullable(),
  drawing_number: Yup.string().nullable(),
  location_id: Yup.number().nullable(),
  cost_code_id: Yup.number().nullable(),
  specification_section_id: Yup.number().nullable(),
  reference: Yup.string().nullable(),
  private: Yup.boolean(),
});

const ProcoreRfiForm = forwardRef(
  (props: ProcoreRfiFormProps, ref: ForwardedRef<FormikProps<ProcoreRfiFormValues>>) => {
    const {
      assignees = [],
      costCodes = [],
      defaultAttachments,
      locations = [],
      onSubmit,
      receivedFroms = [],
      responsibleContractors = [],
      specificationSections = [],
    } = props;

    /**
     * Initial values for the form. In order for the `subject` and `body` fields to be detected on a submission of the
     * form without touching any fields, their initial values need to be set to something.
     */
    const initialValues: ProcoreRfiFormValues = {
      subject: '',
      assignee_ids: [],
      body: `[This RFI was made with OnsiteIQ. Check it out here: ${window.location.origin}${window.location.pathname}]`,
      attachments: defaultAttachments.map((a) => a.file),
      private: false,
      drawing_number: '',
      reference: '',
    };

    const emptyOption: SingleSelectOption = { label: '' };

    const locationOptions: SingleSelectOption[] = [emptyOption].concat(
      locations.map((location) => ({
        label: location.name,
        value: location.id.toString(),
      }))
    );

    const assigneeOptions: MultiSelectOption[] = assignees.map((assignee) => ({
      id: assignee.id,
      label: assignee.name,
      value: assignee.id.toString(),
    }));

    const specSectionOptions: SingleSelectOption[] = [emptyOption].concat(
      specificationSections.map((specificationSection) => ({
        label: specificationSection.label,
        value: specificationSection.id.toString(),
      }))
    );

    const costCodeOptions: SingleSelectOption[] = [emptyOption].concat(
      costCodes.map((costCode) => ({
        label: `${costCode.full_code} - ${costCode.name}`,
        value: costCode.id.toString(),
      }))
    );

    const responsibleContractorOptions: SingleSelectOption[] = [emptyOption].concat(
      responsibleContractors.map((responsibleContractor) => ({
        label: responsibleContractor.name,
        value: responsibleContractor.id.toString(),
      }))
    );

    const receivedFromOptions: SingleSelectOption[] = [emptyOption].concat(
      receivedFroms.map((receivedFrom) => ({
        label: receivedFrom.name,
        value: receivedFrom.id.toString(),
      }))
    );

    const toId = (id?: string) => (id ? Number(id) : undefined);

    return (
      <Formik
        innerRef={ref}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur
        validationSchema={validationSchema}
      >
        <Form>
          <SimpleGrid columns={{ base: 1, md: 2 }} gap="1rem" paddingBlockEnd="1rem">
            <Field name="subject">
              {({ field, meta }: FieldProps<string, ProcoreRfiFormValues>) => (
                <GridItem colSpan={{ base: 1, md: 2 }}>
                  <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                    <FormLabel>Subject</FormLabel>
                    <Input {...field} />
                    <ErrorMessage component={FormErrorMessage} name={field.name} />
                  </FormControl>
                </GridItem>
              )}
            </Field>
            <Field name="assignee_ids">
              {({ field, form, meta }: FieldProps<number[], ProcoreRfiFormValues>) => (
                <GridItem colSpan={{ base: 1, md: 2 }}>
                  <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                    <FormLabel>Assignee(s)</FormLabel>
                    <MultiSelect
                      {...field}
                      chakraStyles={{
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? 'var(--primary-100)' : 'white',
                          color: 'var(--gray-800)',
                        }),
                      }}
                      classNamePrefix="chakra-react-select"
                      colorScheme="purple"
                      components={{ ClearIndicator, Control, DropdownIndicator }}
                      isMulti
                      menuPlacement="top"
                      onBlur={() => form.setFieldTouched(field.name, true)}
                      onChange={(nextAssignees) =>
                        form.setFieldValue(
                          field.name,
                          nextAssignees.map(({ id }) => id)
                        )
                      }
                      options={assigneeOptions}
                      placeholder="Select assignee(s)"
                      selectedOptionStyle="color"
                      selectedOptionColor="purple"
                      useBasicStyles
                      value={assigneeOptions.filter(({ id }) => field.value.includes(Number(id)))}
                    />
                    <ErrorMessage component={FormErrorMessage} name={field.name} />
                  </FormControl>
                </GridItem>
              )}
            </Field>
            <Field name="body">
              {({ field, meta }: FieldProps<string, ProcoreRfiFormValues>) => (
                <GridItem colSpan={{ base: 1, md: 2 }}>
                  <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                    <FormLabel>Question</FormLabel>
                    <Textarea {...field} rows={6} />
                    <ErrorMessage component={FormErrorMessage} name={field.name} />
                  </FormControl>
                </GridItem>
              )}
            </Field>
            <Field name="attachments">
              {({ field, form, meta }: FieldProps<File[], ProcoreRfiFormValues>) => (
                <GridItem colSpan={{ base: 1, md: 2 }}>
                  <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                    <FormLabel>Attachments</FormLabel>
                    <AddAttachment
                      defaultValue={defaultAttachments}
                      {...field}
                      onChange={(attachments) => form.setFieldValue(field.name, attachments)}
                    />
                    <ErrorMessage component={FormErrorMessage} name={field.name} />
                  </FormControl>
                </GridItem>
              )}
            </Field>
          </SimpleGrid>
          <Accordion allowToggle reduceMotion>
            <AccordionItem>
              <AccordionButton fontSize="1rem" paddingInline="0.125rem">
                More Options
                <AccordionIcon as={ChevronDownIcon} color={theme.colors.brand.gray[600]} marginInlineStart="0.25rem" />
              </AccordionButton>
              <AccordionPanel paddingInline={0}>
                <SimpleGrid columns={{ base: 1, md: 2 }} gap="1rem" paddingBlockEnd="1rem">
                  <Field name="due_date">
                    {({ field, form, meta }: FieldProps<string, number>) => (
                      <GridItem>
                        <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                          <FormLabel>Due Date</FormLabel>
                          <DatePicker
                            {...field}
                            buttonProps={{
                              'aria-label': 'Due Date',
                              fontSize: '0.875rem',
                              justifyContent: 'flex-start',
                              minWidth: '100%',
                            }}
                            onChange={(date) =>
                              form.setFieldValue(field.name, date ? format(date, 'yyyy-MM-dd') : null)
                            }
                            popoverPlacement="top-end"
                            selected={field.value ? parseISO(field.value) : null}
                            value={field.value}
                          />
                          <ErrorMessage component={FormErrorMessage} name={field.name} />
                        </FormControl>
                      </GridItem>
                    )}
                  </Field>
                  <Field name="responsible_contractor_id">
                    {({ field, form, meta }: FieldProps<string, ProcoreRfiFormValues>) => (
                      <GridItem>
                        <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                          <FormLabel>Responsible Contractor</FormLabel>
                          <Select
                            {...field}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                              form.setFieldValue(field.name, toId(event.target.value))
                            }
                          >
                            {responsibleContractorOptions.map(({ label, value }) => (
                              <option key={`responsible-contractor-option-${value}`} value={value}>
                                {label}
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage component={FormErrorMessage} name={field.name} />
                        </FormControl>
                      </GridItem>
                    )}
                  </Field>
                  <Field name="received_from_login_information_id">
                    {({ field, form, meta }: FieldProps<string, ProcoreRfiFormValues>) => (
                      <GridItem>
                        <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                          <FormLabel>Received From</FormLabel>
                          <Select
                            {...field}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                              form.setFieldValue(field.name, toId(event.target.value))
                            }
                          >
                            {receivedFromOptions.map(({ label, value }) => (
                              <option key={`received-from-option-${value}`} value={value}>
                                {label}
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage component={FormErrorMessage} name={field.name} />
                        </FormControl>
                      </GridItem>
                    )}
                  </Field>
                  <Field name="drawing_number">
                    {({ field, meta }: FieldProps<string, ProcoreRfiFormValues>) => (
                      <GridItem>
                        <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                          <FormLabel>Drawing Number</FormLabel>
                          <Input {...field} />
                          <ErrorMessage component={FormErrorMessage} name={field.name} />
                        </FormControl>
                      </GridItem>
                    )}
                  </Field>
                  <Field name="location_id">
                    {({ field, form, meta }: FieldProps<number, ProcoreRfiFormValues>) => (
                      <GridItem>
                        <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                          <FormLabel>Location</FormLabel>
                          <Select
                            {...field}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                              form.setFieldValue(field.name, toId(event.target.value))
                            }
                          >
                            {locationOptions.map(({ label, value }) => (
                              <option key={`location-options-${value}`} value={value}>
                                {label}
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage component={FormErrorMessage} name={field.name} />
                        </FormControl>
                      </GridItem>
                    )}
                  </Field>
                  <Field name="cost_code_id">
                    {({ field, form, meta }: FieldProps<number, ProcoreRfiFormValues>) => (
                      <GridItem>
                        <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                          <FormLabel>Cost Code</FormLabel>
                          <Select
                            {...field}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                              form.setFieldValue(field.name, toId(event.target.value))
                            }
                          >
                            {costCodeOptions.map(({ label, value }) => (
                              <option key={`cost-code-options-${value}`} value={value}>
                                {label}
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage component={FormErrorMessage} name={field.name} />
                        </FormControl>
                      </GridItem>
                    )}
                  </Field>
                  <Field name="specification_section_id">
                    {({ field, form, meta }: FieldProps<number, ProcoreRfiFormValues>) => (
                      <GridItem>
                        <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                          <FormLabel>Spec Section</FormLabel>
                          <Select
                            {...field}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                              form.setFieldValue(field.name, toId(event.target.value))
                            }
                          >
                            {specSectionOptions.map(({ label, value }) => (
                              <option key={`specification-section-options-${value}`} value={value}>
                                {label}
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage component={FormErrorMessage} name={field.name} />
                        </FormControl>
                      </GridItem>
                    )}
                  </Field>
                  <Field name="reference">
                    {({ field, meta }: FieldProps<string, ProcoreRfiFormValues>) => (
                      <GridItem>
                        <FormControl isInvalid={meta.touched && Boolean(meta.error)}>
                          <FormLabel>Reference</FormLabel>
                          <Input {...field} />
                          <ErrorMessage component={FormErrorMessage} name={field.name} />
                        </FormControl>
                      </GridItem>
                    )}
                  </Field>
                  <Field name="private">
                    {({ field, meta }: FieldProps<string, ProcoreRfiFormValues>) => (
                      <GridItem colSpan={{ base: 1, md: 2 }}>
                        <Checkbox {...field} isInvalid={meta.touched && Boolean(meta.error)}>
                          Private
                        </Checkbox>
                        <ErrorMessage component={FormErrorMessage} name={field.name} />
                      </GridItem>
                    )}
                  </Field>
                </SimpleGrid>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Form>
      </Formik>
    );
  }
);

export default ProcoreRfiForm;
